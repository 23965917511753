import { MultiSelectField } from './components/multiselect'
import { SelectField } from './components/select'
import { DatePickerField } from "./components/datePicker";
import { TextAreaField } from "./components/textArea";
import { CheckboxField } from "./components/checkbox";
import { InputField } from "./components/input";
import { ToggleField } from './components/toggle';
import { RadioGroupField } from './components/radioGroup';


export const FIELD_COMPONENT_MAP = {
    "input": InputField,
    "multiselect": MultiSelectField,
    "select": SelectField,
    "datePicker": DatePickerField,
    "textArea": TextAreaField,
    "checkbox": CheckboxField,
    "toggle": ToggleField,
    "radio": RadioGroupField
};


