import React from "react";
import { DatePicker } from '../../Formik-Cloudscape/components/date-picker/date-picker';
import { FormField } from '../../Formik-Cloudscape/components/form-field/form-field';
import { IDynamicFieldProps } from "../Interfaces";

export const DatePickerField = ({ name, readOnly, label, action }: IDynamicFieldProps) => {
    return (
        <>
            <FormField stretch label={label} name="input">
                <DatePicker name={name}
                    ariaLabel={name}
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    readOnly={readOnly || false}
                    onChange={({ detail }) => {
                        action(detail.value, name);
                    }}
                />
            </FormField>
        </>
    )
}