import React, { useEffect, useRef, useState } from 'react';
import { ColDef, GridOptions, ColGroupDef, EditableCallbackParams, ValueSetterParams } from "ag-grid-enterprise";
import { Alert, Badge, Box, Button, Modal, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { Flashbar } from '@amzn/awsui-components-react';
import { IChangedCells, ICustomSettings } from '../../../common/GridTable/GridTableInterface';
import { GridTable } from '../../../common/GridTable/GridTable';
import ScreenUtils from '../../utils/screenUtils';
import { ALL_ASSIGNEES_KEY, MUTATION_METHODS, NA_LABEL, STATUS_CODES, USER_ACTION_ROLES } from '../../constants/constants';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import { useAppSelector } from 'src/components/redux/hooks';
import { selectVersions, selectAttrsOrder } from './versionSlice';
import { useGetProgAttrOrderQuery, useGetVersionsQuery, useRemoveVersionMutation, useUpdateVersionsMutation } from '../../services/apis/api';
import { ALERT_MESSAGES } from '../../constants/userMessages';
import { selectAttributesServerData } from '../../../RPT/features/attributes/attributesSlice';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import useNotification from 'src/components/Hooks/notifications';
import VersionHelper from './versionHelper';
import RemoveBtnRenderer from './removeBtnRenderer';
import { getSku } from '../sku/skuSlice';

interface IVersionsTableProps {
    hasWriteAccess: boolean;
}

export const VersionsTable = ({ hasWriteAccess }: IVersionsTableProps) => {
    const { program } = useAppSelector(selectLocationData);
    const versionsData = useAppSelector(selectVersions);
    const attrOrder = useAppSelector(selectAttrsOrder);
    const attrValues = useAppSelector(selectAttributesServerData).allAttributesValues;
    const { SKUs } = useAppSelector(getSku);
    const { getVersionDescription, getGraphQLPayload, getUserId } = ScreenUtils;
    const { getDuplicates, getMissingAttributes, generateMissingAttrPayload, getUpdatedIdDataMap } = VersionHelper();

    const { isLoading: verisonLoading, isFetching: versionFetching, refetch } = useGetVersionsQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const { isLoading: attrLoading, isFetching: attrFetching } = useGetProgAttrOrderQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const [updateVersion, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateVersionsMutation();
    const [removeVersion] = useRemoveVersionMutation();

    const [missingAttr, setMissingAttr] = useState<string[]>([]);
    const [prepareInitialized, setPrepareInitialized] = useState<boolean>(false);
    const [duplicateVersion, setDuplicateVersion] = useState<string[]>([]);
    const { notificationItems, addNotificationItem } = useNotification();
    const [tableKey, setTableKey] = useState(0);
    const [updatedEvent, setUpdatedEvent] = useState<Record<string, IChangedCells>>({});
    const [visible, setVisible] = useState(false);

    const assignedVersions = useRef<number[]>([]);

    const getCustomSettings = (): ICustomSettings => {
        return {
            className: 'ag-theme-alpine',
            uid: "concat_string",
            readOnly: false,
            allowLocalFilters: true,
            filterId: 'versions',
            rowHeight: 30,
            defaultAutoSizeAll: true,
            allowAddRow: true,
            numAddRows: 1,
            enableOnChangeHighlight: true
        } as ICustomSettings;
    };

    const [customSettings, setCustomSettings] = useState<ICustomSettings>(getCustomSettings());
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [],
        rowData: []
    });

    useEffect(() => {
        SKUs?.forEach((sku) => {
            if (sku.version_id && sku.pn) assignedVersions.current.push(sku.version_id);
        });
    }, [SKUs]);

    useEffect(() => {
        if (!versionFetching && !verisonLoading) {
            setMissingAttr(getMissingAttributes(versionsData, attrOrder));
            setDuplicateVersion(getDuplicates(versionsData));
        }
    }, [versionsData, attrOrder, versionFetching, verisonLoading]);

    useEffect(() => {
        setPrepareInitialized(true);
        const items = Object.values(versionsData).map(obj => {
            return { ...obj };
        });
        setGridOptions((prevState: any) => {
            return {
                ...prevState,
                rowData: items
            };
        });
        setTableKey(tableKey + 1);
    }, [versionsData]);

    const removeVersionsHandler = async (version_id: number) => {
        return removeVersion({ "version_id": version_id });
    };

    useEffect(() => {
        setCustomSettings((prev) => ({
            ...prev,
            isSaveDisabled: attrOrder.length ? false : true,
            isAddRowDisabled: attrOrder.length ? false : true
        }));
        const colDefs: (ColGroupDef | ColDef)[] = [
            {
                headerName: "Version id",
                field: "version_id",
                editable: false,
                hide: true
            },
            {
                headerName: 'Description',
                field: 'description',
                editable: false,
                tooltipField: 'rowId',
                tooltipComponent: () => {
                    return (<Badge> Readonly </Badge>);
                }
            },
            ...attrOrder.map((attr: string) => {
                return {
                    field: attr,
                    headerName: attr,
                    editable: (params: EditableCallbackParams) => {
                        const vId = params.data?.version_id;
                        return ((vId && ((params.data[attr]?.length && !params.data.updatedCells?.has(params.colDef.field))) || assignedVersions.current.includes(vId))) ? false : true;
                    },
                    valueSetter: (params: ValueSetterParams) => {
                        params.data[attr] = params.newValue;
                        params.data.description = getVersionDescription(params.data, attrOrder);
                        return true;
                    },
                    suppressPaste: true,
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        values: attrValues[attr] ?? []
                    }
                };
            }),
            {
                headerName: "Remove",
                field: "version_id",
                editable: false,
                cellRenderer: RemoveBtnRenderer,
                cellRendererParams: {
                    removeVersionsHandler,
                    addNotificationItem,
                    assignedVersions: assignedVersions.current,
                    hasWriteAccess
                }
            }
        ];
        setGridOptions((prevState: any) => {
            return {
                ...prevState,
                columnDefs: colDefs
            };
        });
    }, [attrOrder, attrValues, hasWriteAccess]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) addNotificationItem({
                type: "error",
                dismissible: true,
                content: ALERT_MESSAGES.versionError,
                id: "VERSION_SAVE_FAILED"
            });
            if (mutationData.data && mutationData?.data?.[MUTATION_METHODS.updateAgmVersions]) {
                const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateAgmVersions];
                statusCode === STATUS_CODES.success ? addNotificationItem({
                    type: "success",
                    dismissible: true,
                    content: ALERT_MESSAGES.versionSuccess,
                    id: "VERSION_SAVE_SUCCESS"
                }) : addNotificationItem({
                    type: "error",
                    dismissible: true,
                    content: statusCode === STATUS_CODES.handledException ? error : ALERT_MESSAGES.versionError,
                    id: "VERSION_SAVE_FAILED"
                });
            }
        }
    }, [mutationData, mutationError]);

    const onSave = async (event: Record<string, IChangedCells>) => {
        console.log(event); // console added for beta testing
        const updatedRows = getUpdatedIdDataMap(event, versionsData);
        const missingAttr = getMissingAttributes(updatedRows, attrOrder);
        const duplicate = getDuplicates(updatedRows);
        setMissingAttr(missingAttr);
        setDuplicateVersion(duplicate);
        if (!duplicate.length) {
            setUpdatedEvent(event);
            if (missingAttr.length > 0) {
                setVisible(true);
            } else if (Object.keys(event).length > 0) updateVersions(event);
        }
    };

    const updateVersions = async (event: Record<string, IChangedCells>) => {
        setVisible(false);
        const updatedRows = getUpdatedIdDataMap(event, versionsData);
        // Save all existing versions missing values with N/A
        const requestData: Record<string, any> = generateMissingAttrPayload(updatedRows, attrOrder);
        if (event && Object.keys(event).length > 0) {
            Object.values(event).forEach((cell) => {
                const cellId = cell.row.version_id ?? cell.row.rowId;
                // Adding new rows
                if (!cell.row.version_id && !requestData[cellId]) {
                    requestData[cellId] = attrOrder.map(item => {
                        return {
                            attribute_type: item,
                            attribute_value: cell.row[item] ?? NA_LABEL
                        };
                    });
                }
                // Updating rows
                if (cell.row.version_id && cell.colId) {
                    const newItem = { attribute_type: cell.colId, attribute_value: cell.newValue, version_id: cell.row.version_id };
                    if (cellId in requestData) {
                        requestData[cellId].push(newItem);
                    } else requestData[cellId] = [newItem];
                }
            });
        }
        const records = Object.values(requestData);
        if (records.length) {
            const payload = {
                user_id: getUserId(),
                verity_rpn_id: parseInt(program.id),
                versions: records
            };
            await updateVersion(getGraphQLPayload(payload)).unwrap();
        }
    };

    return (
        <div key={JSON.stringify(gridOptions.columnDefs)}>
            <SpaceBetween direction="vertical" size="xl">
                {hasWriteAccess && <SpaceBetween direction="vertical" size="xl">
                    {missingAttr.length > 0 && <Alert
                        statusIconAriaLabel="Error"
                        type="warning"
                        header=""
                    >
                        These attributes are missing values and will be populated with N/A:
                        <div><strong>{missingAttr.join(', ')}</strong></div>
                    </Alert>}
                    {duplicateVersion.length > 0 && <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header=""
                    >
                        Duplicate versions are not allowed : {duplicateVersion.map(item => {
                            return (<div>{item}</div>);
                        })}
                    </Alert>}
                    {attrOrder.length === 0 && <Alert
                        statusIconAriaLabel="Warning"
                        type="warning"
                    >
                        Attribute order not set. Please click "Modify attribute" button and save order.
                    </Alert>}
                </SpaceBetween>}
                <Flashbar items={notificationItems} stackItems />

                {(!mutationLoading && !verisonLoading && !versionFetching && !attrLoading && !attrFetching) ?
                    <GridTable
                        key={tableKey}
                        gridSettings={gridOptions}
                        customSettings={{
                            ...customSettings,
                            prepareInitialized,
                            allowAddRow: hasWriteAccess
                        }}
                        onSave={hasWriteAccess ? onSave : undefined}
                        onRefresh={refetch}
                    /> :
                    <div className='loadspinner'><Spinner size="large" /></div>
                }
            </SpaceBetween>
            {visible && <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                header='Confirm'
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>
                                Cancel
                            </Button>
                            <Button className='bg-primary' variant="primary" onClick={() => updateVersions(updatedEvent)}>Save</Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                These attributes are missing values and will be populated with N/A:
                {missingAttr.map(attr => {
                    return (<div><strong>{attr}</strong></div>);
                })}

            </Modal>}
        </div>

    );
};
