export default class Queries {
    public static readonly getAttrTypeMaster = () => {
        return `query  ($is_deprecated: Boolean){
            get_attr_type_master (is_deprecated: $is_deprecated) {
                body {
                    attribute_type
                    is_std_attr
                    id
                }
                error
                statusCode
            }
        }`;
    }

    public static readonly getAttrListMaster = () => {
        return `query ($is_deprecated: Boolean) {
            get_attr_list_master (is_deprecated: $is_deprecated) {
                body {
                    attribute_type
                    attribute_value
                    id
                }
                error
                statusCode
            }
        }`;
    }

    public static readonly getAttrTypePL = () => {
        return `query ($is_deprecated: Boolean, $verity_product_line_id: Int) {
            get_attribute_type_pl (is_deprecated: $is_deprecated, verity_product_line_id: $verity_product_line_id) {
                body {
                    attribute_type
                    pl_attr_order
                    in_fpn
                    id
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getAttrTypeRPN = () => {
        return `query ($is_deprecated: Boolean, $verity_rpn_id: Int) {
            get_attr_type_rpn (is_deprecated: $is_deprecated, verity_rpn_id: $verity_rpn_id) {
                body {
                    attribute_type
                    rpn_attr_order
                    in_fpn
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getAttrListRPN = () => {
        return `query ($is_deprecated: Boolean, $verity_rpn_id: Int!) {
            get_attr_list_rpn (is_deprecated: $is_deprecated, verity_rpn_id: $verity_rpn_id) {
                body {
                    attribute_type
                    attribute_value
                    asin_count
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getAttrListFPN = () => {
        return `query ($is_deprecated: Boolean, $verity_fpn_id: Int!) {
            get_attr_list_fpn (is_deprecated: $is_deprecated, verity_fpn_id: $verity_fpn_id) {
                body {
                    attribute_type
                    attribute_value
                    asin_count
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getPlFPNRule = () => {
        return `query ($is_deprecated: Boolean, $verity_product_line_id: Int) {
            get_pl_fpn_rule (is_deprecated: $is_deprecated, verity_product_line_id: $verity_product_line_id) {
                body {
                    attribute_type
                    concat_order
                    is_required
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getRpnFPNRule = () => {
        return `query ($is_deprecated: Boolean, $verity_rpn_id: Int) {
            get_rpn_fpn_rule (is_deprecated: $is_deprecated, verity_rpn_id: $verity_rpn_id) {
                body {
                    attribute_type
                    concat_order
                    is_required
                }
                error
                statusCode
              }
        }`;
    }
}