import { ALL_ASSIGNEES_KEY, USER_ROLES } from '../../constants/constants';

export default () => {
    // This method is used for product line & program screens only. If multiple roles assigned then PL admin takes precedent
    const getUserRole = (roleResMap: any, resources: string[]) => {
        if (roleResMap && Object.keys(roleResMap).length) {
            const roles = Object.values(USER_ROLES).filter((role) => roleResMap[role]?.some((res: string) => resources.includes(res)));
            if (roles.includes(USER_ROLES.plAdmin)) return USER_ROLES.plAdmin;
            if (roles.length) roles[0];
        }
        return USER_ROLES.readonly;
    };

    const getProgAssignees = (roleResMap: any, role: string, tab: string, section: string) => {
        const obj = role && tab ? section ? roleResMap[role]?.[tab]?.[section] : roleResMap[role]?.[tab]?.[ALL_ASSIGNEES_KEY] : null;
        return obj?.ItemValues ?? null;
    };

    const getPLAssignees = (roleResMap: any, role: string, tab: string, section: string) => {
        const obj = role && tab ? section ? roleResMap[role]?.[tab]?.[section] : roleResMap[role]?.[tab]?.[ALL_ASSIGNEES_KEY] : null;
        return obj?.ItemValues ?? null;
    };

    const getProgAssigneesRespObj = (roleResMap: any, role: string, tab: string, section: string) => {
        return role && tab ? section ? roleResMap[role]?.[tab]?.[section] : roleResMap[role]?.[tab]?.[ALL_ASSIGNEES_KEY] : {};
    };

    return { getUserRole, getProgAssignees, getPLAssignees, getProgAssigneesRespObj };
};
