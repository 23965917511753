import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";
import DeleteCCExpLineRenderer from './cellRenderer/deleteCCExpLineRenderer';
import { tableNames } from '../../constants/constants';

export const REQUIRED = {
    'cost_center_code': 'Cost Center Code',
    'budget_type': 'Budget Type',
    'line_item': 'Line item'
};
export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Cost Center Code",
        field: "cost_center_code",
        editable: GridValidation.existingRecord,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Budget Type",
        field: "budget_type",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Line item",
        field: "line_item",
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Line item Description",
        field: "line_item_description",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "",
        field: "id",
        editable: false,
        maxWidth: 50, 
        cellRenderer: DeleteCCExpLineRenderer,
        cellRendererParams: {
            tableName: tableNames.ccExpLine
        }
    }
];