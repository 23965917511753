import React from 'react';
import { Box, Button, Grid, Header, Icon, Table, TableProps } from "@cloudscape-design/components";
import { ATTRIBUTES_ACTION } from '../../constants/constants';

export interface IPLAttributes {
    id: number,
    attribute: string,
}

export interface IAttributeTableProps {
    setTableItems: any,
    tableItems: IPLAttributes[]
}

export const AttributeOrderingTable = ({ setTableItems, tableItems }: IAttributeTableProps) => {

    const columnDefinitionsEdit: TableProps.ColumnDefinition<IPLAttributes>[] = [
        {
            id: 'id',
            header: '#',
            cell: (item: IPLAttributes) => item.id,
            maxWidth: 30
        },
        {
            id: 'attributes ',
            header: 'Attributes',
            cell: (item: IPLAttributes) => item.attribute,
            minWidth: 200
        },
        {
            id: 'Actions',
            cell: (item: IPLAttributes) => (
                <>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <div >
                            <Button disabled={item.id > 1 ? false : true} onClick={() => reorder(item.id, ATTRIBUTES_ACTION.up)} variant="link" > Move up <Icon name="angle-up" /></Button>
                        </div>
                        <div className='border-left'>
                            <Button disabled={item.id >= tableItems.length ? true : false} onClick={() => reorder(item.id, ATTRIBUTES_ACTION.down)} variant="link" > Move down <Icon name="angle-down" /></Button>
                        </div>
                    </Grid>
                </>

            ),
            header: 'Actions',
            width: 400
        }
    ];

    const reorder = (id: number, action: string) => {
        const index = id - 1;
        const items = [...tableItems];
        const actions: Record<string, () => void> = {
            up: () => [items[index], items[index - 1]] = [{ ...items[index - 1], id: index + 1 }, { ...items[index], id: index }],
            down: () => [items[index], items[index + 1]] = [{ ...items[index + 1], id: index + 1 }, { ...items[index], id: index + 2 }]
        };
        actions[action]();
        setTableItems(items);
    };


    return (
        <Table
            columnDefinitions={columnDefinitionsEdit}
            items={tableItems}
            sortingDisabled
            variant="embedded"
            resizableColumns={false}
            header={
                <Header variant='h3'>
                    Order
                </Header>
            }
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    Attributes doesn't exists
                </Box>
            }
        ></Table>
    );
};
