import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormField, Multiselect, Select, SelectProps, SpaceBetween, MultiselectProps, DateRangePickerProps, Button, Grid } from "@amzn/awsui-components-react";
import { useAppSelector } from '../../../../redux/hooks';
import { selectAllProductLines, selectCountryMap, selectProductHierarchy } from '../../appLayout/appLayoutSlice';
import { useGetPRMChartResourcesQuery } from 'src/components/RPT/services/apis/ganttChartApi';
import { IApiProductHierarchy, IApiRPNResponse, IPRMFormResources, IPRMScenarios } from 'src/components/RPT/interfaces/interfaces';
import { useGetPRMScenariosQuery } from 'src/components/RPT/services/apis/scenarioSnapshotApi';
import { IApiResponse } from 'src/components/Interfaces/interface';
import { FIELD_NAMES } from 'src/components/RPT/constants/fieldData';
import { DateRangePickerField } from 'src/components/common/Components/DateRangePicker/dateRangePicker';
import { ATTRIBUTE_TYPE_NAMES, SUBMIT_ALL_OPTION } from 'src/components/RPT/constants/constants';
import { useGetCountryNamesQuery } from 'src/components/redux/services/commonApi';
import { selectAttributeTypeListMap } from '../../attributes/attributesSlice';

export interface IFilters {
    productLine: SelectProps.Option | null;
    programs: MultiselectProps.Option[];
    snroId: SelectProps.Option | null;
    dateRange: DateRangePickerProps.Value | null,
    captionTitle: MultiselectProps.Option[];
    captionSubtitle: MultiselectProps.Option[];
    groupBy: SelectProps.Option | null;
    country: SelectProps.Option | null;
}

export interface IInputPanel {
    generate: (value: IFilters) => void;
    loading: boolean;
}

export const InputPanel = ({ generate, loading }: IInputPanel) => {
    useGetCountryNamesQuery();
    const prodHierarchy = useAppSelector(selectProductHierarchy);
    const countryNames = useAppSelector(selectCountryMap);
    const { data: prmResources, isError: resError, isLoading: resLoading, isFetching: resFetching } = useGetPRMChartResourcesQuery();
    const { data: scenarioData, isError: snroErr, isLoading: snrLoading, isFetching: snrFetch } = useGetPRMScenariosQuery();
    const allTypeToValues = useAppSelector(selectAttributeTypeListMap);

    const [plOpts, setPlOpts] = useState<SelectProps.Option[]>([]);
    const [plRpnOptionsMap, setPlRpnOptionsMap] = useState<Record<string, MultiselectProps.Option[]>>({});
    const [snroOpts, setSnroOpts] = useState([]);
    const [allResOpts, setAllResOpts] = useState<SelectProps.Option[]>([]);
    const [cTitleOpts, setCTitleOpts] = useState<SelectProps.Option[]>([]);
    const [cSubTitleOpts, setCSubTitleOpts] = useState<SelectProps.Option[]>([]);
    const [grupByOpts, setGroupByOpts] = useState<SelectProps.Option[]>([]);
    const [countryOpts, setCountryOpts] = useState<SelectProps.Option[]>([]);
    const [formFilters, setFormFilters] = useState<IFilters>({
        productLine: null,
        programs: [],
        snroId: null,
        dateRange: {
            key: "Next 3 years",
            amount: 3,
            unit: "year",
            type: "relative"
        },
        captionTitle: [],
        captionSubtitle: [],
        groupBy: null,
        country: null
    });
    const nonTitleFields = [FIELD_NAMES.programDesc, FIELD_NAMES.ContactAddInfo, FIELD_NAMES.comments, FIELD_NAMES.majorCallOuts];

    useEffect(() => {
        if (prodHierarchy) {
            const plRpnOptionsMap: Record<string, MultiselectProps.Option[]> = {};
            const plOpts: MultiselectProps.Option[] = [];
            prodHierarchy.forEach(({ product_line, verity_product_line_id, rpn }: IApiProductHierarchy) => {
                if (product_line && verity_product_line_id) {
                    plOpts.push({ label: product_line, value: verity_product_line_id.toString() });
                    plRpnOptionsMap[verity_product_line_id] = [{ label: SUBMIT_ALL_OPTION, value: SUBMIT_ALL_OPTION }];
                    rpn.forEach(({ rpn, verity_rpn_id }: IApiRPNResponse) => {
                        if (rpn && verity_rpn_id) plRpnOptionsMap[verity_product_line_id].push({ label: rpn, value: verity_rpn_id?.toString() });
                    });
                }
            });
            setPlOpts(plOpts);
            setPlRpnOptionsMap(plRpnOptionsMap);
        }
    }, [prodHierarchy]);

    useEffect(() => {
        const titles = formFilters.captionTitle.map(item => item.value);
        setCSubTitleOpts(allResOpts.filter(item => !titles.includes(item.value)));
        setValue('captionSubtitle', formFilters.captionSubtitle.filter(item => !titles.includes(item.value)));
    }, [formFilters.captionTitle]);

    useEffect(() => {
        if (scenarioData?.data?.get_prm_scenarios) {
            const { body }: IApiResponse = scenarioData.data?.get_prm_scenarios;
            setSnroOpts(body?.map(({ scenario_id, scenario_name }: IPRMScenarios) => {
                return { label: scenario_name, value: scenario_id };
            }) ?? []);
        }
    }, [scenarioData, snroErr]);

    useEffect(() => {
        const opts: MultiselectProps.Option[] = [];
        prmResources?.forEach(({ attribute_id, attribute_label }: IPRMFormResources) => {
            if (attribute_label && attribute_id) opts.push({ label: attribute_label, value: attribute_id.toString() });
        });
        const titleOpts = opts.filter((item: MultiselectProps.Option) => !nonTitleFields.includes(item.label!));
        setCTitleOpts(titleOpts);
        setCSubTitleOpts(opts);
        setGroupByOpts(titleOpts);
        setAllResOpts(opts);
    }, [prmResources]);

    useEffect(() => {
        const opts: MultiselectProps.Option[]  = [];
        const allOptions = allTypeToValues[ATTRIBUTE_TYPE_NAMES.country] ?? [];
        allOptions.forEach(item => opts.push({label: item + ' - ' + countryNames[item], value: item,}));
        setCountryOpts(opts);
    }, [countryNames, allTypeToValues]);

    const setValue = (field: string, value: any) => {
        setFormFilters((prevState: any) => {
            return {
                ...prevState,
                [field]: value
            };
        });
    };
    return (
        <>
            <SpaceBetween direction="vertical" size="xl">

                <Grid gridDefinition={[
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                    { colspan: { s: 3, default: 6 } },
                ]}>
                    <FormField label="Product line">
                        <Select
                            selectedOption={formFilters.productLine}
                            onChange={({ detail }) =>
                                setValue('productLine', detail.selectedOption)
                            }
                            options={plOpts}
                            loadingText="Loading product lines"
                            placeholder='Select product line'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-product-line'
                            statusType={!plOpts.length ? 'loading' : 'finished'}
                        />
                    </FormField>

                    <FormField label="Scenario">
                        <Select
                            selectedOption={formFilters.snroId}
                            onChange={({ detail }) =>
                                setValue('snroId', detail.selectedOption)
                            }
                            options={snroOpts}
                            placeholder='Select scenario'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-snro'
                            statusType={!snroOpts.length ? 'loading' : 'finished'}
                        />
                    </FormField>

                    <FormField label="Date range">
                        <DateRangePickerField
                            setValue={(val) => setValue('dateRange', val)}
                            value={formFilters.dateRange}
                        />
                    </FormField>
                    <FormField label="Group by">
                        <Select
                            selectedOption={formFilters.groupBy}
                            onChange={({ detail }) =>
                                setValue('groupBy', detail.selectedOption)
                            }
                            options={grupByOpts}
                            placeholder='Choose option for group by'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-group-by'
                            statusType={!grupByOpts.length ? 'loading' : 'finished'}
                        />
                    </FormField>
                    <FormField label="Country - optional">
                        <Select
                            selectedOption={formFilters.country}
                            onChange={({ detail }) =>
                                setValue('country', detail.selectedOption)
                            }
                            options={countryOpts}
                            placeholder='Choose option country'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-country'
                            statusType={!grupByOpts.length ? 'loading' : 'finished'}
                        />
                    </FormField>

                    <FormField label="Programs - optional">
                        <Multiselect
                            selectedOptions={formFilters.programs}
                            filteringType="auto"
                            placeholder='Select programs'
                            onChange={({ detail }) =>
                                setValue('programs', detail.selectedOptions)
                            }
                            tokenLimit={4}
                            options={formFilters.productLine?.value ? plRpnOptionsMap[formFilters.productLine?.value] : []}
                        />
                    </FormField>

                    <FormField label="Caption - title" >
                        <Multiselect
                            selectedOptions={formFilters.captionTitle}
                            onChange={({ detail }) => {
                                if (detail.selectedOptions.length < 4) setValue('captionTitle', detail.selectedOptions)
                            }}
                            options={cTitleOpts}
                            placeholder='Customize title'
                            filteringType="auto"
                            ariaLabel='select-caption-title'
                            selectedAriaLabel="Selected"
                            statusType={!cTitleOpts.length ? 'loading' : 'finished'}
                        />
                    </FormField>

                    <FormField label="Caption - subtitle">
                        <Multiselect
                            selectedOptions={formFilters.captionSubtitle}
                            onChange={({ detail }) => {
                                if (detail.selectedOptions.length < 4) setValue('captionSubtitle', detail.selectedOptions)
                            }}
                            options={cSubTitleOpts}
                            placeholder='Customize subtitle'
                            filteringType="auto"
                            ariaLabel='select-caption-subtitle'
                            selectedAriaLabel="Selected"
                            statusType={!cSubTitleOpts.length ? 'loading' : 'finished'}
                        />
                    </FormField>

                    <FormField label="&nbsp;">
                        <Button disabled={!formFilters.captionTitle.length || !formFilters.groupBy || !formFilters.productLine || !formFilters.snroId} loading={loading} ariaLabel='generate-chart' iconName="search" variant="primary" onClick={() => generate(formFilters)} >Generate chart</Button>
                    </FormField>

                </Grid>
                <hr></hr>
            </SpaceBetween>
        </>
    );
};
