import React, { useEffect, useState } from "react";
import { Box, Input, Spinner, Toggle } from "@cloudscape-design/components";
import SideNavigation, { SideNavigationProps } from "@cloudscape-design/components/side-navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from '../../../redux/hooks';
import { selectProductHierarchy } from "./appLayoutSlice";
import { selectUserPolicy } from '../userPolicy/userPolicySlice';
import { DEFAULT_HEADER } from "../../constants/constants";
import { ConfirmationModal } from "../../../common/Components/ConfirmationAlert/ConfirmationModal";
import AppLayoutHelper from './appLayoutHelper';
import { APP_LAYOUT_MESSAGES } from "../../constants/displayMessages";

export interface ISideNav {
    policyLoading: boolean,
    hasError: boolean
}

export const RPTSideNav = ({ policyLoading, hasError }: ISideNav) => {

    const [activeHref, setActiveHref] = React.useState("/");
    const [pendingHref, setPendingHref] = React.useState("/");
    const userPolicy = useAppSelector(selectUserPolicy);
    const productHierarchy = useAppSelector(selectProductHierarchy);
    const location = useLocation();
    const { generateSideNavItems } = AppLayoutHelper();
    const pendingChanges = false; // Temporary: logic will be added later
    const [showModal, setShowModal] = useState(false);
    const [items, setItems] = useState<SideNavigationProps.Item[]>([]);
    const [showRoadmapPrograms, setShowRoadmapPrograms] = useState(false);
    const [showAssigned, setShowAssigned] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        setActiveHref(window.location.hash);
    }, [location]);

    useEffect(() => {
        if (productHierarchy.length) setItems(generateSideNavItems(productHierarchy, userPolicy, showRoadmapPrograms, showAssigned, searchValue));
    }, [userPolicy, productHierarchy, showRoadmapPrograms, showAssigned, searchValue]);

    const leavePage = () => {
        navigate(pendingHref.substring(1), { replace: true });
        setShowModal(false);
        setActiveHref(pendingHref);
    };

    const loadSideNav = () => {
        return (
            <>
                <h2 className="side-nav-header">{DEFAULT_HEADER}</h2>
                <hr className="hr-divider" />
                <Box className="side-nav-toggle" padding={{ bottom: 'n' }}>
                    <Box><Input
                        onChange={({ detail }) => setSearchValue(detail.value)}
                        value={searchValue}
                        placeholder="Search"
                    /></Box>
                </Box>
                <Box className="side-nav-toggle" padding={{ bottom: 'n' }}>
                    <Box fontSize="body-m" variant="span">Roadmap programs </Box>
                    <Box float="right"><Toggle
                        onChange={({ detail }) => setShowRoadmapPrograms(detail.checked)}
                        checked={showRoadmapPrograms}
                    /></Box>
                </Box>

                <Box className="side-nav-toggle">
                    <Box fontSize="body-m" variant="span">Assigned programs </Box>
                    <Box float="right"><Toggle
                        onChange={({ detail }) => setShowAssigned(detail.checked)}
                        checked={showAssigned}
                    /></Box>
                </Box>
                <hr className="hr-divider" />
                {hasError && <Box padding='m'>{APP_LAYOUT_MESSAGES.progLoadingError}</Box>}
                {items?.length > 0 && !productHierarchy.length && <div className='loadspinner'><Spinner />{APP_LAYOUT_MESSAGES.updatingProg}</div>}
                {items?.length > 0 && policyLoading && <div className='loadspinner'><Spinner />{APP_LAYOUT_MESSAGES.policyLoading}</div>}
                {items?.length > 0 ? <SideNavigation
                    className="verity-side-nav"
                    activeHref={activeHref}
                    onFollow={(event) => {
                        if (!event.detail.external) {
                            if (pendingChanges) {
                                event.preventDefault();
                                setPendingHref(event.detail.href);
                                setShowModal(true);
                            } else setActiveHref(event.detail.href);
                        }
                    }}
                    items={items}
                /> : !hasError && <div className='loadspinner'><Spinner />{APP_LAYOUT_MESSAGES.progLoading}</div>}
            </>
        );
    };

    return (
        <>
            {loadSideNav()}
            <ConfirmationModal
                visible={showModal}
                setVisible={setShowModal}
                action={leavePage}
                header="Leave Page"
                type="warning"
                btnLabel="Leave"
                message="Are you sure that you want to leave the current page? The changes that you made won't be saved." />
        </>
    );
};