import React from 'react';
import { Alert, Box, Button, Modal, SpaceBetween } from "@cloudscape-design/components";

export interface IConfirmationModal {
    visible: boolean,
    setVisible: (visible: boolean) => void,
    action: () => void,
    message: string,
    header: string,
    type?: "info" | "success" | "warning" | "error",
    btnLabel: string
}

export const ConfirmationModal = ({ visible, setVisible, action, message, btnLabel, header, type }: IConfirmationModal) => {
    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            header={header}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisible(false)}>
                            Cancel
                        </Button>
                        <Button className='bg-primary' variant="primary" onClick={action}>{btnLabel}</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {!type ? message : <Alert type={type}>{message}</Alert> }
        </Modal>

    )

}