import React from "react";
import { Alert } from "@cloudscape-design/components";
import { useAppSelector } from "../../../redux/hooks";
import { selectIsLocked } from '../appLayout/appLayoutSlice';

export const OverviewAlert = ({loading}: any) => {
    const isLocked = useAppSelector(selectIsLocked);

    return (
        <> 
        {isLocked && <Alert
            className="mg-bottom-md"
            statusIconAriaLabel="Warning"
            type="warning"
            header="'Change program name/status' is currently locked for OP1 first pass, please contact @huberwan for more information."
        />}
        </>
    );
};
