import React from "react";
import { Toggle } from '../../Formik-Cloudscape/components/toggle/toggle';
import { IDynamicFieldProps } from "../Interfaces";

export const ToggleField = ({ name, readOnly, label, action }: IDynamicFieldProps) => {
    return (
        <>
            <Toggle name={name}
                disabled={readOnly || false}
                onChange={({ detail }) => {
                    action(detail.checked, name);
                }}
            > {label}</Toggle>
        </>
    )
}