import React from 'react';
import { DateRangePickerProps, DateRangePicker } from "@amzn/awsui-components-react";

export interface IDateRangePicker {
    setValue: (dateRange: DateRangePickerProps.Value | null) => void,
    value: DateRangePickerProps.Value | null,
    relativeOptions?: DateRangePickerProps.RelativeOption[]
}

const relativeOptionsDefault: DateRangePickerProps.RelativeOption[] = [
    {
        key: "Next 3 years",
        amount: 3,
        unit: "year",
        type: "relative"
    },
    {
        key: "Next 5 years",
        amount: 5,
        unit: "year",
        type: "relative"
    },
    {
        key: "All years",
        amount: 10,
        unit: "year",
        type: "relative"
    }
]
export const DateRangePickerField = ({ setValue, value, relativeOptions = relativeOptionsDefault }: IDateRangePicker) => {
    return (
        <DateRangePicker
            onChange={({ detail }) => setValue(detail.value)}
            value={value}
            rangeSelectorMode={'default'}
            relativeOptions={relativeOptions}
            dateOnly
            isValidRange={() => { return { valid: true }; }}
            isDateEnabled={() => true}
            i18nStrings={{
                applyButtonLabel: 'Apply',
                cancelButtonLabel: 'Cancel',
                startDateLabel: 'Start',
                endDateLabel: 'End',
                todayAriaLabel: 'Today',
                nextMonthAriaLabel: 'Next Month',
                customRelativeRangeUnitLabel: 'Type',
                customRelativeRangeDurationLabel: 'Count',
                customRelativeRangeOptionLabel: 'Custom',
                previousMonthAriaLabel: 'Last Month',
                ariaLabel: 'Filter by a date range',
                customRelativeRangeOptionDescription: '',
                customRelativeRangeDurationPlaceholder: 'placeholder',
                formatUnit: (unit: DateRangePickerProps.TimeUnit, value: number) => (value === 1 ? unit : `${unit}s`),
                formatRelativeRange: (range: DateRangePickerProps.RelativeValue) => range.key ?? '',
                renderSelectedAbsoluteRangeAriaLive: (startDate, endDate) => `Range selected from ${startDate} to ${endDate}`,
                relativeRangeSelectionHeading: 'Choose a range',
                absoluteModeTitle: 'Absolute Range',
                relativeModeTitle: 'Relative Range',
            }}
            expandToViewport={false}
            placeholder="Filter by a date range"
        />

    )

}
