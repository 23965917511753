export default class Queries {
    public static readonly getCountryNames = () => {
        return `query {
            get_country {
                body {
                    country
                    country_2_code
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getProductLines = () => {
        return `query ($is_deprecated: Boolean, $verity_version: String) {
            get_product_line(is_deprecated: $is_deprecated, verity_version: $verity_version) {
                body {
                    product_line
                    verity_product_line_id
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getRPNs = () => {
        return `query ($is_deprecated: Boolean, $is_root_program: Boolean) {
            get_program_v1(is_deprecated: $is_deprecated, is_root_program: $is_root_program) {
                statusCode
                error
                body {
                    program
                    program_id
                    product_line_id
                }
              }
        }`;
    }


    public static readonly getUpdateQuery = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
                ${api_name}(input: ${payload}) {
                    body
                    error
                    statusCode
                }
            }`
        };
    }
    public static readonly getUpdateQueryWithCodes = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
                ${api_name}(input: ${payload}) {
                    body
                    error
                    statusCode
                    updatedCodes
                    erroredCode
                }
            }`
        };
    }
}