import { useContext, useEffect } from "react";
import {
  CellClassParams,
  Column,
  EditableCallbackParams,
  GridApi,
  IColumnToolPanel,
  IFilter,
} from "ag-grid-community";
import {
  GridApiParams,
  GridColumnApiType,
  IChangedCells,
} from "./GridTableInterface";
import { ValueParserParams } from "ag-grid-community";
// import { cleanExpense } from "src/components/Inputs/details/DetailsUtils";



const GRID_FIXED_DEC_PLACE: number = 2;
export const LOCAL_STORAGE_FILTERS_MODEL_ID: string = "local-filters-model-id";
export const LOCAL_STORAGE_COLUMNS_MODEL_ID: string = "local-columns-model-id";
const cleanExpense = (val: string)=>null;
export const AUTO_HEIGHT_TEXT: string = "Auto-Height";
export const AUTO_HEIGHT_ID: string = "grid-auto-height-id";
export const FIXED_HEIGHT_TEXT: string = "Fixed-Height";
export const FIXED_HEIGHT_ID: string = "grid-fixed-height-id";
export const MISSING_FILTER_ID_ERR: string =
  "Property (filterId: string) missing for GridTableProps (customSettings: ICustomSettings). Cannot apply local filters.";

export default class GridTableUtils {
  constructor() {}

  public static readonly cellStyleRules = (
    changes: Record<string, IChangedCells>
  ): any => {
    return {
      "bg-change": (params: CellClassParams): boolean => {
        if (params.colDef) {
          const cellId = (params.colDef?.field! + params.rowIndex).toString();
          return (
            changes?.[cellId] &&
            params.value &&
            params.value !== changes?.[cellId]?.oldValue
          );
        }
        return false;
      },
    };
  };
  private static _colId: string | undefined = undefined;
  public static setColId(colField: string, rowIndex: number) {
    this._colId = (colField + rowIndex).toString();
  }
  public static get colId(): string {
    if (!this._colId) {
      throw "Column Id cannot be undefined";
    }
    return this._colId;
  }
  public static setEditable(isEditable: boolean) {
    const editableCallBack = (
      params: EditableCallbackParams,
      fn: (value: boolean) => boolean
    ) => {
      if (params.data?.newRow === "newRow") fn(true);
      if (isEditable === false) fn(false);
      fn(true);
    };
    return editableCallBack;
  }
}

export const useCellClass = ({
  touchedCells,
}: {
  touchedCells?: Record<string, IChangedCells>;
}) => {
  const cellStyleRule = {
    "bg-change": (params: CellClassParams) => {
      if (params.colDef) {
        const cellId = (params.colDef?.field! + params.rowIndex).toString();
        return (touchedCells?.[cellId] &&
          params.value &&
          params.value !== touchedCells?.[cellId]?.oldValue) as boolean;
      }
      return false;
    },
    // "non-editable": (params: CellClassParams) => {
    //   return !params.colDef.editable ? true : (false as boolean);
    // },
  };

  useEffect(() => {
    return () => {};
  }, [touchedCells]);
  return [cellStyleRule];
};

export class Parsers {
  public static numberParser(params: ValueParserParams) {
    const { newValue } = params;
    if (newValue !== undefined || newValue !== null) {
      const val: number = Number(cleanExpense(params?.newValue?.toString()));
      if (!isNaN(val)) {
        return val;
      }
    }
    return params.oldValue;
  }
}

export class PureParsers {
  public static numberParser(val: string | number | null) {
    if (val !== undefined && val !== null) {
      const conversion: number = Number(cleanExpense(val?.toString()));
      if (!isNaN(conversion)) {
        return conversion;
      }
    }
    return 0;
  }
}

export const fixFractionDigits = (
  num: number,
  min: number = GRID_FIXED_DEC_PLACE,
  max: number = GRID_FIXED_DEC_PLACE
): string => {
  return num.toLocaleString(undefined, {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
};

export const formatTotal = (total: number): string => {
  if (total === undefined) total = 0;
  return total < 0
    ? `$ (${fixFractionDigits(total).substring(1)})`
    : `$ ${fixFractionDigits(total)}`;
};

export const saveFiltersModel = (filterId: string, api: GridApi) => {
  try {
    const itemKey: string = `${filterId}-${LOCAL_STORAGE_FILTERS_MODEL_ID}`;
    debugger;
    const savedFilters = api.getFilterModel();
    localStorage.setItem(itemKey, JSON.stringify(savedFilters));
  } catch (e) {
    console.error("Failed to save filter model settings");
  }
};

export const saveColumnsModel = (
  filterId: string,
  columnApi: GridColumnApiType
) => {
  try {
    const itemKey: string = `${filterId}-${LOCAL_STORAGE_COLUMNS_MODEL_ID}`;
    const savedColumns: Record<string, boolean> = localStorage.getItem(itemKey)
      ? { ...JSON.parse(localStorage.getItem(itemKey)!) }
      : {};
    const allCols: Column[] = columnApi.getAllColumns()!;
    allCols.forEach((c: Column) => {
      const field: string = c?.getColId()!;
      const isVisible: boolean = c?.isVisible()!;
      if (field && isVisible !== undefined) savedColumns[field] = isVisible;
    });
    localStorage.setItem(itemKey, JSON.stringify(savedColumns));
  } catch (e) {
    console.error("Failed to save column model settings");
  }
};

export const applySavedFilters = (filterId: string, params: GridApiParams) => {
  try {
    const filterItemKey: string = `${filterId}-${LOCAL_STORAGE_FILTERS_MODEL_ID}`;
    const savedFilters = JSON.parse(localStorage.getItem(filterItemKey)!);
    if (savedFilters) {
        params.api.setFilterModel(savedFilters)
    }
  } catch (e) {
    console.error("Failed to apply filter model settings");
  }

  try {
    const columnsItemKey: string = `${filterId}-${LOCAL_STORAGE_COLUMNS_MODEL_ID}`;
    const columnsVisibilityMap: Record<string, boolean> = JSON.parse(
      localStorage.getItem(columnsItemKey)!
    );
    const toolPanel: IColumnToolPanel = params.api.getToolPanelInstance(
      "columns"
    )! as any as IColumnToolPanel;
    if (toolPanel) {
      toolPanel.setPivotModeSectionVisible(false);
      toolPanel.setPivotSectionVisible(false);
      toolPanel.setRowGroupsSectionVisible(false);
      toolPanel.setValuesSectionVisible(false);
      toolPanel.collapseColumnGroups();
    }
    if (columnsVisibilityMap) {
      Object.keys(columnsVisibilityMap).forEach((c) => {
        params.gridColumnApi?.setColumnVisible(c, columnsVisibilityMap[c]);
      });
    }
  } catch (e) {
    console.error("Failed to apply columns model settings");
  }
};
export const defaultSideBar = {
  toolPanels: [
      {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
              suppressExpandAll: true,
              suppressFilterSearch: false
          }
      },
      {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: true
          }
      }
  ],
  defaultToolPanel: ''
};