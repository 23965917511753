import { createSlice} from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { verityCommonAPI } from '../../services/apis/commonApi';
import { ICountry, ILocationData } from '../../interfaces/interfaces';

type AppLayoutState = {
    countryMap: Record<string, string>;
    locationData: ILocationData
};

const initialState: AppLayoutState = {
    countryMap: {},
    locationData: {
        currentScreen: ''
    }
};

export const verityAppLayoutSlice = createSlice({
    name: 'verityAppLayout',
    initialState,
    reducers: {
        updateLocationData: (state, action) => {
            state.locationData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                verityCommonAPI.endpoints.getCountries.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const countryMap : Record<string, string> = {};
                        payload.forEach((obj: ICountry) => countryMap[obj.country_2_code] = obj.country);
                        state.countryMap = countryMap;
                    }
                }
            );
    }
});

export default verityAppLayoutSlice.reducer;

export const { updateLocationData } = verityAppLayoutSlice.actions;

export const selectCountryMap = (state: RootState) => state.verityAppLayout.countryMap;
export const selectLocationData = (state: RootState) => state.verityAppLayout.locationData;