import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const [ccCategoryData, setCcCategoryData] = useState();
    const [bbLineItemData, setBbLineItemData] = useState();
    const queryClient = useQueryClient();

    const fetchBudgetBaseline = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getBdjBaselineQuery());
        const { body }: IApiResponse = response.data.data.get_budget_bl;
        return body;
    };
    const fetchCCCategory = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getCCCategoryQuery());
        const { body }: IApiResponse = response.data.data.get_cc_cat;
        return body;
    };
    const fetchLineItem = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getLineItemQuery());
        const { body }: IApiResponse = response.data.data.get_line_item;
        return body;
    };

    const updateBudgetBaseline = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_budget_bl'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_budget_bl as IApiUpdateResponse;
    };


    const useBudgetBaselineData = () => {
        return useQuery(
            ['budgetBaseline'],
            fetchBudgetBaseline,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useCCCategoryData = () => {
        return useQuery(
            ['ccCategory'],
            fetchCCCategory,
            {
                onSuccess: (data) => {
                    setCcCategoryData(data);
                }
            }
        );
    };
    const useLineItemData = () => {
        return useQuery(
            ['bbLineItem'],
            fetchLineItem,
            {
                onSuccess: (data) => {
                    setBbLineItemData(data);
                }
            }
        );
    };
    const useUpdatedBudgetBaselineData = () => {
        return useMutation(
            (requestData) => updateBudgetBaseline(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['budgetBaseline']);
                }
            }
        );
    };
    return { componentData, useBudgetBaselineData, useUpdatedBudgetBaselineData, useCCCategoryData, useLineItemData, ccCategoryData, bbLineItemData };
};


