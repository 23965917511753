import { DateRangePickerProps } from "@amzn/awsui-components-react";
import { IPRMFormResources, IPRMGanttChartData } from "src/components/RPT/interfaces/interfaces";
import { IFilters } from "./inputPanel";
import { FIELD_NAMES } from "src/components/RPT/constants/fieldData";
import { SUBMIT_ALL_OPTION } from "src/components/RPT/constants/constants";

export default () => {

    const getMinMaxDateFromInput = (dateRange: DateRangePickerProps.Value | null) => {
        const currYear = new Date().getFullYear()
        const daterRange = { min: new Date(currYear, 0, 1), max: new Date(currYear, 11, 31) };
        if (dateRange) {
            if (dateRange.type === 'relative') {
                daterRange.max = new Date(currYear + dateRange.amount, 11, 31);
            } else {
                daterRange.min = new Date(dateRange.startDate);
                daterRange.max = new Date(dateRange.endDate);
            }
        }
        return daterRange;
    }

    const generateChartSeriesData = (chartData: IPRMGanttChartData[], formInput: IFilters, rpnIdName: Record<string, string>, isCategorizedView: boolean) => {
        const chartSeriesData: Record<string, any>[] = [];
        if (formInput?.groupBy?.value) {
            const fieldWithProgId = [FIELD_NAMES.programFollowOn, FIELD_NAMES.programPredecessor];
            const yAxisKeyObjMap: Record<string, any[]> = {}
            const selectedPrograms = formInput.programs?.map(obj => obj.value) ?? [];
            chartData.forEach(({ start_date, end_date, attributes, program_alias, program_id }) => {
                if (start_date && end_date && !selectedPrograms.length || selectedPrograms.includes(SUBMIT_ALL_OPTION) || selectedPrograms.includes(program_id?.toString())) {
                    const attrValMap: Record<string, any> = {};
                    attributes.forEach((item: IPRMFormResources) => {
                        const attrVal = fieldWithProgId.includes(item.attribute_type) ? rpnIdName[item.attribute_value] ?? item.attribute_value : item.attribute_value;
                        attrValMap[item.attribute_id] = { label: item.attribute_label, value: attrVal }
                    });
                    const yAxisKey = attrValMap[formInput?.groupBy!.value!.toString()]?.value ?? '';
                    const data = {
                        start: Date.parse(start_date),
                        end: Date.parse(end_date),
                        name: yAxisKey,
                        program: program_alias,
                        ...attrValMap
                    };
                    yAxisKeyObjMap[yAxisKey] ? yAxisKeyObjMap[yAxisKey].push(data) : yAxisKeyObjMap[yAxisKey] = [data];
                }
            });
            Object.entries(yAxisKeyObjMap).forEach(([yAxisKey, records]) => {
                if (isCategorizedView && checkIfDateOverlap(records)) {
                    chartSeriesData.push({
                        start: null,
                        end: null,
                        name: yAxisKey,
                        id: yAxisKey + 'parent',
                        isParent: true
                    });
                    chartSeriesData.push(...records.map(obj => ({ ...obj, parent: yAxisKey + 'parent', name: 'hide'+obj.program, hideYAxisLabel: true })));
                } else {
                    chartSeriesData.push(...records);
                }
            });
        }
        return chartSeriesData;
    }

    const checkIfDateOverlap = (records: any[]) => {
        records.sort((a, b) => a.start - b.start);
        for (let i = 1; i < records.length; i++)
            if (records[i - 1].end > records[i].start)
                return true;
        return false;
    }

    return { getMinMaxDateFromInput, generateChartSeriesData }
}