import { VerityDataState } from './../features/programOverview/programOverviewSlice';
import { 
    IPLAttributeTypeResponse,
    IPlFPNRuleResponse,
    IRPNAttributeTypeResponse
} from './../interfaces/interfaces';

/**
 * Determines which attributes should be excluded from the program attributes list.
 *
 * @param programData - The program data state.
 * @param standardAttributes - An array of standard attributes to exclude.
 * @param allPLAttributes - An array of all product line attributes.
 * @param plFpnRuleAttributes - An array of FPN rule attributes.
 * @param includedProgramAttributes - An array of already included program attributes.
 * @returns An array of attributes to exclude.
 */
const getAttributesToExclude = (
    programData: VerityDataState,
    standardAttributes: string[],
    allPLAttributes: IPLAttributeTypeResponse[],
    plFpnRuleAttributes: IPlFPNRuleResponse[],
    includedProgramAttributes: IRPNAttributeTypeResponse[]) => {

    // check if program has ASINs
    // if yes, then exclude any attributes required by the FPN rule
    const asinFPNRuleAttributes = programData.asinCount > 0
        ? plFpnRuleAttributes.filter(rule => rule?.is_required).map(rule => rule?.attribute_type) ?? []
        : [];

    const plAttributes = allPLAttributes.map(attr => attr?.attribute_type) ?? [];
    // attributes already included so can't add twice
    const programAttributes = includedProgramAttributes.map(attr => attr?.attribute_type) ?? [];

    const excludedAttributes = [
        ...standardAttributes,
        ...asinFPNRuleAttributes,
        ...plAttributes,
        ...programAttributes
    ];

    return excludedAttributes;
};

export {getAttributesToExclude};