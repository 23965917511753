import React from "react";
import { Input } from '../../Formik-Cloudscape/components/input/Input';
import { FormField } from '../../Formik-Cloudscape/components/form-field/form-field';
import { IDynamicFieldProps } from "../Interfaces";

export const InputField = ({ name, readOnly, inputType, label, action, placeholder, validation, errors, onBlur }: IDynamicFieldProps) => {
    return (
        <>
            <FormField stretch label={label} name="input" errorText={errors}>
                <Input name={name}
                    type={inputType}
                    ariaLabel={name}
                    placeholder={placeholder}
                    validate={validation}
                    readOnly={readOnly}
                    onChange={({ detail }) => {
                        action(detail.value, name);
                    }}
                    onBlur={() => {onBlur?.()}}
                />
            </FormField>

        </>
    )
}