import React, { useState, useEffect } from 'react';
import { Button, ColumnLayout, Container, FormField, Header, Input, Link, SpaceBetween, Table, TableProps } from "@cloudscape-design/components";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateDocumentLinks, selectDocumentLinks, selectUserRole } from '../programOverview/programOverviewSlice';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import ScreenUtils from '../../utils/screenUtils';
import deleteIcon from '../../../../images/delete.svg';
import { MUTATION_ACTION, SEPERATOR, SK_MAPPING, TAB_ID_LABEL, USER_ACTION_ROLES } from '../../constants/constants';
import { ALERT_MESSAGES } from '../../constants/constants';

type IURLLinks = {
    "Link description": string,
    "URL": string,
}
export const Documents = () => {
    const dispatch = useAppDispatch();
    const linksData = useAppSelector(selectDocumentLinks);
    const [linkName, setLinkName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [linkURL, setLinkURL] = useState<string>('');
    const [urlError, setUrlError] = useState<string>('');
    const [linkItems, setLinkItems] = useState<IURLLinks[]>([]);
    const locationData = useAppSelector(selectLocationData);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole));
    const btnDisabled = locationData.fpn.id ? true : !hasWriteAccess;

    useEffect(() => {
        let items: IURLLinks[] = [];
        if (linksData['URL links'] && linksData['URL links'].ItemValues.length > 0) items = linksData['URL links'].ItemValues;
        setLinkItems(items);
    }, [linksData]);

    const addLink = () => {
        if (!linkName) setNameError(ALERT_MESSAGES.linkNameRequired);
        if (!linkURL) setUrlError(ALERT_MESSAGES.urlRequired);
        if (linkName && linkURL) {
            if (!linkItems.find((obj: IURLLinks) => obj["Link description"] === linkName)) {
                const storeData = { ...linksData };
                const values = [...linkItems];
                values.push({
                    "Link description": linkName,
                    "URL": linkURL
                });
                if (!storeData['URL links']) {
                    storeData['URL links'] = {
                        PK: ScreenUtils.getPrimaryKey(locationData, 'Sandbox'),
                        SK: TAB_ID_LABEL.overview + SEPERATOR + SK_MAPPING.documents + SEPERATOR + 'URL links',
                        Action: MUTATION_ACTION.put,
                        ItemValues: values,
                        Version: 0
                    };
                } else {
                    const action = storeData['URL links'].Action === MUTATION_ACTION.put ? MUTATION_ACTION.put : MUTATION_ACTION.update;
                    storeData['URL links'] = { ...storeData['URL links'], ItemValues: values, Action: action };
                }
                dispatch(updateDocumentLinks(storeData));
                setLinkName('');
                setLinkURL('');
            }
        }

    };
    const deleteLink = (name: string) => {
        const storeData = { ...linksData };
        const values = [...storeData['URL links'].ItemValues];
        const findIndex = values.findIndex((obj: IURLLinks) => obj["Link description"] === name);
        if (findIndex !== -1) {
            values.splice(findIndex, 1);
            const action = storeData['URL links'].Action === MUTATION_ACTION.put ? MUTATION_ACTION.put : MUTATION_ACTION.update;
            storeData['URL links'] = { ...storeData['URL links'], ItemValues: values, Action: action };
            if(action === MUTATION_ACTION.put && !values.length) delete storeData['URL links'];
            dispatch(updateDocumentLinks(storeData));
        }
    };
    const columnDefinitions: TableProps.ColumnDefinition<IURLLinks>[] = [
        {
            id: 'Link description',
            sortingField: 'Link description',
            header: 'Link description',
            cell: (item: IURLLinks) => item["Link description"],
            minWidth: 180
        },
        {
            id: 'URL',
            sortingField: 'url',
            header: 'URL',
            cell: (item: IURLLinks) => (
                <Link external href={item["URL"]}>{item["URL"]}</Link>
            ),
            minWidth: 140
        },
        {
            id: 'Action',
            sortingField: 'name',
            cell: (item: IURLLinks) => (
                <Button disabled={btnDisabled} iconUrl={deleteIcon} variant="icon" onClick={() => deleteLink(item["Link description"])} />
            ),
            header: 'Action',
            minWidth: 100
        }
    ];
    const loadLinksTable = () => {
        if (linkItems) {
            return (
                <>
                    <Table
                        columnDefinitions={columnDefinitions}
                        items={linkItems}
                        variant="embedded"
                    ></Table>
                </>
            );
        } else {
            return "No data to display.";
        }
    };

    return (
        <>
            <Container className=''
                header={
                    <Header variant="h2"> Documents/Links to Share</Header>
                }
            >
                <SpaceBetween size="l">
                    <SpaceBetween size="l">
                        <Header variant="h3"> URL Links </Header>
                        <ColumnLayout columns={3}>
                            <FormField
                                label="Link description"
                                errorText={nameError}
                            >
                                <Input
                                    value={linkName}
                                    placeholder="Input description"
                                    onChange={event => {
                                        setLinkName(event.detail.value);
                                        if (event.detail.value) setNameError('');
                                    }
                                    }
                                />

                            </FormField>
                            <FormField
                                label="URL"
                                errorText={urlError}
                            >
                                <Input
                                    value={linkURL}
                                    placeholder="Paste URL here"
                                    onChange={event => {
                                        setLinkURL(event.detail.value);
                                        if (event.detail.value) setUrlError('');
                                    }
                                    }
                                />
                            </FormField>
                            <FormField
                                label="&nbsp;"
                            >
                                <Button disabled={btnDisabled} onClick={addLink}>Add</Button>
                            </FormField>
                        </ColumnLayout>
                        <div>{loadLinksTable()}</div>
                    </SpaceBetween>
                </SpaceBetween>
            </Container>
        </>

    );
};
