import React from "react";
import { Checkbox } from '../../Formik-Cloudscape/components/checkbox/checkbox';
import { IDynamicFieldProps } from "../Interfaces";

export const CheckboxField = ({ name, readOnly, label, action }: IDynamicFieldProps) => {
    return (
        <>
            <Checkbox name={name}
                ariaLabel={name}
                disabled={readOnly || false}
                onChange={({ detail }) => {
                    action(detail.checked, name);
                }}
            > {label}</Checkbox>
        </>
    )
}