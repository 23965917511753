import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const [accCodeData, setAccCodeData] = useState();
    const queryClient = useQueryClient();

    const fetchAccounts = async (reqData: any) => {
        const response = await xhr.post(Endpoints.getBaseUrl(), reqData);
        const { body }: IApiResponse = response.data.data.get_accounts;
        return body;
    };

    const updateAccounts = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_accounts'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_accounts as IApiUpdateResponse;
    };

    const useAccountsData = () => {
        return useQuery(
            ['account'],
            () => fetchAccounts(Queries.getAccountQuery()),
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useAccCodesData = () => {
        return useQuery(
            ['accountCodes'],
            () => fetchAccounts(Queries.getAccCodesQuery()),
            {
                onSuccess: (data) => {
                    setAccCodeData(data);
                }
            }
        );
    };
    
    const useUpdatedAccountData = () => {
        return useMutation(
            (requestData) => updateAccounts(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['account']);
                }
            }
        );
    };
   
    return { componentData, useAccountsData, useUpdatedAccountData, accCodeData, useAccCodesData };
};
