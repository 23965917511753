import React from 'react';
import { Modal, Box, SpaceBetween, Header, Button } from '@amzn/awsui-components-react';

interface DeactivateModalProps {
  visible: boolean;
  onDismiss: () => void;
  onDeactivate: () => void;
}

const DeactivateSkuModal: React.FC<DeactivateModalProps> = ({ visible, onDismiss, onDeactivate }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      header={<Header variant="h3">Deactivate SKU</Header>}
    >
      <Box padding="m">
        <span>
          This SKU has Part Number and/or ASIN created, are you sure you want to proceed? If so, please also deprecate in Townsend.
        </span>
      </Box>
      <Box textAlign="right" padding="m">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="normal" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onDeactivate}>
            Proceed
          </Button>
        </SpaceBetween>
      </Box>
    </Modal>
  );
};

export default DeactivateSkuModal;
