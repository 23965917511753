import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { agmUserPolicyApi } from '../../services/apis/userPolicyApi';
import { ALL_ASSIGNEES_KEY, SEPERATOR, USER_ROLES } from '../../constants/constants';

type UserAccessState = {
    userPolicyData: Record<string, Record<string, string[]>>;
    progRoleResourceMap: Record<string, any[]>;
};

const initialState: UserAccessState = {
    userPolicyData: {}, //This is set below using extraReducers on app load. 
    progRoleResourceMap: {} // This is set in programSetupHome on program change using userPolicyData
};

export const agmUserPolicySlice = createSlice({
    name: 'agmUserPolicy',
    initialState,
    reducers: {
        resetUserPolicy: () => initialState,
        resetRoleResourceMap: (state) => {
            state.progRoleResourceMap = initialState.progRoleResourceMap;
        },
        updateRoleResourceMap: (state, action) => {
            state.progRoleResourceMap = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                agmUserPolicyApi.endpoints.getAGMUserPolicyData.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const userPolicyData: Record<string, any> = {};
                        payload.data?.get_user_access_agm?.body?.forEach((obj: any) => {
                            if (obj.roles?.length && obj.resource?.name) {
                                const allRoles = obj.roles.map((item: any) => item.name);
                                allRoles.forEach((role: string) => {
                                    const [pl, rpn] = obj.resource.name.split(SEPERATOR);
                                    const plOrRPN = rpn ?? pl;
                                    if (!userPolicyData[role]) userPolicyData[role] = {};
                                    if (!userPolicyData[role][plOrRPN]) userPolicyData[role][plOrRPN] = [];
                                    if (!obj.resource.resources || obj.resource.resources?.length === 0) userPolicyData[role][plOrRPN].push(ALL_ASSIGNEES_KEY);
                                    obj.resource.resources?.forEach((tab: any) => userPolicyData[role][plOrRPN].push(tab.name));
                                });
                            }
                        });
                        state.userPolicyData = userPolicyData;
                    }
                }
            );
    }
});

export default agmUserPolicySlice.reducer;

export const { resetUserPolicy, resetRoleResourceMap, updateRoleResourceMap } = agmUserPolicySlice.actions;

export const selectUserPolicy = (state: RootState) => state.agmUserPolicy.userPolicyData;
export const selectAgmRoleResourceMap = (state: RootState) => state.agmUserPolicy.progRoleResourceMap;