export const ALL_ASSIGNEES_KEY = '*'; // This needs to be same as PRM variable for attributes screen

export const SEPERATOR = '#';

export const USER_POLICY_SEPERATOR = '~';

export const NA_LABEL = 'N/A';

export const USER_ASSIGNEES = 'Assignees';

export const PERSON_TYPE = 'PERSON';

export const SUPPORT_SIM_URL = 'https://t.corp.amazon.com/create/templates/3bcfa193-b591-41c5-a427-90febbbd4b99';

export const SUPPORT_WIKI_LINK = 'https://w.amazon.com/bin/view/DaS-FinTech/Products/Verity2/';

export const API_METHODS = {
    get: 'GET',
    post: 'POST'
};

export const METRICS_APP_NAME = 'AGM';

export const METRICS_PAGE_NAMES = {
    programHome: 'AGMProgramHome'
};

export const PRIMARY_KEY_MAPPING = {
    pl: "PL",
    rpn: 'RPN',
    fpn: 'FPN'
};

export const TAB_ID_LABEL = {
    attributes: 'Attributes',
    versions: 'Versions',
    assortments: 'Assortments',
    sku: 'SKU'
};

export const TAB_ID = {
    attributes: 'attributes',
    versions: 'versions',
    assortments: 'assortments',
    sku: 'sku'
};

export const ATTRIBUTE_TYPE_NAMES = {
    country: 'COUNTRY',
    channel: 'CHANNEL',
    skuTypes: 'SKU_TYPE',
    productType: 'PRODUCT_TYPE',
    version: 'VERSION',
    color: 'COLOR',
    row: 'ROW',
    dtcp: 'DTCP'
};

export const MANDATORY_VERSION_ATTR = [ATTRIBUTE_TYPE_NAMES.productType, ATTRIBUTE_TYPE_NAMES.color];

export const SAME_FOR_ALL_FPNS = [ATTRIBUTE_TYPE_NAMES.productType, ATTRIBUTE_TYPE_NAMES.version];

export const MUTATION_METHODS = {
    updateAttrTypeRPN: 'post_attr_type_rpn',
    updateAttrListRPN: 'post_attr_list_rpn',
    updateRpnFpnRule: 'post_rpn_fpn_rule',
    updateAttrListFPN: 'post_attr_list_fpn',
    updateAgmVersions: 'update_agm_versions',
    updateAttrOrder: 'update_agm_att_sort',
    updateAssortment: 'update_agm_assort',
    updateUserPolicy: 'add_user_access_agm'
};

export const MUTATION_ACTION = {
    addPolicy: "add",
    deletePolicy: "delete"
};

export const ATTRIBUTES_ACTION = {
    up: 'up',
    down: 'down',
    remove: 'remove'
};

export const STATUS_CODES = {
    success: 200,
    serverError: 500,
    handledException: 400
};

export const SCREEN_PATHS = {
    basePath: '#/agm',
    program: 'program',
    admin: 'admin'
};

export const URL_PARAMS = {
    productLine: 'productline',
    rpn: 'rpn',
    fpn: 'fpn'
};

export const HOME_PATH_HEADER = {
    admin: 'Access Management'
};

export const USER_ROLES = {
    asinOwner: 'ASIN Owner',
    techAdmin: 'Tech Admin',
    agmAdmin: 'AGM Admin',
    readonly: 'Readonly'
};

export const USER_ROLE_DESCRIPTIONS = {
    asinOwner: 'Edit role to create ASIN',
    techAdmin: 'Only used by tech team to create ASIN, not visible in UI dropdown',
    agmAdmin: 'Admin role to assign user role, deactivate ASIN',
    readonly: 'Readonly access'
}

export const DISPLAY_ROLES = {
    asinOwner: 'ASIN Owner',
    techAdmin: 'Tech Admin',
    readonly: 'Readonly'
};

export const USER_ACTION_ROLES = {
    save: [USER_ROLES.asinOwner, USER_ROLES.techAdmin],
    adminPanel: [USER_ROLES.agmAdmin],
    write: [USER_ROLES.asinOwner, USER_ROLES.techAdmin]
};

export const SUCCESS_STRING = {
    MARKETPLACE_SAVE_SUCCESS: "Marketplace updated successfully."
};

export const ERROR_STRINGS = {
    PN_EMPTY_MARKETPLACE: "Marketplace can not be empty. Please add marketplace to the SKU.",
    PN_REQUEST_FAILED: "Request for Part Number Failed.",
    MARKETPLACE_SAVE_FAILED: "Failed to update marketplace."
};