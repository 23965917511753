import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { scenarioUserPolicyApi } from '../../services/apis/userPolicyApi';
import { ALL_ASSIGNEES_KEY, SEPERATOR, USER_ROLES } from '../../constants/constants';

type UserAccessState = {
    userPolicyData: Record<string, Record<string, string[]>>;
};

const initialState: UserAccessState = {
    userPolicyData: {} //This is set below using extraReducers on app load. 
};

export const verityUserPolicySlice = createSlice({
    name: 'verityUserPolicy',
    initialState,
    reducers: {
        resetUserPolicy: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                scenarioUserPolicyApi.endpoints.getCurrSnrUserPolicyData.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const userPolicyData: Record<string, any> = {};
                        payload.data?.get_user_access_snro?.body?.forEach((obj: any) => {
                            if (obj.roles?.length && obj.resource?.name) {
                                const allRoles = obj.roles.map((item: any) => item.name);
                                const role = allRoles.includes(USER_ROLES.scenarioConfigMgr) ? USER_ROLES.scenarioConfigMgr : allRoles[0];
                                const [plOrALL, rpn] = obj.resource.name.split(SEPERATOR);
                                const allOrPlOrRpn = rpn ?? plOrALL;
                                if (!userPolicyData[role]) userPolicyData[role] = {};
                                if (!userPolicyData[role][allOrPlOrRpn]) userPolicyData[role][allOrPlOrRpn] = [];
                                if (!obj.resource.resources || obj.resource.resources?.length === 0) userPolicyData[role][allOrPlOrRpn].push(ALL_ASSIGNEES_KEY);
                                obj.resource.resources?.forEach((tab: any) => userPolicyData[role][allOrPlOrRpn].push(tab.name));
                            }
                        });
                        state.userPolicyData = userPolicyData;
                    }
                }
            );
    }
});

export default verityUserPolicySlice.reducer;

export const { resetUserPolicy } = verityUserPolicySlice.actions;

export const selectUserPolicy = (state: RootState) => state.verityUserPolicy.userPolicyData;