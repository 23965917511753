
export default class Queries {

    public static readonly getProjectsQuery = () => {
        return {
            "query": `query {
                get_projects {
                    body {
                      accessory_product_code
                      accessory_project_name
                      baseline_date
                      depreciation_months
                      complexity_factor
                      device_product_code
                      device_project_name
                      id
                      is_active
                      is_mfr
                      pred_project_code
                      pred_project_name
                      product_life_in_months
                      proj_hw_id
                      project_code
                      street_date
                      street_name
                      sub_device_project_name
                    }
                    statusCode
                    error
                  }
            }`
        };
    }
    public static readonly getProjCodesQuery = () => {
        return {
            "query": `query {
                get_projects {
                    body {
                      project_code
                    }
                    statusCode
                    error
                  }
            }`
        };
    }

    public static readonly getProductsQuery = () => {
        return {
            "query": `query {
                get_products {
                    body {
                        comments
                        geo_market
                        id
                        is_accessory
                        product_code
                        product_group
                        product_name
                      }
                  }
            }`
        };
    }
    public static readonly getProductsListQuery = () => {
        return {
            "query": `query {
                get_products {
                    body {
                        product_code
                      }
                  }
            }`
        };
    }

    public static readonly getProductGroupQuery = () => {
        return {
            "query": `query {
                get_product_gr {
                    body {
                      id
                      description
                      product_group
                    }
                }
            }`
        };
    }

    public static readonly getCostCenterQuery = () => {
        return {
            "query": `query {
                get_cc {
                    body {
                        comments
                        cost_center_code
                        cost_center_function_head
                        cost_center_manager
                        cost_center_group
                        cost_center_name
                        cost_center_type
                        depreciation_months
                        hc_finance_contact
                        id
                        non_hc_finance_contact
                        organization
                    }
                }
            }`
        };
    }
    public static readonly getCCCodeQuery = () => {
        return {
            "query": `query {
                get_cc {
                    body {
                        cost_center_code
                    }
                }
            }`
        };
    }

    public static readonly getCostCenterGroupQuery = () => {
        return {
            "query": `query {
                get_cc_gr {
                    body {
                      cost_center_group
                      description
                      id
                      long_label
                      short_label
                    }
                }
            }`
        };
    }
    public static readonly getAccProjQuery = () => {
        return {
            "query": `query {
                get_acc_proj {
                    body {
                      accessory_product_code
                      accessory_project_code
                      accessory_project_name
                      id
                      project_code
                    }
                  }
            }`
        };
    }
    public static readonly getBdjTypeQuery = () => {
        return {
            "query": `query {
                get_bdgt_typ {
                    body {
                      budget_type
                      description
                      id
                      long_label
                      short_label
                    }
                  }
            }`
        };
    }
    public static readonly getBdjTypeListQuery = () => {
        return {
            "query": `query {
                get_bdgt_typ {
                    body {
                      budget_type
                    }
                  }
            }`
        };
    }
    public static readonly getBdjBaselineQuery = () => {
        return {
            "query": `query {
                get_budget_bl {
                    body {
                      account_type
                      budget_type
                      cost_center_category
                      cost_center_code
                      id
                      line_item
                    }
                  }
            }`
        };
    }
    public static readonly getCCExpLineQuery = () => {
        return {
            "query": `query {
                get_cc_el {
                    body {
                      budget_type
                      cost_center_code
                      id
                      line_item
                      line_item_description
                    }
                }
            }`
        };
    }

    public static readonly getAccountQuery = () => {
        return {
            "query": `query {
                get_accounts {
                    body {
                      account_code
                      budget_type
                      description
                      id
                    }
                }
            }`
        };
    }
    public static readonly getAccCodesQuery = () => {
        return {
            "query": `query {
                get_accounts {
                    body {
                      account_code
                    }
                }
            }`
        };
    }
    public static readonly getCommodityQuery = () => {
        return {
            "query": `query {
                get_comdty {
                    body {
                      commodity
                      description
                      id
                    }
                }
            }`
        };
    }
    public static readonly getExpTypeQuery = () => {
        return {
            "query": `query {
                get_exp_typ {
                    body {
                      budget_type
                      description
                      expense_type
                      id
                    }
                }
            }`
        };
    }
    public static readonly getExpUpdReasonQuery = () => {
        return {
            "query": `query {
                get_exp_up_re {
                    body {
                      description
                      expense_update_reason
                      id
                    }
                }
            }`
        };
    }
    public static readonly getCCCategoryQuery = () => {
        return {
            "query": `query {
                get_cc_cat {
                    body {
                      cost_center_category
                    }
                }
            }`
        };
    }
    public static readonly getLineItemQuery = () => {
        return {
            "query": `query {
                get_line_item {
                    body {
                      line_item
                    }
                }
            }`
        };
    }
    public static readonly getUpdateQuery = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
                ${api_name}(input: ${payload}) {
                    body
                    error
                    erroredCode
                    statusCode
                    updatedCodes
                }
            }`

        };
    }
    public static readonly getFramesProj = () => {
        return {
            "query": `query {
                get_frms_proj {
                    body {
                      code_id
                      code_name
                      code_status
                    }
                }
            }`
        };
    }
    public static readonly getFramesProd = () => {
        return {
            "query": `query {
                get_frms_prod {
                    body {
                      code_id
                      code_name
                      code_status
                    }
                }
            }`
        };
    }
    public static readonly getFramesCC = () => {
        return {
            "query": `query {
                get_frms_cc {
                    body {
                      code_id
                      code_name
                      code_status
                    }
                }
            }`
        };
    }
    public static readonly getUserValidation = (userId: string) => {
        return {
            "query": `query {
                ca_user_validation(emp_login: "${userId}") {
                    statusCode
                    error
                    body {
                      exact_match {
                        employee_login
                        employee_name
                      }
                      fuzzy_match {
                        employee_login
                        employee_name
                      }
                    }
                }
            }`
        };
    }
}
