import Queries from '../queries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';
import ScreenUtils from '../../utils/screenUtils';

export const plAPIs = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getProductLineDetails: build.query({
            query: (PK: string) => ({ method: API_METHODS.post, data: Queries.getProgramOverview(PK) }),
            providesTags: (result, error, PK) => [{ type: 'ProductLineDetails', PK }],
            transformResponse: (response: any) => {
                if (response.data?.data?.get_prog_overview?.body) return ScreenUtils.formatProgramAPIData(response.data?.data?.get_prog_overview?.body);
                return response;
            }
        }),
        updateProductLineSetup: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateProgramSetup) }),
            invalidatesTags: () => [{ type: 'ProductLineDetails' }],
            transformResponse: (response: any) => response.data
        })
    })
});

export const { 
    useGetProductLineDetailsQuery,
    useUpdateProductLineSetupMutation
} = plAPIs;