import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { SpaceBetween, Modal, Box, Button, FormField, Multiselect, MultiselectProps, ColumnLayout, Spinner } from "@cloudscape-design/components";
import { Flashbar } from '@amzn/awsui-components-react';
import { useAppSelector } from '../../../redux/hooks';
import { selectAttrsOrder, selectVersions } from './versionSlice';
import { selectAllPlAttributes, selectAttributesServerData } from '../../../RPT/features/attributes/attributesSlice';
import ScreenUtils from '../../utils/screenUtils';
import { AttributeOrderingTable, IPLAttributes } from './attrsOrderingTable';
import { MANDATORY_VERSION_ATTR, MUTATION_METHODS, STATUS_CODES, USER_ACTION_ROLES } from '../../constants/constants';
import { useGetProgAttrOrderQuery, useGetVersionsQuery, useUpdateAttrOrderMutation } from '../../services/apis/api';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { useGetAttributeListRPNQuery } from 'src/components/redux/services/attributesApi';
import { ALERT_MESSAGES } from '../../constants/userMessages';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import useNotification from 'src/components/Hooks/notifications';

interface IAttributesModalProps {
    setVisible: (arg: boolean) => void;
    hasWriteAccess: boolean;
}

export const AddAttributeModal = ({ setVisible, hasWriteAccess}: IAttributesModalProps) => {
    const { generateOptions, getUserId, getGraphQLPayload } = ScreenUtils;
    const attrOrder = useAppSelector(selectAttrsOrder);
    const plAttr = useAppSelector(selectAllPlAttributes);
    const progAttr = useAppSelector(selectAttributesServerData).allProgAttributes;
    const { program, fpn } = useAppSelector(selectLocationData);
    const { notificationItems, addNotificationItem } = useNotification();
    const versionsData = useAppSelector(selectVersions);
    const { isLoading: verisonLoading, isFetching: versionFetching, isError: versionError } = useGetVersionsQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const { isLoading: attrListLoading, isFetching: attrListFetching } = useGetAttributeListRPNQuery({ verity_rpn_id: program.id }, { skip: !program.id || fpn.id ? true : false });
    const { isLoading: attrOrderLoading, isFetching: attrOrderFetching } = useGetProgAttrOrderQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const [updateAttrOrder, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateAttrOrderMutation();
    const [tableItems, setTableItems] = useState<IPLAttributes[]>([]);
    const [l1Values, setL1Values] = useState<MultiselectProps.Options>(generateOptions(MANDATORY_VERSION_ATTR, MANDATORY_VERSION_ATTR));
    const [l2Values, setL2Values] = useState<MultiselectProps.Options>([]);
    const [l3Values, setL3Values] = useState<MultiselectProps.Options>([]);
    const [l2Options, setL2Options] = useState<MultiselectProps.Options>([]);
    const [l3Options, setL3Options] = useState<MultiselectProps.Options>([]);
    const [untrackedAttr, setUntrackedAttr] = useState<string[]>([]);
    const isLoading = attrOrderLoading || attrOrderFetching || mutationLoading;
    const hasVersions = verisonLoading || versionFetching || versionError || Object.keys(versionsData)?.length;
    const saveDisabled = _.isEqual(tableItems?.map(item => item.attribute), attrOrder) || isLoading || !hasWriteAccess;

    useEffect(() => {
        const l3Attr = plAttr?.map(({ attribute_type }) => attribute_type) ?? [];
        if (plAttr) {
            const existingAttr = attrOrder?.filter(attr => l3Attr.includes(attr));
            setL2Options(generateOptions(l3Attr));
            setL2Values(generateOptions(existingAttr));
            if (progAttr) {
                const l3AttrList = progAttr.map(({ attribute_type }) => attribute_type);
                // These are attributes not part of PRM attributes tab but added from data load directly into DB
                const untracked = attrOrder?.filter(attr => (!l3AttrList.includes(attr) && !l3Attr.includes(attr) && !MANDATORY_VERSION_ATTR.includes(attr)));
                const existingAttr = attrOrder?.filter(attr => l3AttrList.includes(attr));
                setL3Options(generateOptions(l3AttrList));
                setL3Values(generateOptions(existingAttr));
                setUntrackedAttr(untracked);
            }
        }
    }, [progAttr, attrOrder, hasVersions, plAttr]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) addNotificationItem({
                type: "error",
                dismissible: true,
                content: ALERT_MESSAGES.attrOrderFailure,
                id: "ATTRIBUTE_SAVE_FAILED"
            });
            if (mutationData.data && mutationData?.data?.[MUTATION_METHODS.updateAttrOrder]) {
                const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateAttrOrder];
                statusCode === STATUS_CODES.success ? addNotificationItem({
                    type: "success",
                    dismissible: true,
                    content: ALERT_MESSAGES.attrOrderSuccess,
                    id: "ATTRIBUTE_SAVE_SUCCESS"
                }) : addNotificationItem({
                    type: "error",
                    dismissible: true,
                    content: statusCode === STATUS_CODES.handledException ? error : ALERT_MESSAGES.attrOrderFailure,
                    id: "ATTRIBUTE_SAVE_FAILED"
                });
            }
        }
    }, [mutationData, mutationError]);

    useEffect(() => {
        const existingItems: IPLAttributes[] = [];
        let count = 1;
        attrOrder?.forEach(attr => {
            existingItems.push({ attribute: attr, id: count++ });
        });
        const existingAttr = existingItems?.map(item => item.attribute);
        const updatedAttr = [...l1Values, ...l2Values, ...l3Values].map((item: MultiselectProps.Option) => item.value ?? '');
        const added = updatedAttr?.filter(attr => !existingAttr.includes(attr));
        const removed = existingAttr?.filter(attr => (!updatedAttr.includes(attr) && !untrackedAttr.includes(attr)));
        const items = removed.length > 0 ? existingItems
            .filter(obj => !removed.includes(obj.attribute))
            .map((obj, index) => {
                return { attribute: obj.attribute, id: index + 1 };
            }) : [...existingItems];
        added.forEach(attr => items.push({ attribute: attr, id: count++ }));
        setTableItems(items);
    }, [attrOrder, l1Values, l2Values, l3Values]);

    const save = () => {
        const payload = {
            user_id: getUserId(),
            verity_rpn_id: parseInt(program.id),
            attribute_orders: tableItems?.map(item => {
                return { attribute_type: item.attribute, at_concat_order: item.id };
            })
        };
        updateAttrOrder(getGraphQLPayload(payload));
    };

    return (
        <>
            <Modal
                onDismiss={() => setVisible(false)}
                size="large"
                visible={true}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button className='bg-primary' variant="primary" disabled={saveDisabled} onClick={save}>Save</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Attributes ordering"
            >
                <SpaceBetween direction="vertical" size="l">
                    <Flashbar items={notificationItems} stackItems />
                    {/* Temporary commenting until message is decided{untrackedAttr.length > 0 && <Alert statusIconAriaLabel="Warning" type="warning">
                        These attributes are not added to attributes tab: {untrackedAttr.join(', ')}<br/>
                    </Alert>} */}
                    <Box padding={{ bottom: "xl" }} className='border-bottom'>
                        <ColumnLayout columns={3}>
                            <FormField
                                label="L1 attributes"
                            >
                                <Multiselect
                                    selectedOptions={l1Values}
                                    onChange={({ detail }) => setL1Values(detail.selectedOptions)}
                                    options={[]}
                                    disabled={isLoading}
                                    placeholder="Choose options"
                                />
                            </FormField>
                            <FormField
                                label="L2 attributes"
                            >
                                <Multiselect
                                    selectedOptions={l2Values}
                                    onChange={({ detail }) => setL2Values(detail.selectedOptions)}
                                    options={l2Options}
                                    placeholder="Choose options"
                                    loadingText='loading'
                                    disabled={isLoading}
                                    statusType={(attrListLoading || attrListFetching) ? 'loading' : 'finished'}
                                />
                            </FormField>
                            <FormField
                                label="L3 attributes"
                            >
                                <Multiselect
                                    selectedOptions={l3Values}
                                    onChange={({ detail }) => setL3Values(detail.selectedOptions)}
                                    options={l3Options}
                                    placeholder="Choose options"
                                    loadingText='loading'
                                    disabled={isLoading}
                                    statusType={(attrListLoading || attrListFetching) ? 'loading' : 'finished'}
                                />
                            </FormField>
                        </ColumnLayout>
                    </Box>
                    {isLoading ? <> <Spinner /> </> : <AttributeOrderingTable tableItems={tableItems} setTableItems={setTableItems} />}

                </SpaceBetween>
            </Modal>
        </>
    );
};
