import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";
import DeleteCCExpLineRenderer from './cellRenderer/deleteCCExpLineRenderer';
import { tableNames } from '../../constants/constants';

export const REQUIRED = {
    'budget_type': 'Budget Type',
    'long_label': 'Long Label',
    'short_label': 'Short Label'
};
export const COL_DEFS: (ColGroupDef | ColDef)[] = [
    {
        headerName: "Budget Type",
        field: "budget_type",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Long Label",
        field: "long_label",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Short Label",
        field: "short_label",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Description",
        field: "description",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "",
        field: "id",
        editable: false,
        maxWidth: 50,
        cellRenderer: DeleteCCExpLineRenderer,
        cellRendererParams: {
            tableName: tableNames.budgetType
        }
    }
];