import { apiConfig, apiConfigProd, apiConfigBeta } from "../constants/constants";

export default class Endpoints {

    public static readonly stage = 'alpha';

    public static readonly getBaseUrl  = () => {
        if(Endpoints.stage.match('beta') != null)  return apiConfigBeta.baseUrl;
        if(Endpoints.stage.match('prod') != null)  return apiConfigProd.baseUrl;
        return apiConfig.baseUrl;
    }

    public static readonly getSnapshotRefreshUrl = () => ('parser/handler/async_parse_prm_wip')
    public static readonly getSnapshotPRMRefreshUrl = () => ('parser/handler/async_refresh_prm_snap')
    public static readonly getSnapshotCreateUrl = () => ('parser/handler/async_take_prm_snap')

}
