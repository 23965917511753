import React, { useState } from 'react';
import { SpaceBetween, Multiselect, FormField, Modal, Box, Button, MultiselectProps, SelectProps } from "@cloudscape-design/components";

export interface IAddOptionsModal {
    header: string
    options : SelectProps.Options 
    visibile: boolean
    fieldLabel : string
    setVisibile: (arg:boolean) => void,
    submit: (options:MultiselectProps.Options) => void,
}

export const AddOptionsModal = ({ 
    header,
    options, 
    visibile, 
    fieldLabel,
    setVisibile, 
    submit 
}: IAddOptionsModal) => {
    const [selectedOptions, setSelectedOptions] = useState<MultiselectProps.Options>([]);
    return (
        <>
            <Modal
                onDismiss={() => setVisibile(false)}
                visible={visibile}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisibile(false)} variant="link">Cancel</Button>
                            <Button onClick={() => submit(selectedOptions)} variant="primary">Add</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={header}
            >
                <FormField label={fieldLabel}>
                    <Multiselect
                        selectedOptions={selectedOptions}
                        onChange={({ detail }) =>
                            setSelectedOptions(detail.selectedOptions)
                        }
                        deselectAriaLabel={e => `Remove ${e.label}`}
                        options={options}
                        ariaLabel="add-options"
                        placeholder={"Choose options for "+fieldLabel}
                        filteringType="auto"
                        selectedAriaLabel="Selected"
                    />
                </FormField>
            </Modal>

        </>
    );
};
