import React from "react";
import { Select } from '../../Formik-Cloudscape/components/select/select';
import { FormField } from '../../Formik-Cloudscape/components/form-field/form-field';
import { IDynamicFieldProps } from "../Interfaces";

export const SelectField = ({ name, readOnly, label, action, options, filteringType }: IDynamicFieldProps) => {
    return (
        <>
            <FormField stretch label={label} name="select">
                <Select
                    name={name}
                    ariaLabel={name}
                    className="bg-white-disabled"
                    options={options ?? []}
                    placeholder={"Choose option for " + label}
                    selectedAriaLabel="Selected"
                    disabled={readOnly || false}
                    filteringType={filteringType ?? "none"}
                    onChange={({ detail }) => {
                        action(detail.selectedOption, name);
                    }}
                />
            </FormField>
        </>
    )
}