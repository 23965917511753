import { Button } from '@amzn/awsui-components-react';
import React from 'react';


export default function EditSkuBtnRenderer(props: any) {

    const onClick = async () => {
        props.toggleSKUModal(true , props.node.data);
    };


    return(
        <>
            <div className='sku_edit_btn_renderer'>
                <div className='btn'><Button  variant="icon" iconName='edit' onClick={onClick} disabled={!props.hasWriteAccess}/></div>
            </div>
        </>
    );
}
