import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../redux/store';
import { IResponsePayload } from '../../interfaces/interfaces';
import { SK_MAPPING } from '../../constants/constants';

export type KeyDatesState = {
    streetDates: Record<string, IResponsePayload>
    otherDates: Record<string, IResponsePayload>
}
type KeyDatesSliceState = {
    serverData: KeyDatesState,
    clientData: KeyDatesState
};

const initialState: KeyDatesSliceState = {
    serverData: {
        streetDates: {},
        otherDates: {}
    },
    clientData: {
        streetDates: {},
        otherDates: {}
    }
};

export const keyDatesSlice = createSlice({
    name: 'keyDates',
    initialState,
    reducers: {
        resetKeyDates: (state) => {
            state.serverData = initialState.serverData;
            state.clientData = initialState.clientData;
        },
        discardKeyDatesInfoUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateKeyDatesClientData: (state, action) => {
            state.clientData = action.payload;
        },
        updateKeyDatesServerData: (state, action) => {
            state.serverData = action.payload;
        },
        updateStreetDates: (state, action) => {
            state.clientData.streetDates = action.payload;
        },
        updateOtherDates: (state, action) => {
            state.clientData.otherDates = action.payload;
        }
    }
});

export const { resetKeyDates, discardKeyDatesInfoUpdates, updateKeyDatesServerData, updateKeyDatesClientData, updateStreetDates, updateOtherDates } = keyDatesSlice.actions;

export default keyDatesSlice.reducer;

export const selectStreetDates = (state: RootState) => state.keyDates.clientData.streetDates;
export const selectOtherDates = (state: RootState) => state.keyDates.clientData.otherDates;
export const selectKeyDatesServerData = (state: RootState) => state.keyDates.serverData;
export const selectKeyDatesClientData = (state: RootState) => state.keyDates.clientData;
export const selectKeyDatesModified = (state: RootState) => !_.isEqual(state.keyDates.clientData, state.keyDates.serverData);

export const selectKeyDatesSectionServerData = (state: RootState, section: string) => 
    section === SK_MAPPING.streetDates ? state.keyDates.serverData.streetDates : state.keyDates.serverData.otherDates;
export const selectKeyDatesSectionClientData = (state: RootState, section: string) => 
    section === SK_MAPPING.streetDates ? state.keyDates.clientData.streetDates : state.keyDates.clientData.otherDates;
export const selectKeyDatesSectionModified = (state: RootState, section: string) => {
    return section === SK_MAPPING.streetDates ? 
        !_.isEqual(state.keyDates.clientData.streetDates, state.keyDates.serverData.streetDates) : 
        !_.isEqual(state.keyDates.clientData.otherDates, state.keyDates.serverData.otherDates);
};
