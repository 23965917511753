import { createSlice } from '@reduxjs/toolkit';
import { IApiResponse } from '../../../Interfaces/interface';
import { RootState } from '../../../redux/store';
import { STATUS_CODES } from '../../constants/constants';
import { IApiFPNResponse, IApiProductHierarchy, IApiRPNResponse, IIdName, ILocationData, ICountry } from '../../interfaces/interfaces';
import { commonApi } from '../../../redux/services/commonApi';

type AppLayoutState = {
    productHierarchy: IApiProductHierarchy[];
    locationData: ILocationData;
    attributeTypeListMap: Record<string, string[]>;
    allProductLines: Record<string, string>,
    rpnToFPNMap: Record<string, IIdName[]>,
    allRPNs: Record<string, string>,
    countryMap: Record<string, string>;
};

const initialState: AppLayoutState = {
    productHierarchy: [],
    locationData: {
        currentScreen: '',
        productLine: { id: '', name: '' },
        program: { id: '', name: '' },
        fpn: { id: '', name: '' }
    },
    attributeTypeListMap: {},
    allProductLines: {},
    rpnToFPNMap: {},
    allRPNs: {},
    countryMap: {}
};

export const agmAppLayoutSlice = createSlice({
    name: 'agmAppLayout',
    initialState,
    reducers: {
        resetProductHierarchy: (state) => {
            state.productHierarchy = [];
        },
        updateLocationData: (state, action) => {
            state.locationData = action.payload;
        },
        updateAttrTypeListMap: (state, action) => {
            state.attributeTypeListMap = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                commonApi.endpoints.getProductHierarchy.matchFulfilled,
                (state, { payload }) => {
                    if (payload?.data?.get_nav_bar) {
                        const { body, statusCode }: IApiResponse = payload.data.get_nav_bar;
                        if (statusCode === STATUS_CODES.success) {
                            const allProductLines: Record<string, string> = {};
                            const rpnToFPNMap: Record<string, IIdName[]> = {};
                            const allRPNs: Record<string, string> = {};
                            const programsWithFPN: string[] = [];
                            body.forEach((productLine: IApiProductHierarchy) => {
                                allProductLines[productLine.verity_product_line_id] = productLine.product_line;
                                productLine.rpn.forEach((rpn: IApiRPNResponse) => {
                                    allRPNs[rpn.verity_rpn_id] = rpn.rpn;
                                    if (rpn.fpn?.length > 0) programsWithFPN.push(rpn.verity_rpn_id.toString());
                                    rpn.fpn?.forEach((fpn: IApiFPNResponse) => {
                                        const fpnRecord = { id: fpn.verity_fpn_id.toString(), name: fpn.fpn };
                                        rpnToFPNMap[rpn.verity_rpn_id] ? rpnToFPNMap[rpn.verity_rpn_id].push(fpnRecord) : rpnToFPNMap[rpn.verity_rpn_id] = [fpnRecord];
                                    });
                                });
                            });
                            state.allProductLines = allProductLines;
                            state.rpnToFPNMap = rpnToFPNMap;
                            state.allRPNs = allRPNs;
                            state.productHierarchy = body;
                        }
                    }
                }
            )
            .addMatcher(
                commonApi.endpoints.getCountryNames.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const countryMap: Record<string, string> = {};
                        payload.forEach((obj: ICountry) => countryMap[obj.country_2_code] = obj.country);
                        state.countryMap = countryMap;
                    }
                }
            );
    }
});

export const { resetProductHierarchy, updateLocationData, updateAttrTypeListMap } = agmAppLayoutSlice.actions;

export default agmAppLayoutSlice.reducer;

export const selectProductHierarchy = (state: RootState) => state.agmAppLayoutSlice.productHierarchy;
export const selectLocationData = (state: RootState) => state.agmAppLayoutSlice.locationData;
export const selectAttributeTypeListMap = (state: RootState) => state.agmAppLayoutSlice.attributeTypeListMap;
export const selectAllProductLines = (state: RootState) => state.agmAppLayoutSlice.allProductLines;
export const selectRpnToFpnMap = (state: RootState) => state.agmAppLayoutSlice.rpnToFPNMap;
export const selectAllRPNs = (state: RootState) => state.agmAppLayoutSlice.allRPNs;
export const selectCountryMap = (state: RootState) => state.agmAppLayoutSlice.countryMap;
