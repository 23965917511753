import React from 'react';
import { ColumnLayout, Grid, SpaceBetween } from "@cloudscape-design/components";
import { Formik } from 'formik';
import { Form } from '../../../common/Formik-Cloudscape/components/form/form';
import { Multiselect } from '../../../common/Formik-Cloudscape/components/multiselect/multiselect';
import { Select } from '../../../common/Formik-Cloudscape/components/select/select';
import { Toggle } from '../../../common/Formik-Cloudscape/components/toggle/toggle';
import { FormField } from '../../../common/Formik-Cloudscape/components/form-field/form-field';
import { API_RESPONSE_FIELDS, HELPER_SEPARATOR } from '../../constants/constants';
import { IAttributesFormProps, IFormInputProps } from '../../interfaces/interfaces';

export const StandardAttributeForm = ({ formInputs, action, hideToggle }: IAttributesFormProps) => {
    const initialValues: Record<string, any> = {};
    const renderForm = () => {
        formInputs.forEach((obj: any) => {
            if (obj.values) initialValues[obj.name] = obj.values;
            initialValues[obj.name + HELPER_SEPARATOR + API_RESPONSE_FIELDS.isInheritedByFPN] = obj[API_RESPONSE_FIELDS.isInheritedByFPN] ?? false;
        });
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={() => { }}
                >
                    {() => (
                        <Form
                        >
                            <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
                                <SpaceBetween size="xxl">
                                    <ColumnLayout columns={2}>
                                        {formInputs.map(({ name, label, type, all_options, readOnly, toggleDisabled }: IFormInputProps) => {
                                            const toggleName = name + HELPER_SEPARATOR + API_RESPONSE_FIELDS.isInheritedByFPN;
                                            if (name !== 'Comments') {
                                                return (
                                                    <div key={name}>
                                                        <FormField stretch label={label} name="multiselect">
                                                            {!hideToggle && <Toggle name={toggleName}
                                                                disabled={readOnly ? readOnly : toggleDisabled}
                                                                ariaLabel={toggleName}
                                                                onChange={({ detail }) => {
                                                                    action(detail.checked, toggleName);
                                                                }}
                                                            > Same for all FPNs</Toggle>
                                                            }
                                                            {type === 'multiselect' && 
                                                            <Multiselect
                                                                name={name}
                                                                deselectAriaLabel={(e) => 'Remove ' + e.label}
                                                                options={all_options}
                                                                placeholder={"Choose options for " + label}
                                                                selectedAriaLabel="Selected"
                                                                disabled={readOnly}
                                                                filteringType="auto"
                                                                onChange={({ detail }) => {
                                                                    action(detail.selectedOptions, name);
                                                                }}
                                                            /> }
                                                            {type === 'select' && 
                                                            <Select
                                                                name={name}
                                                                options={all_options}
                                                                placeholder={"Choose option for " + label}
                                                                selectedAriaLabel="Selected"
                                                                disabled={readOnly}
                                                                filteringType="auto"
                                                                onChange={({ detail }) => {
                                                                    action(detail.selectedOption, name);
                                                                }}
                                                            />}
                                                        </FormField>
                                                    </div>
                                                );
                                            }
                                        })
                                        }
                                    </ColumnLayout>
                                </SpaceBetween>
                            </Grid>

                        </Form>
                    )}
                </Formik>

            </>
        );
    };
    return (
        <>
            {renderForm()}
        </>
    );
};