import Queries from '../queries/commonQueries';
import { API_METHODS } from '../../constants/constants';
import { verityApi } from 'src/components/redux/api';

const { getCountryNames, getProductLines, getRPNs } = Queries;

export const verityCommonAPI = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getCountries: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: { "query": getCountryNames() } }),
            keepUnusedDataFor: 10000,
            transformResponse: (response: any) => response.data.data?.get_country?.body ?? null
        }),
        getProductLines: build.query<any, void>({
            query: () => ({
                method: API_METHODS.post, data: {
                    "query": getProductLines(),
                    "variables": { "is_deprecated": false, "verity_version": "v_1" }
                }
            }),
            keepUnusedDataFor: 10000,
            transformResponse: (response: any) => response.data.data?.get_product_line?.body ?? null
        }),
        getRPNs: build.query<any, void>({
            query: () => ({
                method: API_METHODS.post, data: {
                    "query": getRPNs(),
                    "variables": { "is_deprecated": false, "is_root_program": true }
                }
            }),
            keepUnusedDataFor: 10000,
            transformResponse: (response: any) => response.data.data?.get_program_v1?.body ?? null
        })
    })
});

export const {
    useGetCountriesQuery,
    useGetProductLinesQuery,
    useGetRPNsQuery
} = verityCommonAPI;