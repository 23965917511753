import Queries from './queries/queries';
import { API_METHODS } from '../../constants/constants';
import { verityApi } from '../api';

export const commonApi = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getProductHierarchy: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: Queries.getProductHierarchy() }),
            providesTags: () => [{ type: 'ProductHierarchy' }],
            transformResponse: (response: any) => response.data
        }),
        getCountryNames: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: Queries.getCountryNames() }),
            keepUnusedDataFor: 10000,
            transformResponse: (response: any) => response.data.data?.get_country?.body ?? null
        })
    
    })
});

export const { 
    useGetProductHierarchyQuery,
    useGetCountryNamesQuery
} = commonApi;