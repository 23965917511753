import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import { verityApi, VERITY_API_REDUCER_KEY } from "./api";
import { appLayoutSlice } from "../RPT/features/appLayout/appLayoutSlice";
import { programOverviewSlice } from "../RPT/features/programOverview/programOverviewSlice";
import { marketInfoSlice } from "../RPT/features/marketInfo/marketInfoSlice";
import { manufacturingInfoSlice } from "../RPT/features/manufacturingInfo/manufacturingInfoSlice";
import { financialInfoSlice } from "../RPT/features/financialInfo/financialInfoSlice";
import { keyDatesSlice } from "../RPT/features/keyDates/keyDatesSlice";
import { attributesSlice } from "../RPT/features/attributes/attributesSlice";
import { specsSlice } from "../RPT/features/specs/specsSlice";
import { verityAppLayoutSlice } from "../Verity/features/appLayout/appLayoutSlice";
import { userPolicySlice } from "../RPT/features/userPolicy/userPolicySlice";
import { agmAppLayoutSlice } from "../AGM/features/appLayout/appLayoutSlice";
import { agmUserPolicySlice } from "../AGM/features/userPolicy/userPolicySlice";
import { versionSlice } from "../AGM/features/versions/versionSlice";
import { assortmentSlice } from '../AGM/features/assortment/assortmentSlice';
import { skuSlice } from "../AGM/features/sku/skuSlice";
import { verityUserPolicySlice } from "../Verity/features/userPolicy/userPolicySlice";

const rootReducer = combineReducers({
    [VERITY_API_REDUCER_KEY]: verityApi.reducer,
    programOverview: programOverviewSlice.reducer,
    userPolicy: userPolicySlice.reducer,
    marketInfo: marketInfoSlice.reducer,
    manufacturingInfo: manufacturingInfoSlice.reducer,
    financialInfo: financialInfoSlice.reducer,
    keyDates: keyDatesSlice.reducer,
    appLayout: appLayoutSlice.reducer,
    attributes: attributesSlice.reducer,
    specs: specsSlice.reducer,
    verityAppLayout: verityAppLayoutSlice.reducer,
    agmAppLayoutSlice: agmAppLayoutSlice.reducer,
    agmUserPolicy: agmUserPolicySlice.reducer,
    version: versionSlice.reducer,
    assortment: assortmentSlice.reducer,
    sku: skuSlice.reducer,
    verityUserPolicy: verityUserPolicySlice.reducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {

    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(verityApi.middleware)
    });
};

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

