import { IValidationResponse } from "../Interfaces/interface";
import { IChangedCells } from '../common/GridTable/GridTableInterface';
import { EditableCallbackParams, ValueFormatterParams, ValueParserParams } from "ag-grid-community";

export default class GridValidation {

    public static readonly validateRequired = (event: Record<string, IChangedCells>, requiredFields: any) => {
        const result: IValidationResponse = {
            error: false,
            missingFields: []
        };
        Object.values(event).forEach((cell) => {
            Object.keys(requiredFields).forEach((field: string) => {
                if (cell.row[field] === '' || cell.row[field] === undefined) {
                    result.error = true;
                    if (!result.missingFields.includes(requiredFields[field])) result.missingFields.push(requiredFields[field]);
                }
            });
        });
        return result;
    }

    public static readonly floatParser = (params: ValueParserParams) => {
        if (params.newValue === '' || params.newValue === null || Number.isNaN(parseFloat(params.newValue))) return params.oldValue;
        return parseFloat(params.newValue);
    }

    public static readonly numberParser = (params: ValueParserParams) => {
        if (params.newValue === '' || params.newValue === null || !Number.isInteger(Number(params.newValue))) return params.oldValue;
        return Number(params.newValue);
    }
  
    public static readonly intToBoolean = (params: ValueFormatterParams) => params.value === 1 ? 'True' : params.value === 0 ? 'False' : ' ';
    
    public static readonly existingRecord = (params: EditableCallbackParams) => params.data.hasOwnProperty('id') ? false : true;

    public static readonly trimValue = (params: ValueParserParams) => params.newValue !== undefined ? params.newValue.trim() : params.newValue;  

    public static readonly isSixDigitDate = (params: ValueParserParams) => {
        const formattedNum = GridValidation.numberParser(params);
        if (formattedNum === undefined) return params.oldValue;
        const month = Number(formattedNum.toString().slice(-2));
        if(formattedNum === 0 || (formattedNum.toString().length === 6 && month <= 12 && month > 0)) return formattedNum;
        return params.oldValue;
    }
   
}