import { useEffect, useState } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';


export default function useNotification() {
    const [notificationItems, setNotificationItems] = useState<FlashbarProps.MessageDefinition[]>([]);

    // Need to remove this method once all files are updated with new method
    const addNotificationItem = (notificationItem: FlashbarProps.MessageDefinition) => {

        setNotificationItems((prevState) => {
            const uniqueID = `${notificationItem.id}-${Date.now()}`;
            const updatedNotification = {
                ...notificationItem,
                id: uniqueID,
                onDismiss: ()=> dismissNotification(uniqueID)
            };
            return [updatedNotification, ...prevState];
        });
    };

    const addNotification = (type: FlashbarProps.Type, id: string, message: string) => {
        setNotificationItems((prevState) => {
            const uniqueID = `${id}-${Date.now()}`;
            const updatedNotification = {
                type: type,
                content: message,
                id: uniqueID,
                onDismiss: ()=> dismissNotification(uniqueID)
            };
            return [updatedNotification, ...prevState];
        });
    };

    const dismissNotification = (notificationID: string) => {
        setNotificationItems((prevState) => {
            return [...prevState.filter(item => item.id !== notificationID)];
        });
    };

    const clearNotifications = () => {
        setNotificationItems([]);
    };

    useEffect(() => {
        clearNotifications();
    }, []);

    return ({
        notificationItems,
        dismissNotification,
        addNotificationItem,
        addNotification,
        clearNotifications
    });
}