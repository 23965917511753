import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../redux/store';
import { IResponsePayload } from '../../interfaces/interfaces';

type SpecsState = {
    serverData: Record<string, IResponsePayload>
    clientData: Record<string, IResponsePayload>
  };

const initialState: SpecsState = {
    serverData: {},
    clientData: {}
};

export const specsSlice = createSlice({
    name: 'specs',
    initialState,
    reducers: {
        resetSpecs: (state) => {
            state.clientData = initialState.clientData;
            state.serverData = initialState.serverData;
        },
        discardSpecsUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateSpecsClientData: (state, action) => {
            state.clientData = action.payload;
        },
        updateSpecsServerData: (state, action) => {
            state.serverData = action.payload;
        }
    }
});

export const { resetSpecs, discardSpecsUpdates, updateSpecsClientData, updateSpecsServerData} = specsSlice.actions;

export default specsSlice.reducer;

export const selectSpecsClientData = (state: RootState) => state.specs.clientData;
export const selectSpecsServerData = (state: RootState) => state.specs.serverData;
export const selectSpecsModified = (state: RootState) => !_.isEqual(state.specs.clientData, state.specs.serverData);



