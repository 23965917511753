import React, { useState, useEffect } from 'react';
import { Alert, Container, Header, SpaceBetween, Spinner, Toggle } from "@cloudscape-design/components";
import { IDynamicFieldProps } from '../../../common/DynamicForm/Interfaces';
import { SEPERATOR, SK_MAPPING, TAB_ID_LABEL, API_RESPONSE_FIELDS, ALL_ASSIGNEES_KEY, USER_ACTION_ROLES, USER_ROLES, ROLE_DEFAULT_VIEW, STAGES, UPDATED_FIELD } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { MARKET_INFO, IFieldMetaData } from '../../constants/fieldData';
import { selectMarketInforClientData, updateMarketInfoClientData, selecMarketInfoModified, selectMarketInforServerData } from './marketInfoSlice';
import ScreenUtils from '../../utils/screenUtils';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import DynamicForm from '../../../common/DynamicForm/DynamicForm';
import { SubmitTab } from '../programSetup/submitTabAction';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import { ProgramAssignees } from '../userPolicy/program/assigneesList';
import { SANDBOX_UNAPPROVED_MESSAGE, VIEW_WIP_BUTTON } from '../../constants/displayMessages';

export const MarketInfo = () => {
    const dispatch = useAppDispatch();
    const marketInfo = useAppSelector(selectMarketInforClientData);
    const serverData = Object.values(useAppSelector(selectMarketInforServerData));
    const locationData = useAppSelector(selectLocationData);
    const isModified = useAppSelector(selecMarketInfoModified);
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [userRole, setUserRole] = useState(USER_ROLES.readonly);
    const [dynamicFormInput, setDynamicFormInput] = useState<IDynamicFieldProps[][]>();
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [viewApproved, setViewApproved] = React.useState(false);
    const stage = serverData?.length ? serverData?.[0].Stage ?? STAGES.sandbox : STAGES.sandbox;
    const submitDisabled = stage === STAGES.submitted || !hasWriteAccess || serverData.length === 0 || (isModified && serverData.length > 0);

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY, TAB_ID_LABEL.marketInfo, TAB_ID_LABEL.marketInfo + SEPERATOR + SK_MAPPING.marketInfo]);
        setUserRole(currRole);
        if (USER_ACTION_ROLES.write.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    const updateState = (fieldValue: any, fieldName: string) => {
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.marketInfo + SEPERATOR + SK_MAPPING.marketInfo + SEPERATOR + fieldName;
        const value = fieldValue.value ?? fieldValue;
        dispatch(updateMarketInfoClientData(ScreenUtils.updateClientState(value, fieldName, API_RESPONSE_FIELDS.itemValues, marketInfo, PK, SK)));
    };

    useEffect(() => {
        const fMetaData: Record<string, IFieldMetaData> = { ...MARKET_INFO };
        Object.entries(fMetaData).forEach(([field]) => {
            const label = fMetaData[field].label;
            fMetaData[field].actions = updateState;
            fMetaData[field].readonly = locationData.fpn.id ? true : !hasWriteAccess || viewApproved;
            if (label.endsWith(UPDATED_FIELD)) fMetaData[field].label = label.slice(0, label.length - UPDATED_FIELD.length);
            if (!viewApproved && marketInfo[field] && !marketInfo[field].Action && marketInfo[field].ItemValues !== marketInfo[field].ItemValuesWIP) fMetaData[field].label += UPDATED_FIELD;
        });
        const stageView = viewApproved ? STAGES.approved : ROLE_DEFAULT_VIEW[userRole];
        setDynamicFormInput(ScreenUtils.generateDynamicFormInput(marketInfo, fMetaData, stageView));
    }, [marketInfo, hasWriteAccess, userRole, viewApproved, locationData.fpn]);


    const loadMarketDetails = () => {
        if (dynamicFormInput) return (
            <>
                <SpaceBetween size='l' >
                    {locationData.fpn.id && <Alert statusIconAriaLabel="Info">
                        Market information is provided at root program level
                    </Alert>
                    }
                    <DynamicForm formInput={dynamicFormInput} />
                </SpaceBetween>
            </>
        );
        return "No data to display.";
    };

    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                {!locationData.fpn.id &&
                    <SubmitTab currentTab='marketInfo'
                        saveDisabled={!hasWriteAccess || !isModified || stage === STAGES.submitted}
                        submitDisabled={submitDisabled}
                        approveDisabled={isModified || !ScreenUtils.isSandboxUpdated(marketInfo)}
                        setIsLoading={setIsUpdateLoading}
                        userRole={userRole}
                        section={SK_MAPPING.marketInfo}
                    />
                }
                {!isUpdateLoading ?
                    <>
                        <Container className=''
                            header={
                                <Header
                                    actions={
                                        <Toggle
                                            onChange={({ detail }) => setViewApproved(detail.checked)}
                                            checked={viewApproved}
                                        >
                                            {VIEW_WIP_BUTTON}
                                        </Toggle>
                                    }
                                    variant="h2"
                                    info={<ProgramAssignees
                                        tab={TAB_ID_LABEL.marketInfo}
                                        section={SK_MAPPING.marketInfo}
                                    />}
                                >
                                    Market information
                                </Header>
                            }
                            footer={<><strong>{UPDATED_FIELD}</strong> {SANDBOX_UNAPPROVED_MESSAGE}</>}
                        >
                            {loadMarketDetails()}
                        </Container>
                    </> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </SpaceBetween>
        </>
    );
};
