import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Flashbar, FormField, Grid, Input, MultiselectProps, Select, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useAppSelector } from '../../../../../redux/hooks';
import { useGetSnrUserPolicyQuery, useGetCurrSnrUserPolicyDataQuery, useAddSnrUserAccessMutation } from '../../../../services/apis/userPolicyApi';
import { IApiResponse, IApiUpdateResponse } from "../../../../../Interfaces/interface";
import { ALL_ASSIGNEES_KEY, MUTATION_ACTION, MUTATION_METHODS, PERSON_TYPE, STATUS_CODES, USER_ACTION_ROLES, USER_ROLES } from "../../../../constants/constants";
import ScreenUtils from "../../../../utils/screenUtils";
import { ExistingPermissions, IPermissions } from './existingAccessTable';
import { selectUserPolicy } from "../../userPolicySlice";
import { ACCESS_DENIED_MESSAGES, USER_POLICY_MESSAGES } from "../../../../constants/userMessages";
import useNotification from "src/components/Hooks/notifications";

export const ScenarioAdminPanel = () => {
    const [userAlias, setUserAlias] = useState("");
    const [searchAlias, setSearchAlias] = useState("");
    const policyData = useAppSelector(selectUserPolicy);
    const [existingPermissions, setExistingPermissions] = useState<IPermissions[]>([]);
    const [selectedRole, setSelectedRole] = useState<MultiselectProps.Option>({});
    const [hasAccess, setHasAccess] = useState(true);
    const { getGraphQLPayload, getUserId } = ScreenUtils;
    const { data: userPolicyData, isError: userPolicyError, isLoading: policyLoading, isFetching: policyFetching, refetch } = useGetSnrUserPolicyQuery({ userName: searchAlias }, { skip: !searchAlias });
    const { isLoading: currUserAccessLoading, isFetching: currUserAccessFetching } = useGetCurrSnrUserPolicyDataQuery({ userName: getUserId() });
    const [addUserAccess, { isLoading: addMutationLoading, data: addMutationData, isError: addMutationError }] = useAddSnrUserAccessMutation();
    const [deleteUserAccess, { isLoading: deleteMutationLoading, data: deleteMutationData, isError: deleteMutationError }] = useAddSnrUserAccessMutation();
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();

    // Only 1 role assigned at the APP level.
    const roles = [USER_ROLES.scenarioConfigMgr];

    useEffect(() => {
        USER_ACTION_ROLES.adminPanel.some(role => policyData?.[role]) ? setHasAccess(true) : setHasAccess(true);
    }, [policyData]);

    useEffect(() => {
        const items: IPermissions[] = [];
        let count = 1;
        let hasError = false;
        if (userPolicyData?.data?.get_user_access_snro) {
            const { statusCode, body }: IApiResponse = userPolicyData.data?.get_user_access_snro;
            if (statusCode === STATUS_CODES.success) {
                body?.forEach((obj: any) => {
                    if (obj.roles?.length && obj.resource?.name) {
                        obj.roles.forEach((roleObj: any) => {
                            const role = roleObj.name;
                            const item = {
                                id: count++,
                                role: role,
                                resource: {
                                    resource: obj.resource,
                                    role: roleObj
                                }
                            };
                            items.push(item);
                        });
                    }
                });
                setExistingPermissions(items);
            } else hasError = true;
        }
        if (userPolicyData?.errors || userPolicyError) hasError = true;
        if (hasError) addNotificationItem({
            type: "error",
            dismissible: true,
            content: USER_POLICY_MESSAGES.userPolicyGetError,
            id: "USER_POLICY_ERROR"
        });
    }, [userPolicyData]);

    const assign = async () => {
        if (selectedRole?.value) {
            const addPayload = {
                action: MUTATION_ACTION.addPolicy,
                item: {
                    roles: [{ name: selectedRole.value }],
                    resources: { name: ALL_ASSIGNEES_KEY },
                    users: { type: PERSON_TYPE, value: userAlias }
                }
            };
            await addUserAccess(getGraphQLPayload(addPayload)).unwrap();
        }
    };

    const removeAccess = async (res: any) => {
        if (res.resource && res.role) {
            const deletePayload = {
                action: MUTATION_ACTION.deletePolicy,
                item: {
                    roles: res.role,
                    resources: res.resource,
                    users: { type: PERSON_TYPE, value: userAlias }
                }
            };
            await deleteUserAccess(getGraphQLPayload(deletePayload)).unwrap();
        }
    };

    const handleResp = (resp: any, error: boolean, action: string) => {
        const errMsg = action === MUTATION_ACTION.deletePolicy ? USER_POLICY_MESSAGES.removePolicyError : USER_POLICY_MESSAGES.addPolicyError;
        const succMsg = action === MUTATION_ACTION.deletePolicy ? USER_POLICY_MESSAGES.removePolicySuccess : USER_POLICY_MESSAGES.addPolicySuccess;
        if (resp?.errors || error) addNotificationItem({
            type: "error",
            dismissible: true,
            content: errMsg,
            id: "POLICY_ERROR"
        });
        if (resp?.data && resp?.data?.[MUTATION_METHODS.addUserAccessSnr]) {
            const { statusCode, error }: IApiUpdateResponse = resp.data[MUTATION_METHODS.addUserAccessSnr];
            statusCode === STATUS_CODES.success ? addNotificationItem({
                type: "success",
                dismissible: true,
                content: succMsg,
                id: "POLICY_SUCCESS"
            }) :
                addNotificationItem({
                    type: "error",
                    dismissible: true,
                    content: statusCode === STATUS_CODES.handledException ? error : errMsg,
                    id: "POLICY_ERROR"
                });
            refetch();
        }
    };
    useEffect(() => {
        handleResp(addMutationData, addMutationError, MUTATION_ACTION.addPolicy);
    }, [addMutationData, addMutationError]);

    useEffect(() => {
        handleResp(deleteMutationData, deleteMutationError, MUTATION_ACTION.deletePolicy);
    }, [deleteMutationData, deleteMutationError]);

    return (
        <>
            {(currUserAccessLoading && currUserAccessFetching) ? <Spinner /> :
                <>
                    {hasAccess ?
                        <SpaceBetween size="xl">
                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                                <FormField label="User alias">
                                    <Input
                                        value={userAlias}
                                        placeholder="Enter user alias to fetch permissions"
                                        onChange={event =>
                                            setUserAlias(event.detail.value)
                                        }
                                    />
                                </FormField>
                                <FormField label="&nbsp; ">
                                    <Button
                                        iconName="search"
                                        variant="primary"
                                        onClick={() => {
                                            setSearchAlias(userAlias);
                                            clearNotifications();
                                        }}>
                                    </Button>
                                </FormField>
                            </Grid>
                            <Flashbar items={notificationItems} stackItems />
                            {(policyLoading || policyFetching || deleteMutationLoading) ? <><Spinner /></> :
                                <>
                                    {searchAlias.length > 0 &&
                                        <>
                                            <Container>
                                                <SpaceBetween size="xl">
                                                    <Grid gridDefinition={[{ colspan: 4 }]}>
                                                        <FormField label="Role">
                                                            <Select
                                                                selectedOption={selectedRole}
                                                                onChange={({ detail }) => {
                                                                    setSelectedRole(detail.selectedOption);
                                                                }
                                                                }
                                                                options={ScreenUtils.generateOptions(roles)}
                                                            />
                                                        </FormField>
                                                    </Grid>
                                                    <FormField>
                                                        <Button disabled={!selectedRole?.value} loading={addMutationLoading} variant="primary" onClick={assign}>Assign</Button>
                                                    </FormField>

                                                </SpaceBetween>
                                            </Container>
                                            <Container footer="* indicates All">
                                                < ExistingPermissions tableItems={existingPermissions} removeAccess={removeAccess} />
                                            </Container>
                                        </>
                                    }
                                </>
                            }
                        </SpaceBetween> :
                        <>
                            <Alert type="error" header="Access Denied">
                                {ACCESS_DENIED_MESSAGES.adminPanel}
                            </Alert>
                        </>
                    }
                </>
            }
        </>
    );
};