import React, { useEffect, useState } from 'react';
import { SpaceBetween, Modal, Box, Button, Alert } from "@cloudscape-design/components";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateOpenCreateFPNModal, selectOpenCreateFPNModal, selectAllPlAttributes, selectAttributesClientData, selectPlFPNRule } from './attributesSlice';
import { IAttributesOrdering } from '../../interfaces/interfaces';
import { AttributeOrderingPanel, ATTRIBUTE_TYPE } from './attributeOrderingPanel';

export const AttributeOrderingModal = ({ setShowCreateModal, setFpnCreationOrder }: any) => {
    const dispatch = useAppDispatch();
    const showModal = useAppSelector(selectOpenCreateFPNModal);
    const attrClientData = useAppSelector(selectAttributesClientData);
    const plFpnRule = useAppSelector(selectPlFPNRule);
    const allPLAttr = useAppSelector(selectAllPlAttributes);
    const attrClientValues = attrClientData.allAttributesValues;
    const [tableItems, setTableItems] = useState<IAttributesOrdering[]>([]);
    const [missingAttr, setMissingAttr] = useState<string[]>([]);
    const [hasReqAttr, setHasReqAttr] = useState(false);

    useEffect(() => {
        if (attrClientData && allPLAttr) {
            const items: IAttributesOrdering[] = [];
            let count = 1;
            let hasReq = false;
            const missingAttrVal: string[] = [];
            const attrTypeLevelMap: Record<string, string> = {};
            allPLAttr.forEach((item) => {
                attrTypeLevelMap[item.attribute_type] = ATTRIBUTE_TYPE.productLine;
            });
            attrClientData.allProgAttributes.forEach(item => {
                attrTypeLevelMap[item.attribute_type] = ATTRIBUTE_TYPE.program;
            });
            // Attributes part of PL FPN Rule will only be part of FPN
            plFpnRule?.forEach(item => {
                const attrType = item.attribute_type;
                if (attrType in attrTypeLevelMap) {
                    if (!attrClientValues?.[attrType]?.length) missingAttrVal.push(attrType);
                    if (item.is_required && attrTypeLevelMap[attrType] === ATTRIBUTE_TYPE.productLine) hasReq = true;
                    items.push({
                        id: count++,
                        attribute: attrType,
                        type: attrTypeLevelMap[attrType],
                        isRequired: item.is_required
                    });
                }
            });
            setMissingAttr(missingAttrVal);
            setTableItems(items);
            setHasReqAttr(hasReq);
        }
    }, [attrClientData, allPLAttr]);

    const next = () => {
        setFpnCreationOrder(tableItems);
        setShowCreateModal(true);
        dispatch(updateOpenCreateFPNModal(false));
    };


    return (
        <>
            <Modal
                onDismiss={() => dispatch(updateOpenCreateFPNModal(false))}
                size="medium"
                visible={showModal}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => dispatch(updateOpenCreateFPNModal(false))} variant="link">Cancel</Button>
                            <Button className='bg-primary' variant="primary" disabled={missingAttr.length > 0 || (!tableItems.length && hasReqAttr) ? true : false} onClick={next}>Next</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Attributes order"
            >
                {missingAttr.length > 0 && <Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header=""
                > Missing attribute values for: {missingAttr.join(', ')}
                </Alert>}
                <AttributeOrderingPanel tableItems={tableItems} />

            </Modal>
        </>
    );
};
