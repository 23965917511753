import { IChangedCells } from '../../../common/GridTable/GridTableInterface';
import { NA_LABEL } from '../../constants/constants';

export default () => {

    const getDuplicates = (data: Record<string, any>) => {
        const newVersionDesc = Object.values(data).filter(item => !item.version_id);
        return newVersionDesc.filter(newRec => {
            return Object.values(data).filter(obj => (newRec.description === obj.description && newRec.rowId !== obj.rowId)).length;
        }).map(item => item.description);
    };

    const getUpdatedIdDataMap = (event: Record<string, IChangedCells>, versionsData: Record<string, Record<string, any>>) => {
        const allRows: Record<string, any> = {};
        Object.values(event).forEach((cell) => allRows[cell.row.version_id ?? cell.row.rowId] = cell.row);
        Object.values(versionsData).forEach(item => {
            if (!allRows[item.version_id]) allRows[item.version_id] = item;
        });
        return allRows;
    };

    const getMissingAttributes = (data: Record<string, any>, attrOrder: string[]) => {
        const missingAttr: string[] = [];
        Object.values(data).forEach((item) => {
            missingAttr.push(...attrOrder.filter(attr => !item[attr]?.length));
        });
        return Array.from(new Set(missingAttr));
    };

    const generateMissingAttrPayload = (data: Record<string, any>, attrOrder: string[]) => {
        const payload: Record<string, any> = {};
        Object.values(data).forEach((item) => {
            attrOrder.forEach(attr => {
                if (!item[attr]?.length && item.version_id) {
                    const cellId = item.version_id ?? item.rowId;
                    const newItem = { attribute_type: attr, attribute_value: NA_LABEL, ...(item.version_id && {version_id: item.version_id}) };
                    payload[cellId] ? payload[cellId].push(newItem) : payload[cellId] = [newItem];
                }
            });
        });
        return payload;
    };

    return { getDuplicates,  getUpdatedIdDataMap, getMissingAttributes, generateMissingAttrPayload};
};
