import React from 'react';
import { RouterContent } from '../components/AppComponents/RouterContent';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Provider } from 'react-redux';
import { store } from './redux/store';

export default function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    });
    return (
        <>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <RouterContent />
                </QueryClientProvider>
            </Provider>
        </>
    );
}

