import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const [ccGrpData, setCcGrpDataData] = useState();
    const queryClient = useQueryClient();

    const fetchCostCenterGroup = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getCostCenterGroupQuery());
        const { body }: IApiResponse = response.data.data.get_cc_gr;
        return body;
    };
    const updateCostCenter = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_cc_gr'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_cc_gr as IApiUpdateResponse;
    };

    const useCostCenterGroupData = () => {
        return useQuery(
            ['costCenterGroup'],
            fetchCostCenterGroup,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                    setCcGrpDataData(data);
                }
            }
        );
    };
    const useUpdatedCCGrpData = () => {
        return useMutation(
            (requestData) => updateCostCenter(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['costCenterGroup']);
                }
            }
        );
    };

   
    return { componentData, ccGrpData, useCostCenterGroupData, useUpdatedCCGrpData };
};


