
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'src/components/redux/hooks';

import { Box, Button, Checkbox, ColumnLayout, FlashbarProps, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useUpdateMarketPlaceMutation } from '../../services/apis/api';
import { getMarketPlaces } from './skuSlice';
import { ERROR_STRINGS, STATUS_CODES, SUCCESS_STRING } from '../../constants/constants';
import { marketplaceOption } from '../../interfaces/skuInterface';


export default function EditMarketPlaceModal({ visible, setEditMPModalVisible, addNotificationItem, editingSKU }: {
    visible: boolean,
    setEditMPModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    addNotificationItem: (notificationItem: FlashbarProps.MessageDefinition) => void,
    editingSKU: any
}) {

    //hooks

    //from store
    const marketPlaces = useAppSelector(getMarketPlaces);

    //state
    const [savingSku, setSavingSku] = useState(false);
    const [selectedMPs, setSelectedMPs] = useState<Record<string, marketplaceOption>>({});
    const [isDisabled, setIsDisabled] = useState(true);

    //Refs
    const payload = useRef<Record<string, {action: string, marketplace: string}>>({});

    //locals
    const [updateMarketPlace] = useUpdateMarketPlaceMutation();

    const updateMPHandler = async () => {
        try {
            const payloadValues = Object.values(payload.current);
            if(payloadValues.length){
                setSavingSku(true);
                const response = await updateMarketPlace({
                    sku_id: editingSKU.sku_id,
                    marketplaces: payloadValues
                }).unwrap();
                if (response.statusCode === STATUS_CODES.success) {
                    addNotificationItem({
                        type: "success",
                        dismissible: true,
                        content: SUCCESS_STRING.MARKETPLACE_SAVE_SUCCESS,
                        id: "MARKETPLACE_SAVE_SUCCESS"
                    });
                } else {
                    throw new Error(response.error);
                }
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: ERROR_STRINGS.MARKETPLACE_SAVE_FAILED,
                id: "MARKETPLACE_SAVE_FAILED"
            });
        } finally {
            setSavingSku(false);
            setEditMPModalVisible(false);
        }
    };

    const getCheckBoxes = () => {
        return Object.values(selectedMPs).map((marketplace) => {
            return (
                <Checkbox key={marketplace.marketplace}
                    onChange={({ detail }) => {

                        setSelectedMPs((prevState) => {
                            return {
                                ...prevState,
                                [marketplace.marketplace]: {
                                    ...marketplace,
                                    checked: detail.checked
                                }
                            };
                        });

                        if(payload.current[marketplace.marketplace]){
                            delete payload.current[marketplace.marketplace];
                        }else {
                            payload.current[marketplace.marketplace] =  {
                                action: detail.checked ? "add" : "delete",
                                marketplace: marketplace.marketplace
                            };
                        }
                    }}
                    checked={marketplace.checked}
                    disabled={marketplace.disabled}
                >
                    {marketplace.marketplace}
                </Checkbox>
            );
        });
    };

    useEffect(() => {
        Object.keys(payload.current).length && Object.values(selectedMPs).filter((item)=> item.checked).length ? setIsDisabled(false) : setIsDisabled(true);
    }, [selectedMPs]);


    useEffect(() => {
        setSelectedMPs(() => {
            return Object.values(marketPlaces).reduce((map, item)=>{
                map[item.marketplace] = {
                    ...item,
                    checked: (editingSKU.marketPlace?.includes(item.marketplace) || editingSKU.additional_marketplaces?.includes(item.marketplace))?? false,
                    disabled: editingSKU.marketPlace?.includes(item.marketplace) ?? false
                };

                return map;
            }, {} as Record<string, marketplaceOption>);
        });
    }, [marketPlaces]);

    return (
        <>
            <Modal
                onDismiss={() => setEditMPModalVisible(false)}
                visible={visible}
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setEditMPModalVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={updateMPHandler} loading={savingSku} disabled={isDisabled}>Update MarketPlace</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Edit MarketPlace"
            >
                <SpaceBetween size='l'>
                    <ColumnLayout columns={2}>
                        {getCheckBoxes()}
                    </ColumnLayout>
                </SpaceBetween>
            </Modal>
        </>
    );
}
