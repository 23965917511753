import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../redux/store';
import { IResponsePayload } from '../../interfaces/interfaces';

type MarketInfoState = {
    serverData: Record<string, IResponsePayload>
    clientData: Record<string, IResponsePayload>
  };

const initialState: MarketInfoState = {
    serverData: {},
    clientData: {}
};

export const marketInfoSlice = createSlice({
    name: 'marketInfo',
    initialState,
    reducers: {
        resetMarketInfo: (state) => {
            state.clientData = initialState.clientData;
            state.serverData = initialState.serverData;
        },
        discardMarketInfoUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateMarketInfoClientData: (state, action) => {
            state.clientData = action.payload;
        },
        updateMarketInfoServerData: (state, action) => {
            state.serverData = action.payload;
        }
    }
});

export const { resetMarketInfo, discardMarketInfoUpdates, updateMarketInfoClientData, updateMarketInfoServerData} = marketInfoSlice.actions;

export default marketInfoSlice.reducer;

export const selectMarketInforClientData = (state: RootState) => state.marketInfo.clientData;
export const selectMarketInforServerData = (state: RootState) => state.marketInfo.serverData;
export const selecMarketInfoModified = (state: RootState) => !_.isEqual(state.marketInfo.clientData, state.marketInfo.serverData);