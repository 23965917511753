import Queries from '../queries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

export const snroSnapshotAPIs = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getPRMScenarios: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: {
                "query": Queries.getPRMScenarios(),
                "variables": {}
            } }),
            transformResponse: (response: any) => response.data
        })
    })
});

export const {
    useGetPRMScenariosQuery
} = snroSnapshotAPIs;