import { Button } from '@amzn/awsui-components-react';
import React from 'react';

export default function MarketPlaceRenderer(props: any) {

    const onClick = async () => {
        props.toggleMPEditModal(true , props.node.data);
    };

    return(
        <>
            <div className='marketplace_renderer'>
                {props.value} <div className='btn'><Button  variant="icon" iconName='edit' onClick={onClick} disabled={!props.hasWriteAccess}/></div>
            </div>
        </>
    );
}
