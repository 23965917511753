import Queries from '../queries/queries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

const { getUserAccess } = Queries;

export const agmUserPolicyApi = verityApi.injectEndpoints({
    endpoints: (build) => ({
        // Curent logged in user access
        getAGMUserPolicyData: build.query({
            query: ({ userName }) => ({
                method: API_METHODS.post, data: {
                    "query": getUserAccess(), 
                    "variables": { "user_name": userName}
                }
            }),
            providesTags: () => [{ type: 'AGMUserPolicy' }],
            transformResponse: (response: any) => response.data
        }),
        // Fetch access for other users : used in admin panel
        getAGMOtherUserPolicyData: build.query({
            query: ({ userName }) => ({
                method: API_METHODS.post, data: {
                    "query": getUserAccess(), 
                    "variables": { "user_name": userName}
                }
            }),
            transformResponse: (response: any) => response.data
        }),
        updateAGMUserAccess: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateUserPolicy) }),
            transformResponse: (response: any) => response.data
        })
    })
});

export const { 
    useGetAGMUserPolicyDataQuery,
    useGetAGMOtherUserPolicyDataQuery,
    useUpdateAGMUserAccessMutation
} = agmUserPolicyApi;