import React, { useState } from 'react';
import {
    AppLayout,
    Box,
    Container,
    ContentLayout,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { Outlet } from 'react-router-dom';
import { SideNav } from './sideNav';
import { AlertMessageProvider } from '../../components/common/Contexts/alertMessageContext';
import { TopNav } from 'src/components/common/Header/topNav';
import { appName } from 'src/constants/constants';

export function CoaAppLayout() {
    const [hideSideNav] = useState<boolean>(false);
    const [navigationOpen, setNavigationOpen] = useState<boolean>(true);

    const onNavigationChange = () => {
        setNavigationOpen(!navigationOpen);
    };

    return (
        <>
            <TopNav appName={appName} />
            <AppLayout
                id="appLayout"
                contentHeader={<></>}
                content={
                    <ContentLayout
                        disableOverlap
                    >
                        <Box>
                            <SpaceBetween size="l">
                                <AlertMessageProvider>
                                    <Container>
                                        <Outlet />
                                    </Container>
                                </AlertMessageProvider>
                            </SpaceBetween>
                        </Box>
                    </ContentLayout>
                }
                navigation={<SideNav />}
                navigationHide={hideSideNav}
                navigationOpen={navigationOpen}
                onNavigationChange={() => onNavigationChange()}
                toolsHide={true}
                contentType="default"
            />

        </>
    );
}