import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Container, DatePicker, Header, SpaceBetween, Spinner, Table, TableProps, TextContent, Toggle } from "@cloudscape-design/components";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectStreetDates, updateStreetDates } from './keyDatesSlice';
import ScreenUtils from '../../utils/screenUtils';
import { SK_MAPPING, TAB_ID_LABEL, SEPERATOR, API_RESPONSE_FIELDS, USER_ACTION_ROLES, ALL_ASSIGNEES_KEY, ATTRIBUTE_TYPE_NAMES, UPDATED_FIELD } from '../../constants/constants';
import { selectLocationData, selectCountryMap } from '../appLayout/appLayoutSlice';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import { selectAttributesServerData, selectLoadingAPIs } from '../attributes/attributesSlice';
import { ProgramAssignees } from '../userPolicy/program/assigneesList';
import { SubmitKeyDates } from './submitKeyDates';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { USER_ROLES } from '../../constants/constants';
import { VIEW_WIP_BUTTON } from '../../constants/displayMessages';
import { FIELD_NAMES } from '../../constants/fieldData';

interface ITableItems {
    code: string
    name: string
    streetDate: string
    isUpdated?: boolean
}

export const StreetDates = () => {
    const dispatch = useAppDispatch();
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const locationData = useAppSelector(selectLocationData);
    const streetDates = useAppSelector(selectStreetDates);
    const standardAttributes = useAppSelector(selectAttributesServerData).allAttributesValues;
    const [fsdItem, setFsdItem] = useState<ITableItems[]>([]);
    const [items, setItems] = useState<ITableItems[]>([]);
    const countryNameMap = useAppSelector(selectCountryMap);
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const [userRole, setUserRole] = useState(USER_ROLES.readonly);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const loadingAttrAPIs = useAppSelector(selectLoadingAPIs);
    const { getUserRole } = UserPolicyHelper();
    const [viewApproved, setViewApproved] = React.useState(false);

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY, TAB_ID_LABEL.keyDates, TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.streetDates]);
        setUserRole(currRole);
        if (USER_ACTION_ROLES.write.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    useEffect(() => {
        const items: ITableItems[] = [];
        const fsdItems: ITableItems[] = [];
        if (standardAttributes && standardAttributes[ATTRIBUTE_TYPE_NAMES.country]) {
            const countries = standardAttributes[ATTRIBUTE_TYPE_NAMES.country];
            countries.forEach((code: string) => {
                const data = streetDates[code];
                const values = viewApproved ? data?.ItemValuesWIP ?? '' : data?.ItemValues ?? '';
                const isUpdated = !viewApproved && data && !data.Action && data.ItemValues !== data.ItemValuesWIP;
                items.push({
                    code: code,
                    name: countryNameMap[code] ?? '',
                    streetDate: values,
                    isUpdated: isUpdated
                });
            });
        }
        // Temporary sorting until BE sends sorted data
        setItems(_.sortBy(items, ['code']));

        // Populate First street date & country
        const countryData = streetDates?.[FIELD_NAMES.fsdCountry];
        const fsdate = streetDates?.[FIELD_NAMES.fsdate];
        const fsdCountryVal = countryData && viewApproved ? countryData?.ItemValuesWIP ?? '' : countryData?.ItemValues ?? '';
        setFsdItem([{
            code: Array.isArray(fsdCountryVal) ? fsdCountryVal.join(', ') : '',
            name: Array.isArray(fsdCountryVal) ? fsdCountryVal.map(code => countryNameMap[code] ?? '').join(', ') : '',
            streetDate: fsdate && viewApproved ? fsdate?.ItemValuesWIP ?? '' : fsdate?.ItemValues ?? ''
        }]);
    }, [streetDates, standardAttributes, countryNameMap, userRole, viewApproved]);

    const updateStreetDateState = (fieldValue: any, fieldName: string) => {
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.streetDates + SEPERATOR + fieldName;
        let updatedData = ScreenUtils.updateClientState(fieldValue, fieldName, API_RESPONSE_FIELDS.itemValues, streetDates, PK, SK);
        if (locationData.fpn.id && streetDates[fieldName]?.SetAsTBD && fieldValue)
            updatedData = ScreenUtils.updateClientState(false, fieldName, API_RESPONSE_FIELDS.setAsTBD, updatedData, PK, SK);
        dispatch(updateStreetDates(updatedData));
    };

    const columnDefinitions: TableProps.ColumnDefinition<ITableItems>[] = [
        {
            id: 'name',
            sortingField: 'name',
            header: 'Country',
            width: 300,
            cell: (item: ITableItems) => item.name
        },
        {
            id: 'code',
            sortingField: 'code',
            header: 'Country code',
            width: 300,
            cell: (item: ITableItems) => {
                return (<>{item.code}<strong>{item.isUpdated && UPDATED_FIELD}</strong></>);
            }
        },
        ...(locationData.fpn.id ? [{
            id: "streetDate",
            header: "Street date",
            cell: (item: ITableItems) => {
                return (<DatePicker
                    value={item.streetDate}
                    readOnly={!hasWriteAccess || viewApproved}
                    onChange={({ detail }) => {
                        updateStreetDateState(detail.value, item.code);
                    }}
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    expandToViewport
                />);
            }
        }] : [{
            id: "streetDate",
            header: "Street date",
            cell: (item: ITableItems) => !Number.isNaN(Date.parse(item.streetDate)) ? item.streetDate : ''
        }])
    ];

    const fsdColumnDefinitions: TableProps.ColumnDefinition<ITableItems>[] = [
        {
            id: 'name',
            sortingField: 'name',
            header: '',
            width: 300,
            cell: (item: ITableItems) => ''
        },
        {
            id: 'code',
            sortingField: 'code',
            header: 'FSD Countries',
            width: 300,
            cell: (item: ITableItems) => {
                return (<>{item.code}<strong>{item.isUpdated && UPDATED_FIELD}</strong></>);
            }
        }, {
            id: "streetDate",
            header: "First Street date",
            cell: (item: ITableItems) => item.streetDate
        }
    ];

    const loadStreetDatesTable = () => {
        return (
            <SpaceBetween size='xl'>
                <Table
                    columnDefinitions={fsdColumnDefinitions}
                    items={fsdItem}
                    variant="embedded"
                    sortingDisabled
                    wrapLines
                ></Table>
                <Table
                    columnDefinitions={columnDefinitions}
                    items={items}
                    variant="embedded"
                    sortingDisabled
                    wrapLines
                ></Table>
            </SpaceBetween>
        );
    };

    return (
        <>
            <Container className=''
                header={
                    <SpaceBetween direction="vertical" size="l" >
                        <Header
                            variant="h2"
                            actions={
                                <Box float="right" variant='span'>
                                    <SubmitKeyDates
                                        setIsUpdateLoading={setIsUpdateLoading}
                                        userRole={userRole}
                                        section={SK_MAPPING.streetDates} />
                                </Box>
                            }
                            info={<ProgramAssignees
                                tab={TAB_ID_LABEL.keyDates}
                                section={SK_MAPPING.streetDates}
                            />}
                        >
                            Street dates
                        </Header>
                        <Box float='right'>
                            <Toggle
                                onChange={({ detail }) =>
                                    setViewApproved(detail.checked)
                                }
                                checked={viewApproved}
                            >
                                {VIEW_WIP_BUTTON}
                            </Toggle>
                        </Box>

                    </SpaceBetween>
                }
            >
                {(isUpdateLoading || loadingAttrAPIs?.length > 0) ?
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div> : <>{loadStreetDatesTable()}</>
                }
            </Container>
        </>

    );
};
