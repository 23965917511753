export const statusCodes = {
    success: 200,
    serverError: 500,
    handledException: 400
};

export const reactQueryStatus = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
};

export const apiConfig = {
    baseUrl:
        "https://telqtutaareu5lg2j6my73pvtm.appsync-api.us-east-1.amazonaws.com/graphql"

};
export const apiConfigBeta = {
    baseUrl:
        "https://wayprhpysjevpnnzzarxmtgsey.appsync-api.us-east-1.amazonaws.com/graphql"

};

export const apiConfigProd = {
    baseUrl:
        "https://dcdttjphbnebnlqbg7mikqz2qy.appsync-api.us-east-1.amazonaws.com/graphql"

};

export const errorMessage = 'Oh no, Something went wrong!';

export const minUserLength = 4;

export const appName = 'Verity';

export const alertMessages = {
    updateSuccess: "Successfully updated data!",
    updateFailure: "Update failed please try again!",
    failedCodes: "Update failed for this code: ",
    updatedCodes: "Update failed for few records. Successfully updated these : ",
    requiredMissing: "Required fields are missing: ",
    deleteSuccess: "Successfully deleted the record!",
    framesFailure: "Failed to fetch FRAMES data. New records cannot be added!"
};

export const tableNames = {
    ccExpLine : 'Cost Center Exp Line',
    budgetType: 'Budget Type'
};
