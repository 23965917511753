import React, { useState } from 'react';
import { Box, Table, TableProps } from "@cloudscape-design/components";
import { Button, Header } from '@amzn/awsui-components-react';
import { ConfirmationModal } from 'src/components/common/Components/ConfirmationAlert/ConfirmationModal';

export interface IPermissions {
    id: number,
    role: string,
    pl: string,
    resource: any
}

export const ExistingPermissions = ({ tableItems, removeAccess }: any) => {
    const [visible, setVisible] = useState(false);
    const [removeRes, setRemoveRes] = useState({});
    const [removeMsg, setRemoveMsg] = useState('');

    const openRemoveModal = (item: IPermissions) => {
        setRemoveRes(item.resource);
        setRemoveMsg(`${item.role} -> ${item.pl}`);
        setVisible(true);
    };

    const remove = () => {
        removeAccess(removeRes);
        setVisible(false);
    };

    const colDef: TableProps.ColumnDefinition<IPermissions>[] = [
        {
            id: 'id',
            header: '#',
            cell: (item: IPermissions) => item.id,
            sortingField: "id",
            maxWidth: 30
        },
        {
            id: 'role',
            header: 'Role',
            cell: (item: IPermissions) => item.role,
            sortingField: "role",
            minWidth: 200
        },
        {
            id: 'product_line',
            header: 'Product line',
            cell: (item: IPermissions) => item.pl,
            sortingField: "pl",
            minWidth: 200
        },
        {
            id: 'Delete',
            cell: (item: IPermissions) => (
                <>
                    <Button iconName="remove" variant="icon" onClick={() => openRemoveModal(item)} />
                </>
            ),
            header: 'Delete',
            width: 550
        }
    ];


    return (
        <>

            <Table
                columnDefinitions={colDef}
                items={tableItems}
                variant="embedded"
                header={
                    <Header>Existing Permissions</Header>
                }
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        No assigned permissions
                    </Box>
                }
            ></Table>
            <ConfirmationModal 
                visible={visible}
                setVisible={setVisible}
                action={remove}
                btnLabel='Remove'
                header='Remove permission'
                type='warning'
                message={'This permission will be removed: '+removeMsg}
            ></ConfirmationModal>
        </>
    );
};
