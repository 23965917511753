import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { marketPlace, skuItem, skuPayloadItem } from '../../interfaces/skuInterface';

type SkuState = {
    skuLoading: boolean,
    skuData: {
        SKUs: skuItem[] | null,
        skuError: any
    },
    assignedAssortments: string[]
    selectedAssortments: Record<string, skuPayloadItem>,
    marketPlaceData: {
        marketPlaces: marketPlace[],
        marketPlacesError: boolean
    }

  };

const initialState: SkuState = {
    skuLoading: false,
    skuData: {
        SKUs: null,
        skuError: null
    },
    assignedAssortments:[],
    selectedAssortments: {},
    marketPlaceData: {
        marketPlaces: [],
        marketPlacesError: false
    }
};

export const skuSlice = createSlice({
    name: 'sku',
    initialState,
    reducers: {
        resetSkuData: (state) => {
            state.skuData = initialState.skuData;
        },

        updateSkuLoading: (state, action) => {
            state.skuLoading = action.payload;
        },

        updateSku: (state, action) => {
            const { SKUs, skuError, skuLoading } =  action.payload;
            state.skuData = {SKUs, skuError};
            state.skuLoading = skuLoading;
        },

        updateAssignedAssortments: (state, action) => {
            state.assignedAssortments = [...action.payload];
        },

        updateSelectedAssortments: (state, action) => {
            state.selectedAssortments = action.payload;
        },

        updateMarketplaceData: (state, action) => {
            state.marketPlaceData = action.payload;
        }

    }
});

export const { resetSkuData, updateSku, updateAssignedAssortments, updateSelectedAssortments, updateMarketplaceData, updateSkuLoading } = skuSlice.actions;

export default skuSlice.reducer;

export const getSku = (state: RootState) => state.sku.skuData;
export const getSkuLoading = (state: RootState) => state.sku.skuLoading;
export const getAssignedAssortments = (state: RootState) => state.sku.assignedAssortments;
export const getSelectedAssortments = (state: RootState) => state.sku.selectedAssortments;
export const getMarketPlaces = (state: RootState) => state.sku.marketPlaceData.marketPlaces;

