import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const [productCodeData, setProductCodeData] = useState();
    const [framesProdData, setFramesProdData] = useState();
    const queryClient = useQueryClient();

    const fetchProducts = async (reqData: any) => {
        const response = await xhr.post(Endpoints.getBaseUrl(), reqData);
        const { body }: IApiResponse = response.data.data.get_products;
        return body;
    };

    const updateProducts = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_products'));
        if (response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_products as IApiUpdateResponse;
    };

    const fetchframesProduct = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getFramesProd());
        const { body }: IApiResponse = response.data.data.get_frms_prod;
        return body;
    };

    const useProductData = () => {
        return useQuery(
            ['product'],
            () => fetchProducts(Queries.getProductsQuery()),
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useProductCodesData = () => {
        return useQuery(
            ['productCodes'],
            () => fetchProducts(Queries.getProductsListQuery()),
            {
                onSuccess: (data) => {
                    setProductCodeData(data);
                }
            }
        );
    };
    const useFramesProdData = () => {
        return useQuery(
            ['framesProdData'],
            fetchframesProduct,
            {   
                retry: false,
                onSuccess: (data) => {
                    setFramesProdData(data);
                }
            }
        );
    };
    const useUpdatedProductsData = () => {
        return useMutation(
            (requestData) => updateProducts(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['product']);
                }
            }
        );
    };

    return { componentData, productCodeData, useProductData, framesProdData, useProductCodesData, useUpdatedProductsData, useFramesProdData };
};


