import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";
import { UserSearchEditor } from './cellEditor/userSearchEditor';

export const REQUIRED = {
    'cost_center_code': 'Cost Center Code',
    'cost_center_name': 'Cost Center Name',
    'cost_center_group': 'Cost Center Group',
    'cost_center_manager': 'Cost Center Manager',
    'organization' : 'Organization',
    'cost_center_function_head' : 'Cost Center Function Head',
    'hc_finance_contact': 'HC Finance Contact',
    'non_hc_finance_contact': 'Non HC Finance Contact'
};
export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Cost Center Code",
        field: "cost_center_code",
        editable: GridValidation.existingRecord,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Cost Center Group",
        field: "cost_center_group",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Cost Center Name",
        field: "cost_center_name",
        editable: false,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Cost Center Type",
        field: "cost_center_type",
        editable: true
    },
    {
        headerName: "Cost Center Function Head",
        field: "cost_center_function_head",
        editable: true,
        cellEditor: UserSearchEditor,
        cellEditorPopup: true,
        suppressPaste: true
    },
    {
        headerName: "Cost Center Manager",
        field: "cost_center_manager",
        editable: true,
        cellEditor: UserSearchEditor,
        cellEditorPopup: true,
        suppressPaste: true
    },
    {
        headerName: "Organization",
        field: "organization",
        editable: true,
        cellEditor: UserSearchEditor,
        cellEditorPopup: true,
        suppressPaste: true
    },
    {
        headerName: "HC Finance Contact",
        field: "hc_finance_contact",
        editable: true
    },
    {
        headerName: "Non HC Finance Contact",
        field: "non_hc_finance_contact",
        editable: true
    },
    {
        headerName: "Depreciation Months",
        field: "depreciation_months",
        editable: true,
        suppressPaste: true,
        valueParser: GridValidation.numberParser
    },
    {
        headerName: "Comments",
        field: "comments",
        editable: true,
        valueParser: GridValidation.trimValue
    }
];