export default class Queries {

    public static readonly getOpenScenario = () => {
        return `query ($dataset : String!) {
            get_open_scenario(dataset: $dataset) {
                body {
                  scenario
                  scenario_id
                }
                error
                statusCode
              }
        }`;
    }

    public static readonly getScenarioMD = () => {
        return `query {
            get_scenario_md {
                statusCode
                error
                body {
                    dataset_name
                }
              }
        }`;
    }

    public static readonly getDeviceKey = () => {
        return `query ($product_line_id: [Int]!, $rpn_id: [Int]!, $channel: [String]!, $country: [String]!) {
                get_device_key_v1(product_line_id: $product_line_id, rpn_id: $rpn_id, channel: $channel, country: $country) {
                    statusCode
                    error
                    body {
                        DTCP
                        channel
                        config_type
                        country
                        device_key
                        fpn
                        fpn_id
                        memory
                        product_line
                        product_line_id
                        rpn
                        rpn_id
                        wireless
                    }
                  }
          }`;
    }

    public static readonly getConfiguration = () => {
        return `query ($dataset: String, $scenario_id: [Int]!) {
            get_configuration(dataset: $dataset, scenario_id: $scenario_id) {
                statusCode
                error
                body {
                    include_config_list
                    scenario
                    dataset
                    verity_scenario_id
                }
              }
        }`;
    }
}