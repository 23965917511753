export default class Queries {

    public static readonly getCountryNames = () => {
        return {
            "query": `query {
                get_country {
                    body {
                        country
                        country_2_code
                    }
                    error
                    statusCode
                  }
          }`
        };
    }

    public static readonly getProductHierarchy = () => {
        return {
            "query": `query {
                get_nav_bar {
                    body {
                      verity_product_line_id
                      rpn {
                        rpn
                        verity_rpn_id
                        is_on_roadmap
                        fpn {
                          verity_fpn_id
                          fpn
                        }
                      }
                      product_line
                    }
                    error
                    statusCode
                }
          }`
        };
    }

    public static readonly getUpdateQuery = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
            ${api_name}(input: ${payload}) {
                body
                error
                statusCode
            }
        }`
        };
    }
    public static readonly getUpdateQueryWithCodes = (payload: any, api_name: string) => {
        return {
            "query": `mutation {
            ${api_name}(input: ${payload}) {
                body
                error
                statusCode
                updatedCodes
                erroredCode
            }
        }`
        };
    }
}