import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../redux/store';
import { IResponsePayload } from '../../interfaces/interfaces';

type ManufacturingInfoState = {
    serverData: Record<string, IResponsePayload>
    clientData: Record<string, IResponsePayload>
  };

const initialState: ManufacturingInfoState = {
    serverData: {},
    clientData: {}
};

export const manufacturingInfoSlice = createSlice({
    name: 'manufacturingInfo',
    initialState,
    reducers: {
        resetManufacturingInfo: (state) => {
            state.clientData = initialState.clientData;
            state.serverData = initialState.serverData;
        },
        discardMFRInfoUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateManufacturingInfoClientData: (state, action) => {
            state.clientData = action.payload;
        },
        updateManufacturingInfoServerData: (state, action) => {
            state.serverData = action.payload;
        }
    }
});

export const { resetManufacturingInfo, discardMFRInfoUpdates, updateManufacturingInfoClientData, updateManufacturingInfoServerData} = manufacturingInfoSlice.actions;

export default manufacturingInfoSlice.reducer;

export const selectManufacturingInforClientData = (state: RootState) => state.manufacturingInfo.clientData;
export const selectManufacturingInforServerData = (state: RootState) => state.manufacturingInfo.serverData;
export const selectMfrInfoModified = (state: RootState) => !_.isEqual(state.manufacturingInfo.clientData, state.manufacturingInfo.serverData);



