import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'accessory_project_code': 'Accessory Project Code',
    'accessory_project_name': 'Accessory Project Name',
    'project_code': 'Project Code',
    'accessory_product_code': 'Accessory Product Code'
};
export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Accessory Project Code",
        field: "accessory_project_code",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Accessory Project Name",
        field: "accessory_project_name",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Project Code",
        field: "project_code",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Accessory Product Code",
        field: "accessory_product_code",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    }
];