import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'commodity': 'Commodity'
};
export const COL_DEFS : (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Commodity",
        field: "commodity",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Description",
        field: "description",
        editable: true,
        valueParser: GridValidation.trimValue
    }
];