import * as React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";

interface headerProps {
    pageName: string;
}
export const PageHeader = (props: headerProps) => {

    return (
        <Header 
            variant="h1" 
            className="page-header">
            {props.pageName}
        </Header>
    )
}
