import React, { ElementRef, forwardRef } from 'react';
import { FastField, Field, FieldProps } from 'formik';
import PolarisToggle, {
  ToggleProps as PolarisToggleProps,
} from '@cloudscape-design/components/toggle';
import { FormikFieldProps } from '../../field-props';

type ToggleProps = FormikFieldProps & Omit<PolarisToggleProps, 'checked'>;
type ToggleRef = ElementRef<typeof PolarisToggle>;

const Toggle = forwardRef<ToggleRef, ToggleProps>(
  ({ name, validate, fast, onChange, onBlur, ...props }, ref) => {
    const DesiredField = fast === true ? FastField : Field;

    return (
      <DesiredField name={name} validate={validate} fast={fast}>
        {({
          field: { value },
          form: { setFieldValue, setFieldTouched },
        }: FieldProps) => (
          <PolarisToggle
            ref={ref}
            name={name}
            checked={value}
            onBlur={(event) => {
              setFieldTouched(name);
              onBlur?.(event);
            }}
            onChange={(event) => {
              setFieldValue(name, event.detail.checked);
              onChange?.(event);
            }}
            {...props}
          />
        )}
      </DesiredField>
    );
  }
);
Toggle.displayName = 'Toggle';

export { Toggle };