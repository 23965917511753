import * as React from "react";
import { Button, Header, SpaceBetween } from "@cloudscape-design/components";
import { selectLocationData, selectCurrentProgramSetupTab } from "./appLayoutSlice";
import { useAppDispatch, useAppSelector } from "src/components/redux/hooks";
import { HOME_PATH_HEADER, DEFAULT_HEADER, TAB_ID, USER_ROLES } from "../../constants/constants";
import { updateOpenCreateFPNModal } from '../attributes/attributesSlice';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';

export const RPTPageHeader = () => {
    const dispatch = useAppDispatch();
    const { currentScreen, productLine, program, fpn } = useAppSelector(selectLocationData);
    const currentTab = useAppSelector(selectCurrentProgramSetupTab);
    const roleToResMap = useAppSelector(selectRoleResourceMap);
    const hasCreateAccess = roleToResMap?.[USER_ROLES.plAdmin]?.length ? true : false;

    const getHeaderName = () => {
        if (fpn.id) return fpn.name;
        if (program.id) return program.name;
        if (productLine.id) return productLine.name;
        if (currentScreen in HOME_PATH_HEADER) return HOME_PATH_HEADER[currentScreen as keyof typeof HOME_PATH_HEADER];
        return DEFAULT_HEADER;
    };

    return (
        <>
            <Header
                variant="h1"
                className="page-header "
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        {currentTab === TAB_ID.attributes && program.id && !fpn.id && hasCreateAccess && <Button ariaLabel="create-fpn" onClick={() => dispatch(updateOpenCreateFPNModal(true))}>Create FPNs</Button>}
                    </SpaceBetween>}
            >
                {getHeaderName()}
            </Header>
        </>

    );

};
