import * as React from "react";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import { SCREEN_PATHS } from "../../constants/constants";

export const VeritySideNav: React.FC<any> = () => {
    const [activeHref, setActiveHref] = React.useState("/");
    return (
        <SideNavigation
            className="sideNav"
            activeHref={activeHref}
            onFollow={(event) => {
                if (!event.detail.external) {
                    setActiveHref(event.detail.href);
                }
            }}
            items={[ { type: "link", text: "Scenario config manager", href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.snroConfig}` }
            ]}
        />
    );
};