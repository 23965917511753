import React from "react";
import { FormField } from '../../Formik-Cloudscape/components/form-field/form-field';
import { Multiselect } from '../../Formik-Cloudscape/components/multiselect/multiselect';
import { IDynamicFieldProps } from "../Interfaces";

export const MultiSelectField = ({ name, readOnly, label, options, action, filteringType }: IDynamicFieldProps) => {
    return (
        <>
            <FormField stretch label={label} name="multiselect">
                <Multiselect
                    name={name}
                    ariaLabel={name}
                    className="bg-white-disabled"
                    deselectAriaLabel={(e) => 'Remove ' + e.label}
                    options={options ?? []}
                    placeholder={"Choose options for " + label}
                    selectedAriaLabel="Selected"
                    disabled={readOnly || false}
                    filteringType={filteringType ?? "none"}
                    onChange={({ detail }) => {
                        action(detail.selectedOptions, name);
                    }}
                />
            </FormField>
        </>
    )
}