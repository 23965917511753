import React, { useState, useEffect } from 'react';
import { Button, Container, Header, MultiselectProps } from "@cloudscape-design/components";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAttributeTypeListMap, selectAllPlAttributes, selectAllStandardAttributes, selectAttributesClientData, updateProgAttrOrder, updateAllAttributeValues, selectUserRole, selectPlFPNRule, selectFPNsList, selectAsinAttributeValues } from './attributesSlice';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { AttributeForm } from './attributeForm';
import { AddOptionsModal } from '../../../common/Components/AddOptionsModal/AddOptionsModal';
import {
    IFormInputProps,
    IRPNAttributeTypeResponse,
    IPlFPNRuleResponse
} from '../../interfaces/interfaces';
import { HELPER_SEPARATOR, NA_LABEL, USER_ACTION_ROLES } from '../../constants/constants';
import ScreenUtils from '../../utils/screenUtils';
import { PopoverInfo } from 'src/components/common/Components/Popover/popover';
import { selectVerityData } from '../programOverview/programOverviewSlice';
import { getAttributesToExclude } from '../../businessRules/attributes';

export const ProgramAttributes = ({ isLoading }: any) => {
    const dispatch = useAppDispatch();
    const attrClientData = useAppSelector(selectAttributesClientData);
    const attributesValues = attrClientData.allAttributesValues;
    const attrTypeData = attrClientData.allProgAttributes;
    const allPLAttributes = useAppSelector(selectAllPlAttributes);
    const allStandardAttributes = useAppSelector(selectAllStandardAttributes);
    const { fpn } = useAppSelector(selectLocationData);
    const allTypeToValues = useAppSelector(selectAttributeTypeListMap);
    const plFpnRule = useAppSelector(selectPlFPNRule);
    const fpnList = useAppSelector(selectFPNsList);
    const programData = useAppSelector(selectVerityData);
    const asinAttrValues = useAppSelector(selectAsinAttributeValues);
    const [existingAttributeTypesToExclude, setExistingAttributeTypesToExclude] = useState<string[]>([]);
    const [formInput, setFormInput] = useState<IFormInputProps[]>([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole)) && !isLoading;

    useEffect(() => {
        const plFpnRules: IPlFPNRuleResponse[] = plFpnRule ?? [];

        // not all attributes should be available options in Program attributes dropdown list
        const attributesToExclude = getAttributesToExclude(programData, allStandardAttributes, allPLAttributes, plFpnRules, attrTypeData);

        setExistingAttributeTypesToExclude(attributesToExclude);
    }, [allStandardAttributes, allPLAttributes, attrTypeData, plFpnRule, programData]);

    useEffect(() => {
        if (attributesValues && attrTypeData) {
            const formProps: IFormInputProps[] = [];
            const asinCt = programData.asinCount;
            const inFPNAttr = plFpnRule?.map(item => item.attribute_type);
            const partOfFpnValues: string[] = [];

            // RPN: For ASIN programs, if attr values are part of any FPNs then those values cannot be removed.
            const existingFPNValues = !fpn.id ? fpnList?.map(item => item.fpn.split('_')?.filter(item => item !== programData.programName))  ?? [] : [];
            if (!fpn.id && asinCt > 0) existingFPNValues.forEach(item => partOfFpnValues.push(...item));
            
            attrTypeData?.forEach(({ attribute_type }: IRPNAttributeTypeResponse) => {
                const allOptions = allTypeToValues[attribute_type] ?? [];
                const isPartOfFPN = inFPNAttr?.includes(attribute_type);
                const disabledValues: string[] = isPartOfFPN ? [...partOfFpnValues] : [];
                let readonly = !hasWriteAccess;

                // RPN/FPN : If values are used in any ASIN (asin_count > 0) then those values cannot be removed.
                if (asinAttrValues[attribute_type]) disabledValues.push(...asinAttrValues[attribute_type]);

                // If FPN doesn't contain any of the attr values then N/A is one of the values used to create FPN
                if (!fpn.id && asinCt > 0 && isPartOfFPN && attributesValues[attribute_type]) {
                    const isNAapplicable = !existingFPNValues.every(fpnArr => fpnArr.some(value => attributesValues[attribute_type].includes(value)));
                    if (isNAapplicable && attributesValues[attribute_type].includes(NA_LABEL)) disabledValues.push(NA_LABEL);
                }
                if (fpn.id) readonly = isPartOfFPN || asinCt > 0 ? true : !hasWriteAccess;
                formProps.push({
                    name: attribute_type,
                    label: attribute_type,
                    readOnly: readonly,
                    removeDisabled: readonly || disabledValues.length > 0,
                    all_options: ScreenUtils.generateOptions(allOptions, disabledValues),
                    IsPartOfFPN: isPartOfFPN,
                    values: attributesValues[attribute_type] ? ScreenUtils.generateOptions(attributesValues[attribute_type], disabledValues) : []
                });
            });
            setFormInput([...formProps]);
        }
    }, [attributesValues, allTypeToValues, attrTypeData, hasWriteAccess, fpnList, programData]);

    const reorderAction = (id: string) => {
        const arr = id.split(HELPER_SEPARATOR);
        const index = parseInt(arr[1]);
        const order = [...attrTypeData];
        const actions: Record<string, () => void> = {
            up: () => [order[index], order[index - 1]] = [order[index - 1], order[index]],
            down: () => [order[index], order[index + 1]] = [order[index + 1], order[index]],
            remove: () => {
                const values = { ...attributesValues };
                values[order[index].attribute_type] = [];
                dispatch(updateAllAttributeValues(values));
                order.splice(index, 1);
            }
        };
        actions[arr[0]]();
        order.forEach((item, idx) => {
            order[idx] = { ...item, rpn_attr_order: idx + 1};
        });
        dispatch(updateProgAttrOrder(order));
    };

    const addNewAttributes = (selectedOptions: MultiselectProps.Options) => {
        const progAttr = [...(attrTypeData ?? [])];
        selectedOptions.forEach((obj: MultiselectProps.Option) => {
            progAttr.push({
                attribute_type: obj.value!,
                rpn_attr_order: progAttr.length + 1,
                in_fpn: false
            });
        });
        dispatch(updateProgAttrOrder(progAttr));
        setShowAddModal(false);
    };

    const updateState = (fieldValue: any, fieldName: string) => {
        const field = fieldName.includes(HELPER_SEPARATOR) ? fieldName.split(HELPER_SEPARATOR)[0] : fieldName;
        if (fieldName.includes(HELPER_SEPARATOR)) {
            const allValues = [...attrTypeData];
            allValues.forEach((item, index) => {
                if (item.attribute_type === field) {
                    const updatedItem = { ...item, in_fpn: fieldValue };
                    allValues[index] = updatedItem;
                }
            });
            dispatch(updateProgAttrOrder(allValues));
        } else {
            const allValues = { ...attributesValues };
            const values = Array.isArray(fieldValue) ? fieldValue.map(item => item.value) : fieldValue.value ?? fieldValue;
            allValues[field] = values;
            dispatch(updateAllAttributeValues(allValues));
        }
    };

    const loadForm = () => {
        if (formInput) {
            return <AttributeForm formInputs={formInput}
                action={updateState}
                reorder={hasWriteAccess}
                reorderAction={reorderAction}
                isFPN={fpn.id ? true : false}
            />;
        } else {
            return "No data to display.";
        }
    };

    const loadAddModal = () => {
        if (showAddModal) {
            const attrList = Object.keys(allTypeToValues).filter(item => !existingAttributeTypesToExclude.includes(item));
            return <AddOptionsModal
                header='Add program attributes'
                fieldLabel='Program attribute'
                options={ScreenUtils.generateOptions(attrList)}
                setVisibile={setShowAddModal}
                visibile={showAddModal}
                submit={addNewAttributes}
            />;
        }
    };
    return (
        <>
            <Container className=''
                header={
                    <Header
                        variant="h2"
                        actions={
                            !fpn.id && <Button disabled={!hasWriteAccess} ariaLabel='add-program-attributes' onClick={() => setShowAddModal(true)}>Add program attributes</Button>
                        }
                    >
                        Program attributes   <PopoverInfo text='Level3' header='Info' message='WIP' />
                    </Header>
                }
            >
                {loadForm()}
            </Container>
            {loadAddModal()}
        </>
    );
};
