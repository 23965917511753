import React from "react";
import { RadioGroup } from '../../Formik-Cloudscape/components/radio-group/radio-group';
import { FormField } from '../../Formik-Cloudscape/components/form-field/form-field';
import { IDynamicFieldProps } from "../Interfaces";

export const RadioGroupField = ({ name, readOnly, label, action, options }: IDynamicFieldProps) => {
    return (
        <>
            <FormField stretch label={label} name="input">
                <RadioGroup name={name}
                    className="rpt-radio-group"
                    ariaLabel={name}
                    items={options}
                    onChange={({ detail }) => {
                        action(detail.value, name);
                    }}
                />
            </FormField>
        </>
    )
}