import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const queryClient = useQueryClient();

    const fetchExpenseType = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getExpTypeQuery());
        const { body }: IApiResponse = response.data.data.get_exp_typ;
        return body;
    };

    const updateExpType = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_exp_typ'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_exp_typ as IApiUpdateResponse;
    };
   

    const useExpenseTypeData = () => {
        return useQuery(
            ['expenseType'],
            fetchExpenseType,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };

    const useUpdatedExpTypeData = () => {
        return useMutation(
            (requestData) => updateExpType(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['expenseType']);
                }
            }
        );
    };

    return { componentData, useExpenseTypeData, useUpdatedExpTypeData };
};


