import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { ContactInformation } from './contactInformation';
import { StatusInformation } from './statusInformation';
import { ProgramOverview } from './programOverview';
import { Documents } from './documents';
import { SubmitOverview } from './submitOverview';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { ALL_ASSIGNEES_KEY, USER_ACTION_ROLES } from '../../constants/constants';
import { updateUserRole } from './programOverviewSlice';
import { OverviewAlert } from './programAlert';
import { CreateProgramPPLS } from './createProgramPPLS';
import { useGetRPNDetailsQuery } from 'src/components/RPT/services/api';
import { selectVerityData } from './programOverviewSlice';
import { ALERT_MESSAGES as AGM_ALERT_MESSAGES } from 'src/components/AGM/constants/userMessages';

export const OverviewHome = () => {
    const dispatch = useAppDispatch();
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const locationData = useAppSelector(selectLocationData);
    const { program, fpn } = locationData;
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [pplsModal, setPplsModal] = useState(false);
    const programData = useAppSelector(selectVerityData);
    const { isLoading: rpnDataLoading, isFetching: rpnDataFetching }= useGetRPNDetailsQuery(parseInt(program.id), { skip: fpn.id !== '' || !program.id });

    
    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        dispatch(updateUserRole(currRole));
        if (USER_ACTION_ROLES.approve.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    return (
        <>
            <OverviewAlert />
            <SpaceBetween direction="vertical" size="xl">
                {!fpn.id && !rpnDataFetching && !rpnDataLoading && !programData.ppls_id?.length &&
                    <Alert
                        statusIconAriaLabel="Warning"
                        type="warning"
                        action={
                            hasWriteAccess && (
                                <Button onClick={() => setPplsModal(true)}>
                                    Create Program in PPLS
                                </Button>
                            )
                        }
                        header={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: hasWriteAccess
                                        ? AGM_ALERT_MESSAGES.pplsIdMissing
                                        : AGM_ALERT_MESSAGES.pplsIdMissingNoAccess
                                }}
                            ></span>
                        }
                    ></Alert>}
                <CreateProgramPPLS setVisible={setPplsModal} visible={pplsModal} />
                {!fpn.id && hasWriteAccess && <Box float='right'><SubmitOverview setIsUpdateLoading={setIsUpdateLoading}  /></Box>}
                {!isUpdateLoading ?
                    <>
                        <ProgramOverview />
                        {!fpn.id && <StatusInformation />}
                        <ContactInformation />
                        <Documents />
                    </> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </SpaceBetween>
        </>

    );
};
