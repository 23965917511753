import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { Grid, Container } from "@amzn/awsui-components-react";
import { ProductContext } from '../components/context/productContext';
import { GridTable } from '../components/common/GridTable/GridTable';
import { IChangedCells, ICustomSettings } from '../components/common/GridTable/GridTableInterface';
import { ColDef, GridOptions } from "ag-grid-community";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { COL_DEFS, REQUIRED } from "../components/gridHelper/productHelper";
import { errorMessage, alertMessages } from '../constants/constants';
import { PageHeader } from '../components/common/Header/pageHeader';
import AlertMessageContext from '../components/common/Contexts/alertMessageContext';
import { GlobalContext } from '../components/context/globalContext';
import GridValidation from '../components/gridHelper/gridValidation';
import { IMutationResp, IValidationResponse } from "../components/Interfaces/interface";
import ScreenUtils from './Utils/ScreenUtils';
import { SearchEditor } from 'src/components/gridHelper/cellEditor/searchEditor';

export const Product = forwardRef((props, ref) => {
    const productContext = useContext(ProductContext);
    const globalContext = useContext(GlobalContext);
    const { componentData, useProductData, useUpdatedProductsData, useFramesProdData, framesProdData } = { ...productContext };
    const { useProductGroupsData, productGroupData } = { ...globalContext };
    const queryResult = useProductData();
    const prodGroupResult = useProductGroupsData();
    const framesResult = useFramesProdData();
    const updateMutation = useUpdatedProductsData();
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [productList, setProductList] = useState<string[]>([]);
    const getCustomSettings = (): ICustomSettings => {
        return {
            className: 'ag-theme-balham',
            uid: "concat_string",
            readOnly: false,
            groupIncludeTotalFooter: true,
            allowLocalFilters: true,
            enableOnChangeHighlight: true,
            filterId: 'products',
            allowAddRow: true,
            numAddRows: 1
        } as ICustomSettings;
    };
    const [customSettings] = useState<ICustomSettings>(getCustomSettings());
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: COL_DEFS,
        rowData: []
    });
    
    useEffect(() => {
        if (componentData && productGroupData) {
            // Variables to store dropdown data
            const prodCodeList: string[] = [];
            const pdGroupList: string[] = [];
            const codeNamesMap = new Map();
            const prodDropdown: string[] = [];
            componentData.forEach((obj: any) => {
                prodCodeList.push(obj.product_code);
            });
            setProductList(prodCodeList);
            productGroupData.forEach((obj: any) => {
                pdGroupList.push(obj.product_group);
            });
            if (framesProdData) {
                framesProdData.forEach((obj: any) => {
                    if (!prodCodeList.includes(obj.code_id)) prodDropdown.push(obj.code_id);
                    codeNamesMap.set(obj.code_id, obj.code_name);
                });
            }
            COL_DEFS.forEach(function (colDef: ColDef) {
                colDef.floatingFilter = true;
                if (colDef.cellEditorParams && colDef.field == 'product_group') {
                    colDef.cellEditorParams.values = pdGroupList;
                }
                if (framesProdData) {
                    if (colDef.field == 'product_code') {
                        colDef.cellEditor = SearchEditor;
                        colDef.cellEditorParams.options = prodDropdown;
                        colDef.valueSetter = (params) => {
                            params.data.product_code = params.newValue;
                            if (codeNamesMap.has(params.newValue)) params.data.product_name = codeNamesMap.get(params.newValue);
                            return true;
                        };
                    }
                }
            });
            setGridOptions((prevState: any) => {
                return {
                    ...prevState,
                    columnDefs: COL_DEFS,
                    rowData: componentData
                };
            });
        } 
    }, [componentData, productGroupData, framesProdData]);

    const onSave = async (event: Record<string, IChangedCells>) => {
        const requestData: Record<string, any> = {};
        const payload: any = [];
        if (event && Object.keys(event).length > 0) {
            const vResponse: IValidationResponse = GridValidation.validateRequired(event, REQUIRED);
            if (vResponse.error) { // Required fields validation
                setError?.(alertMessages.requiredMissing + vResponse.missingFields.join(', '));
            } else {
                Object.values(event).forEach((cell) => {
                    const cellId = cell.row.id ?? cell.row.rowId;
                    const userId = ScreenUtils.getUserId();
                    // Adding new rows
                    if (!cell.row.id && !Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                        requestData[cellId] = {
                            comments: cell.row.comments,
                            geo_market: cell.row.geo_market,
                            is_accessory: cell.row.is_accessory,
                            product_code: cell.row.product_code,
                            product_group: cell.row.product_group,
                            product_name: cell.row.product_name,
                            user_id: userId
                        };
                    }
                    // Updating rows
                    if (cell.row.id && cell.colId) {
                        if (!Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                            requestData[cellId] = {
                                id: cellId,
                                product_code: cell.row.product_code,
                                product_name: cell.row.product_name,
                                user_id: userId
                            };
                        }
                        requestData[cellId][cell.colId] = cell.newValue;
                    }
                });
                Object.keys(requestData).forEach(cellId => {
                    payload.push(requestData[cellId]);
                });
                if (payload.length > 0) updateMutation.mutate(payload);
            }
        }
    };
    useImperativeHandle(ref, () => ({
        onSave(event: Record<string, IChangedCells>) {
            onSave(event);
        }
    }));

    useEffect(() => {
        if (updateMutation.data) {
            const resp: IMutationResp = ScreenUtils.getMutationMessage(updateMutation.data);
            resp.status === 'success' ? setSuccess?.(resp.message) : setError?.(resp.message);
        }
    }, [updateMutation.data]);

    useEffect(() => {
        if (framesResult.error) setError?.(alertMessages.framesFailure);
    }, [framesResult.error]);

    const loadGridTable = () => {
        if (gridOptions.rowData?.length) {
            return (
                <GridTable
                    gridSettings={gridOptions}
                    customSettings={{
                        ...customSettings
                    }}
                    onRefresh={queryResult.refetch}
                    onSave={onSave}
                />
            );
        } else {
            return "No data to display.";
        }
    };

    if (queryResult.isLoading || queryResult.isFetching || prodGroupResult.isLoading || updateMutation.isLoading || framesResult.isLoading) return <div className='loadspinner'><Spinner size="large" /></div>;

    if (queryResult.error || prodGroupResult.error || updateMutation.error) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {errorMessage}
            </Container>
        </Grid>
    );
    return (
        <>
            <PageHeader
                pageName='Product'
            />
            {loadGridTable()}
        </>
    );

});