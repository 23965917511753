import React, { ReactElement, createContext} from "react";
import commodityHelper from './commodityHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";
interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: [];
    useCommodityData: () => UseQueryResult;
    useUpdatedCommodityData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}
const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const CommodityContext = createContext<IProjectsContext>({
    useCommodityData: () => qResp,
    useUpdatedCommodityData: () => mResp
});

export const CommodityContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...commodityHelper()
    };

    return (
        <CommodityContext.Provider value={provider}>
            {children}
        </CommodityContext.Provider>
    );
};
