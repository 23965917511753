import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Flashbar, FormField, Grid, Input, Multiselect, MultiselectProps, Select, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetPRMUserPolicyQuery, useGetUserPolicyDataQuery, useUpdateUserAccessMutation } from '../../../services/apis/userPolicyApi';
import { IApiResponse, IApiUpdateResponse, IValueDescription } from "../../../../Interfaces/interface";
import { ALL_ASSIGNEES_KEY, MUTATION_ACTION, MUTATION_METHODS, PERSON_TYPE, SEPERATOR, STATUS_CODES, USER_ACTION_ROLES, USER_POLICY_SEPERATOR, USER_ROLES, USER_ROLE_DESCRIPTIONS } from "../../../constants/constants";
import { IApiProductHierarchy, IApiRPNResponse } from "../../../interfaces/interfaces";
import ScreenUtils from "../../../utils/screenUtils";
import { ExistingPermissions, IPermissions } from './existingAccessTable';
import { selectAllProductLines, selectAllRPNs, selectProductHierarchy } from "../../appLayout/appLayoutSlice";
import { selectUserPolicy } from "../userPolicySlice";
import { ACCESS_DENIED_MESSAGES, ALERT_MESSAGES } from "../../../constants/displayMessages";
import useNotification from "src/components/Hooks/notifications";

export const PRMAdminPanel = () => {
    const [userAlias, setUserAlias] = useState("");
    const [searchAlias, setSearchAlias] = useState("");
    const plIdNameMap = useAppSelector(selectAllProductLines);
    const rpnNameMap = useAppSelector(selectAllRPNs);
    const policyData = useAppSelector(selectUserPolicy);
    const prodHierarchy = useAppSelector(selectProductHierarchy);
    const [existingPermissions, setExistingPermissions] = useState<IPermissions[]>([]);
    const [selectedRole, setSelectedRole] = useState<MultiselectProps.Option>({});
    const [selectedPL, setSelectedPL] = useState<MultiselectProps.Option>({});
    const [selectedRPNs, setSelectedRPNs] = useState<MultiselectProps.Options>([]);
    const [plOptions, setPlOptions] = useState<MultiselectProps.Option[]>([]);
    const [hasAccess, setHasAccess] = useState(true);
    const [plRpnOptionsMap, setPlRpnOptionsMap] = useState<Record<string, MultiselectProps.Option[]>>({});
    const { getRPNUserPolicyKey, getPLUserPolicyKey, getGraphQLPayload, getNotificationItem, getUserId } = ScreenUtils;
    const { data: userPolicyData, isError: userPolicyError, isLoading: policyLoading, isFetching: policyFetching, refetch } = useGetPRMUserPolicyQuery({ userName: searchAlias }, { skip: !searchAlias });
    const { isLoading: currUserAccessLoading, isFetching: currUserAccessFetching } = useGetUserPolicyDataQuery({ userName: getUserId() });
    const [addUserAccess, { isLoading: addMutationLoading, data: addMutationData, isError: addMutationError }] = useUpdateUserAccessMutation();
    const [deleteUserAccess, { isLoading: deleteMutationLoading, data: deleteMutationData, isError: deleteMutationError }] = useUpdateUserAccessMutation();
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();

    // Attribute Owner & PRM Admin are at application level and can only have * resources
    // PL admins can be assigned at PL or * level
    // Input provider can be assigned at PL or RPN level
    const rolesWithDescriptions: IValueDescription = {
        [USER_ROLES.plAdmin]: USER_ROLE_DESCRIPTIONS.plAdmin,
        [USER_ROLES.inputProvider]: USER_ROLE_DESCRIPTIONS.inputProvider,
        [USER_ROLES.attrOwner]: USER_ROLE_DESCRIPTIONS.attrOwner,
        [USER_ROLES.prmAdmin]: USER_ROLE_DESCRIPTIONS.prmAdmin
    }
    const plValidRoles = [USER_ROLES.plAdmin, USER_ROLES.inputProvider];
    const rpnValidRoles = [USER_ROLES.inputProvider];

    useEffect(() => {
        USER_ACTION_ROLES.adminPanel.some(role => policyData?.[role]) ? setHasAccess(true) : setHasAccess(false);
    }, [policyData]);

    const isAssignDisabled = () => {
        if (searchAlias !== userAlias) return true;
        if (selectedRole?.value) {
            if (plValidRoles.includes(selectedRole?.value) && !selectedPL?.value) return true;
            if (rpnValidRoles.includes(selectedRole?.value) && !selectedRPNs.length) return true;
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (prodHierarchy) {
            const plOpts: MultiselectProps.Option[] = [{ label: 'ALL', value: ALL_ASSIGNEES_KEY, description: "All Product lines will get assigned with the role." }];
            const plRpnOptionsMap: Record<string, MultiselectProps.Option[]> = {};
            prodHierarchy.forEach(({ product_line, verity_product_line_id, rpn }: IApiProductHierarchy) => {
                if (product_line && verity_product_line_id) {
                    plOpts.push({ label: product_line, value: verity_product_line_id.toString() });
                    plRpnOptionsMap[verity_product_line_id] = [{ label: 'ALL', value: ALL_ASSIGNEES_KEY, description: "All Programs will get assigned with the role. Individually selected programs will be ignored." }];
                    rpn.forEach(({ rpn, verity_rpn_id }: IApiRPNResponse) => {
                        if (rpn && verity_rpn_id) plRpnOptionsMap[verity_product_line_id].push({ label: rpn, value: verity_rpn_id?.toString() });
                    });
                }
            });
            setPlOptions(plOpts);
            setPlRpnOptionsMap(plRpnOptionsMap);
        }
    }, [prodHierarchy]);

    useEffect(() => {
        const items: IPermissions[] = [];
        let count = 1;
        let hasError = false;
        if (userPolicyData?.data?.get_user_access) {
            const { statusCode, body }: IApiResponse = userPolicyData.data?.get_user_access;
            if (statusCode === STATUS_CODES.success) {
                body?.forEach((obj: any) => {
                    if (obj.roles?.length && obj.resource?.name) {
                        obj.roles.forEach((roleObj: any) => {
                            const role = roleObj.name;
                            const [pl, rpn] = obj.resource.name.split(SEPERATOR);
                            const [, plId] = pl?.split(USER_POLICY_SEPERATOR) ?? [];
                            const [, rpnId] = rpn?.split(USER_POLICY_SEPERATOR) ?? [];
                            const tabs = (!obj.resource.resources || obj.resource.resources?.length === 0) ? [ALL_ASSIGNEES_KEY] : [];
                            obj.resource.resources?.map((tab: any) => tabs.push(tab.name));
                            tabs.forEach(tabName => {
                                const item = {
                                    id: count++,
                                    role: role,
                                    pl: plId ? plIdNameMap?.[plId] ?? '' : ALL_ASSIGNEES_KEY,
                                    program: rpnId ? rpnNameMap?.[rpnId] ?? '' : ALL_ASSIGNEES_KEY,
                                    tab: tabName,
                                    resource: {
                                        resource: {
                                            name: obj.resource.name,
                                            resources: tabName !== ALL_ASSIGNEES_KEY ? { name: tabName } : null
                                        },
                                        role: roleObj
                                    }
                                };
                                items.push(item);
                            });
                        });
                    }
                });
                setExistingPermissions(items);
            } else hasError = true;
        }
        if (userPolicyData?.errors || userPolicyError) hasError = true;
        if (hasError) addNotificationItem(getNotificationItem("error", "USER_POLICY_ERROR", ALERT_MESSAGES.userPolicyGetError));
    }, [userPolicyData]);

    const assign = async () => {
        const userPolicyResources: any[] = [];
        // Assign * if ALL PLs/RPNs selected
        if (!selectedPL.value || selectedPL.value === ALL_ASSIGNEES_KEY) {
            userPolicyResources.push({
                name: ALL_ASSIGNEES_KEY
            });
        } else {
            const allRpns = selectedRPNs.map((rpn: MultiselectProps.Option) => rpn.value);
            if (!allRpns.length || allRpns.includes(ALL_ASSIGNEES_KEY)) {
                userPolicyResources.push({
                    name: getPLUserPolicyKey(selectedPL.value)
                });
            } else {
                allRpns.forEach(rpn => {
                    userPolicyResources.push({
                        name: getPLUserPolicyKey(selectedPL.value) + SEPERATOR + getRPNUserPolicyKey(rpn)
                    });
                });
            }
        }
        if (userPolicyResources.length) {
            const addPayload = {
                action: MUTATION_ACTION.addPolicy,
                item: {
                    roles: [{ name: selectedRole.value }],
                    resources: userPolicyResources,
                    users: { type: PERSON_TYPE, value: userAlias }
                }
            };
            await addUserAccess(getGraphQLPayload(addPayload)).unwrap();
        }
    };

    const removeAccess = async (res: any) => {
        if (res.resource && res.role) {
            const deletePayload = {
                action: MUTATION_ACTION.deletePolicy,
                item: {
                    roles: res.role,
                    resources: res.resource,
                    users: { type: PERSON_TYPE, value: userAlias }
                }
            };
            await deleteUserAccess(getGraphQLPayload(deletePayload)).unwrap();
        }
    };

    const handleResp = (resp: any, error: boolean, action: string) => {
        const errMsg = action === MUTATION_ACTION.deletePolicy ? ALERT_MESSAGES.removePolicyError : ALERT_MESSAGES.addPolicyError;
        const succMsg = action === MUTATION_ACTION.deletePolicy ? ALERT_MESSAGES.removePolicySuccess : ALERT_MESSAGES.addPolicySuccess;
        if (resp?.errors || error) addNotificationItem(getNotificationItem("error", "POLICY_ERROR", errMsg));
        if (resp?.data && resp?.data?.[MUTATION_METHODS.updateUserAccess]) {
            const { statusCode, error }: IApiUpdateResponse = resp.data[MUTATION_METHODS.updateUserAccess];
            statusCode === STATUS_CODES.success ? addNotificationItem(getNotificationItem("success", "POLICY_SUCCESS", succMsg)) :
                addNotificationItem(getNotificationItem("error", "POLICY_ERROR", statusCode === STATUS_CODES.handledException ? error : errMsg));
            refetch();
        }
    };
    useEffect(() => {
        handleResp(addMutationData, addMutationError, MUTATION_ACTION.addPolicy);
    }, [addMutationData, addMutationError]);

    useEffect(() => {
        handleResp(deleteMutationData, deleteMutationError, MUTATION_ACTION.deletePolicy);
    }, [deleteMutationData, deleteMutationError]);

    return (
        <>
            {(currUserAccessLoading && currUserAccessFetching) ? <Spinner /> :
                <>
                    {hasAccess ?
                        <SpaceBetween size="xl">
                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                                <FormField label="User alias">
                                    <Input
                                        value={userAlias}
                                        ariaLabel="user-alias"
                                        placeholder="Enter user alias to fetch permissions"
                                        onChange={event =>
                                            setUserAlias(event.detail.value)
                                        }
                                    />
                                </FormField>
                                <FormField label="&nbsp; ">
                                    <Button
                                        ariaLabel="search-access"
                                        iconName="search"
                                        variant="primary"
                                        onClick={() => {
                                            setSearchAlias(userAlias);
                                            clearNotifications();
                                        }}>
                                    </Button>
                                </FormField>
                            </Grid>
                            <Flashbar items={notificationItems} stackItems />
                            {(policyLoading || policyFetching || deleteMutationLoading) ? <><Spinner /></> :
                                <>
                                    {searchAlias.length > 0 &&
                                        <>
                                            <Container>
                                                <SpaceBetween size="xl">
                                                    <Grid gridDefinition={[{ colspan: 4 }]}>
                                                        <FormField label="Role">
                                                            <Select
                                                                selectedOption={selectedRole}
                                                                onChange={({ detail }) => {
                                                                    setSelectedRole(detail.selectedOption);
                                                                    setSelectedPL({});
                                                                    setSelectedRPNs([]);
                                                                }
                                                                }
                                                                options={ScreenUtils.generateOptionsWithDescription(rolesWithDescriptions)}
                                                            />
                                                        </FormField>
                                                    </Grid>
                                                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
                                                        {(selectedRole?.value && plValidRoles.includes(selectedRole.value)) && <FormField label="Product line">
                                                            <Select
                                                                selectedOption={selectedPL}
                                                                filteringType="auto"
                                                                onChange={({ detail }) =>
                                                                    setSelectedPL(detail.selectedOption)
                                                                }
                                                                options={plOptions}
                                                            />
                                                        </FormField>
                                                        }
                                                        {(selectedRole?.value && rpnValidRoles.includes(selectedRole.value)) && <FormField label="Programs">
                                                            <Multiselect
                                                                selectedOptions={selectedRPNs}
                                                                filteringType="auto"
                                                                onChange={({ detail }) =>
                                                                    setSelectedRPNs(detail.selectedOptions)
                                                                }
                                                                tokenLimit={4}
                                                                options={selectedPL.value ? plRpnOptionsMap[selectedPL.value] : []}
                                                            />
                                                        </FormField>
                                                        }

                                                    </Grid>
                                                    <FormField>
                                                        <Button disabled={isAssignDisabled()} ariaLabel="assign" loading={addMutationLoading} variant="primary" onClick={assign}>Assign</Button>
                                                    </FormField>

                                                </SpaceBetween>
                                            </Container>
                                            <Container footer="* indicates All">
                                                < ExistingPermissions tableItems={existingPermissions} removeAccess={removeAccess} />
                                            </Container>
                                        </>
                                    }
                                </>
                            }
                        </SpaceBetween> :
                        <>
                            <Alert type="error" header="Access Denied">
                                {ACCESS_DENIED_MESSAGES.adminPanel}
                            </Alert>
                        </>
                    }
                </>
            }
        </>
    );
};