import React, { useEffect, useState } from "react";
import { Box, BreadcrumbGroup, Grid } from "@cloudscape-design/components";
import { useAppSelector } from '../../../redux/hooks';
import { selectLocationData } from "./appLayoutSlice";
import AppLayoutHelper from './appLayoutHelper';
import { selectRoleResourceMap } from "../userPolicy/userPolicySlice";
import { DISPLAY_ROLES, USER_ROLES } from "../../constants/constants";

export const RPTBreadcrumbs = () => {

    const locationData = useAppSelector(selectLocationData);
    const { generateBreadCrumbsItems } = AppLayoutHelper();
    const roleToResMap = useAppSelector(selectRoleResourceMap);
    const [currRole, setCurrRole] = useState('');

    useEffect(() => {
        if (locationData.productLine.id) {
            const isPLAdmin = roleToResMap?.[USER_ROLES.plAdmin]?.length ? true : false;
            const isIProvider = roleToResMap?.[USER_ROLES.inputProvider]?.length ? true : false;
            const isISubmitter = roleToResMap?.[USER_ROLES.inputSubmitter]?.length ? true : false;
            const role = isPLAdmin ? DISPLAY_ROLES.plAdmin : isIProvider && isISubmitter ? DISPLAY_ROLES.inputSP :
                isISubmitter ? DISPLAY_ROLES.inputSubmitter : isIProvider ? DISPLAY_ROLES.inputProvider : DISPLAY_ROLES.readonly;
            setCurrRole(role);
        } else  setCurrRole('');
    }, [locationData, roleToResMap]);

    return (
        <>
            <Grid
                gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
            >
                <div> <BreadcrumbGroup
                    items={generateBreadCrumbsItems(locationData)}
                    ariaLabel="Breadcrumbs"
                /></div>
                <Box textAlign="right">{currRole}</Box>
            </Grid>
        </>

    );
};