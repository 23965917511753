import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'expense_update_reason': 'Expense Update Reason'
};
export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Expense Update Reason",
        field: "expense_update_reason",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Description",
        field: "description",
        editable: true,
        valueParser: GridValidation.trimValue
    }
];