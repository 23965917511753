import React, { useState, useEffect } from 'react';
import { Container, Grid, Spinner } from "@cloudscape-design/components";
import ScreenUtils from '../../utils/screenUtils';
import { ERROR_MESSAGE, TAB_ID_LABEL, TAB_ID, SK_MAPPING, USER_ASSIGNEES, USER_ROLES, LOADING_APIS, ALL_ASSIGNEES_KEY, METRICS_PAGE_NAMES, METRICS_APP_NAME } from '../../constants/constants';
import { useGetFPNDetailsQuery, useGetProgramDetailsQuery, useGetRPNDetailsQuery } from '../../services/api';
import { useGetCountryNamesQuery } from '../../../redux/services/commonApi';
import { useGetAttributeListFPNQuery, useGetAttributeListRPNQuery, useGetAttributeTypePLQuery, useGetAttributeTypeRPNQuery, useGetPlFPNRuleQuery } from '../../../redux/services/attributesApi';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { resetOverview, updateOverviewClientData, updateOverviewServerData, OverviewState, resetVerityData } from '../programOverview/programOverviewSlice';
import { resetAllPLAttributes, updateAllPlAttributes, updateLoadingAPIs, updateAllAttrValuesServer, updateAllAttributeValues, resetAttributesValues, updateAsinAttributeValues, resetProgAttrTypes, updateProgAttrServer, updateProgAttrOrder } from '../attributes/attributesSlice';
import { updateCurrentProgramSetupTab, updateIsDataLoaded, selectIsLoading, updateIsLoading } from '../appLayout/appLayoutSlice';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { updateKeyDatesServerData, updateKeyDatesClientData, resetKeyDates, KeyDatesState } from '../keyDates/keyDatesSlice';
import { updateMarketInfoClientData, updateMarketInfoServerData, resetMarketInfo } from '../marketInfo/marketInfoSlice';
import { updateManufacturingInfoClientData, updateManufacturingInfoServerData, resetManufacturingInfo } from '../manufacturingInfo/manufacturingInfoSlice';
import { updateFinancialInfoClientData, updateFinancialInfoServerData, resetFinancialInfo } from '../financialInfo/financialInfoSlice';
import { updateProgAssignee, updatePLAssignee, selectUserPolicy, updateRoleResourceMap, resetPLAssignees, resetProgAssignees } from '../userPolicy/userPolicySlice';
import { IPLAttributeTypeResponse, IProgramAttrListResponse, IRPNAttributeTypeResponse, IResponsePayload } from '../../interfaces/interfaces';
import { ProgramSetupTabs } from './programSetupTabs';
import { resetSpecs, updateSpecsClientData, updateSpecsServerData } from '../specs/specsSlice';
import { useGetProductLineDetailsQuery } from '../../services/apis/productLineApi';
import { getPageTimeMetric, logUserAction } from "../../../KatalMetrics/metricsHelper";
import { ACTIONS } from 'src/components/KatalMetrics/metricsConstants';

export const ProgramSetup = () => {
    const dispatch = useAppDispatch();
    const locationData = useAppSelector(selectLocationData);
    const { productLine, program, fpn } = locationData;
    const isMutationLoading = useAppSelector(selectIsLoading);
    const userPolicyData = useAppSelector(selectUserPolicy);
    const [isDataDispatched, setIsDataDispatched] = useState(false);
    const { getPrimaryKey, getPLPrimaryKey, getPrimaryKeyRPNId, getPLUserPolicyKey, getRPNUserPolicyKey } = ScreenUtils;
    const { isLoading: programDataLoading, isError: programdataError, data: programData, isFetching: programDataFetching } = useGetProgramDetailsQuery(getPrimaryKey(locationData, 'Sandbox'));
    const { isLoading: rpnDataLoading, isError: rpndataError, data: rpnData, isFetching: rpnDataFetching } = useGetProgramDetailsQuery(getPrimaryKeyRPNId(program.id, 'Sandbox'), { skip: !locationData.fpn.id });
    const { data: plRptData } = useGetProductLineDetailsQuery(getPLPrimaryKey(productLine.id), { skip: !productLine.id });
    const { data: plAttrData } = useGetAttributeTypePLQuery({ verity_product_line_id: parseInt(productLine.id) }, { skip: !productLine.id });
    const { data: rpnAttrListData, isLoading: attrListLoading, isFetching: attrListFetching } = useGetAttributeListRPNQuery({ verity_rpn_id: program.id }, { skip: !program.id || fpn.id ? true : false });
    const { data: fpnAttrListData, isLoading: fpnAttrListLoading, isFetching: fpnAttrListFetching } = useGetAttributeListFPNQuery({ verity_fpn_id: fpn.id }, { skip: !fpn.id ? true : false });
    const { data: rpnAttrTypeData, isLoading: attrTypeLoading, isFetching: attrTypeFetching } = useGetAttributeTypeRPNQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const attrsLoading = attrListLoading || attrListFetching || fpnAttrListLoading || fpnAttrListFetching;
    useGetFPNDetailsQuery(parseInt(fpn.id), { skip: !fpn.id });
    useGetRPNDetailsQuery(parseInt(program.id), { skip: fpn.id !== '' || !program.id });
    useGetCountryNamesQuery(); // data processed in appLayoutSlice
    useGetPlFPNRuleQuery({ verity_product_line_id: productLine.id }); // data processed in attributesSlice

    useEffect(() => {
        dispatch(updateCurrentProgramSetupTab(TAB_ID.overview)); 
    }, []);

    useEffect(() => {
        return getPageTimeMetric(METRICS_APP_NAME, METRICS_PAGE_NAMES.programSetup);
    }, []);

    useEffect(() => {
        dispatch(resetVerityData());
        logUserAction(METRICS_APP_NAME, METRICS_PAGE_NAMES.programSetup, ACTIONS.PROGRAM_HOME, {ProductLine: productLine.name, RPN: program.name, FPN: fpn.name});
    }, [locationData]);

    useEffect(() => {
        if (rpnData) dispatchRPNData(rpnData);
    }, [rpnData, locationData]);

    useEffect(() => {
        const loadingApis = [];
        if ( attrsLoading ) loadingApis.push(LOADING_APIS.rpnAttrList);
        if ( attrTypeLoading || attrTypeFetching ) loadingApis.push(LOADING_APIS.rpnAttrType);
        dispatch(updateLoadingAPIs(loadingApis));
    }, [attrsLoading, attrTypeLoading, attrTypeFetching]);

    // Store role to assigned resources map on RPN change
    useEffect(() => {
        const roleToResourceMap: any = {};
        const rpnKey = getRPNUserPolicyKey(program.id);
        const plKey = getPLUserPolicyKey(productLine.id);
        Object.entries(userPolicyData).forEach(([role, obj]) => {
            if (!roleToResourceMap[role]) roleToResourceMap[role] = [];
            if (obj[ALL_ASSIGNEES_KEY]) roleToResourceMap[role].push(ALL_ASSIGNEES_KEY);
            if (obj[rpnKey]) roleToResourceMap[role].push(...obj[rpnKey]);
            if (obj[plKey]) roleToResourceMap[role].push(...obj[plKey]);
        });
        dispatch(updateRoleResourceMap(roleToResourceMap));
    }, [userPolicyData, program]);

   
    // Populate RPN/FPN attribute list data (L1, L2, L3 values)
    useEffect(() => {
        dispatch(resetAttributesValues());
        const attrData = fpn.id && fpnAttrListData ? fpnAttrListData : !fpn.id && rpnAttrListData ? rpnAttrListData : null;
        if (attrData && !attrsLoading) {
            const attrValues: Record<string, string[]> = {};
            const asinValues: Record<string, string[]> = {};
            attrData.forEach(({attribute_type, attribute_value, asin_count }: IProgramAttrListResponse) => {
                attrValues[attribute_type] ? attrValues[attribute_type].push(attribute_value) : attrValues[attribute_type] = [attribute_value];
                if (asin_count && asin_count > 0) asinValues[attribute_type] ? asinValues[attribute_type].push(attribute_value) : asinValues[attribute_type] = [attribute_value];
            });
            dispatch(updateAllAttrValuesServer(attrValues));
            dispatch(updateAllAttributeValues(attrValues));
            dispatch(updateAsinAttributeValues(asinValues));
        }
    }, [rpnAttrListData, fpnAttrListData, locationData]);

    // Populate RPN attribute type 
    useEffect(() => {
        dispatch(resetProgAttrTypes());
        if (rpnAttrTypeData && !attrTypeLoading && !attrTypeFetching) {
            const progAttr: IRPNAttributeTypeResponse[] = [];
            rpnAttrTypeData.forEach((obj: IRPNAttributeTypeResponse) => {
                progAttr[obj.rpn_attr_order] = obj;
            });
            const filteredAttr = progAttr.filter(item => item !== undefined);
            filteredAttr.forEach((item, idx) => {
                filteredAttr[idx] = { ...item, rpn_attr_order: idx + 1};
            });
            dispatch(updateProgAttrServer(filteredAttr));
            dispatch(updateProgAttrOrder(filteredAttr));
        }
    }, [rpnAttrTypeData, locationData.program]);

    // Populate PL attribute type data on PL change (Verity API)
    useEffect(() => {
        dispatch(resetAllPLAttributes());
        if (plAttrData) {
            const plAttr: IPLAttributeTypeResponse[] = [];
            plAttrData.forEach((obj: IPLAttributeTypeResponse) => {
                plAttr[obj.pl_attr_order] = obj;
            });
            dispatch(updateAllPlAttributes(plAttr.filter(item => item !== undefined)));
        }
    }, [plAttrData, locationData.productLine]);

    // Populate PL assignees from PRM dynamodb tables 
    useEffect(() => {
        dispatch(resetPLAssignees());
        if (plRptData?.[USER_ASSIGNEES]?.[USER_ROLES.inputProvider]) {
            dispatch(updatePLAssignee(ScreenUtils.formatUserAssigneeData(plRptData[USER_ASSIGNEES][USER_ROLES.inputProvider])));
        }
    }, [plRptData, locationData.productLine]);

    useEffect(() => {
        resetStore();
        if (programData && !isMutationLoading) {
            console.log(programData); // console added for beta testing
            if (!locationData.fpn.id) dispatchRPNData(programData);
            if (programData[TAB_ID_LABEL.keyDates]) {
                const { [SK_MAPPING.streetDates]: streetDates,
                    [SK_MAPPING.alexaDates]: alexaDates,
                    [SK_MAPPING.otherDates]: otherDates } = programData[TAB_ID_LABEL.keyDates];

                const keyDates: KeyDatesState = {
                    otherDates: otherDates ? ScreenUtils.formatData(otherDates) : {},
                    streetDates: streetDates ? ScreenUtils.formatData(streetDates) : {},
                };
                dispatch(updateKeyDatesClientData(keyDates));
                dispatch(updateKeyDatesServerData(keyDates));
            }
            if (programData[TAB_ID_LABEL.financialInfo]) {
                const { [SK_MAPPING.financialInfo]: financialInfo } = programData[TAB_ID_LABEL.financialInfo];
                const financial: Record<string, IResponsePayload> = financialInfo ? ScreenUtils.formatData(financialInfo) : {};
                dispatch(updateFinancialInfoClientData(financial));
                dispatch(updateFinancialInfoServerData(financial));
            }
            if (programData[TAB_ID_LABEL.specs]) {
                const { [SK_MAPPING.specs]: specsInfo } = programData[TAB_ID_LABEL.specs];
                const specs: Record<string, IResponsePayload> = specsInfo ? ScreenUtils.formatData(specsInfo) : {};
                dispatch(updateSpecsClientData(specs));
                dispatch(updateSpecsServerData(specs));
            }
            dispatch(updateIsDataLoaded(true));
            setIsDataDispatched(true);
            dispatch(updateIsLoading(false));
        }
    }, [programData, locationData, isMutationLoading]);

    // Display RPN data (overview, mfrInfo & marketInfo tabs) for FPNs 
    const dispatchRPNData = (programData: Record<string, Record<string, IResponsePayload[]>>) => {
        if (programData[TAB_ID_LABEL.overview]) {
            const { [SK_MAPPING.overview]: overview,
                [SK_MAPPING.contactInfo]: contactInfo,
                [SK_MAPPING.documents]: documents } = programData[TAB_ID_LABEL.overview];

            const programOverview: OverviewState = {
                overview: overview ? ScreenUtils.formatData(overview) : {},
                contactInfo: contactInfo ? ScreenUtils.formatData(contactInfo) : {},
                documentLinks: documents ? ScreenUtils.formatData(documents) : {}
            };
            dispatch(updateOverviewServerData(programOverview));
            dispatch(updateOverviewClientData(programOverview));
        }
        if (programData[TAB_ID_LABEL.marketInfo]) {
            const { [SK_MAPPING.marketInfo]: marketInfo } = programData[TAB_ID_LABEL.marketInfo];
            const marketInformation: Record<string, IResponsePayload> = marketInfo ? ScreenUtils.formatData(marketInfo) : {};
            dispatch(updateMarketInfoClientData(marketInformation));
            dispatch(updateMarketInfoServerData(marketInformation));
        }
        if (programData[TAB_ID_LABEL.manufacturingInfo]) {
            const { [SK_MAPPING.manufacturingInfo]: manufacturingInfo } = programData[TAB_ID_LABEL.manufacturingInfo];
            const mfrInfo: Record<string, IResponsePayload> = manufacturingInfo ? ScreenUtils.formatData(manufacturingInfo) : {};
            dispatch(updateManufacturingInfoServerData(mfrInfo));
            dispatch(updateManufacturingInfoClientData(mfrInfo));
        }
        // Assignees are set at RPN level only
        if (programData[USER_ASSIGNEES]) {
            const { [USER_ROLES.inputProvider]: inputProvider } = programData[USER_ASSIGNEES];
            if (inputProvider) dispatch(updateProgAssignee(ScreenUtils.formatUserAssigneeData(inputProvider)));
        }
    };

    const resetStore = () => {
        if (!locationData.fpn.id) {
            dispatch(resetOverview());
            dispatch(resetMarketInfo());
            dispatch(resetManufacturingInfo());
        }
        dispatch(resetProgAssignees());
        dispatch(resetKeyDates());
        dispatch(resetFinancialInfo());
        dispatch(resetSpecs());
        dispatch(updateIsDataLoaded(false));
    };

    if (programDataLoading || programDataFetching || rpnDataLoading || rpnDataFetching) return <div className='loadspinner mg-top-md'><Spinner size="large" /></div>;

    if (programdataError || rpndataError) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {ERROR_MESSAGE}
            </Container>
        </Grid>
    );

    return (
        <>
            {isDataDispatched && <ProgramSetupTabs />}
        </>
    );
};

