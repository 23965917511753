import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { Grid, Container } from "@amzn/awsui-components-react";
import { ProductGroupContext } from '../components/context/productGroupContext';
import { GridTable } from '../components/common/GridTable/GridTable';
import { IChangedCells, ICustomSettings } from '../components/common/GridTable/GridTableInterface';
import { ColDef, GridOptions, ColGroupDef} from "ag-grid-community";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { COL_DEFS, REQUIRED } from "../components/gridHelper/productGroupHelper";
import { errorMessage, alertMessages } from 'src/constants/constants';
import { PageHeader } from '../components/common/Header/pageHeader';
import AlertMessageContext from '../components/common/Contexts/alertMessageContext';
import GridValidation from '../components/gridHelper/gridValidation';
import { IMutationResp, IValidationResponse } from "../components/Interfaces/interface";
import ScreenUtils from './Utils/ScreenUtils';

export const ProductGroup = forwardRef((props, ref) => {

    const productGroupContext = useContext(ProductGroupContext);
    const { componentData, useProductGroupsData, useUpdatedProdGrpData } = { ...productGroupContext };
    const queryResult = useProductGroupsData();
    const updateMutation = useUpdatedProdGrpData();
    const [prodGrpList, setprodGrpList] = useState<string[]>([]);
    const { setSuccess, setError } = useContext(AlertMessageContext);

    const columnDefs: (ColDef | ColGroupDef)[] = COL_DEFS;
    const getCustomSettings = (): ICustomSettings => {
        return {
            className: 'ag-theme-balham',
            uid: "concat_string",
            readOnly: false,
            groupIncludeTotalFooter: true,
            allowLocalFilters: true,
            filterId: 'productGroup',
            enableOnChangeHighlight: true,
            allowAddRow: true,
            numAddRows: 1
        } as ICustomSettings;
    };
    const [customSettings] = useState<ICustomSettings>(
        getCustomSettings()
    );
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: columnDefs,
        rowData: []
    });
    useEffect(() => {
        if (componentData) {
            const pdGroupList: string[] = [];
            componentData.forEach((obj: any) => {
                pdGroupList.push(obj.product_group);
            });
            setprodGrpList(pdGroupList);
            COL_DEFS.forEach(function (colDef: ColDef) {
                colDef.floatingFilter = true;
            });
            setGridOptions((prevState: any) => {
                return {
                    ...prevState,
                    columnDefs: COL_DEFS,
                    rowData: componentData
                };
            });
        }
    }, [componentData]);

    const onSave = async (event: Record<string, IChangedCells>) => {
        const requestData: Record<string, any> = {};
        const payload: any = [];
        let validationError = false;
        if (event && Object.keys(event).length > 0) {
            const vResponse: IValidationResponse = GridValidation.validateRequired(event, REQUIRED);
            if (vResponse.error) { // Required fields validation
                setError?.(alertMessages.requiredMissing + vResponse.missingFields.join(', '));
            } else {
                Object.values(event).forEach((cell) => {
                    const cellId = cell.row.id ?? cell.row.rowId;
                    if (!cell.row.id && prodGrpList.includes(cell.row.product_group)) {
                        setError?.('Product group already exists.');
                        validationError = true;
                    }
                    const userId = ScreenUtils.getUserId();
                    // Adding new rows
                    if (!cell.row.id && !Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                        requestData[cellId] = {
                            product_group: cell.row.product_group,
                            description: cell.row.description,
                            user_id: userId
                        };
                    }
                    // Updating rows
                    if (cell.row.id && cell.colId) {
                        if (!Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                            requestData[cellId] = {
                                id: cellId,
                                product_group: cell.row.product_group,
                                user_id: userId
                            };
                        }
                        requestData[cellId][cell.colId] = cell.newValue;
                    }
                });
                if (!validationError) {
                    Object.keys(requestData).forEach(cellId => {
                        payload.push(requestData[cellId]);
                    });
                    if (payload.length > 0) {
                        updateMutation.mutate(payload);
                    }
                }
            }
        }
    };
    useImperativeHandle(ref, () => ({
        onSave(event: Record<string, IChangedCells>) {
            onSave(event);
        }
    }));
    useEffect(() => {
        if (updateMutation.data) {
            console.log(updateMutation.data); //console added for Beta testing
            const resp : IMutationResp = ScreenUtils.getMutationMessage(updateMutation.data);
            resp.status === 'success' ? setSuccess?.(resp.message) : setError?.(resp.message);
        }
    }, [updateMutation.data]);
    const loadGridTable = () => {
        if (gridOptions.rowData?.length) {
            return (
                <GridTable
                    gridSettings={gridOptions}
                    customSettings={{
                        ...customSettings
                    }}
                    onRefresh={queryResult.refetch}
                    onSave={onSave}
                />
            );
        } else {
            return "No data to display.";
        }
    };
    if (queryResult.isLoading || queryResult.isFetching || updateMutation.isLoading) return <div className='loadspinner'><Spinner size="large" /></div>;

    if (queryResult.error || updateMutation.error) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {errorMessage}
            </Container>
        </Grid>
    );
    return (
        <>
            <PageHeader
                pageName='Product Group'
            />
            {loadGridTable()}
        </>
    );

});