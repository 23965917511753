import React, { useEffect, useState } from 'react';
import { Button, Container, Header, SpaceBetween, Flashbar, Box, StatusIndicator } from '@amzn/awsui-components-react';

import useNotification from 'src/components/Hooks/notifications';
import ScreenUtils from '../../utils/screenUtils';
import { SNAPSHOT_MESSAGES } from '../../constants/displayMessages';
import { HttpMethod, acknowledge,  process } from "src/components/common/AsyncHandler/WebSocketHandler";
import Endpoints from 'src/constants/endpoints';
import { ASYNC_SERVICE_NAME } from 'src/components/Verity/constants/constants';
import { getUserId } from 'src/components/common/Auth/Auth';
import { useAsyncResponse } from 'src/components/Hooks/AsynResponseState';
import { statusCodes } from 'src/constants/constants';

// DELETE this file after UAT if no longer needed
export const RefreshWIP = () => {
    const { notificationItems, addNotificationItem } = useNotification();
    const { getNotificationItem } = ScreenUtils;
    const { setCurrentProcessId, getCurrentProcessId, deleteCurrentProcessId } = useAsyncResponse();
    const [asyncResponse, setAsyncResponse] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        try {
            const processID = getCurrentProcessId("REFRESH_WIP_SCENARIO");
            if (processID && asyncResponse?.response?.statusCode) {
                if (asyncResponse.response.statusCode === statusCodes.success) {
                    addNotificationItem(getNotificationItem("success", "SNAPSHOT_SUCCESS", SNAPSHOT_MESSAGES.snapshotRfrWIPSuccess));
                } else {
                    addNotificationItem(getNotificationItem("error", "SNAPSHOT_ERROR", SNAPSHOT_MESSAGES.snapshotRfrWIPError));
                }
                acknowledge(processID);
                deleteCurrentProcessId("REFRESH_WIP_SCENARIO");
                setIsLoading(false);
            }
        } catch (error) {
            addNotificationItem(getNotificationItem("error", "SNAPSHOT_ERROR", SNAPSHOT_MESSAGES.snapshotRfrWIPError));
            setIsLoading(false);
        }

    }, [asyncResponse]);


    const save = async () => {
        setIsLoading(true);
        const processResponse = await process(
            Endpoints.getSnapshotRefreshUrl(),
            HttpMethod.Post,
            ASYNC_SERVICE_NAME,
            {
                latestStatus: '',
                response: {}
            },
            setAsyncResponse,
            getUserId()|| '',
            `${getUserId()}@amazon.com` || '',
            {
                target: {}
            }
        );
        setCurrentProcessId("REFRESH_WIP_SCENARIO", processResponse?.processId);
    };

    return (

        <Container
            header={
                <Header variant="h2">
                    Refresh WIP
                </Header>
            }
        >
            <SpaceBetween size="xxl">
                <Flashbar items={notificationItems} stackItems />

                <Button className="bg-primary"
                    ariaLabel="refresh-wip"
                    disabled={false}
                    loading={isLoading}
                    onClick={save}
                    variant="primary"
                    data-testid="RefreshButton"
                >
                    Refresh WIP</Button>
            </SpaceBetween>
        </Container>

    );
};