import * as React from "react";
import { Button, Container, FormField, Grid, Header, Input, Select, SelectProps, SpaceBetween } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { selectAttributeTypeListMap } from "../attributes/attributesSlice";
import { IAttributeTypeResponse } from "../../interfaces/interfaces";
import { useGetAttributeTypeMasterQuery, useUpdateAttrListMasterMutation } from "../../../redux/services/attributesApi";
import { ATTR_VALUE_REGEX, STATUS_CODES, USER_ACTION_ROLES, VALIDATION_MESSAGES } from "../../constants/constants";
import { MUTATION_METHODS } from "../../../constants/constants";
import { ACCESS_DENIED_MESSAGES, ALERT_MESSAGES } from "../../constants/displayMessages";
import { useAppSelector } from '../../../redux/hooks';
import ScreenUtils from "../../utils/screenUtils";
import useNotification from "src/components/Hooks/notifications";
import { Alert, Flashbar } from "@amzn/awsui-components-react";
import { IApiUpdateResponse } from "src/components/Interfaces/interface";
import { selectUserPolicy } from "../userPolicy/userPolicySlice";

export const RequestNewAttributeValue = () => {
    const { data: attrTypeData } = useGetAttributeTypeMasterQuery();
    const [newValue, setNewValue] = useState('');
    const policyData = useAppSelector(selectUserPolicy);
    const [selectedType, setSelectedType] = useState<SelectProps.Option>({});
    const [errorText, setErrorText] = useState('');
    const allTypesToValueMap = useAppSelector(selectAttributeTypeListMap);
    const [addAttrValue, { isLoading, data: mutationData, isError: mutationError }] = useUpdateAttrListMasterMutation();
    const [allTypes, setAllTypes] = useState<SelectProps.Option[]>([]);
    const [hasAccess, setHasAccess] = useState(true);
    const { notificationItems, addNotificationItem } = useNotification();

    useEffect(() => {
        if (attrTypeData) {
            const attrTypes: SelectProps.Option[] = [];
            attrTypeData.forEach((obj: IAttributeTypeResponse) => {
                attrTypes.push({
                    label: obj.attribute_type,
                    value: obj.attribute_type
                });
            });
            setAllTypes(attrTypes);
        }
    }, [attrTypeData]);

    useEffect(() => {
        USER_ACTION_ROLES.attributeRequest.some(role => policyData?.[role]) ? setHasAccess(true) : setHasAccess(false);
    }, [policyData]);

    const validate = () => {
        let error = '';
        if (newValue && !newValue.match(ATTR_VALUE_REGEX)) error = VALIDATION_MESSAGES.attributeValueName;
        if (selectedType?.label && allTypesToValueMap[selectedType.label]?.includes(newValue)) error = VALIDATION_MESSAGES.attributeValueExists + selectedType.label;
        setErrorText(error);
        return error.length > 0 ? false : true;
    };

    const save = async () => {
        if (newValue.length && selectedType.value?.length && validate()) {
            const payload = {
                attribute_value: newValue,
                attribute_type: selectedType.value
            };
            await addAttrValue(ScreenUtils.getGraphQLPayload(payload)).unwrap();
        }
    };

    useEffect(() => {
        if (mutationData?.errors || mutationError) addNotificationItem({
            type: "error",
            dismissible: true,
            content: ALERT_MESSAGES.attrValueFail,
            id: "ATTR_FAIL"
        });
        if (mutationData?.data && mutationData?.data?.[MUTATION_METHODS.updateAttrListMaster]) {
            const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateAttrListMaster];
            if (statusCode === STATUS_CODES.success) {
                addNotificationItem({
                    type: "success",
                    dismissible: true,
                    content: ALERT_MESSAGES.attrValueSuccess + newValue,
                    id: "ATTR_SUCCESS"
                });
                setNewValue('');
            } else addNotificationItem({
                type: "error",
                dismissible: true,
                content: statusCode === STATUS_CODES.handledException ? error : ALERT_MESSAGES.attrValueFail,
                id: "ATTR_FAIL"
            });
        }

    }, [mutationData, mutationError]);

    return (

        <Container
            header={
                <Header variant="h2">
                    Request new attribute value
                </Header>
            }
        >
            {hasAccess ?
                <SpaceBetween size="xxl">
                    <Flashbar items={notificationItems} stackItems />
                    <Grid
                        gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                    >
                        <SpaceBetween size="xxl">
                            <FormField
                                label="Select attribute type"
                            >
                                <Select
                                    selectedOption={selectedType}
                                    onChange={({ detail }) =>
                                        setSelectedType(detail.selectedOption)
                                    }
                                    options={allTypes}
                                    filteringType="auto"
                                    placeholder="Choose option for attribute type"
                                    ariaLabel="attribute-type"
                                />
                            </FormField>
                            <FormField
                                label="Enter attribute value"
                                errorText={errorText}
                            >
                                <Input
                                    value={newValue}
                                    onChange={event =>
                                        setNewValue(event.detail.value?.toUpperCase().trim())
                                    }
                                    onBlur={validate}
                                    disabled={!allTypes.length}
                                    ariaLabel="attribute-value"
                                />
                            </FormField>
                            <Button className="bg-primary"
                                loading={isLoading}
                                onClick={save}
                                disabled={!newValue.length || !selectedType.value?.length || errorText.length > 0}
                                variant="primary">
                                Submit</Button>
                        </SpaceBetween>
                    </Grid>
                </SpaceBetween>
                :
                <Alert type="error" header="Access Denied">
                    {ACCESS_DENIED_MESSAGES.attrRequest}
                </Alert>}
        </Container>
    );
};