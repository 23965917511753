import { ColDef, ColGroupDef, GridOptions, RowNode, ValueGetterParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import { GridTable } from 'src/components/common/GridTable/GridTable';
import ScreenUtils from '../../utils/screenUtils';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import {getExistingAssortmentsStrings, getSelectedAssortments, resetAssortmentData, updateSelectedAssortments} from './assortmentSlice';
import { selectVersions } from '../versions/versionSlice';
import { Alert, Box, Button, FlashbarProps, FormField, Grid, Modal, Multiselect, SpaceBetween } from '@amzn/awsui-components-react';
import { selectAttributesServerData } from '../../../RPT/features/attributes/attributesSlice';
import { useAddAssortmentMutation } from '../../services/apis/api';
import { ATTRIBUTE_TYPE_NAMES, NA_LABEL, STATUS_CODES } from '../../constants/constants';

export default function AddAssortmentModal({visible, setVisible, addNotificationItem}: {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    addNotificationItem:  (notificationItem: FlashbarProps.MessageDefinition) => void
}) {

    //hooks
    const dispatch = useAppDispatch();
    const [ addAssortment ] = useAddAssortmentMutation();

    //from store
    const attrValues = useAppSelector(selectAttributesServerData).allAttributesValues;
    const productVersions = useAppSelector(selectVersions);
    const existingAssortmentsStrings = useAppSelector(getExistingAssortmentsStrings);
    const selectedAssortments = useAppSelector(getSelectedAssortments);
    const versions = useAppSelector(selectVersions);

    //state
    const [savingAssortment, setSavingAssortment] = useState(false);
    const [programAttributes, setProgramAttributes] = useState<Record<string, any>>({
        selectedChannels: [],
        selectedCountries: [],
        selectedROWs: [],
        selectedSKUs: [],
        selectedProductVersions:[]
    });
    const {selectedChannels, selectedCountries, selectedROWs, selectedSKUs, selectedProductVersions } = programAttributes;

    //locals
    const channels  = ScreenUtils.generateOptions(attrValues[ATTRIBUTE_TYPE_NAMES.channel], [NA_LABEL]);
    const countries  = ScreenUtils.generateOptions(attrValues[ATTRIBUTE_TYPE_NAMES.country], []);
    const ROWs = ScreenUtils.generateOptions(attrValues[ATTRIBUTE_TYPE_NAMES.row], []);
    const skuTypes = ScreenUtils.generateOptions(attrValues[ATTRIBUTE_TYPE_NAMES.skuTypes], [NA_LABEL]);
    const versionOptions = Object.values(versions).reduce((map, value)=>{
        return {
            ...map,
            [value.version_id]: {
                label: value.description,
                value: value?.version_id?.toString()
            }
        };
    }, {});

    const isRowSelectable = (rowNode: RowNode) =>  rowNode.data.comment ? false : true;

    const onRowSelection = (selectedRows: any)=>{
        dispatch(updateSelectedAssortments(selectedRows));
    };

    const addAssortmentHandler  = async ()=>{
        try {
            if(selectedAssortments.length){
                setSavingAssortment(true);
                const payload: {user_id: string, assortments: any[]} = {
                    user_id: ScreenUtils.getUserId(),
                    assortments: []
                };

                selectedAssortments.forEach((assortment: any) => {
                    payload.assortments.push({
                        version_id: parseInt(assortment.product_version),
                        channel: assortment.channel,
                        sku_type: assortment.sku_type,
                        description: versions[assortment.product_version].description,
                        country:  assortment.country ?? '',
                        row: assortment.row ?? ''
                    });
                });

                const response =  await addAssortment(payload).unwrap();

                if (response.statusCode === STATUS_CODES.success) {
                    addNotificationItem({
                        type: "success",
                        dismissible: true,
                        content: "Assortment added successfully",
                        id: "ASSORTMENT_SAVE_SUCCESS"
                    });
                } else {
                    throw new Error(response.error);
                }
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Failed to add Assortments.",
                id: "ASSORTMENT_SAVE_FAILED"
            });
        }finally {
            setSavingAssortment(false);
            setVisible(false);
        }
    };

    const customSettings = {
        className: 'ag-theme-alpine',
        readOnly: false,
        allowLocalFilters: true,
        filterId: 'assortments',
        onlyGrid: true,
        onRowSelection
    };

    const columnDefs: (ColDef | ColGroupDef)[] = [
        {
            headerName: "Channel",
            field: "channel",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            editable: false

        },
        {
            headerName: "Country",
            field: "country",
            editable: false,
            maxWidth: 150
        },
        {
            headerName: "ROW",
            field: "row",
            editable: false,
            maxWidth: 150
        },
        {
            headerName: "SKU Type",
            field: "sku_type",
            editable: false
        },
        {
            headerName: "Product Version",
            field: "product_version",
            editable: false,
            valueGetter: (params: ValueGetterParams)=>{
                return productVersions[params.node?.data.product_version]?.description;
            }
        },
        {
            headerName: "Comment",
            field: "comment",
            editable: false
        }

    ];

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: columnDefs,
        rowData: [],
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        isRowSelectable
    });

    const checkForSmartAdd = (item: any)=>{

        //No Online Channel for SKU types - Demo
        if(item.channel === "ONLINE" && item.sku_type === "DEMO"){
            item.comment = "No Online Channel for SKU types - Demo";
        }

        //No Offline channel for Certified Refurb, Refurb, Warranty
        if(item.channel === "OFFLINE" && ['CERTIFIED REFURB', 'REFURB', 'WARRANTY'].includes(item.sku_type)){
            item.comment = "No Offline channel for Certified Refurb, Refurb, Warranty.";
        }

        //No Special offers for Demo and Refurb SKU types
        if(item.sku_type === "DEMO" && productVersions?.[item.product_version]?.[ATTRIBUTE_TYPE_NAMES.dtcp] === 'DTCP'){
            item.comment = "No Special offers for Demo and Refurb SKU types.";
        }

        // Filter existing assortments
        if(existingAssortmentsStrings.includes(`${item.channel}_${item.country ?? item.row}_${item.sku_type}_${item.product_version}`)){
            item.comment = "Assortment already exist.";
        }

        return true;
    };

    useEffect(() => {
        dispatch(resetAssortmentData());
        const countriesORRows = selectedCountries.length > 0 ? selectedCountries : selectedROWs;
        const combinations = ScreenUtils.generateCombinations([selectedChannels, countriesORRows, selectedSKUs, selectedProductVersions]);
        if (combinations.length) {
            const data: any[] = [];
            combinations.forEach((combination)=>{
                data.push(combination.reduce((acc: any, item: any)=>{
                    return {
                        ...acc,
                        [item.attrName]: item.value,
                        [item.comment]: null
                    };
                }, {}));
            });

            if(data.length){
                setGridOptions((prevState)=>{
                    return {
                        ...prevState,
                        rowData: [...data.filter(checkForSmartAdd)]
                    };
                });
            }
        } else {
            setGridOptions((prevState)=>{
                return {
                    ...prevState,
                    rowData: []
                };
            });
        }
        return ()=>{
            if(!visible){
                setProgramAttributes({
                    selectedChannels: [],
                    selectedCountries: [],
                    selectedROW: [],
                    selectedSKUs: [],
                    selectedProductVersions:[]
                });
            }
        };
    }, [programAttributes]);



    return (
        <>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={()=>setVisible(false)}>Cancel</Button>
                            <Button variant="primary" onClick={addAssortmentHandler} loading={savingAssortment} disabled={selectedAssortments.length ? false : true}>Add Assortment</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Add Assortments"
            >
                <SpaceBetween size='l'>
                    <Grid gridDefinition={[{ colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 2 }, { colspan: 4 }]}>
                        <FormField
                            label="Channel"
                            constraintText={attrValues?.[ATTRIBUTE_TYPE_NAMES.channel]?.includes(NA_LABEL) ? "Valid Channel needs to be selected" : ''}
                        >
                            <Multiselect
                                selectedOptions={programAttributes.selectedChannels}
                                onChange={({ detail }) =>{
                                    const value = detail.selectedOptions.map((item)=>{
                                        return {
                                            ...item,
                                            attrName: 'channel'
                                        };
                                    });
                                    setProgramAttributes((prevState)=>{
                                        return {
                                            ...prevState,
                                            selectedChannels: [...value]
                                        };
                                    });
                                }}
                                options={channels}
                                placeholder="Choose Channels"
                                empty="Please add Channel from attribute screen."
                                tokenLimit={20}
                            />
                        </FormField>

                        <FormField
                            label="Country"
                        >
                            <Multiselect
                                selectedOptions={programAttributes.selectedCountries}
                                onChange={({ detail }) =>{
                                    const value = detail.selectedOptions.map((item)=>{
                                        return {
                                            ...item,
                                            attrName: 'country'
                                        };
                                    });
                                    setProgramAttributes((prevState)=>{
                                        return {
                                            ...prevState,
                                            selectedCountries: [...value],
                                            selectedROWs: []
                                        };
                                    });
                                }}
                                options={countries}
                                placeholder="Choose Countries"
                                empty="Please add Country from attribute screen."
                                tokenLimit={20}
                                filteringType="auto"
                            />
                        </FormField>

                        <FormField
                            label="ROW"
                        >
                            <Multiselect
                                selectedOptions={programAttributes.selectedROWs}
                                onChange={({ detail }) =>{
                                    const value = detail.selectedOptions.map((item)=>{
                                        return {
                                            ...item,
                                            attrName: 'row'
                                        };
                                    });
                                    setProgramAttributes((prevState)=>{
                                        return {
                                            ...prevState,
                                            selectedROWs: [...value],
                                            selectedCountries: []
                                        };
                                    });
                                }}
                                options={Object.values(ROWs) ?? []}
                                placeholder="Choose ROW"
                                empty="Please add ROW from attributes tab."
                                tokenLimit={20}
                            />
                        </FormField>

                        <FormField
                            label="SKU Type"
                            constraintText={attrValues?.[ATTRIBUTE_TYPE_NAMES.skuTypes]?.includes(NA_LABEL) ? "Valid SKU Type needs to be selected" : ''}
                        >
                            <Multiselect
                                selectedOptions={programAttributes.selectedSKUs}
                                onChange={({ detail }) =>{
                                    const value = detail.selectedOptions.map((item)=>{
                                        return {
                                            ...item,
                                            attrName: 'sku_type'
                                        };
                                    });
                                    setProgramAttributes((prevState)=>{
                                        return {
                                            ...prevState,
                                            selectedSKUs: [...value]
                                        };
                                    });
                                }}
                                options={skuTypes}
                                empty="Please add SKU Type from attribute screen."
                                placeholder="Choose SKUs"
                                tokenLimit={20}
                            />
                        </FormField>

                        <FormField
                            label="Product Version"
                        >
                            <Multiselect
                                selectedOptions={programAttributes.selectedProductVersions}
                                onChange={({ detail }) =>{
                                    const value = detail.selectedOptions.map((item)=>{
                                        return {
                                            ...item,
                                            attrName: 'product_version'
                                        };
                                    });
                                    setProgramAttributes((prevState)=>{
                                        return {
                                            ...prevState,
                                            selectedProductVersions: [...value]
                                        };
                                    });
                                }}
                                options={Object.values(versionOptions) ?? []}
                                placeholder="Choose Product Versions"
                                empty="Please create Product versions on Versions Tab."
                                tokenLimit={20}
                                filteringType="auto"
                            />
                        </FormField>

                    </Grid>
                    <Alert
                        statusIconAriaLabel="Info"
                        header=""
                    >
                        Only one can be selected <strong>Country</strong> or <strong>ROW</strong>
                    </Alert>
                    <GridTable
                        gridSettings={gridOptions}
                        customSettings={{
                            ...customSettings
                        }}
                    />
                </SpaceBetween>
            </Modal>
        </>
    );
}