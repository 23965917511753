import _ from "lodash";
import { FlashbarProps, MultiselectProps } from '@amzn/awsui-components-react';
import { IDynamicFieldProps } from "src/components/common/DynamicForm/Interfaces";
import { ALL_ASSIGNEES_KEY, MUTATION_ACTION, PRIMARY_KEY_MAPPING, SEPERATOR, STAGES, TAB_ID_LABEL, USER_POLICY_SEPERATOR, VALIDATION_MESSAGES } from "../constants/constants";
import { IFieldMetaData } from "../constants/fieldData";
import { IResponsePayload, ISelectOptions } from "../interfaces/interfaces";
import { IValueDescription } from "src/components/Interfaces/interface";

// This file is used for all screens & any changes here needs complete testing
export default class ScreenUtils {

    public static readonly generateOptions = (values: any[], disabled : string[] = []) => {
        const options: ISelectOptions[] = [];
        values?.forEach((value: any) => {
            options.push({
                label: value,
                value: value,
                disabled: disabled.includes(value) ? true: false
            });
        });
        return options;
    }
    
    public static readonly generateOptionsWithDescription = (valuesWithDescriptions: IValueDescription) => {
        const options: ISelectOptions[] = [];
        Object.entries(valuesWithDescriptions).forEach(([value, description]) => {
            options.push({
                label: value,
                value: value,
                description: description,
            });
        });
        return options;
    }

    public static readonly generateOptionsFromIdLabel = (IdNameMap: Record<string, string>) => {
        let options: ISelectOptions[] = [];
        if(IdNameMap){
            Object.entries(IdNameMap).forEach(([id, name]) => {
                options.push({
                    label: name,
                    value: id
                });
            });
            options = _.sortBy(options, ['label']);
        }
        return options;
    }

    public static readonly formatData = (data: IResponsePayload[]) => {
        const dataMap: Record<string, IResponsePayload> = {};
        data.forEach((obj: IResponsePayload) => {
            const arr = obj.SK!.split('#');
            if (arr.length > 2) {
                dataMap[arr[2]] = { 
                    ...obj, 
                    ItemValues: JSON.parse(obj.ItemValues ?? null), 
                    ItemValuesSubmitted: JSON.parse(obj.ItemValuesSubmitted ?? null),
                    ItemValuesWIP: JSON.parse(obj.ItemValuesWIP ?? null) 
                };
            } 
        });
        return dataMap;
    };

    public static readonly formatUserAssigneeData = (data: IResponsePayload[]) => {
        const dataMap: Record<string, any> = {};
        data.forEach((obj: IResponsePayload) => {
            // e.g Assignees#Input Provider#Key Dates#Street Dates
            const [, role, tab, subTab] = obj.SK!.split(SEPERATOR);
            const formattedObj = { ...obj, ItemValues: JSON.parse(obj.ItemValues) };
            const section = subTab ?? ALL_ASSIGNEES_KEY;
            if(role && tab) {
                dataMap[role] ? dataMap[role][tab] ? dataMap[role][tab][section] = formattedObj : dataMap[role][tab] = { [section] : formattedObj} : dataMap[role] = { [tab]: { [section] : formattedObj}};
            }    
        });
        return dataMap;
    };

    public static readonly formatAttributesData = (data: IResponsePayload[]) => {
        return data.map(({ ItemValues, ...obj }) => ({ ...obj, ItemValues: JSON.parse(ItemValues) }));
    };

    public static readonly generateDynamicFormInput = (dataMap: any, fieldMetaData: Record<string, IFieldMetaData>, stage?: string) => {
        const formInputMap = new Map<number, IDynamicFieldProps[]>();
        let row = 0;
        Object.entries(fieldMetaData).forEach(([field, fMetadata]) => {
            const data = dataMap[field];
            row = fMetadata.row ?? row + 1;
            let values = data?.ItemValues ?? '';
            if (stage === STAGES.submitted) values = data?.ItemValuesSubmitted !== null ? data?.ItemValuesSubmitted : data?.ItemValuesWIP ?? '';
            if (stage === STAGES.approved) values = data?.ItemValuesWIP ?? '';
            if (fMetadata.type === 'select' && values && !values.value) values = ScreenUtils.generateOptions([values])[0];
            if (fMetadata.visibility !== false) {
                const inputObj: IDynamicFieldProps = {
                    "name": field,
                    "label": fMetadata.label,
                    "type": fMetadata.type,
                    "inputType": fMetadata.inputType,
                    "value": values,
                    "readOnly": fMetadata.readonly,
                    "full_width": fMetadata.fullWidth ?? false,
                    "placeholder": fMetadata.placeholder ?? '',
                    "validation": fMetadata.validation,
                    "options": fMetadata.options ?? [],
                    "filteringType": fMetadata.filteringType ?? 'none',
                    "action": fMetadata.actions,
                    "onBlur": fMetadata.onBlur
                };
                formInputMap.has(row) ? formInputMap.get(row)?.push(inputObj) : formInputMap.set(row, [inputObj]);
            }
        });
        return Array.from(formInputMap.values());
    }
    
    public static readonly formatProgramAPIData = (response: any) => {
        const resp: any = {};
        response.forEach((obj: any) => {
            const inputGroups = obj.SK.split('#');
            if (inputGroups.length > 2) {
                const [in_grp, sub_grp] = inputGroups;
                resp[in_grp] ? resp[in_grp][sub_grp] ? resp[in_grp][sub_grp].push(obj) : resp[in_grp][sub_grp] = [obj] : resp[in_grp] = { [sub_grp]: [obj] };
            } else {
                resp[inputGroups] = obj;
            }
        });
        return resp;
    };

    // This method is used on all screens and complete testing is required for any changes
    public static readonly updateClientState = (fieldValue: any, fieldName: string, fieldToUpdate: string, clientData: any, PK: string, SK: string) => {
        const data = { ...clientData };
        if (data[fieldName]) {
            //Temporary exclude key dates fields as it includes helper fields
            if(!SK.includes(TAB_ID_LABEL.keyDates) && data[fieldName].Action === MUTATION_ACTION.put && !fieldValue?.length) {
                delete data[fieldName];
            } else {
                const action = data[fieldName].Action === MUTATION_ACTION.put ? data[fieldName].Action : MUTATION_ACTION.update;
                const oldValue = data[fieldName].ItemValues?.toString() ?? '';
                data[fieldName] = {
                    ...data[fieldName],
                    [fieldToUpdate]: fieldValue,
                    Action: action
                };
                if (action === MUTATION_ACTION.update && !data[fieldName].Changes) data[fieldName].Changes = 'Old value ' + oldValue;
            }
        } else {
            if (fieldValue) {
                const newAttribute: IResponsePayload = {
                    PK: PK,
                    SK: SK,
                    [fieldToUpdate]: fieldValue,
                    Action: MUTATION_ACTION.put,
                    Version: 0
                };
                data[fieldName] = newAttribute;
            }
        }
        return data;
    };

    public static readonly isSandboxUpdated = (dataMap: Record<string, IResponsePayload>) => 
        Object.values(dataMap).some(item => !_.isEqual(item.ItemValues, item.ItemValuesWIP));

    public static readonly getGraphQLPayload = (data: any) => JSON.stringify(data).replace(/"([^(")"]+)":/g, "$1:");

    // This method is used on all screens and complete testing is required for any changes
    public static readonly generatePayload = (allValues: IResponsePayload[]) => {
        let mutationStr = '[';
        allValues.forEach((value: IResponsePayload) => {
            if (value.Action &&
                (value.Action !== MUTATION_ACTION.put ||
                    (value.Action === MUTATION_ACTION.put && value.ItemValues !== ''))) {
                // This logic will be updated if better fix available
                mutationStr += `{PK: "${value.PK}", SK: "${value.SK}", Action: "${value.Action}"`;
                if (value.ItemValues !== undefined) mutationStr += `, ItemValues: ${JSON.stringify(JSON.stringify(value.ItemValues))}`;
                if (value.IsInheritedByFPN !== undefined) mutationStr += `,IsInheritedByFPN: ${value.IsInheritedByFPN} `;
                if (value.IsPartOfFPN !== undefined) mutationStr += `,IsPartOfFPN: ${value.IsPartOfFPN}`;
                if (value.SetAsTBD !== undefined) mutationStr += `,SetAsTBD: ${value.SetAsTBD}`;
                if (value.Changes) mutationStr += `,Changes: ${JSON.stringify(JSON.stringify(value.Changes))}`;
                if (value.Version !== undefined) mutationStr += `,Version: ${value.Version}`;
                mutationStr += '},';
            }
        });
        if (mutationStr.length > 2) return mutationStr.substring(0, mutationStr.length - 1) + ']';
        return null;
    }

    public static readonly getPrimaryKey = (locationData: any, status: string) =>
        locationData.fpn.id ? `${PRIMARY_KEY_MAPPING.fpn}${SEPERATOR}${locationData.fpn.id}` : `${PRIMARY_KEY_MAPPING.rpn}${SEPERATOR}${locationData.program.id}`

    public static readonly getPrimaryKeyFPNId = (id: any, status: string) => `${PRIMARY_KEY_MAPPING.fpn}${SEPERATOR}${id}`

    public static readonly getPrimaryKeyRPNId = (id: any, status: string) => `${PRIMARY_KEY_MAPPING.rpn}${SEPERATOR}${id}`

    public static readonly getPLPrimaryKey = (id: any) => `${PRIMARY_KEY_MAPPING.pl}${SEPERATOR}${id}`

    public static readonly getPLUserPolicyKey = (id: any) => `${PRIMARY_KEY_MAPPING.pl}${USER_POLICY_SEPERATOR}${id}`

    public static readonly getRPNUserPolicyKey = (id: any) => `${PRIMARY_KEY_MAPPING.rpn}${USER_POLICY_SEPERATOR}${id}`

    public static readonly getUserId = () => sessionStorage.getItem('userName')?.split('_')[1] ?? '';

    public static readonly getCombinations = (chars: string[]) => {
        const result: string[] = [];
        const f = (prefix: string, chars: string[]) => {
            for (let i = 0; i < chars.length; i++) {
                result.push(prefix + chars[i]);
                f(prefix + chars[i] + '_', chars.slice(i + 1));
            }
        };
        f('', chars);
        return result;
    };

    public static readonly cartesianProduct = (sets: string[][]): string[][] =>
        sets.reduce<string[][]>(
            (results, ids) =>
                results
                    .map(result => ids.map(id => [...result, id]))
                    .reduce((nested, result) => [...nested, ...result]),
            [[]]
        );

    public static readonly getNameFromOptLabel = (label: string) =>  label.substring(0, label.indexOf('(')-1);

    public static readonly generateIdLabelMap = (opts: MultiselectProps.Option[]) => {
        const idLabelMap: Record<string, string> = {};
        opts.forEach(opt => {
            if (opt.value) idLabelMap[opt.value!] = opt.label ?? '';
        });
        return idLabelMap;
    }

    // Form validations

    public static readonly validateProgramName = (value: string) => value?.includes('_') ? VALIDATION_MESSAGES.rpnName : null;

    public static readonly getNotificationItem = (type: FlashbarProps.Type, id: string, message: string) => { 
        return {
            type: type,
            dismissible: true,
            content: message,
            id: id
        };
    }
}
