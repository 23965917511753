
import { Button, Container, Flashbar, Header, SpaceBetween, Spinner, Toggle } from '@amzn/awsui-components-react';
import { GridOptions } from 'ag-grid-community';
import React, { useEffect, useRef, useState } from 'react';
import { GridTable } from 'src/components/common/GridTable/GridTable';
import useNotification from 'src/components/Hooks/notifications';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { useDeactivateSKUMutation, useLazyGetSkuQuery, useRequestPNMutation } from '../../services/apis/api';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { selectVersions } from '../versions/versionSlice';
import { getSku, getSkuLoading, updateAssignedAssortments } from './skuSlice';
import { AgGridApi, skuItem } from '../../interfaces/skuInterface';
import { getAssortments, getAssortmentsLoading } from '../assortment/assortmentSlice';
import { groupSKUs } from './skuHelpers';
import AddNewSkuModal from './addNewSkuModal';
import EditSkuModal from './editSkuModal';
import EditMarketPlaceModal from './editMarketPlaceModal';
import { skuColDefs } from './columnDefs';
import { ALL_ASSIGNEES_KEY, USER_ACTION_ROLES } from '../../constants/constants';
import { selectAgmRoleResourceMap } from '../userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { selectVerityData } from 'src/components/RPT/features/programOverview/programOverviewSlice';


export default function Sku() {
    const dispatch = useAppDispatch();

    const locationData = useAppSelector(selectLocationData);
    const versions = useAppSelector(selectVersions);
    const { assortments, assortmentsError } = useAppSelector(getAssortments);
    const { SKUs, skuError } = useAppSelector(getSku);
    const roleResMap = useAppSelector(selectAgmRoleResourceMap);
    const assortmentsLoading = useAppSelector(getAssortmentsLoading);
    const skuLoading = useAppSelector(getSkuLoading);
    const pplsId = useAppSelector(selectVerityData).ppls_id;

    //locals
    const { program, productLine } = locationData;
    const { getUserRole, getUserRoles } = UserPolicyHelper();
    const filterModal = {
        is_deactivated: {
            "filterType": "multi",
            "filterModels": [
                null,
                {
                    "values": ["false"],
                    "filterType": "set"
                }
            ]
        }
    };

    //hooks
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();
    const [fetchSKUs] = useLazyGetSkuQuery();
    const [deactivateSKU] = useDeactivateSKUMutation();
    const [requestPN] = useRequestPNMutation();

    //state
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [hasDeactivateAccess, setHasDeactivateAccess] = useState(false);
    const [visible, setVisible] = React.useState(false);
    const [editSKUModalVisible, setEditSKUModalVisible] = useState(false);
    const [editMPModalVisible, setEditMPModalVisible] = useState(false);
    const [prepareInitialized, setPrepareInitialized] = useState<boolean>(false);
    const [hideDisabled, setHideDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);

    //Refs
    const apiRef = useRef<AgGridApi>({
        grid: undefined,
        column: undefined
    });
    const editingSKU = useRef({});

    const getRowId = (params: any) => {
        return params.sku_id;
    };

    //Handlers
    const onRefresh = async () => {
        try {
            if(assortments && SKUs){
                setIsLoading(true);
                const response = await fetchSKUs({ verity_rpn_id: program.id });
                if (response.isSuccess) {
                    const groupedSKUs = groupSKUs(response.data, assortments);
                    setGridOptions((prevState) => {
                        return {
                            ...prevState,
                            rowData: [...Object.values(groupedSKUs)]
                        };
                    });
                    dispatch(updateAssignedAssortments(SKUs.reduce((assortmentStrings: string[], skuItem: skuItem) => {
                        const assortmentString = `${skuItem.version_id}_${skuItem.sku_type}_${assortments[skuItem.assortment_id].channel}_${assortments[skuItem.assortment_id].country ?? assortments[skuItem.assortment_id].row}`;
                        if (!assortmentStrings.includes(assortmentString)) {
                            assortmentStrings.push(assortmentString);
                        }
                        return assortmentStrings;
                    }, [])));
                    setFilterModel(hideDisabled);
                }
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Error occurred in fetching Assortments.",
                id: "ASSORTMENT_FETCH_FAILED"
            });
        } finally {
            setIsLoading(false);
        }
    };

    const requestPNHandler = (sku_id: number, description: string) => {
        try {
            return requestPN({input: {
                sku_id,
                description
            }});
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Request for Part Number Failed.",
                id: "PN_REQUEST_FAILED"
            });
        }
    };

    const deactivateSKUHandler = async (sku_id: number) => {
        return await deactivateSKU({input: {"sku_id": sku_id}});
    };

    const toggleSKUModal = (visible: boolean, sku:any)=>{
        setEditSKUModalVisible(visible);
        editingSKU.current = sku;
    };

    const toggleMPEditModal = (visible: boolean, sku:any) => {
        setEditMPModalVisible(visible);
        editingSKU.current = sku;
    };

    const setFilterModel = (hideDisabled: boolean) => {
        if(hideDisabled){
            apiRef.current.grid?.setFilterModel(filterModal);
        }else {
            apiRef.current.grid?.setFilterModel(null);
        }
    };

    const onGridReady = (event: any): void => {
        apiRef.current.grid = event.api;
        apiRef.current.column = event.columnApi;
        apiRef.current.grid?.setFilterModel(filterModal);
    };

    const onComponentStateChanged = () => {
        apiRef.current.column?.autoSizeAllColumns();
    };

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        defaultColDef: {
            editable: false
        },
        onGridReady,
        onComponentStateChanged,
        rowData: []
    });

    const customSettings = {
        className: 'ag-theme-alpine',
        readOnly: false,
        allowLocalFilters: true,
        filterId: 'sku',
        rowHeight: 50,
        defaultAutoSizeAll: true,
        getRowId
    };

    const getActionComponents = () => {
        return (
            <div className='hideDisableToggle toggle'>
                <Toggle
                    onChange={({ detail }) =>{
                        setFilterModel(detail.checked);
                        setHideDisabled(detail.checked);
                    }
                    }
                    checked={hideDisabled}
                >
                    Hide disabled SKUs.
                </Toggle>
            </div>
        );
    };

    const getAddSkuTable = () => {
        if (visible) {
            return <AddNewSkuModal visible={visible} setVisible={setVisible} addNotificationItem={addNotificationItem} />;
        }
    };

    const getEditSKUModal = () => {
        if(editSKUModalVisible){
            return <EditSkuModal visible={editSKUModalVisible} setEditSKUModalVisible={setEditSKUModalVisible} addNotificationItem={addNotificationItem} editingSKU={editingSKU.current}/>;
        }
    };

    const getMPEditModal = () => {
        if(editMPModalVisible){
            return <EditMarketPlaceModal visible={editMPModalVisible} setEditMPModalVisible={setEditMPModalVisible} addNotificationItem={addNotificationItem} editingSKU={editingSKU.current}/>;
        }
    };

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY]);
        setHasWriteAccess((USER_ACTION_ROLES.write.includes(currRole) && pplsId?.length > 0) ? true : false);
        const roles = getUserRoles(roleResMap, [ALL_ASSIGNEES_KEY]);
        setHasDeactivateAccess((USER_ACTION_ROLES.adminPanel.some(item => roles.includes(item)) && pplsId?.length > 0) ? true : false);
    }, [roleResMap, program, productLine, pplsId]);

    useEffect(() => {
        clearNotifications();
    }, [program]);


    useEffect(() => {
        setIsLoading(true);
        if (SKUs && assortments && Object.keys(versions).length) {
            const groupedSKUs = groupSKUs(SKUs, assortments);
            setPrepareInitialized(true);
            setGridOptions((prevState) => {
                return {
                    ...prevState,
                    columnDefs: skuColDefs(hasWriteAccess, hasDeactivateAccess, requestPNHandler, addNotificationItem, versions, toggleMPEditModal, toggleSKUModal, deactivateSKUHandler),
                    rowData: [...Object.values(groupedSKUs)]
                };
            });

            dispatch(updateAssignedAssortments(SKUs.reduce((assortmentStrings: string[], skuItem: skuItem) => {
                const assortmentString = `${skuItem.version_id}_${skuItem.sku_type}_${assortments[skuItem.assortment_id]?.channel}_${assortments[skuItem.assortment_id]?.country ?? assortments[skuItem.assortment_id]?.row}`;
                if (!assortmentStrings.includes(assortmentString)) {
                    assortmentStrings.push(assortmentString);
                }
                return assortmentStrings;
            }, [])));

        }
        if(!assortmentsLoading && !skuLoading){
            setIsLoading(false);
        }

    }, [SKUs, assortments, assortmentsLoading, skuLoading, versions, hasWriteAccess, hasDeactivateAccess]);

    useEffect(() => {
        if (skuError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Error occurred in fetching SKUs.",
                id: "SKU_FETCH_FAILED"
            });
        }
        if (assortmentsError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Error occurred in fetching Assortments.",
                id: "ASSORTMENT_FETCH_FAILED"
            });
        }
    }, [skuError, assortmentsError]);


    return (
        <>
            <Container key={program.id}
                header={
                    <Header
                        variant="h2"
                        description=""
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button onClick={() => setVisible(true)} disabled={!hasWriteAccess} >Add SKU</Button>
                            </SpaceBetween>
                        }
                    >
                        SKUs
                    </Header>
                }
            >
                <SpaceBetween size='l'>
                    <Flashbar items={notificationItems} stackItems />
                    {isLoading
                        ? <div className='loadspinner'><Spinner size="large" /></div>
                        : <GridTable
                            gridSettings={gridOptions}
                            customSettings={{
                                ...customSettings,
                                prepareInitialized
                            }}
                            onRefresh={onRefresh}
                            actionComponents = {getActionComponents()}
                        />
                    }
                </SpaceBetween>
            </Container>
            {getAddSkuTable()}
            {getEditSKUModal()}
            {getMPEditModal()}
        </>

    );
}