import React from 'react';
import { Box, ButtonDropdown, ColumnLayout, Grid, SpaceBetween } from "@cloudscape-design/components";
import { Form } from '../../../common/Formik-Cloudscape/components/form/form';
import { Formik } from 'formik';
import { Multiselect } from '../../../common/Formik-Cloudscape/components/multiselect/multiselect';
import { Checkbox } from 'src/components/common/Formik-Cloudscape/components/checkbox/checkbox';
import { FormField } from 'src/components/common/Formik-Cloudscape/components/form-field/form-field';
import { ATTRIBUTES_ACTION, HELPER_SEPARATOR, API_RESPONSE_FIELDS } from '../../constants/constants';
import { IAttributesFormProps, IFormInputProps } from '../../interfaces/interfaces';

export const AttributeForm = ({ formInputs, action, reorder, reorderAction, fpnReadonly, isFPN }: IAttributesFormProps) => {

    const initialValues: Record<string, any> = {};
    const renderForm = () => {
        formInputs.forEach((obj: IFormInputProps) => {
            if (obj.values) initialValues[obj.name] = obj.values;
            initialValues[obj.name + HELPER_SEPARATOR + API_RESPONSE_FIELDS.isPartOfFPN] = obj.IsPartOfFPN ?? false;
        });
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={() => { }}
                >
                    {() => (
                        <Form
                        >
                            <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
                                <SpaceBetween size="xxl">

                                    {formInputs.map(({ name, label, all_options, readOnly, removeDisabled }: IFormInputProps, index: number) => {

                                        const fpnChecboxName = name + HELPER_SEPARATOR + API_RESPONSE_FIELDS.isPartOfFPN;
                                        return (
                                            <div key={name}>
                                                <FormField stretch label={label} name="multiselect">
                                                    <ColumnLayout columns={2}>
                                                        <Multiselect
                                                            name={name}
                                                            disabled={readOnly}
                                                            deselectAriaLabel={(e) => 'Remove ' + e.label}
                                                            options={all_options}
                                                            placeholder={"Choose options for " + label}
                                                            selectedAriaLabel="Selected"
                                                            filteringType="auto"
                                                            onChange={({ detail }) => {
                                                                action(detail.selectedOptions, name);
                                                            }}
                                                        />
                                                        <div>
                                                            <Grid
                                                                gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                                                            >
                                                                <div> <Checkbox name={fpnChecboxName}
                                                                    disabled={fpnReadonly ?? true}
                                                                    onChange={({ detail }) => {
                                                                        action(detail.checked, fpnChecboxName);
                                                                    }}
                                                                > In FPN</Checkbox></div>
                                                                {!isFPN &&
                                                                    <Box float="right"> {reorder && <ButtonDropdown
                                                                        onItemClick={({ detail }) => {
                                                                            if (reorderAction) reorderAction(detail.id);
                                                                        }}
                                                                        items={[
                                                                            { text: "Move attribute up", id: ATTRIBUTES_ACTION.up + HELPER_SEPARATOR + index, disabled: index === 0 ? true : false },
                                                                            { text: "Move attribute down", id: ATTRIBUTES_ACTION.down + HELPER_SEPARATOR + index, disabled: index === formInputs.length - 1 ? true : false },
                                                                            { text: "Remove attribute", id: ATTRIBUTES_ACTION.remove + HELPER_SEPARATOR + index, disabled: removeDisabled }
                                                                        ]}
                                                                    >
                                                                        Re-order
                                                                    </ButtonDropdown>}</Box>}
                                                            </Grid>
                                                        </div>
                                                    </ColumnLayout>
                                                </FormField>

                                            </div>

                                        );
                                    })

                                    }

                                </SpaceBetween>
                            </Grid>

                        </Form>
                    )}
                </Formik>

            </>
        );
    };

    return (
        <>
            {renderForm()}
        </>
    );
};