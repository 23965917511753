import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import { SpaceBetween, Table, Modal, Box, Button, TextContent, TableProps, Spinner, Alert, FormField } from "@cloudscape-design/components";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { selectAttributesServerData, selectAttributesClientData, selectSameForAllFPNs, selectAllPlAttributes, selectFPNsList, selectPlFPNRule } from '../attributes/attributesSlice';
import { IAttributesOrdering, IPostFPN, IFPNAttrListMutation, IResponsePayload, IRpnFpnRuleMutation } from '../../interfaces/interfaces';
import ScreenUtils from '../../utils/screenUtils';
import { resetProductHierarchy, selectLocationData } from '../appLayout/appLayoutSlice';
import { useCreateFPNMutation, useUpdateFPNsMutation } from '../../services/api';
import { useUpdateRpnFpnRuleMutation, useUpdateAttrListFPNMutation } from '../../../redux/services/attributesApi';
import AlertMessageContext from 'src/components/common/Contexts/alertMessageContext';
import { ALERT_MESSAGES, ATTRIBUTE_TYPE_NAMES, FPN_RULE_PROGRAM, MUTATION_ACTION, MUTATION_METHODS, NA_LABEL, SEPERATOR, SK_MAPPING, STATUS_CODES, TAB_ID_LABEL, VALIDATION_MESSAGES } from '../../constants/constants';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import { selectKeyDatesSectionServerData } from '../keyDates/keyDatesSlice';
import { selectVerityData } from '../programOverview/programOverviewSlice';

interface IFPNList {
    id: number
    FPN: string
}

interface ICreateFPNModal {
    showModal: any;
    setShowModal: any;
    fpnCreationOrder: IAttributesOrdering[];
    fpnDataLoading: boolean;
    fpnDataError: boolean;
}
export const CreateFPNsModal = ({ showModal, setShowModal, fpnCreationOrder, fpnDataLoading, fpnDataError }: ICreateFPNModal) => {
    const dispatch = useAppDispatch();
    const { program } = useAppSelector(selectLocationData);
    const [createFPNs, { isLoading, data: newProgramData, isError: newProgramError }] = useCreateFPNMutation();
    const [updateProgramSetup, { isLoading: mutationLoading, data: updateFPNData, isError: updateFPNError }] = useUpdateFPNsMutation();
    const [updateRpnFpnRule, { isLoading: fpnRuleLoading, data: fpnRuleData, isError: fpnRuleError }] = useUpdateRpnFpnRuleMutation();
    const [updateAttrListFPN, { isLoading: attrListLoading, data: attrListData, isError: attrListError }] = useUpdateAttrListFPNMutation();

    const { setSuccess, setError } = useContext(AlertMessageContext);
    const attrserverData = useAppSelector(selectAttributesServerData);
    const programData = useAppSelector(selectVerityData);
    const attrClientData = useAppSelector(selectAttributesClientData);
    const sameForAllFPNs = useAppSelector(selectSameForAllFPNs);
    const fpnList = useAppSelector(selectFPNsList);
    const plFpnRule = useAppSelector(selectPlFPNRule);
    const attrClientValues = attrClientData.allAttributesValues;
    const progAttrTypes = attrClientData.allProgAttributes;
    const plAttrTypes = useAppSelector(selectAllPlAttributes);
    const keyDatesData = useAppSelector((state) => selectKeyDatesSectionServerData(state, SK_MAPPING.streetDates));
    const [items, setItems] = useState<IFPNList[]>([]);
    const [invalidFPNs, setInvalidFPNs] = useState<string[]>([]);
    const [selectedItems, setSelectedItems] = useState<IFPNList[]>([]);
    const [asinFPNs, setAsinFPNs] = useState<string[]>([]);
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const isEqual = _.isEqual(attrserverData, attrClientData);
    const { getUserId, getGraphQLPayload, cartesianProduct, getPrimaryKeyFPNId, generatePayload } = ScreenUtils;
    const userId = getUserId() ?? '';
    const columnDefinitions: TableProps.ColumnDefinition<IFPNList>[] = [
        {
            id: 'id',
            sortingField: 'id',
            header: '#',
            cell: (item: any) => item.id
        },
        {
            id: 'FPN',
            sortingField: 'FPN',
            header: 'FPN',
            cell: (item: any) => item.FPN
        }
    ];

    const generateFPNList = () => {
        const allApplicableValues: string[][] = [[program.name]];
        const selectedFPNs: IFPNList[] = [];
        const fpnWithAsins: string[] = [];
        const existingFPNs = fpnList?.map(item => item.fpn) ?? [];
        const existingAsinFPNs = fpnList?.filter(item => item.asin_count > 0).map(item => item.fpn) ?? [];
        fpnCreationOrder.forEach(item => {
            const values = attrClientValues[item.attribute];
            if (values?.length) allApplicableValues.push(values);
        });
        const result = cartesianProduct(allApplicableValues);
        const fpns = new Set<string>([]);
        const FPNlist: IFPNList[] = [];
        let count = 1;
        result.forEach((obj: string[]) => fpns.add(obj.filter((ele: string) => ele !== NA_LABEL).join('_')));
        const invalidFPN = existingFPNs.filter(item => !existingAsinFPNs.includes(item) && !fpns.has(item));
        Array.from(fpns).sort().forEach((ele: string) => {
            const item = {
                id: count++,
                FPN: ele
            };
            FPNlist.push(item);
            if (existingFPNs.includes(ele)) selectedFPNs.push(item);
            if (existingAsinFPNs.includes(ele)) fpnWithAsins.push(ele);
        });

        setSelectedItems(selectedFPNs);
        setAsinFPNs(fpnWithAsins);
        setInvalidFPNs(invalidFPN);
        return FPNlist;
    };

    useEffect(() => {
        setItems(generateFPNList());
    }, [fpnList]);

    const submit = async () => {
        const existingFPNs = fpnList?.map(item => item.fpn) ?? [];
        const allFPNs = selectedItems.map((obj: IFPNList) => obj.FPN);
        const removeFPNs = existingFPNs.filter(fpn => !allFPNs.includes(fpn) && !asinFPNs.includes(fpn));
        const addFPNs = allFPNs.filter(fpn => !existingFPNs.includes(fpn));
        if (addFPNs.length || removeFPNs.length) {
            const payload: IPostFPN[] = [{
                verity_RPN_id: parseInt(program.id),
                user_id: userId,
                add_fpn: addFPNs,
                remove_fpn: removeFPNs
            }];
            setIsCreateLoading(true);
            if (programData.asinCount === 0) postRpnFpnRule();
            await createFPNs(getGraphQLPayload(payload)).unwrap();
        }
    };

    useEffect(() => {
        if (newProgramData) {
            if (newProgramData?.errors || newProgramError) {
                setIsCreateLoading(false);
                setError?.(ALERT_MESSAGES.programFailure);
            }
            if (newProgramData?.data?.[MUTATION_METHODS.postFPN]) {
                const { statusCode, updatedCodes, error }: IApiUpdateResponse = newProgramData.data[MUTATION_METHODS.postFPN];
                if (statusCode === STATUS_CODES.success && updatedCodes) {
                    const fpnsIdMap: Record<string, string> = {};
                    updatedCodes.forEach((str: string) => {
                        // Temporary fix: will update once API sends valid json
                        const arr = str.split(',');
                        if (arr.length > 2) {
                            const action = arr[2].split('=')[1];
                            if (!action?.includes('is_fpn_key_active set to 0')) fpnsIdMap[arr[0].split('=')[1]] = getSubstring(arr[1], '=', '}');
                        } else fpnsIdMap[arr[0].split('=')[1]] = getSubstring(arr[1], '=', '}');
                    });
                    dispatch(resetProductHierarchy());
                    setSuccess?.(ALERT_MESSAGES.updateSuccess);
                    createFPNDetailsVerity(fpnsIdMap);
                    createFPNDetailsPRM(fpnsIdMap);
                } else {
                    (statusCode === STATUS_CODES.handledException && error) ? setError?.(error) : setError?.(ALERT_MESSAGES.programFailure);
                }
                setIsCreateLoading(false);
                setShowModal(false);
            }
        }
    }, [newProgramData, newProgramError]);

    // Temporary usage: will remove once api sends valid json 
    const getSubstring = (str: string, char1: string, char2: string) => {
        const lastIdx = str.includes(char2) ? str.lastIndexOf(char2) : str.length;
        return str.substring(
            str.indexOf(char1) + 1,
            lastIdx
        );
    };

    const postRpnFpnRule = async () => {
        const payload: IRpnFpnRuleMutation[] = [];
        plFpnRule?.forEach(item => {
            payload.push({
                attribute_type: item.attribute_type, concat_order: item.concat_order, user_id: userId, verity_rpn_id: parseInt(program.id)
            });
        });
        await updateRpnFpnRule(getGraphQLPayload(payload)).unwrap();
    };

    const createFPNDetailsPRM = async (fpnIds: Record<string, string>) => {
        if (sameForAllFPNs.includes(ATTRIBUTE_TYPE_NAMES.country)) {
            const payload: IResponsePayload[] = [];
            Object.keys(fpnIds).forEach(fpnId => {
                attrserverData.allAttributesValues[ATTRIBUTE_TYPE_NAMES.country]?.forEach((code: string) => {
                    const rpnKeydate = keyDatesData[code];
                    const values = rpnKeydate?.IsInheritedByFPN ? rpnKeydate?.ItemValues ?? null : null;
                    payload.push({
                        PK: getPrimaryKeyFPNId(fpnId, 'Sandbox'),
                        SK: TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.streetDates + SEPERATOR + code,
                        ItemValues: values,
                        Action: MUTATION_ACTION.put,
                        Version: 0
                    });
                });
            });
            if (payload.length) {
                const formattedPayload = generatePayload(payload);
                if (formattedPayload) await updateProgramSetup(formattedPayload).unwrap();
            }
        }
    };

    const createFPNDetailsVerity = async (fpnIds: Record<string, string>) => {
        const attrListPayload: IFPNAttrListMutation[] = [];
        const attrsPartOfFPN = fpnCreationOrder.map(obj => obj.attribute);
        Object.entries(fpnIds).forEach(([fpnId, fpnName]) => {
            const fpnAttributes = fpnName.split('_');
            sameForAllFPNs.forEach(type => {
                if (attrClientValues[type]?.length) {
                    attrListPayload.push({
                        verity_fpn_id: parseInt(fpnId),
                        attribute_type: type,
                        add_attribute_value: attrClientValues[type],
                        remove_attribute_value: [],
                        user_id: userId
                    });
                }
            });

            [...progAttrTypes, ...plAttrTypes].forEach(({ attribute_type }) => {
                let values: string[] = [];
                if (attrsPartOfFPN.includes(attribute_type)) {
                    let valueFound = false;
                    attrClientValues[attribute_type].forEach((value: string) => {
                        if (fpnAttributes.includes(value)) {
                            values = [value];
                            valueFound = true;
                        }
                    });
                    if (!valueFound && attrClientValues[attribute_type].includes(NA_LABEL)) values = [NA_LABEL];
                } else values = attrClientValues[attribute_type];
                attrListPayload.push({
                    verity_fpn_id: parseInt(fpnId),
                    attribute_type: attribute_type,
                    add_attribute_value: values,
                    remove_attribute_value: [],
                    user_id: userId
                });
            });
        });
        if (attrListPayload.length) await updateAttrListFPN(getGraphQLPayload(attrListPayload)).unwrap();
    };

    return (
        <>
            <Modal
                onDismiss={() => setShowModal(false)}
                size="large"
                visible={showModal}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setShowModal(false)} variant="link">Cancel</Button>
                            <FormField constraintText={!isEqual ? VALIDATION_MESSAGES.unsavedAttributes : undefined}>
                                <Button className='bg-primary' disabled={!isEqual || !items.length || fpnDataLoading || fpnDataError} onClick={submit} variant="primary">Create</Button>
                            </FormField>
                        </SpaceBetween>
                    </Box>
                }
                header="Create FPNs"
            >
                {!items.length && <Alert statusIconAriaLabel="Info">

                </Alert>}
                {isCreateLoading || isLoading || mutationLoading || fpnRuleLoading || attrListLoading ? <div className='loadspinner mg-top-md'><Spinner size="large" /></div> :
                    <>
                        {fpnDataLoading && <div className='loadspinner mg-top-md'><Spinner size="normal" />Fetching existing FPNs...</div>}
                        {fpnDataError && <Alert statusIconAriaLabel="Error" type="error">{ALERT_MESSAGES.fpnListError}</Alert>}
                        <TextContent>
                            <p>Select the following FPNs to create under a program</p>
                        </TextContent>
                        <Table
                            selectionType={fpnDataLoading ? undefined : "multi"}
                            columnDefinitions={columnDefinitions}
                            items={items}
                            variant="embedded"
                            selectedItems={selectedItems}
                            isItemDisabled={item => asinFPNs.includes(item.FPN)}
                            sortingDisabled
                            onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)
                            }
                        ></Table>
                        {invalidFPNs?.length > 0 &&
                            <Box className='mg-top-md'>
                                <Alert statusIconAriaLabel="Warning" type="warning" header="These FPNs are not valid and will be deactivated:">
                                    {invalidFPNs.map(item => {
                                        return (<div>{item}</div>);
                                    })}
                                </Alert>
                            </Box>
                        }
                    </>
                }
            </Modal>
        </>
    );
};
