import React, { useEffect, useState } from 'react';
import { Button, ColumnLayout, Container, FormField, Header, MultiselectProps, Select, SpaceBetween, Flashbar } from '@amzn/awsui-components-react';
import { useGetPRMScenariosQuery } from '../../services/apis/scenarioSnapshotApi';
import useNotification from 'src/components/Hooks/notifications';
import ScreenUtils from '../../utils/screenUtils';
import { STATUS_CODES } from '../../constants/constants';
import { IApiResponse } from 'src/components/Interfaces/interface';
import { IPRMScenarios } from '../../interfaces/interfaces';
import { SNAPSHOT_MESSAGES } from '../../constants/displayMessages';
import Endpoints from 'src/constants/endpoints';
import { ASYNC_SERVICE_NAME } from 'src/components/Verity/constants/constants';
import { getUserId } from 'src/components/common/Auth/Auth';
import { HttpMethod, acknowledge, process } from 'src/components/common/AsyncHandler/WebSocketHandler';
import { useAsyncResponse } from 'src/components/Hooks/AsynResponseState';
import { statusCodes } from 'src/constants/constants';

export const RefreshSnapshot = () => {
    const [selectedScenario, setSelectedScenario] = useState<MultiselectProps.Option>({});
    const [snroOpts, setSnroOpts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [asyncResponse, setAsyncResponse] = useState<any>({});
    const { setCurrentProcessId, getCurrentProcessId, deleteCurrentProcessId } = useAsyncResponse();
    const { data: scenarioData, isError: snroErr, isLoading: snrLoading, isFetching: snrFetch } = useGetPRMScenariosQuery();
    const { notificationItems, addNotificationItem } = useNotification();
    const { getNotificationItem } = ScreenUtils;

    useEffect(() => {
        try {
            const processID = getCurrentProcessId('REFRESH_SNAPSHOT');
            if (processID && asyncResponse?.response?.statusCode) {
                if (asyncResponse.response.statusCode == statusCodes.success) {
                    addNotificationItem(getNotificationItem('success', 'SNAPSHOT_SUCCESS', SNAPSHOT_MESSAGES.snapshotRfrSuccess));
                } else {
                    addNotificationItem(getNotificationItem('error', 'SNAPSHOT_ERROR', SNAPSHOT_MESSAGES.snapshotRfrError));
                }
                acknowledge(processID);
                deleteCurrentProcessId('REFRESH_SNAPSHOT');
                setIsLoading(false);
            }
        } catch (error) {
            addNotificationItem(getNotificationItem('error', 'SNAPSHOT_ERROR', SNAPSHOT_MESSAGES.snapshotRfrError));
            setIsLoading(false);
        }
    }, [asyncResponse]);

    useEffect(() => {
        if (scenarioData?.errors || snroErr) addNotificationItem(getNotificationItem('error', 'USER_POLICY_ERROR', SNAPSHOT_MESSAGES.snroGetError));
        if (scenarioData?.data?.get_prm_scenarios) {
            const { statusCode, body }: IApiResponse = scenarioData.data?.get_prm_scenarios;
            if (statusCode === STATUS_CODES.success) {
                const opts = body.map(({ scenario_id, scenario_name }: IPRMScenarios) => {
                    return { label: scenario_name, value: scenario_id };
                });
                setSnroOpts(opts);
            } else addNotificationItem(getNotificationItem('error', 'USER_POLICY_ERROR', SNAPSHOT_MESSAGES.snroGetError));
        }
    }, [scenarioData, snroErr]);

    const save = async () => {
        if (selectedScenario?.value) {
            setIsLoading(true);
            const processResponse = await process(
                Endpoints.getSnapshotPRMRefreshUrl(),
                HttpMethod.Post,
                ASYNC_SERVICE_NAME,
                {
                    latestStatus: '',
                    response: {}
                },
                setAsyncResponse,
                getUserId() || '',
                `${getUserId()}@amazon.com` || '',
                {
                    target: { verity_scenario_id: parseInt(selectedScenario.value)}
                }
            );
            setCurrentProcessId('REFRESH_SNAPSHOT', processResponse?.processId);
        }
    }

    return (

        <Container
            header={
                <Header variant='h2'>
                    Refresh snapshot
                </Header>
            }
        >
            <SpaceBetween size='xxl'>
                <Flashbar items={notificationItems} stackItems />

                <ColumnLayout columns={3}>
                    <FormField
                        label='Scenario'
                    >
                        <Select
                            selectedOption={selectedScenario}
                            filteringType='auto'
                            loadingText={'Loading scenarios'}
                            statusType={(snrLoading || snrFetch) ? 'loading' : 'finished'}
                            onChange={({ detail }) =>
                                setSelectedScenario(detail.selectedOption)
                            }
                            options={snroOpts}
                        />
                    </FormField>
                </ColumnLayout>
                <Button className='bg-primary'
                    ariaLabel='create-snapshot'
                    disabled={!selectedScenario?.value}
                    loading={isLoading}
                    onClick={save}
                    variant='primary'
                    data-testid='RefreshSnapshotButton'
                    >
                    Refresh snapshot</Button>
            </SpaceBetween>
        </Container>

    );
};