import React, { useEffect, useState } from 'react';
import { Box, MultiselectProps, Spinner } from "@cloudscape-design/components";
import { selectProgAssignees, selectPLAssignees } from '../userPolicySlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { USER_ROLES } from '../../../constants/constants';
import { selectUserDetailsMap, updateUserDetailsMap } from '../../appLayout/appLayoutSlice';
import { useGetListOfUserDetailsQuery } from '../../../services/api';
import { IApiUserResponse } from '../../../interfaces/interfaces';
import { getUserOption } from '../../userSearch/userSearchMultiselect';
import ScreenUtils from '../../../utils/screenUtils';
import UserPolicyHelper from '../userPolicyHelper';

export interface IAssignUserModal {
    tab: string,
    section: string
}

export const ProgramAssignees = ({ tab, section }: IAssignUserModal) => {
    const dispatch = useAppDispatch();
    const allProgAssignees = useAppSelector(selectProgAssignees);
    const allPLAssignees = useAppSelector(selectPLAssignees);
    const { getNameFromOptLabel } = ScreenUtils;
    const { getProgAssignees, getPLAssignees } = UserPolicyHelper();
    const progAssignees = getProgAssignees(allProgAssignees, USER_ROLES.inputProvider, tab, section);
    const plAssignees = getPLAssignees(allPLAssignees, USER_ROLES.inputProvider, tab, '');
    const userDetailsMap = useAppSelector(selectUserDetailsMap);
    const [userAliases, setUserAliases] = useState<string[]>([]);
    const { data: userData, isLoading: usersLoading } = useGetListOfUserDetailsQuery(userAliases, { skip: userAliases.length === 0 });
    const [displayUsers, setDisplayUsers] = useState('');

    useEffect(() => {
        setDisplayUsers(Array.from(new Set([...(progAssignees?.length ? progAssignees : []), ...(plAssignees?.length ? plAssignees : [])])).map(alias => getNameFromOptLabel(userDetailsMap?.[alias]?.label ?? '')).join(', '));
    }, [userDetailsMap, progAssignees, plAssignees]);

    useEffect(() => {
        const aliases: string[] = [];
        Array.from(new Set([...(progAssignees?.length ? progAssignees : []), ...(plAssignees?.length ? plAssignees : [])]))?.forEach((alias: string) => {
            if (!userDetailsMap[alias]) aliases.push(alias);
        });
        if (aliases.length) setUserAliases(aliases);
    }, [plAssignees, progAssignees]);

    useEffect(() => {
        if (userData) {
            const userMap: Record<string, MultiselectProps.Option> = {};
            userData.forEach((obj: IApiUserResponse) => {
                if (obj?.exact_match?.length > 0) userMap[obj.exact_match[0].employee_login] = getUserOption(obj.exact_match[0]);
            });
            dispatch(updateUserDetailsMap(userMap));
        }
    }, [userData]);

    return (
        <Box padding={{ left: "l" }} color="text-body-secondary" variant="span">Assignees<Box variant="span" className='user-list'>{usersLoading ? <Spinner /> : displayUsers}</Box></Box>
    );
};
