import React, { useEffect, useState } from "react";
import { Alert } from "@cloudscape-design/components";
import { useAppSelector } from "../../../redux/hooks";
import { selectAttributesClientData, selectUserRole, selectAllStandardAttributes, selectAllPlAttributes } from './attributesSlice';
import { ATTRIBUTE_TYPE_NAMES, USER_ACTION_ROLES } from '../../constants/constants';
import { selectLocationData, selectIsLocked } from '../appLayout/appLayoutSlice';

export const AttributeAlert = ({loading}: any) => {

    const clientValues = useAppSelector(selectAttributesClientData).allAttributesValues;
    const stdAttr = useAppSelector(selectAllStandardAttributes);
    const {fpn} = useAppSelector(selectLocationData);
    const isLocked = useAppSelector(selectIsLocked);
    const plAttrObj = useAppSelector(selectAllPlAttributes);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole));
    const [missingAttr, setMissingAttr] = useState<string[]>([]);

    useEffect(() => {
        const missing : string[] = [];
        const plAttr = plAttrObj.map(obj => obj.attribute_type);
        [...stdAttr, ...plAttr].forEach(attr => {
            if(attr && !clientValues[attr]?.length && attr !== ATTRIBUTE_TYPE_NAMES.row) missing.push(attr);
        });
        setMissingAttr(missing);
    }, [plAttrObj, stdAttr, clientValues]);

    return (
        <> 
        {isLocked && <Alert
            className="mg-bottom-md"
            statusIconAriaLabel="Warning"
            type="warning"
            header="Attribute changes are currently locked for OP1 first pass, please contact @huberwan for more information."
        />}
        {!fpn.id && hasWriteAccess && missingAttr.length > 0 && !loading && <Alert
            className="mg-bottom-md"
            statusIconAriaLabel="Error"
            type="error"
            header="Attributes setup is incomplete"
        >
            These attributes are missing values: {missingAttr.join(', ')}
        </Alert>}
        </>
    );
};
