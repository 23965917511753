import * as React from "react";
import { Header } from "@cloudscape-design/components";
import { selectLocationData } from "./appLayoutSlice";
import { useAppSelector } from "../../../redux/hooks";
import { HOME_PATH_HEADER } from "../../constants/constants";
import { DEFAULT_HEADER } from "../../constants/userMessages";

export const AGMHeader = () => {
    const { currentScreen, productLine, program } = useAppSelector(selectLocationData);

    const getHeaderName = () => {
        if (program.id) return program.name;
        if (productLine.id) return productLine.name;
        if (currentScreen in HOME_PATH_HEADER) return HOME_PATH_HEADER[currentScreen as keyof typeof HOME_PATH_HEADER];
        return DEFAULT_HEADER;
    };

    return (
        <>
            <Header
                variant="h1"
                className="page-header "
                description=""
            >
                {getHeaderName()}
            </Header>
        </>

    );

};
