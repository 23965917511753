import React, { useContext, useEffect, useState } from 'react';
import { ColDef, GridOptions, ColGroupDef } from "ag-grid-enterprise";
import { Spinner } from "@cloudscape-design/components";
import { IDeviceKey, IScenarioMutation } from '../../interfaces/interfaces';
import { IChangedCells, ICustomSettings } from '../../../common/GridTable/GridTableInterface';
import { useUpdateScenariConfigMutation } from '../../services/apis/scenarioApi';
import { GridTable } from '../../../common/GridTable/GridTable';
import CheckboxRenderer from './gridHelper/checkboxRenderer';
import ScreenUtils from '../../utils/screenUtils';
import AlertMessageContext from "../../../common/Contexts/alertMessageContext";
import { ALERT_MESSAGES, ALL_ASSIGNEES_KEY, STATUS_CODES, USER_ROLES } from '../../constants/constants';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import { COL_DEFS } from './gridHelper/configColumnDef';
import { defaultSideBar } from 'src/components/common/GridTable/GridTableUtils';
import { useAppSelector } from 'src/components/redux/hooks';
import { selectUserPolicy } from '../userPolicy/userPolicySlice';

interface IScenarioTableProps {
    results: IDeviceKey[];
    scenarios: Record<string, string>;
    onRefresh: () => void;
}

export const ConfigTable = ({ results, scenarios, onRefresh }: IScenarioTableProps) => {
    const userPolicy = useAppSelector(selectUserPolicy);
    const [tableItems, setTableItems] = useState<IDeviceKey[]>([]);
    const [prepareInitialized, setPrepareInitialized] = useState<boolean>(false);
    const [updateConfiguration, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateScenariConfigMutation();
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);

    const getCustomSettings = (): ICustomSettings => {
        return {
            className: 'ag-theme-alpine',
            uid: "concat_string",
            readOnly: false,
            groupIncludeTotalFooter: true,
            allowLocalFilters: true,
            filterId: 'scenarioConfig',
            pinnedId: 'scenarioConfigPinned',
            defaultAutoSizeAll: true,
            sideBar: defaultSideBar,
            pinnedResetState: [],
            allowAddRow: false,
            enableOnChangeHighlight: true
        } as ICustomSettings;
    };

    const [customSettings] = useState<ICustomSettings>(getCustomSettings());
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: COL_DEFS,
        rowData: []
    });

    const onCellChange = (props: any) => props.api.refreshHeader();

    useEffect(() => {
        const items: any[] = [...results];
        results.forEach((obj: IDeviceKey, index: number) => {
            const tableItem = { ...items[index] };
            Object.keys(scenarios).map((scenario: string) => {
                tableItem[scenario] = obj.scenario_ids.includes(parseInt(scenario));
            });
            items[index] = tableItem;
        });
        setTableItems(items);

    }, [results]);

    useEffect(() => {   
        if (userPolicy?.[USER_ROLES.scenarioConfigMgr]?.[ALL_ASSIGNEES_KEY]) setHasWriteAccess(true);
    }, [userPolicy]);

    useEffect(() => {
        setPrepareInitialized(true);
        setGridOptions((prevState: any) => {
            return {
                ...prevState,
                rowData: tableItems
            };
        });
    }, [tableItems]);

    useEffect(() => {
        const colDefs: (ColGroupDef | ColDef)[] = [
            ...COL_DEFS,
            ...Object.keys(scenarios).map((scenario: string) => {
                return {
                    field: scenario,
                    headerName: scenarios[scenario],
                    onCellValueChanged: onCellChange,
                    cellRenderer: CheckboxRenderer,
                    cellRendererParams: {
                        disabled: !hasWriteAccess
                    },
                    minWidth: 300,
                    suppressColumnsToolPanel: true
                };
            })
        ];
        setGridOptions((prevState: any) => {
            return {
                ...prevState,
                columnDefs: colDefs
            };
        });
    }, [scenarios]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) setError?.(ALERT_MESSAGES.updateFailure);
            if (mutationData.data && mutationData.data.update_snro_config) {
                const { statusCode }: IApiUpdateResponse = mutationData.data.update_snro_config;
                statusCode === STATUS_CODES.success ? setSuccess?.(ALERT_MESSAGES.updateSuccess) : setError?.(ALERT_MESSAGES.updateFailure);
            }
        }
    }, [mutationData, mutationError]);

    const onSave = async (event: Record<string, IChangedCells>) => {
        console.log(event); // console added for beta testing
        const requestData: Record<string, IScenarioMutation> = {};
        if (event && Object.keys(event).length > 0) {
            Object.values(event).forEach((cell) => {
                if (cell.colId && cell.row.device_key) {
                    const deviceKey = cell.row.device_key;
                    const scnrId = parseInt(cell.colId.toString());
                    if (!requestData[deviceKey]) {
                        requestData[deviceKey] = {
                            verity_config_key: deviceKey,
                            user_id: ScreenUtils.getUserId(),
                            include_scenario_ids: [],
                            exclude_scenario_ids: []
                        };
                    }
                    cell.newValue ? requestData[deviceKey].include_scenario_ids.push(scnrId) : requestData[deviceKey].exclude_scenario_ids.push(scnrId);
                }
            });
        }
        const payload = Object.values(requestData);
        if(payload.length) await updateConfiguration(ScreenUtils.getGraphQLPayload(payload)).unwrap();
    };

    return (
        <>
            {!mutationLoading ?
                <GridTable
                    gridSettings={gridOptions}
                    customSettings={{
                        ...customSettings,
                        prepareInitialized
                    }}
                    onSave={hasWriteAccess ? onSave : undefined}
                    onRefresh={onRefresh}
                /> :
                <div className='loadspinner'><Spinner size="large" /></div>
            }
        </>

    );
};
