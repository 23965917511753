import React, { useEffect, useState } from "react";
import { Box, BreadcrumbGroup, Grid } from "@cloudscape-design/components";
import { selectLocationData } from "./appLayoutSlice";
import { useAppSelector } from "src/components/redux/hooks";
import { ALL_ASSIGNEES_KEY, SCREEN_PATHS, USER_ROLES } from "../../constants/constants";
import { selectUserPolicy } from "../userPolicy/userPolicySlice";

export const VerityBreadcrumbs = () => {
    const { currentScreen } = useAppSelector(selectLocationData);
    const userPolicy = useAppSelector(selectUserPolicy);
    const [currRole, setCurrRole] = useState('');

    useEffect(() => {
        if (currentScreen === SCREEN_PATHS.snroConfig ) {
            userPolicy?.[USER_ROLES.scenarioConfigMgr]?.[ALL_ASSIGNEES_KEY] ? setCurrRole('') : setCurrRole(USER_ROLES.readonly);
        } else setCurrRole('');
    }, [currentScreen, userPolicy]);

    return (
        <>
            <Grid
                gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
            >
                <div> <BreadcrumbGroup
                    items={[
                        { text: "Verity", href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.snroConfig}` },
                        { text: "Scenario config manager", href: "" }
                    ]}
                    ariaLabel="Breadcrumbs"
                /></div>
                <Box textAlign="right">{currRole}</Box>
            </Grid>
        </>


    );
};