import React from 'react';
import { FastField, Field, FieldProps } from 'formik';
import PolarisInput, {
  InputProps as PolarisInputProps,
} from "@cloudscape-design/components/input";
import { FormikFieldProps } from '../../field-props';

type InputProps = FormikFieldProps & Omit<PolarisInputProps, 'value'>;

export const Input: React.FC<InputProps> = ({
  name,
  validate,
  fast,
  onBlur,
  onChange,
  ...props
}: InputProps) => {
  const DesiredField = fast === true ? FastField : Field;

  return (
    <DesiredField name={name} validate={validate} fast={fast}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <PolarisInput
          name={name}
          value={value}
          onBlur={(event) => {
            setFieldTouched(name);
            onBlur?.(event);
          }}
          onChange={(event) => {
            setFieldValue(name, event.detail.value);
            onChange?.(event);
          }}
          {...props}
        />
      )}
    </DesiredField>
  );
};