import React, { useEffect, useState } from "react";
import { Box, BreadcrumbGroup, Grid } from "@cloudscape-design/components";
import { useAppSelector } from '../../../redux/hooks';
import { selectLocationData } from "./appLayoutSlice";
import AppLayoutHelper from './appLayoutHelper';
import { selectAgmRoleResourceMap } from "../userPolicy/userPolicySlice";
import { DISPLAY_ROLES, USER_ROLES } from "../../constants/constants";

export const AGMBreadcrumbs = () => {

    const locationData = useAppSelector(selectLocationData);
    const { generateBreadCrumbsItems } = AppLayoutHelper();
    const roleToResMap = useAppSelector(selectAgmRoleResourceMap);
    const [currRole, setCurrRole] = useState('');

    useEffect(() => {
        if (locationData.productLine.id) {
            const isAsinOwner = roleToResMap?.[USER_ROLES.asinOwner]?.length ? true : false;
            const isTechAdmin = roleToResMap?.[USER_ROLES.techAdmin]?.length ? true : false;
            setCurrRole(isAsinOwner ? DISPLAY_ROLES.asinOwner : isTechAdmin ? DISPLAY_ROLES.techAdmin : DISPLAY_ROLES.readonly);
        } else  setCurrRole('');
    }, [locationData, roleToResMap]);
    
    return (
        <>
            <Grid
                gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
            >
                <div> <BreadcrumbGroup
                    items={generateBreadCrumbsItems(locationData)}
                    ariaLabel="Breadcrumbs"
                /></div>
                <Box textAlign="right">{currRole}</Box>
            </Grid>
        </>

    );
};