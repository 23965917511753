import * as KatalMetrics from '@amzn/katal-metrics';
import appMetrics from '../../metrics';
import { ACTIONS } from './metricsConstants';
import { v4 as uuidv4 } from 'uuid';

const getDefaultCloudWatchDimensions = ()=>{
    let sessionID = sessionStorage.getItem('sessionID');
    if(!sessionID) {
        sessionID = uuidv4();
        sessionStorage.setItem('sessionID', sessionID);
    }
    return [
        new KatalMetrics.Metric.String("user", sessionStorage.getItem("userName")?.split("_")[1] ?? "Invalid User"),
        new KatalMetrics.Metric.String("user_session_id", sessionID)
    ];};


export const getPageTimeMetric = (app: string, page: string) => {
    const appDimension = new KatalMetrics.Metric.String(app, app);
    const timerMetric = new KatalMetrics.Metric.TimerStopwatch(ACTIONS.PAGE_TIME).withMonitor();

    const additionalContext = new KatalMetrics.Context(
        {
            cloudWatchDimensions: [appDimension, ...getDefaultCloudWatchDimensions()]
        }
    );
    return () => {
        appMetrics()?.newChildActionPublisherForMethod(page, additionalContext).publish(timerMetric);
    };
};

export const logUserAction = (app: string, page: string, action: string, valueProps: Record<string, string>)=>{
    try {
        const appDimension = new KatalMetrics.Metric.String(app, app);
        const valueDimensions: KatalMetrics.Metric.String[] = [];
        Object.keys(valueProps).forEach((key)=>{
            if(!valueProps[key]){
                return;
            }
            valueDimensions.push(new KatalMetrics.Metric.String(key, valueProps[key]));
        });

        const additionalContext = new KatalMetrics.Context(
            {
                cloudWatchDimensions: [
                    appDimension,
                    ...getDefaultCloudWatchDimensions(),
                    new KatalMetrics.Metric.String('action', action),
                    ...valueDimensions
                ]
            }
        );


        let userActionLog = JSON.parse(sessionStorage.getItem('actionLog') ?? "{}");

        if(userActionLog?.[page]?.[action]){
            userActionLog = {
                ...userActionLog
            };
            userActionLog[page][action] += 1;
        }else {
            userActionLog = {
                ...userActionLog,
                [page]: {
                    ...userActionLog[page],
                    [action]: 1
                }
            };
        }
        sessionStorage.setItem('actionLog', JSON.stringify(userActionLog));
        const actionCount  = userActionLog[page][action];

        appMetrics()?.newChildActionPublisherForMethod(page, additionalContext).publishCounter(action, actionCount);

    } catch (error) {
        console.warn(error);
    }
};