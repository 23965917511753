import React from 'react';
import { useFormikContext } from 'formik';
import PolarisForm, {
  FormProps as PolarisFormProps,
} from '@cloudscape-design/components/form';

interface FormProps extends PolarisFormProps {
  /**
   * Scroll to the first error key in Formik instance.
   */
  scrollToError?: boolean;
  prompt?: React.ReactNode;
}
/**
 * Form that uses https://formik.org/docs/api/formik#status-any to display top level error
 */
export const Form = ({
  scrollToError = true,
  prompt,
  children,
  ...props
}: FormProps) => {
  const { status } = useFormikContext();

  const errorText = !status?.success ? status?.message : '';

  return (
    <PolarisForm errorText={errorText} {...props}>
      {children}
      {prompt}
    </PolarisForm>
  );
};