import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Autosuggest, FormField } from '@amzn/awsui-components-react';

type ISelectOptions = {
    label: string,
    value: string
}
export const SearchEditor = forwardRef((props: any, ref: any) => {
    const [value, setValue] = useState(props.value || '');
    const [selectedValue, setSelectedValue] = useState('');
    const [options, setOptions] = useState<ISelectOptions[]>();
    useEffect(() => {
        if (props.options) {
            const result: ISelectOptions[] = [];
            props.options.forEach((ele: any) => {
                result.push({
                    label: ele,
                    value: ele
                });
            });
            setOptions(result);
        }
    }, [props.options]);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            }
        };
    });
    return (
        <>
            <div>
                <FormField>
                    <Autosuggest
                        id="alias"
                        value={selectedValue}
                        options={options}
                        enteredTextLabel={() => ''}
                        ariaLabel="search"
                        empty="Not found"
                        onChange={({ detail }) => setSelectedValue(detail.value)}
                        onSelect={({ detail }) => setValue(detail.value)}
                        className='auto-suggest'
                        selectedAriaLabel={value}
                    />
                </FormField>
            </div>
        </>
    );
});