import React from "react";
import { Container, Header, Button, ColumnLayout } from "@cloudscape-design/components";
import { SCREEN_PATHS } from "../../constants/constants";

export function RPTHome() {
    const paths = {
        newProgram: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.createProgram}`,
        newAttribute: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.newAttribute}`,
        newAttributeValue: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.newAttributeValue}`,
        newSnapshot: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.createSnapshot}`
    };
    return (
        <>
            <ColumnLayout columns={3}>
                <div><Container
                    header={
                        <Header
                            variant="h2"
                            className="mid-height"
                            description="Create a new program under existing product line."
                        >
                            Create new program
                        </Header>
                    }
                >
                    <Button className="bg-primary" variant="primary" href={paths.newProgram} >Create Program</Button>
                </Container></div>
               
                <div><Container
                    header={
                        <Header
                            variant="h2"
                            className="mid-height"
                            description="Create a new product line or program attribute for a existing program."
                        >
                            Request new attribute
                        </Header>
                    }
                >
                    <Button className="bg-primary" variant="primary" href={paths.newAttribute} >Request new attribute</Button>
                </Container></div>
                <div><Container
                    header={
                        <Header
                            variant="h2"
                            className="mid-height"
                            description="Create a new value for product line or program attribute."
                        >
                            Request new value for attribute
                        </Header>
                    }
                >
                    <Button className="bg-primary" variant="primary" href={paths.newAttributeValue} >Request value for attribute</Button>
                </Container></div>
                <div><Container
                    header={
                        <Header
                            variant="h2"
                            className="mid-height"
                            description="Create snapshot"
                        >
                            Create snapshot
                        </Header>
                    }
                >
                    <Button className="bg-primary" variant="primary" href={paths.newSnapshot} >Create Snapshot</Button>
                </Container></div>
            </ColumnLayout>

        </>
    );
}