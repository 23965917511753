export default class Queries {
    public static readonly getUserAccessSnro = () => {
        return `query ($user_name: String) {
            get_user_access_snro(user_name: $user_name, user_type: "PERSON") {
                body {
                    resource {
                      name
                      resources {
                        name
                      }
                    }
                    roles {
                      name
                    }
                  }
                error
                statusCode
              }
        }`;
    }
}