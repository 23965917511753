import React, { useState, useEffect } from 'react';
import { Alert, Container, Header, SpaceBetween, Spinner, Toggle } from "@cloudscape-design/components";
import { IDynamicFieldProps } from '../../../common/DynamicForm/Interfaces';
import { SANDBOX_UNAPPROVED_MESSAGE, VIEW_WIP_BUTTON } from '../../constants/displayMessages';
import { SEPERATOR, SK_MAPPING, TAB_ID_LABEL, API_RESPONSE_FIELDS, ALL_ASSIGNEES_KEY, USER_ACTION_ROLES, STAGES, USER_ROLES, ROLE_DEFAULT_VIEW, UPDATED_FIELD } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { MANUFACTORING_INFO, IFieldMetaData } from '../../constants/fieldData';
import { selectManufacturingInforClientData, updateManufacturingInfoClientData, selectManufacturingInforServerData, selectMfrInfoModified } from './manufacturingInfoSlice';
import ScreenUtils from '../../utils/screenUtils';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import DynamicForm from '../../../common/DynamicForm/DynamicForm';
import { SubmitTab } from '../programSetup/submitTabAction';
import { useGetCostCenterCodeQuery } from '../../services/api';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import { ProgramAssignees } from '../userPolicy/program/assigneesList';

interface ICCResp {
    cost_center_code: string;
    cost_center_name: string;
}
export const ManufacturingInfo = () => {
    const dispatch = useAppDispatch();
    const mfrInfo = useAppSelector(selectManufacturingInforClientData);
    const serverData = Object.values(useAppSelector(selectManufacturingInforServerData));
    const locationData = useAppSelector(selectLocationData);
    const isModified = useAppSelector(selectMfrInfoModified);
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [userRole, setUserRole] = useState(USER_ROLES.readonly);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [dynamicFormInput, setDynamicFormInput] = useState<IDynamicFieldProps[][]>();
    const { data: ccData, isLoading } = useGetCostCenterCodeQuery();
    const [formMetadata, setFormMetaData] = useState(MANUFACTORING_INFO);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [viewApproved, setViewApproved] = React.useState(false);
    const stage = serverData?.length ? serverData?.[0].Stage ?? STAGES.sandbox : STAGES.sandbox;
    const isSubmitted = stage === STAGES.submitted;
    const submitDisabled = isSubmitted || !hasWriteAccess || serverData.length === 0 || (isModified && serverData.length > 0);

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY, TAB_ID_LABEL.financialInfo, TAB_ID_LABEL.financialInfo + SEPERATOR + SK_MAPPING.financialInfo]);
        setUserRole(currRole);
        if (USER_ACTION_ROLES.write.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    useEffect(() => {
        if (ccData) {
            const fMetaData: Record<string, IFieldMetaData> = { ...formMetadata };
            const ccList = ccData.map((cc: ICCResp) => {
                return {
                    "label": cc.cost_center_code + ' - ' + cc.cost_center_name,
                    "value": cc.cost_center_code
                };
            });
            fMetaData['Cost center code'].options = ccList;
            setFormMetaData(fMetaData);
        }
    }, [ccData, isLoading]);

    const updateState = (fieldValue: any, fieldName: string) => {
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.manufacturingInfo + SEPERATOR + SK_MAPPING.manufacturingInfo + SEPERATOR + fieldName;
        const value = fieldValue.value ?? fieldValue;
        dispatch(updateManufacturingInfoClientData(ScreenUtils.updateClientState(value, fieldName, API_RESPONSE_FIELDS.itemValues, mfrInfo, PK, SK)));
    };

    useEffect(() => {
        const fMetaData: Record<string, IFieldMetaData> = { ...formMetadata };
        Object.entries(fMetaData).forEach(([field]) => {
            const label = fMetaData[field].label;
            fMetaData[field].actions = updateState;
            fMetaData[field].readonly = viewApproved || !hasWriteAccess || locationData.fpn.id ? true : false;
            if (label.endsWith(UPDATED_FIELD)) fMetaData[field].label = label.slice(0, label.length - UPDATED_FIELD.length);
            if (!viewApproved && mfrInfo[field] && !mfrInfo[field].Action && mfrInfo[field].ItemValues !== mfrInfo[field].ItemValuesWIP) fMetaData[field].label += UPDATED_FIELD;
        });
        const stageView = viewApproved ? STAGES.approved : ROLE_DEFAULT_VIEW[userRole];
        setDynamicFormInput(ScreenUtils.generateDynamicFormInput(mfrInfo, fMetaData, stageView));
    }, [mfrInfo, hasWriteAccess, formMetadata, userRole, viewApproved, locationData.fpn]);


    const loadManufacturingDetails = () => {
        if (dynamicFormInput) return (
            <>
                <SpaceBetween size='l' >
                    {locationData.fpn.id && <Alert statusIconAriaLabel="Info">
                        Manufacturing information is provided at root program level
                    </Alert>
                    }
                    <DynamicForm formInput={dynamicFormInput} />
                </SpaceBetween>
            </>
        );
        return "No data to display.";
    };
    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                {!locationData.fpn.id &&
                    <SubmitTab currentTab='manufacturingInfo'
                        saveDisabled={!hasWriteAccess || !isModified || isSubmitted}
                        submitDisabled={submitDisabled}
                        approveDisabled={isModified || !ScreenUtils.isSandboxUpdated(mfrInfo)}
                        setIsLoading={setIsUpdateLoading}
                        userRole={userRole}
                        section={SK_MAPPING.manufacturingInfo}
                    />
                }
                {!isUpdateLoading ?
                    <>
                        <Container
                            header={
                                <Header
                                    actions={
                                        <Toggle
                                            onChange={({ detail }) =>setViewApproved(detail.checked)}
                                            checked={viewApproved}
                                        >
                                            {VIEW_WIP_BUTTON}
                                        </Toggle>
                                    }
                                    variant="h2"
                                    info={<ProgramAssignees
                                        tab={TAB_ID_LABEL.manufacturingInfo}
                                        section={SK_MAPPING.manufacturingInfo}
                                    />}
                                >
                                    Manufacturing information
                                </Header>
                            }
                            footer={<><strong>{UPDATED_FIELD}</strong> {SANDBOX_UNAPPROVED_MESSAGE}</>}
                        >
                            {loadManufacturingDetails()}
                        </Container>
                    </> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </SpaceBetween>
        </>

    );
};
