export const APP_LAYOUT_MESSAGES = {
    policyLoading: 'Fetching user permission...',
    userPolicyError: 'Error fetching user access. Please reload and try again'
};

export const ACCESS_DENIED_MESSAGES = {
    adminPanel: "You don't have admin permissions to view this page. Please create a SIM ticket to request access."
};

export const USER_POLICY_MESSAGES = {
    userPolicyGetError : 'Error fetching user permissions.',
    addPolicySuccess: 'Succesfully assigned user permission',
    addPolicyError: 'Error assigning user permission',
    removePolicySuccess: 'Succefully removed user permission',
    removePolicyError: 'Error removing user permission'
};