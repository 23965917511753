import React, { useState, useEffect } from 'react';
import { Container, Header } from "@cloudscape-design/components";
import { NA_LABEL, USER_ACTION_ROLES } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ScreenUtils from '../../utils/screenUtils';
import { selectAttributeTypeListMap, selectAllPlAttributes, selectAttributeValues, updateAllAttributeValues, selectPlFPNRule, selectUserRole, selectFPNsList, selectAsinAttributeValues } from './attributesSlice';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { AttributeForm } from './attributeForm';
import { IFormInputProps, IPLAttributeTypeResponse } from '../../interfaces/interfaces';
import { PopoverInfo } from 'src/components/common/Components/Popover/popover';
import { selectVerityData } from '../programOverview/programOverviewSlice';

export const ProductLineAttributes = ({ isLoading }: any) => {
    const dispatch = useAppDispatch();
    const attributesValues = useAppSelector(selectAttributeValues);
    const asinAttrValues = useAppSelector(selectAsinAttributeValues);
    const allAttributes = useAppSelector(selectAllPlAttributes);
    const programData = useAppSelector(selectVerityData);
    const { fpn, productLine } = useAppSelector(selectLocationData);
    const plFpnRule = useAppSelector(selectPlFPNRule);
    const allTypeToValues = useAppSelector(selectAttributeTypeListMap);
    const [formInput, setFormInput] = useState<IFormInputProps[]>([]);
    const fpnList = useAppSelector(selectFPNsList);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole)) && !isLoading;

    useEffect(() => {
        const inFPNAttr = plFpnRule?.map(item => item.attribute_type);
        const formProps: IFormInputProps[] = [];
        const asinCt = programData.asinCount;
        const partOfFpnValues: string[] = [];

        // RPN: For ASIN programs, if attr values are part of any FPNs then those values cannot be removed.
        const existingFPNValues = !fpn.id ? fpnList?.map(item => item.fpn.split('_')?.filter(item => item !== programData.programName)) ?? [] : [];
        if (!fpn.id && asinCt > 0) existingFPNValues.forEach(item => partOfFpnValues.push(...item));
        
        allAttributes?.forEach(({ attribute_type }: IPLAttributeTypeResponse) => {
            const allOptions = allTypeToValues[attribute_type] ?? [];
            const isPartOfFPN = inFPNAttr?.includes(attribute_type);
            const disabledValues: string[] = isPartOfFPN ? [...partOfFpnValues] : [];
            let readonly = !hasWriteAccess;

            // RPN/FPN : If values are used in any ASIN (asin_count > 0) then those values cannot be removed.
            if (asinAttrValues[attribute_type]) disabledValues.push(...asinAttrValues[attribute_type]);

            // If FPN doesn't contain any of the attr values then N/A is one of the values used to create FPN
            if (!fpn.id && asinCt > 0 && isPartOfFPN && attributesValues[attribute_type]) {
                const isNAapplicable = !existingFPNValues.every(fpnArr => fpnArr.some(value => attributesValues[attribute_type].includes(value)));
                if (isNAapplicable && attributesValues[attribute_type].includes(NA_LABEL)) disabledValues.push(NA_LABEL);
            }
            if (fpn.id) readonly = isPartOfFPN || asinCt > 0 ? true : !hasWriteAccess;
            formProps.push({
                name: attribute_type,
                label: attribute_type,
                readOnly: readonly,
                all_options: ScreenUtils.generateOptions(allOptions, disabledValues),
                IsPartOfFPN: isPartOfFPN,
                values: attributesValues[attribute_type] ? ScreenUtils.generateOptions(attributesValues[attribute_type], disabledValues) : []
            });
        });
        setFormInput(formProps);

    }, [allAttributes, attributesValues, allTypeToValues, plFpnRule, hasWriteAccess, fpnList, programData]);

    const updateState = (fieldValue: any, fieldName: string) => {
        const allValues = { ...attributesValues };
        const values = Array.isArray(fieldValue) ? fieldValue.map(item => item.value) : fieldValue.value ?? fieldValue;
        allValues[fieldName] = values;
        dispatch(updateAllAttributeValues(allValues));
    };

    const loadForm = () => {
        if (formInput.length > 0) {
            return <AttributeForm
                formInputs={Object.values(formInput)}
                action={updateState}
                reorder={false}
                isFPN={fpn.id ? true : false}
            />;
        } else {
            return `Product line ${productLine.name} does not have any Level2 attributes.`;
        }
    };

    return (
        <>
            <Container className=''
                header={
                    <Header variant="h2">
                        Product line attributes  <PopoverInfo text='Level2' header='Info' message='WIP' />
                    </Header>
                }
            >
                {loadForm()}
            </Container>
        </>
    );
};
