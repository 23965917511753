import * as React from "react";
import { Button, Container, FormField, Grid, Header, Input, SpaceBetween } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { selectAttributeTypeListMap } from "../attributes/attributesSlice";
import { ATTR_NAME_REGEX, STATUS_CODES, USER_ACTION_ROLES, VALIDATION_MESSAGES } from "../../constants/constants";
import { MUTATION_METHODS } from "../../../constants/constants";
import { useAppSelector } from '../../../redux/hooks';
import { useUpdateAttrTypeMasterMutation } from "../../../redux/services/attributesApi";
import ScreenUtils from "../../utils/screenUtils";
import useNotification from "src/components/Hooks/notifications";
import { ACCESS_DENIED_MESSAGES, ALERT_MESSAGES } from "../../constants/displayMessages";
import { IApiUpdateResponse } from "src/components/Interfaces/interface";
import { Alert, Flashbar } from "@amzn/awsui-components-react";
import { selectUserPolicy } from "../userPolicy/userPolicySlice";

export const RequestNewAttribute = () => {
    const [newAttribute, setNewAttribute] = useState('');
    const [errorText, setErrorText] = useState('');
    const policyData = useAppSelector(selectUserPolicy);
    const [addAttribute, { isLoading, data: mutationData, isError: mutationError }] = useUpdateAttrTypeMasterMutation();
    const allTypes = Object.keys(useAppSelector(selectAttributeTypeListMap) ?? {});
    const [hasAccess, setHasAccess] = useState(true);
    const { notificationItems, addNotificationItem } = useNotification();

    const validate = () => {
        let error = '';
        if (newAttribute && !newAttribute.match(ATTR_NAME_REGEX)) error = VALIDATION_MESSAGES.attributeTypeName;
        if (allTypes.includes(newAttribute)) error = VALIDATION_MESSAGES.attributeTypeExists;
        setErrorText(error);
        return error.length > 0 ? false : true;
    };

    const save = async () => {
        if (newAttribute.length && validate()) await addAttribute(ScreenUtils.getGraphQLPayload({ attribute_type: newAttribute })).unwrap();
    };

    useEffect(() => {
        USER_ACTION_ROLES.attributeRequest.some(role => policyData?.[role]) ? setHasAccess(true) : setHasAccess(false);
    }, [policyData]);

    useEffect(() => {
        if (mutationData?.errors || mutationError) addNotificationItem({
            type: "error",
            dismissible: true,
            content: ALERT_MESSAGES.attrTypeFail,
            id: "ATTR_FAIL"
        });
        if (mutationData?.data && mutationData?.data?.[MUTATION_METHODS.updateAttrTypeMaster]) {
            const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateAttrTypeMaster];
            if (statusCode === STATUS_CODES.success) {
                addNotificationItem({
                    type: "success",
                    dismissible: true,
                    content: ALERT_MESSAGES.attrTypeSuccess + newAttribute,
                    id: "ATTR_SUCCESS"
                });
                setNewAttribute('');
            } else addNotificationItem({
                type: "error",
                dismissible: true,
                content: statusCode === STATUS_CODES.handledException ? error : ALERT_MESSAGES.attrTypeFail,
                id: "ATTR_FAIL"
            });
        }

    }, [mutationData, mutationError]);

    return (

        <Container
            header={
                <Header variant="h2">
                    Request new attribute
                </Header>
            }
        >
            {hasAccess ?
                <SpaceBetween size="xxl">
                    <Flashbar items={notificationItems} stackItems />
                    <Grid
                        gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                    >
                        <SpaceBetween size="xxl">
                            <FormField
                                label="Enter attribute name"
                                errorText={errorText}
                            >
                                <Input
                                    value={newAttribute}
                                    onChange={event =>
                                        setNewAttribute(event.detail.value?.toUpperCase().trim())
                                    }
                                    onBlur={validate}
                                    disabled={!allTypes.length}
                                    ariaLabel="attribute-name"
                                />
                            </FormField>
                            <Button className="bg-primary"
                                onClick={save}
                                loading={isLoading}
                                disabled={!newAttribute.length || errorText.length > 0}
                                variant="primary">
                                Submit</Button>
                        </SpaceBetween>
                    </Grid>
                </SpaceBetween>
                : <>
                    <Alert type="error" header="Access Denied">
                        {ACCESS_DENIED_MESSAGES.attrRequest}
                    </Alert>
                </>
            }
        </Container>
    );
};