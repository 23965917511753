import { WebSocketClient } from './WebSocketClient'

export enum HttpMethod {
	// Create new instances of the same class as static attributes
	Post = "POST",
	Get = "GET",
	Delete = "DELETE",
	Put = "PUT",
}
	 
export interface AsyncResponse {
	latestStatus: string,
	response: object
}

/* process: kick off long running lambda services
 * @param resource - http url for the api gateway or unique path (along with service name) for the lambda service
 * @param equivalentHttpMethod - equivalent to http method such as post, get
 * @param serviceName - unique service name for identifying group of lambda services such as labfinbackend
 * @param responseArray - AsyncResponse object which will contain intermittent or final response from lambda service
 * @parm setResponse - function to set response object
 * @parm user - user
 * @parm email - email
 * @returns unique id for this instance of process being invoked
*/
export async function process (resource: string, equivalentHttpMethod: string, serviceName: string, response: AsyncResponse, setResponse: (response: AsyncResponse) => void, user:string, email: string, body?: any) {
	const ws = await WebSocketClient.build()
	return ws ? ws.process(resource, equivalentHttpMethod, serviceName, response, setResponse, user, email, body) : undefined
}
	 
export async function cancel (processId: string) {
	const ws = await WebSocketClient.build()
	if(ws) {
	    ws.cancel(processId)
	} 
}
	 
export async function status (processId: string) {
	const ws = await WebSocketClient.build()
	return ws ? ws.status(processId) : undefined
}

export async function disconnectWS () {
    const ws = await WebSocketClient.build()
    if(ws) {
        ws.close()
    }
}

/* updateSetResponse: reset setResponse function originally given in process function
 * @param processId - processId originally given in process function
 * @param setResponse - setResponse function originally given in process function
 * @returns void
*/
export async function updateSetResponse (processId: string, setResponse: (response: AsyncResponse) => void) {
	const ws = await WebSocketClient.build()
	return ws ? ws.updateSetResponse(processId, setResponse) : undefined
}

/* getResponse: get response for given processId
 * @param processId - processId 
 * @returns response - response saved for the given processId
*/
export async function getResponse (processId: string) {
	const ws = await WebSocketClient.build()
	return ws ? ws.getResponse(processId) : undefined
}

/* acknowledgeResponse: acknowledge the display of response to user
 *                      with acknowledgement, entry in process_requests is updated with proper status
 * @param processId - processId 
*/
export async function acknowledge (processId: string) {
	const ws = await WebSocketClient.build()
	if (ws) {
		ws.acknowledge(processId)
	}
}