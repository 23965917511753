import React, { ReactElement, createContext } from "react";
import costCenterExpLineHelper from './costCenterExpLineHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: [];
    useCCExpLineData: () => UseQueryResult;
    useUpdatedCCExpLineData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
    useDeleteCCExpLineData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}
const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const CostCenterExpLineContext = createContext<IProjectsContext>({
    useCCExpLineData: () => qResp,
    useUpdatedCCExpLineData: () => mResp,
    useDeleteCCExpLineData: () => mResp
});

export const CostCenterExpLineContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...costCenterExpLineHelper()
    };

    return (
        <CostCenterExpLineContext.Provider value={provider}>
            {children}
        </CostCenterExpLineContext.Provider>
    );
};
