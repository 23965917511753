import React, { useState, forwardRef, useImperativeHandle, useCallback, useContext } from 'react';
import { GlobalContext } from '../../context/globalContext';
import { debounce } from 'lodash';
import { IDropdownSelectedValue, IexistingUser } from "../../Interfaces/interface";
import { Autosuggest, FormField } from '@amzn/awsui-components-react';
import { minUserLength } from '../../../constants/constants';

export const UserSearchEditor = forwardRef((props: any, ref: any) => {
    const [value, setValue] = useState(props.value);
    const [selectedUser, setSelectedUser] = useState('');
    const globalContext = useContext(GlobalContext);
    const { fetchUsers } = { ...globalContext };
    const [userOptions, setUserOptions] = useState<IexistingUser[]>();
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [errorLoading, serErrorLoading] = useState(false);

    const fetchUserDetails = useCallback(debounce(async (user: string) => {
        if (user.length >= minUserLength) {
            setLoadingOptions(true);
            const res = await fetchUsers!(user);
            if (res === undefined || res === null || (res.exact_match === undefined && res.fuzzy_match === undefined)) {
                serErrorLoading(true);
            } else if (res.exact_match.length > 0) {
                setUserOptions(() => {
                    return [{
                        value: res.exact_match[0].employee_name + ' (' + res.exact_match[0].employee_login + ')'
                    }];
                });
            } else if (res.fuzzy_match.length > 0) {
                setUserOptions(() => {
                    return res.fuzzy_match.map((user: any) => {
                        return {
                            value: user.employee_name + ' (' + user.employee_login + ')'
                        };
                    });
                });
            }
            setLoadingOptions(false);
        } else {
            setUserOptions([]);
        }
    }, 500), []);

    const onUserChange = async ({ detail }: IDropdownSelectedValue) => {
        setSelectedUser(detail.value);
        serErrorLoading(false);
        if (detail.value) await fetchUserDetails(detail.value);
    };

    const onUserSelect = async ({ detail }: IDropdownSelectedValue) => {
        setValue(detail.value.split(' (')[0]);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            }
        };
    });
    return (
        <>
            <div>
                <FormField>
                    <Autosuggest
                        id="userAlias"
                        value={selectedUser}
                        options={userOptions}
                        enteredTextLabel={() => ''}
                        ariaLabel="user-search"
                        placeholder="Enter user alias"
                        empty="No user found"
                        onChange={onUserChange}
                        onSelect={onUserSelect}
                        className='auto-suggest'
                        selectedAriaLabel={selectedUser}
                        errorText="Values could not be retrieved"
                        statusType={errorLoading ? "error" : loadingOptions ? "loading" : "finished"}
                    />
                </FormField>
            </div>
        </>
    );
});