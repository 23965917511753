import React, { useEffect, useState } from 'react';
import { AppLayout, Box, ContentLayout } from "@cloudscape-design/components";
import { Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../../../redux/store';
import { VeritySideNav } from './sideNav';
import { AlertMessageProvider } from '../../../common/Contexts/alertMessageContext';
import { VerityBreadcrumbs } from './breadcrumbs';
import { VerityPageHeader } from './pageHeader';
import { useGetCountriesQuery } from '../../services/apis/commonApi';
import { appName } from 'src/constants/constants';
import { TopNav } from 'src/components/common/Header/topNav';
import { updateLocationData } from './appLayoutSlice';
import { useAppDispatch } from 'src/components/redux/hooks';

export function VerityAppLayout() {
    const dispatch = useAppDispatch();
    const [navigationOpen, setNavigationOpen] = useState<boolean>(false);
    const {pathname} = useLocation();
    
    useGetCountriesQuery();

    useEffect(() => {
        const pathArray = pathname.split("/");
        if (pathArray.length > 2) dispatch(updateLocationData({currentScreen: pathArray[2]}));
    }, [pathname]);

    return (
        <>
            <AlertMessageProvider>
                <TopNav appName={appName} />
                <AppLayout
                    id="verityAppLayout"
                    breadcrumbs={<VerityBreadcrumbs />}
                    content={
                        <ContentLayout
                            disableOverlap
                            header={<VerityPageHeader />}
                        >
                            <Box margin={{ top: "l" }}>
                                <Outlet />
                            </Box>
                        </ContentLayout>
                    }
                    navigation={<VeritySideNav />}
                    navigationHide={false}
                    navigationOpen={navigationOpen}
                    onNavigationChange={() => setNavigationOpen(!navigationOpen)}
                    toolsHide={true}
                    contentType="default"
                />
            </AlertMessageProvider>
        </>
    );
}