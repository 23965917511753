import React from 'react';
import { OtherKeyDates } from './otherKeyDates';
import { StreetDates } from './streetDates';
import { SpaceBetween } from '@cloudscape-design/components';

export const KeyDates = () => {
    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                <StreetDates />
                <OtherKeyDates />
            </SpaceBetween>
        </>
    );
};
