import Endpoints from '../../constants/endpoints';
import xhr from '../common/Auth/xhr';
import { IApiResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const fetchUsers = async (user: string) => {
        try {
            const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUserValidation(user));
            const { body }: IApiResponse = response.data.data.ca_user_validation;
            return body;
        } catch (error) {
            return null;
        }
    };
    return { fetchUsers };
};
