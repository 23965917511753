import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const queryClient = useQueryClient();
 
    const fetchCCExpLine = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getCCExpLineQuery());
        const { body }: IApiResponse = response.data.data.get_cc_el;
        return body;
    };

    const updateCCExpLine = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_cc_el'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_cc_el as IApiUpdateResponse;
    };

    const deleteCCExpLine = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'delete_cc_el'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.delete_cc_el as IApiUpdateResponse;
    };

    const useCCExpLineData = () => {
        return useQuery(
            ['costCenterExpLine'],
            fetchCCExpLine,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useUpdatedCCExpLineData = () => {
        return useMutation(
            (requestData) => updateCCExpLine(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['costCenterExpLine']);
                }
            }
        );
    };
    const useDeleteCCExpLineData = () => {
        return useMutation(
            (requestData) => deleteCCExpLine(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['costCenterExpLine']);
                }
            }
        );
    };
    return { componentData, useCCExpLineData, useUpdatedCCExpLineData, useDeleteCCExpLineData };
};
