import React from 'react';
import deleteIcon from '../../../images/delete.svg';
import Button from "@amzn/awsui-components-react/polaris/button";
import { tableNames } from '../../../constants/constants';

export default (props: any) => {
    const deleteRecord = () => {
        if(props.tableName === tableNames.ccExpLine && 
            confirm(`Delete this record?\nLine Item: ${props.data.line_item}\nCost Center Code: ${props.data.cost_center_code} \nBudget Type : ${props.data.budget_type}`)) {
            props.context.deleteRecord(props.data);
        }
        if(props.tableName === tableNames.budgetType &&
            confirm(`Delete this record?\nBudget Type: ${props.data.budget_type}\n\nNote: Corresponding Accounts will be deleted!`)){
            props.context.deleteRecord(props.data);
        }
    };

    return (
        <span title="Delete">
            <Button iconUrl={deleteIcon} variant="icon" className="delete-icon" onClick={deleteRecord}></Button>
        </span>
    );
};