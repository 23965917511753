import { createSlice } from '@reduxjs/toolkit';
import _ from "lodash";
import { RootState } from '../../../redux/store';
import { IAttributeTypeResponse, IPLAttributeTypeResponse, IRPNAttributeTypeResponse, IPlFPNRuleResponse, IFPNsByRPNResponse, IAttributesOrdering } from '../../interfaces/interfaces';
import { attributesAPIs } from '../../../redux/services/attributesApi';
import { verityApi } from '../../services/api';
import { SAME_FOR_ALL_FPNS, USER_ROLES } from '../../constants/constants';

export type AttributesDataState = {
    allAttributesValues: Record<string, string[]>
    allProgAttributes: IRPNAttributeTypeResponse[]
}

type AttributesState = {
    serverData: AttributesDataState,
    clientData: AttributesDataState,
    asinAttributeValues: Record<string, string[]>,
    attributeMasterTypeListMap: Record<string, string[]>;
    allPLAttributes: IPLAttributeTypeResponse[],
    allStandardAttributes: string[],
    sameForAllFPNs: string[],
    userRole: string,
    loadingAPIs: string[],
    openCreateFPNModal: boolean,
    plFpnRule?: IPlFPNRuleResponse[],
    fpnCreationOrder: IAttributesOrdering[];
    fpnsList?: IFPNsByRPNResponse[]
};

const initialState: AttributesState = {
    serverData: {
        allAttributesValues: {},
        allProgAttributes: []
    },
    clientData: {
        allAttributesValues: {},
        allProgAttributes: []
    },
    asinAttributeValues: {},
    sameForAllFPNs: SAME_FOR_ALL_FPNS,
    attributeMasterTypeListMap: {},
    allPLAttributes: [],
    allStandardAttributes: [],
    userRole: USER_ROLES.readonly,
    loadingAPIs: [],
    openCreateFPNModal: false,
    plFpnRule: [],
    fpnCreationOrder: [],
    fpnsList: []
};

export const attributesSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {
        resetAttributes: (state) => {
            state.clientData = initialState.clientData;
            state.serverData = initialState.serverData;
            state.sameForAllFPNs = initialState.sameForAllFPNs;
        },
        resetAttributesValues: (state) => {
            state.clientData.allAttributesValues = initialState.clientData.allAttributesValues;
            state.serverData.allAttributesValues = initialState.serverData.allAttributesValues;
            state.sameForAllFPNs = initialState.sameForAllFPNs;
        },
        resetProgAttrTypes: (state) => {
            state.clientData.allProgAttributes = initialState.clientData.allProgAttributes;
            state.serverData.allProgAttributes = initialState.serverData.allProgAttributes;
        },
        resetAllPLAttributes: (state) => {
            state.allPLAttributes = initialState.allPLAttributes;
        },
        discardAttributesUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateClientAttributes: (state, action) => {
            state.clientData = action.payload;
        },
        updateServerAttribute: (state, action) => {
            state.serverData = action.payload;
        },
        updateMasterAttrTypeListMap: (state, action) => {
            state.attributeMasterTypeListMap = action.payload;
        },
        updateAllAttrValuesServer: (state, action) => {
            state.serverData.allAttributesValues = action.payload;
        },
        updateProgAttrServer: (state, action) => {
            state.serverData.allProgAttributes = action.payload;
        },
        updateAllAttributeValues: (state, action) => {
            state.clientData.allAttributesValues = action.payload;
        },
        updateAsinAttributeValues: (state, action) => {
            state.asinAttributeValues = action.payload;
        },
        updateAllPlAttributes: (state, action) => {
            state.allPLAttributes = action.payload;
        },
        updateAllStandardAttributes: (state, action) => {
            state.allStandardAttributes = action.payload;
        },
        updateSameForAllFPNs: (state, action) => {
            state.sameForAllFPNs = action.payload;
        },
        updateUserRole: (state, action) => {
            state.userRole = action.payload;
        },
        updateLoadingAPIs: (state, action) => {
            state.loadingAPIs = action.payload;
        },
        updateProgAttrOrder: (state, action) => {
            state.clientData.allProgAttributes = action.payload;
        },
        updateOpenCreateFPNModal: (state, action) => {
            state.openCreateFPNModal = action.payload;
        },
        updateFPNCreationAttrOrder: (state, action) => {
            state.fpnCreationOrder = action.payload;
        },
        updatePlFpnRule: (state, action) => {
            state.plFpnRule = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                attributesAPIs.endpoints.getAttributeTypeMaster.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const standardAttributes: string[] = [];
                        payload.forEach((obj: IAttributeTypeResponse) => {
                            if (obj.is_std_attr) standardAttributes.push(obj.attribute_type);
                        });
                        state.allStandardAttributes = standardAttributes;
                    }
                }
            )
            .addMatcher(
                verityApi.endpoints.getFPNsByRPN.matchFulfilled,
                (state, { payload }) => {
                    state.fpnsList = payload?.data?.get_fpn?.body ?? [];
                }
            )
            .addMatcher(
                attributesAPIs.endpoints.getPlFPNRule.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const plFpnRuleOrdered: IPlFPNRuleResponse[] = [];
                        payload.forEach((obj: IPlFPNRuleResponse) => {
                            plFpnRuleOrdered[obj.concat_order] = obj;
                        });
                        state.plFpnRule = plFpnRuleOrdered.filter(item => item !== undefined);
                    }
                }
            );
    }
});

export const { resetAttributesValues, resetAttributes, resetProgAttrTypes, resetAllPLAttributes, discardAttributesUpdates, updateAllAttrValuesServer, updateProgAttrServer,
    updateSameForAllFPNs, updateFPNCreationAttrOrder, updateUserRole, updateClientAttributes, updateServerAttribute, updateAsinAttributeValues, updateLoadingAPIs, 
    updateAllPlAttributes, updateOpenCreateFPNModal, updateProgAttrOrder, updateAllAttributeValues, updateMasterAttrTypeListMap } = attributesSlice.actions;

export default attributesSlice.reducer;
export const selectAttributesServerData = (state: RootState) => state.attributes.serverData;
export const selectAttributesClientData = (state: RootState) => state.attributes.clientData;
export const selectAllPlAttributes = (state: RootState) => state.attributes.allPLAttributes;
export const selectAllStandardAttributes = (state: RootState) => state.attributes.allStandardAttributes;
export const selectOpenCreateFPNModal = (state: RootState) => state.attributes.openCreateFPNModal;
export const selectFPNCreateAttrOrder = (state: RootState) => state.attributes.fpnCreationOrder;
export const selectAttributeModified = (state: RootState) => !_.isEqual(state.attributes.clientData, state.attributes.serverData);
export const selectAttributeValues = (state: RootState) => state.attributes.clientData.allAttributesValues;
export const selectAsinAttributeValues = (state: RootState) => state.attributes.asinAttributeValues;
export const selectPlFPNRule = (state: RootState) => state.attributes.plFpnRule;
export const selectUserRole = (state: RootState) => state.attributes.userRole;
export const selectSameForAllFPNs = (state: RootState) => state.attributes.sameForAllFPNs;
export const selectLoadingAPIs = (state: RootState) => state.attributes.loadingAPIs;
export const selectFPNsList = (state: RootState) => state.attributes.fpnsList;
export const selectAttributeTypeListMap = (state: RootState) => state.attributes.attributeMasterTypeListMap;