import * as React from "react";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { TopNavigationProps } from "@amzn/awsui-components-react";
import { getUserId } from "../Auth/Auth";

interface headerProps {
  appName: string;
  logo?: TopNavigationProps.Logo;
  NavUtilities?: Array<TopNavigationProps.Utility>;
}

export const TopNav = (props: headerProps) => {
  const NavIdentity: TopNavigationProps.Identity = {
    href: "/#",
    title: props.appName
  };
  const utilities : Array<TopNavigationProps.Utility> = [...(props.NavUtilities ?? []), {
    type: "button",
    text: getUserId(),
    iconName: "user-profile"} 
  ];
  return (
    <TopNavigation
      className="nav"
      identity={NavIdentity}
      utilities={utilities}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
    />
  );
}