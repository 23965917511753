import React from 'react';
import { Alert, Box, Button, Modal, Popover, SpaceBetween } from "@cloudscape-design/components";

export interface IPopover {
    text: string,
    header: string,
    message: string,
}

export const PopoverInfo = ({ text, header, message }: IPopover) => {
    return (
        <Box color="text-status-info" variant="span">
            <Popover
                header={header}
                size="medium"
                triggerType="text"
                content={
                    <>{message}</>
                }
                renderWithPortal={true}
                dismissAriaLabel="Close"
            >
                <Box
                    variant="span"
                    color="text-status-info"
                    fontSize="body-s"
                    fontWeight="bold"
                >
                    {text}
                </Box>
            </Popover>
        </Box>

    )

}