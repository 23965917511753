import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'account_type': 'Account Code',
    'budget_type': 'Budget Type',
    'cost_center_category': 'Cost Center Category',
    'line_item': 'Line Item',
    'cost_center_code': 'Cost Center Code'
};
export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Line Item",
        field: "line_item",
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Cost Center Category",
        field: "cost_center_category",
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Cost Center Code",
        field: "cost_center_code",
        editable: GridValidation.existingRecord,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Budget Type",
        field: "budget_type",
        editable: GridValidation.existingRecord,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Account Code",
        field: "account_type",
        editable: GridValidation.existingRecord,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    }
];