export const REPORTS_DATA = [
    {
        title: 'PRM Detailed Report',
        source: "Verity 2.0",
        ownership: "Verity Team",
        description: 'Detailed report of Root Programs and attributes synced from Program Roadmap Manager (PRM).',
        link: "https://skynet.db.amazon.com/#/views/PRMReportingtesting/PRMDetailedReport-Live"
    },
    {
        title: 'PRM WIP Report',
        source: "Verity 2.0",
        ownership: "Verity Team",
        description: 'Program Roadmap Manager WIP data of standard, FPN rules and wip attributes. Refresh on-demand',
        link: "https://skynet.db.amazon.com/#/views/PRMReportingtesting/PRMLatestRawData-Live?:iid=2"
    },
    {
        title: 'PRM Change Report',
        source: "Verity 2.0",
        ownership: "Verity Team",
        description: 'Program Roadmap Manager Change Report of standard, FPN, RPN rules and attributes. Refresh on-demand',
        link: "https://skynet.db.amazon.com/#/views/PRMReportingtesting/PRMChangeReport-Live?:iid=3"
    },
    {
        title: 'ASIN FPN Mapping',
        source: "Verity 2.0",
        ownership: "Verity Team",
        description: 'Mapping of ASIN to FPN details',
        link: "https://skynet.db.amazon.com/#/views/VerityDeviceConfiguration2/ASIN-FPNMapping?:iid=8"
    },
    {
        title: 'Device Config Report',
        source: "Verity 1.0",
        ownership: "Verity Team",
        description: 'Device Configurations synced from Program Roadmap Manager via backward compatibility process.',
        link: "https://skynet.db.amazon.com/#/views/Verity_DeviceConfiguration/DeviceConfigurations?:iid=1"
    },
    {
        title: 'Program Details Report',
        source: "Verity 1.0",
        ownership: "Verity Team",
        description: 'Program Details synced from Program Roadmap Manager via backward compatibility process.',
        link: "https://skynet.db.amazon.com/#/views/Verity_DeviceConfiguration/ProgramDetails?:iid=1"
    },
]