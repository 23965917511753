import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { Grid, Container } from "@amzn/awsui-components-react";
import { BudgetBaselineContext } from '../components/context/budgetBaselineContext';
import { GridTable } from '../components/common/GridTable/GridTable';
import { IChangedCells, ICustomSettings } from '../components/common/GridTable/GridTableInterface';
import { ColDef, GridOptions, ColGroupDef} from "ag-grid-community";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { COL_DEFS, REQUIRED } from "../components/gridHelper/budgetBaselineHelper";
import { errorMessage, alertMessages } from '../constants/constants';
import { PageHeader } from '../components/common/Header/pageHeader';
import AlertMessageContext from '../components/common/Contexts/alertMessageContext';
import GridValidation from '../components/gridHelper/gridValidation';
import { IMutationResp, IValidationResponse } from "../components/Interfaces/interface";
import ScreenUtils from './Utils/ScreenUtils';
import { GlobalContext } from '../components/context/globalContext';

export const BudgetBaseline = forwardRef((props: any, ref: any) => {

    const budgetBaselineContext = useContext(BudgetBaselineContext);
    const globalContext = useContext(GlobalContext);
    const { componentData, useBudgetBaselineData, useUpdatedBudgetBaselineData } = { ...budgetBaselineContext };
    const { useCCCategoryData, useLineItemData, ccCategoryData, bbLineItemData, useCostCenterCodesData, ccCodesData, useBudgetTypeListData, budgetTypeData, accCodeData, useAccCodesData } = { ...globalContext };
    const updateMutation = useUpdatedBudgetBaselineData();
    const queryResult = useBudgetBaselineData();
    const ccCatQueryResult = useCCCategoryData();
    const ccQueryResult = useCostCenterCodesData();
    const bdgtQueryResult = useBudgetTypeListData();
    const accQueryResult = useAccCodesData();
    const ltQueryResult = useLineItemData();

    const { setSuccess, setError } = useContext(AlertMessageContext);
    const columnDefs: (ColDef | ColGroupDef)[] = COL_DEFS;
    const getCustomSettings = (): ICustomSettings => {
        return {
            className: 'ag-theme-balham',
            uid: "concat_string",
            readOnly: false,
            groupIncludeTotalFooter: true,
            allowLocalFilters: true,
            filterId: 'budgetBaseline',
            enableOnChangeHighlight: true,
            allowAddRow: true,
            numAddRows: 1
        } as ICustomSettings;
    };

    const [customSettings] = useState<ICustomSettings>(
        getCustomSettings()
    );
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: columnDefs,
        rowData: []
    });
    useEffect(() => {
        if (componentData && ccCategoryData && bbLineItemData && ccCodesData && budgetTypeData && accCodeData) {
            // Variables to store dropdown data
            const ccCatList: string[] = [];
            const lineItemList: string[] = [];
            const ccList: string[] = [];
            const bTypeList: string[] = [];
            const accList: string[] = [];
            ccCategoryData.forEach((obj: any) => {
                ccCatList.push(obj.cost_center_category);
            });
            bbLineItemData.forEach((obj: any) => {
                lineItemList.push(obj.line_item);
            });
            ccCodesData.forEach((obj: any) => {
                ccList.push(obj.cost_center_code);
            });
            budgetTypeData.forEach((obj: any) => {
                bTypeList.push(obj.budget_type);
            });
            accCodeData.forEach((obj: any) => {
                accList.push(obj.account_code);
            });
            COL_DEFS.forEach(function (colDef: ColDef) {
                colDef.floatingFilter = true;
                if (colDef.cellEditorParams) {
                    if (colDef.field == 'cost_center_category') colDef.cellEditorParams.values = ccCatList;
                    if (colDef.field == 'line_item') colDef.cellEditorParams.values = lineItemList;
                    if (colDef.field == 'cost_center_code') colDef.cellEditorParams.values = ccList;
                    if (colDef.field == 'budget_type') colDef.cellEditorParams.values = bTypeList;
                    if (colDef.field == 'account_type') colDef.cellEditorParams.values = accList;
                }
            });
            setGridOptions((prevState: any) => {
                return {
                    ...prevState,
                    columnDefs: COL_DEFS,
                    rowData: componentData
                };
            });
        }
    }, [componentData, ccCategoryData, bbLineItemData, ccCodesData, budgetTypeData, accCodeData]);

    const onSave = async (event: Record<string, IChangedCells>) => {
        const requestData: Record<string, any> = {};
        const payload: any = [];
        if (event && Object.keys(event).length > 0) {
            const vResponse: IValidationResponse = GridValidation.validateRequired(event, REQUIRED);
            if (vResponse.error) { // Required fields validation
                setError?.(alertMessages.requiredMissing + vResponse.missingFields.join(', '));
            } else {
                Object.values(event).forEach((cell) => {
                    const cellId = cell.row.id ?? cell.row.rowId;
                    const userId = ScreenUtils.getUserId();
                    // Adding new rows
                    if (!cell.row.id && !Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                        requestData[cellId] = {
                            cost_center_code: cell.row.cost_center_code,
                            budget_type: cell.row.budget_type,
                            line_item: cell.row.line_item,
                            cost_center_category: cell.row.cost_center_category,
                            account_type: cell.row.account_type,
                            user_id: userId
                        };
                    }
                    // Updating rows
                    if (cell.row.id && cell.colId) {
                        if (!Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                            requestData[cellId] = {
                                id: cellId,
                                user_id: userId,
                                account_type: cell.row.account_type,
                                budget_type: cell.row.budget_type,
                                cost_center_code: cell.row.cost_center_code
                            };
                        }
                        requestData[cellId][cell.colId] = cell.newValue;
                    }
                });
                Object.keys(requestData).forEach(cellId => {
                    payload.push(requestData[cellId]);
                });
                if (payload.length > 0) {
                    updateMutation.mutate(payload);
                }
            }
        }
    };
    useImperativeHandle(ref, () => ({
        onSave(event: Record<string, IChangedCells>) {
            onSave(event);
        }
    }));
    useEffect(() => {
        if (updateMutation.data) {
            console.log(updateMutation.data); //console added for Beta testing
            const resp: IMutationResp = ScreenUtils.getMutationMessage(updateMutation.data);
            resp.status === 'success' ? setSuccess?.(resp.message) : setError?.(resp.message);
        }
    }, [updateMutation.data]);

    const loadGridTable = () => {
        if (gridOptions.rowData?.length) {
            return (
                <GridTable
                    gridSettings={gridOptions}
                    customSettings={{
                        ...customSettings
                    }}
                    onRefresh={queryResult.refetch}
                    onSave={onSave}
                />
            );
        } else {
            return "No data to display.";
        }
    };
    if (queryResult.isLoading || queryResult.isFetching || ccCatQueryResult.isLoading || ltQueryResult.isLoading || updateMutation.isLoading || ccQueryResult.isLoading || bdgtQueryResult.isLoading || accQueryResult.isLoading) return <div className='loadspinner'><Spinner size="large" /></div>;

    if (queryResult.error || ccCatQueryResult.error || ltQueryResult.error || updateMutation.error) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {errorMessage}
            </Container>
        </Grid>
    );
    return (
        <>
            <PageHeader
                pageName='Budget Baseline'
            />
            {loadGridTable()}
        </>
    );

});