import { IApiUpdateResponse, IMutationResp } from '../../components/Interfaces/interface';
import { alertMessages, statusCodes } from '../../constants/constants';

export default class ScreenUtils {

    public static readonly getMutationMessage  = (data : IApiUpdateResponse) => {
        let result : IMutationResp = {
            status : 'success',
            message : alertMessages.updateSuccess
        };
        if(data.errors) {    
            result.message = data.errors.map(err => err.message).join(', ');
            result.status = 'error';
        } else if(data.statusCode != statusCodes.success){
            result.status = 'error';
            if(data.updatedCodes.length === 0) {
                result.message = data.statusCode == statusCodes.handledException ? data.error : alertMessages.updateFailure;
            } else {
                result.message = data.statusCode == statusCodes.handledException ? data.error + '. ' + alertMessages.updatedCodes + data.updatedCodes.join(',') : alertMessages.updatedCodes + data.updatedCodes.join(',');
            }
        }
        return result;
    }
    public static readonly getUserId = () => sessionStorage.getItem('userName')?.split('_')[1];
    
}
