import React, { useEffect, useState } from 'react';
import { ProgramHomeTabs } from './programHomeTabs';
import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useGetAttributeListRPNQuery, useGetAttributeTypePLQuery, useGetAttributeTypeRPNQuery, useGetPlFPNRuleQuery } from '../../../redux/services/attributesApi';
import { useGetVersionsQuery, useGetProgAttrOrderQuery, useGetAssortmentsQuery, useGetSkuQuery, useGetMarketPlacesQuery } from '../../services/apis/api';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { resetAllPLAttributes, updateAllPlAttributes, updateAllAttrValuesServer, updateAllAttributeValues, resetAttributesValues, updateAsinAttributeValues, resetProgAttrTypes, updateProgAttrServer, updateProgAttrOrder, updateLoadingAPIs } from '../../../RPT/features/attributes/attributesSlice';
import { IPLAttributeTypeResponse, IProgramAttrListResponse, IRPNAttributeTypeResponse } from '../../../Interfaces/attributesInterfaces';
import { resetVersionData, updateVersions, updateAttrsOrder } from '../versions/versionSlice';
import { selectVerityData } from '../../../RPT/features/programOverview/programOverviewSlice';
import { IAttrOrder, IVersion } from '../../interfaces/interfaces';
import ScreenUtils from '../../utils/screenUtils';
import { LOADING_APIS } from 'src/components/RPT/constants/constants';
import { useGetRPNDetailsQuery } from 'src/components/RPT/services/api';
import { useGetCountryNamesQuery } from 'src/components/redux/services/commonApi';
import { selectUserPolicy, updateRoleResourceMap } from '../userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { resetAssortmentData, updateAssortments, updateAssortmentsLoading } from '../assortment/assortmentSlice';
import { resetSkuData, updateMarketplaceData, updateSku, updateSkuLoading } from '../sku/skuSlice';
import { ALL_ASSIGNEES_KEY, METRICS_PAGE_NAMES, METRICS_APP_NAME, USER_ACTION_ROLES } from '../../constants/constants';
import { CreateProgramPPLS } from 'src/components/RPT/features/programOverview/createProgramPPLS';
import { Alert, Button, SpaceBetween } from '@amzn/awsui-components-react';
import { ALERT_MESSAGES } from '../../constants/userMessages';
import { getPageTimeMetric, logUserAction } from 'src/components/KatalMetrics/metricsHelper';
import { ACTIONS } from 'src/components/KatalMetrics/metricsConstants';

export function ProgramHome() {
    const dispatch = useAppDispatch();
    const locationData = useAppSelector(selectLocationData);
    const { productLine, program, fpn } = locationData;
    const { getPLUserPolicyKey, getRPNUserPolicyKey, getVersionDescription } = ScreenUtils;
    const userPolicyData = useAppSelector(selectUserPolicy);
    const programData = useAppSelector(selectVerityData);
    const { getUserRole } = UserPolicyHelper();

    const { data: plAttrData } = useGetAttributeTypePLQuery({ verity_product_line_id: parseInt(productLine.id) }, { skip: !productLine.id });
    const { data: rpnAttrListData, isLoading: attrListLoading, isFetching: attrListFetching } = useGetAttributeListRPNQuery({ verity_rpn_id: program.id }, { skip: !program.id || fpn.id ? true : false });
    const { data: rpnAttrTypeData, isLoading: attrTypeLoading, isFetching: attrTypeFetching } = useGetAttributeTypeRPNQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const { data: versionData } = useGetVersionsQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const { data: attrOrderData } = useGetProgAttrOrderQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const { isLoading: rpnDataLoading, isFetching: rpnDataFetching }= useGetRPNDetailsQuery(parseInt(program.id), { skip: fpn.id !== '' || !program.id });
    useGetCountryNamesQuery(); // data processed in appLayoutSlice
    useGetPlFPNRuleQuery({ verity_product_line_id: productLine.id }); // data processed in attributesSlice

    const { data: assortments, error: assortmentsError, isFetching: assortmentsLoading } = useGetAssortmentsQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const { data: marketPlaces, error: marketPlacesError } = useGetMarketPlacesQuery({});
    const { data: SKUs, error: skuError, isFetching: skuLoading } = useGetSkuQuery({ verity_rpn_id: program.id }, { skip: !program.id ? true : false });
    const attrsLoading = attrListLoading || attrListFetching;
    const [pplsModal, setPplsModal] = useState(false);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);

    useEffect(() => {
        return getPageTimeMetric(METRICS_APP_NAME, METRICS_PAGE_NAMES.programHome);
    }, []);

    useEffect(() => {
        logUserAction(METRICS_APP_NAME, METRICS_PAGE_NAMES.programHome, ACTIONS.PROGRAM_HOME, {ProductLine: productLine.name, RPN: program.name});
    }, [locationData]);
    
    // Store role to assigned resources map on RPN change
    useEffect(() => {
        const roleToResourceMap: any = {};
        const rpnKey = getRPNUserPolicyKey(program.id);
        const plKey = getPLUserPolicyKey(productLine.id);
        Object.entries(userPolicyData).forEach(([role, obj]) => {
            if (!roleToResourceMap[role]) roleToResourceMap[role] = [];
            if (obj[ALL_ASSIGNEES_KEY]) roleToResourceMap[role].push(ALL_ASSIGNEES_KEY);
            if (obj[rpnKey]) roleToResourceMap[role].push(...obj[rpnKey]);
            if (obj[plKey]) roleToResourceMap[role].push(...obj[plKey]);
        });
        const currRole = getUserRole(roleToResourceMap, [ALL_ASSIGNEES_KEY]);
        USER_ACTION_ROLES.write.includes(currRole) ? setHasWriteAccess(true) : setHasWriteAccess(false);
        dispatch(updateRoleResourceMap(roleToResourceMap));
    }, [userPolicyData, program]);

    useEffect(() => {
        const loadingApis = [];
        if (attrsLoading) loadingApis.push(LOADING_APIS.rpnAttrList);
        if (attrTypeLoading || attrTypeFetching) loadingApis.push(LOADING_APIS.rpnAttrType);
        dispatch(updateLoadingAPIs(loadingApis));
    }, [attrsLoading, attrTypeLoading, attrTypeFetching]);

    // Populate RPN/FPN attribute list data (L1, L2, L3 values)
    useEffect(() => {
        dispatch(resetAttributesValues());
        if (rpnAttrListData && !attrsLoading) {
            const attrValues: Record<string, string[]> = {};
            const asinValues: Record<string, string[]> = {};
            rpnAttrListData.forEach(({ attribute_type, attribute_value, asin_count }: IProgramAttrListResponse) => {
                attrValues[attribute_type] ? attrValues[attribute_type].push(attribute_value) : attrValues[attribute_type] = [attribute_value];
                if (asin_count && asin_count > 0) asinValues[attribute_type] ? asinValues[attribute_type].push(attribute_value) : asinValues[attribute_type] = [attribute_value];
            });
            dispatch(updateAllAttrValuesServer(attrValues));
            dispatch(updateAllAttributeValues(attrValues));
            dispatch(updateAsinAttributeValues(asinValues));
        }
    }, [rpnAttrListData, locationData]);

    // Populate RPN attribute type
    useEffect(() => {
        dispatch(resetProgAttrTypes());
        if (rpnAttrTypeData && !attrTypeLoading && !attrTypeFetching) {
            const progAttr: IRPNAttributeTypeResponse[] = [];
            rpnAttrTypeData.forEach((obj: IRPNAttributeTypeResponse) => {
                progAttr[obj.rpn_attr_order] = obj;
            });
            const filteredAttr = progAttr.filter(item => item !== undefined);
            filteredAttr.forEach((item, idx) => {
                filteredAttr[idx] = { ...item, rpn_attr_order: idx };
            });
            dispatch(updateProgAttrServer(filteredAttr));
            dispatch(updateProgAttrOrder(filteredAttr));
        }
    }, [rpnAttrTypeData, program]);

    // Populate PL attribute type data on PL change (Verity API)
    useEffect(() => {
        dispatch(resetAllPLAttributes());
        if (plAttrData) {
            const plAttr: IPLAttributeTypeResponse[] = [];
            plAttrData.forEach((obj: IPLAttributeTypeResponse) => {
                plAttr[obj.pl_attr_order] = obj;
            });
            dispatch(updateAllPlAttributes(plAttr.filter(item => item !== undefined)));
        }
    }, [plAttrData, productLine]);

    // Populate Versions and attr order : WIP method
    useEffect(() => {
        dispatch(resetVersionData());
        if (versionData || attrOrderData) {
            const versions: Record<string, Record<string, any>> = {};
            const attrOrderArr: string[] = [];
            attrOrderData?.forEach(({ attribute_type, at_concat_order }: IAttrOrder) => {
                attrOrderArr[at_concat_order] = attribute_type;
            });
            const filterArr = attrOrderArr.filter(item => item !== undefined);
            versionData?.forEach(({ attribute_type, attribute_value, version_id }: IVersion) => {
                if (version_id) {
                    const rec = versions[version_id] ?? { version_id: version_id };
                    versions[version_id] = { ...rec, [attribute_type]: attribute_value };
                }
            });
            Object.entries(versions).forEach(([id, obj]) => {
                versions[id].description = getVersionDescription(obj, filterArr);
                versions[id].descriptionPN = getVersionDescription(obj, filterArr, false);
            });
            dispatch(updateVersions(versions));
            dispatch(updateAttrsOrder(filterArr));
        }
    }, [versionData, attrOrderData, program]);

    // Populate assortments
    useEffect(() => {
        dispatch(resetAssortmentData());
        if (assortmentsLoading) {
            dispatch(updateAssortmentsLoading(assortmentsLoading));
            return;
        }
        let idToAssortmentMap = {};
        if (assortments) {
            idToAssortmentMap = assortments.reduce((assortmentMap: any, assortment: any) => {
                return assortmentMap = {
                    ...assortmentMap,
                    [assortment.assortment_id]: { ...assortment }
                };
            }, {});
        }
        dispatch(updateAssortments({
            assortments: idToAssortmentMap,
            assortmentsError,
            assortmentsLoading
        }));
    }, [assortmentsLoading]);

    useEffect(() => {
        if (marketPlacesError) {
            dispatch(updateMarketplaceData({
                marketPlaces: [],
                marketPlacesError: true
            }));
        } else {
            const marketplaceMap = marketPlaces?.reduce((map: any, item: any) => {
                if (!map[item.marketplace]) {
                    map[item.marketplace] = {
                        ...item
                    };
                }
                return map;
            }, {});

            dispatch(updateMarketplaceData({
                marketPlaces: marketplaceMap,
                marketPlacesError: false
            }));
        }
    }, [marketPlaces, marketPlacesError]);

    useEffect(() => {
        dispatch(resetSkuData());
        if (skuLoading) {
            dispatch(updateSkuLoading(true));
            return;
        }
        dispatch(updateSku({
            SKUs,
            skuError,
            skuLoading
        }));
    }, [skuLoading]);

    return (
        <>
            <SpaceBetween direction="vertical" size="l">
                {!rpnDataFetching && !rpnDataLoading && !programData.ppls_id?.length &&
                    <Alert
                        statusIconAriaLabel="Warning"
                        type="warning"
                        header={
                                ALERT_MESSAGES.pplsButtonRemoved
                            }
                    ></Alert>}
                <ProgramHomeTabs />
            </SpaceBetween>
            <CreateProgramPPLS setVisible={setPplsModal} visible={pplsModal} />
        </>

    );
}