import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState(); 
    const queryClient = useQueryClient();

    const fetchCommodity = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getCommodityQuery());
        const { body }: IApiResponse = response.data.data.get_comdty;
        return body;
    };

    const updateCommodity = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_comdty'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_comdty as IApiUpdateResponse;
    };

    const useCommodityData = () => {
        return useQuery(
            ['commodity'],
            fetchCommodity,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useUpdatedCommodityData = () => {
        return useMutation(
            (requestData) => updateCommodity(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['commodity']);
                }
            }
        );
    };
   
    return { componentData, useCommodityData, useUpdatedCommodityData };
};


