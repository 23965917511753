('use strict');

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from 'react';

import { AgGridReact } from 'ag-grid-react';

import {
  GridReadyEvent,
  ColumnApi,
  GridApi,
  ColDef,
  RowSelectedEvent,
  RowClickedEvent,
  CellClassParams,
  EditableCallbackParams,
  GetContextMenuItemsParams,
  MenuItemDef,
  CsvExportModule,
  ColGroupDef,
  FillOperationParams,
  SideBarDef,
  CellDoubleClickedEvent,
  CellClickedEvent,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ProcessGroupHeaderForExportParams,
  FirstDataRenderedEvent,
  GridSizeChangedEvent,
  RowHeightParams,
  ExcelStyle,
  FilterChangedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise';

// import { ClientSideRowModelModule  } from '@ag-grid-community/client-side-row-model';

import {
  Container,
  SpaceBetween,
  Grid,
  Box,
  Header,
  SegmentedControl,
  Icon,
  ButtonDropdown,
  ButtonDropdownProps,
} from '@amzn/awsui-components-react';
import Button from '@amzn/awsui-components-react/polaris/button';

import {
  IChangedCells,
  ICustomSettings,
  ColumnDef,
  GridTableProps,
  GridColumnApiType,
  GridApiParams,
} from './GridTableInterface';

import GridTableUtils, {
  applySavedFilters,
  AUTO_HEIGHT_ID,
  AUTO_HEIGHT_TEXT,
  FIXED_HEIGHT_ID,
  FIXED_HEIGHT_TEXT,
  Parsers,
  saveColumnsModel,
  saveFiltersModel,
  MISSING_FILTER_ID_ERR,
  LOCAL_STORAGE_FILTERS_MODEL_ID,
  LOCAL_STORAGE_COLUMNS_MODEL_ID
} from './GridTableUtils';

import './GridTable.scss';
import { useCellClass } from './GridTableUtils';
import AlertMessageContext from '../Contexts/alertMessageContext';
import { componentProperties } from 'src/constants/componentProperties';

// const {LicenseManager} = require('../../../vendor/@ag-grid-enterprise/core');
// const {ClipboardModule} = require('../../../vendor/@ag-grid-enterprise/clipboard');
// const {MenuModule} = require('../../../vendor/@ag-grid-enterprise/menu');
// const {RangeSelectionModule} = require('../../../vendor/@ag-grid-enterprise/range-selection');
// const {RichSelectModule } = require('../../../vendor/@ag-grid-enterprise/rich-select');
// const { FiltersToolPanelModule } = require('../../../vendor/@ag-grid-enterprise/filter-tool-panel');
// const {ColumnsToolPanelModule} = require('../../../vendor/@ag-grid-enterprise/column-tool-panel');
const licenseKey =
  'CompanyName=Amazon.Com Services LLC,LicensedGroup=DaS_Finance_FP&A,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-026289,ExpiryDate=29_April_2023_[v2]_MTY4MjcyMjgwMDAwMA==efc1773a45ba36f207d8489564f0fd52';
LicenseManager.setLicenseKey(licenseKey);

export const GridTable = forwardRef(
  (
    {
      gridHeader,
      gridSettings,
      customSettings,
      tableKey,
      onSave,
      onRefresh,
      children,
      actionComponents
    }: GridTableProps,
    ref: any
  ) => {
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const gridRef = useRef<any>();
    const containerStyle = useMemo(
      () => ({ width: '100%', height: '100%' }),
      []
    );
    const className = customSettings?.className
      ? customSettings?.className!
      : 'ag-theme-alpine';
    const gridStyle = useMemo(
      () => ({
        height: customSettings?.gridHeight || '70Vh',
        width: customSettings?.gridWidth || '100%',
      }),
      []
    );

    const rowClass = customSettings?.rowClass ?? '';

    const [columnDef, setColumnDef] = useState<any>(gridSettings.columnDefs);
    const [defaultColumnDef, setDefaultColumnDef] = useState<any>(
      gridSettings.defaultColDef
    );
    
    const [defaultColumnGroupDef, setDefaultGroupColumnDef] = useState<any>(
      gridSettings.defaultColGroupDef
    );
    const [selectedGridHeight, setSelectedGridHeight] =
      useState<string>(FIXED_HEIGHT_ID);
    const [sideBar, setSideBar] = useState<SideBarDef>({});
    const [rowData, setRowData] = useState(gridSettings.rowData);
    const [contextMethods, setContextMethods] = useState(gridSettings.context);
    const [rightActionPanelItems, setRightActionPanelItems] = useState<ButtonDropdownProps.ItemOrGroup[]>([{
      id: "sizing",
      text: "Sizing",
      items: [
        { id: "size-to-fit", text: "Size-to-Fit" },
        { id: "auto-size", text: "Auto-Size" }
      ]
    }]);

    // Storing Grid API and Grid ColummnApi states
    const [gridApi, setGridApi] = useState<GridApi>(new GridApi());
    const [gridColumApi, setGridColumnApi] = useState<ColumnApi>(
      new ColumnApi()
    );

    const [accessedCellValue, setAccessedCellValue] = useState<
      Record<string, IChangedCells>
    >({});

    // const [cellStyleRule] = useCellClass({ touchedCells: accessedCellValue });
    const defaultColumnDefs: ColDef = useMemo<ColDef>(() => {
      return {
        ...gridSettings.defaultColDef,
        flex: 1,
        minWidth: 150,
        width: 200,
        sortable: true,
        filter: 'agMultiColumnFilter',
        resizable: true,
        enableCellChangeFlash: true,
        editable: customSettings?.readOnly ? false : true,
        floatingFilter: false,
        cellClassRules: {
          'non-editable': (params: CellClassParams) => !params.colDef.editable,
          'updated-cell': (params: CellClassParams) => {
            if(!params.data.updatedCells) return false;
            return params.data.updatedCells.has(params.colDef.field)  
          }
        },
      };
    }, []);

    const excelStyles = useMemo<ExcelStyle[]>(() => {
      if (customSettings?.excelStyles) {
        return customSettings.excelStyles;
      }
      return [{ id: '' }];
    }, []);

    const columnTypes: {
      [key: string]: ColDef;
    } = {
      ...gridSettings.columnTypes,
      nonEditableColumn: { editable: false },
      valueColumn: {
        valueParser: Parsers.numberParser,
        filter: 'agNumberColumnFilter',
      },
    };

    const transposeHeaderCols = new Map<string, Set<string>>();
    const updateColumnDefs = () => {
      const colDefs: ColumnDef[] = [];
      if (gridSettings.columnDefs?.length) {
        gridSettings.columnDefs.forEach((col: any) => {
          colDefs.push({
            ...col,
          } as ColumnDef);
        });
        if (customSettings?.enabledTranspose) {
          for (let colSet of Array.from(transposeHeaderCols.values())) {
            Array.from(colSet).forEach((col) => {
              colDefs.push({
                headerName: col.toString(),
                field: col.toString(),
              } as ColumnDef);
            });
          }
        }
        const newRowCol: ColDef = {
          headerName: 'Row Id',
          field: 'rowId',
          hide: true,
        };
        setColumnDef([...colDefs, newRowCol]);
      }
    };

    const updateGridRowData = () => {
      if (!customSettings?.enabledTranspose) {
        setRowData([...gridSettings.rowData!]);
        customSettings?.onPrepareData?.({
          data: gridSettings.rowData,
          columnDef,
          gridSettings,
          gridApi,
          columnApi: gridColumApi,
        });
      } else {
        const { transposeGroupProp, transposeValueProp, uid }: ICustomSettings =
          { ...customSettings };
        const processedData = gridSettings.rowData?.reduce(
          (aggRowData: any, currentRow: any) => {
            if (!aggRowData[currentRow[uid!]]) {
              aggRowData[currentRow[uid!]] = currentRow;
            }
            for (
              let transposePropIndex = 0;
              transposePropIndex < transposeGroupProp?.length!;
              transposePropIndex++
            ) {
              transposeHeaderCols.set(
                transposeGroupProp![transposePropIndex],
                new Set<string>(
                  transposeHeaderCols.get(
                    transposeGroupProp![transposePropIndex]
                  )
                ).add(currentRow[transposeGroupProp![transposePropIndex]])
              );
              aggRowData[currentRow[uid!]][
                currentRow[transposeGroupProp![transposePropIndex]]
              ] = currentRow[transposeValueProp![transposePropIndex]];
            }
            return { ...aggRowData };
          },
          {}
        );
        setRowData([...Object.values(processedData)]);
        if (!customSettings.onPrepareData) updateColumnDefs();
        else {
          customSettings.prepareInitialized = true;
        }
        customSettings?.onPrepareData?.({
          data: processedData,
          columnDef,
          gridSettings,
          gridApi,
          columnApi: gridColumApi,
        });
      }
    };

    const updateSideBar = () => {
      if (customSettings?.sideBar) {
        setSideBar(customSettings?.sideBar!);
      }
    };

    const onGridReady = (params: GridReadyEvent) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
      minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
      currentRowHeight = minRowHeight;
      gridSettings.onGridReady?.(params);
      if(customSettings?.defaultAutoSizeAll)  autoSizeAll(false);
      if (customSettings?.filterId)
        onApplyFilters(params.api, params.columnApi);
      gridSettings.onGridReady?.(params)
    };

    const downloadCsv = (e: any, action: GridApi = gridApi): any => {
      action.exportDataAsCsv({allColumns: true});
    };

    const getContextMenuItems = useCallback(
      (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
        let menuItems = [];
        menuItems = [
          'copy',
          'copyWithHeaders',
          'copyWithGroupHeaders',
          'paste',
          'separator',
          'autoSizeAll',
          'separator',
          'csvExport',
          'insert',
        ];
        return menuItems;
      },
      []
    );

    const onCellValueChanged = useCallback(
      (event: any): void => {        
        const { oldValue, newValue, data, colDef, rowIndex, column } = event;
        const { colId } = column;
        GridTableUtils.setColId(colId, rowIndex);
        const cellId = GridTableUtils.colId;
        
        // updateColumnDefs();
        const newCellsData: Record<string, IChangedCells> = {
          ...accessedCellValue,
        };
        // first time assignment
        if (!accessedCellValue[cellId]) {
          newCellsData[cellId] = {
            oldValue,
            newValue,
            rowIndex,
            colId,
            row: data,
          };
        } else {
          // preserving the original data (as set for the first time with the new updated data)
          newCellsData[cellId] = {
            ...newCellsData[cellId],
            newValue,
            row: data,
          };
        }
        setAccessedCellValue({ ...newCellsData });
        // highlight updated cells
        if(customSettings?.enableOnChangeHighlight && oldValue !== newValue && !data.rowId) {
          if(!data.updatedCells) data.updatedCells = new Set();
          data.updatedCells.add(colDef.field);
          if(newValue === accessedCellValue[cellId]?.oldValue){
            data.updatedCells.delete(colDef.field);
          }
          event.api.refreshCells({
            rowNodes: [event.node] 
          }); 
        }
        if (newValue === accessedCellValue[cellId]?.oldValue || (accessedCellValue[cellId] && accessedCellValue[cellId]?.oldValue === undefined && newValue === '')) {
          delete accessedCellValue[cellId];
          setAccessedCellValue(accessedCellValue);
        }
      },
      [accessedCellValue]
    );
    useImperativeHandle(ref, () => ({
      resetChangedCells() {
        setAccessedCellValue({});
      },
    }));

    const saveData = useCallback(() => {
      if (onSave) {
        onSave(accessedCellValue);
      }
    }, [accessedCellValue]);

    const refreshData = () => {
      if (onRefresh) {
        onRefresh();
        sizeToFit();
      }
    };

    const addRow = (): void => {
      const newRows = [];
      let defaultAccessedValues: Record<string, IChangedCells> = {};
      for (
        let rowIndex = 0;
        rowIndex < customSettings?.numAddRows!;
        rowIndex++
      ) {
        let rowId: number = gridApi.getDisplayedRowCount();
        newRows.push({
          rowId: `newRow#${rowId}`,
          ...customSettings?.defaultsOnInsert,
        });
        defaultAccessedValues = Object.entries({
          ...customSettings?.defaultsOnInsert,
        })
          .map(([key, val], index) => {
            let rowIdx: number = rowId ? rowId + rowIndex : index;
            return {
              oldValue: undefined,
              newValue: val?.toString(),
              colId: key.toString(),
              rowIndex: rowIdx,
              row: { rowId: `newRow#${rowId}` },
            };
          })
          .reduce((agg: Record<string, IChangedCells>, val: IChangedCells) => {
            const colId: string = val?.rowIndex?.toString()!;
            agg[val?.colId!.toString().concat(colId)] = val;
            return agg as Record<string, IChangedCells>;
          }, {});
      }

      gridApi.applyTransaction({
        add: newRows,
      });
      setAccessedCellValue({ ...accessedCellValue, ...defaultAccessedValues });
      gridApi.ensureIndexVisible(gridApi.getDisplayedRowCount() - 1, 'top');
    };

    const onRowSelection = useCallback((selectionData: RowSelectedEvent) => {
      const selectedRows = gridRef.current.api.getSelectedRows();
      if (customSettings?.onRowSelection)
        customSettings?.onRowSelection(selectedRows);
    }, []);

    const onCellDblClick = (event: CellDoubleClickedEvent) => {
      if (customSettings?.onCellDblClick) {
        customSettings?.onCellDblClick({ ...event });
      }
    };
    const onCellClick = (event: CellClickedEvent) => {
      if (customSettings?.onCellClick) {
        customSettings?.onCellClick({ ...event });
      }
    };

    const sizeToFit = useCallback(() => {
      // Make the currently visible COLUMNS fit the screen.
      // The COLUMNS will scale (growing or shrinking) to fit the available width.
      gridRef?.current?.api?.sizeColumnsToFit();
    }, []);

    const autoSizeAll = useCallback((skipHeader) => {
      // Make the currently visible COLUMNS and HEADERS fit the screen.
      // The COLUMNS and HEADERS will scale (growing or shrinking) to fit the available width.
      // When skipHeader is toggled, will skip the scaling of headers
      const allColumnIds: any[] = [];
      gridRef.current.columnApi.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.getId());
      });
      gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
    }, []);

    const setAutoHeight = useCallback(() => {
      gridRef.current!.api.setDomLayout('autoHeight');
      // auto height will get the grid to fill the height of the contents,
      // so the grid div should have no height set, the height is dynamic.
    }, []);

    const setFixedHeight = useCallback(() => {
      // we could also call setDomLayout() here as normal is the default
      gridRef.current!.api.setDomLayout('normal');
      // when auto height is off, the grid ahs a fixed height, and then the grid
      // will provide scrollbars if the data does not fit into it.
      gridRef.current!.clientHeight = '40vh';
    }, []);

    const popupParent = useMemo<HTMLElement>(() => {
      return document.querySelector('body')!;
    }, []);

    const onFillOperation = useCallback((e: FillOperationParams) => {
      return customSettings?.onFillOperation?.(e);
    }, []);

    const onProcessCellForClipboard = useCallback(
      (params: ProcessCellForExportParams) => {
        return customSettings?.onProcessCellForClipboard
          ? customSettings?.onProcessCellForClipboard?.(params)
          : params.value;
      },
      []
    );

    const onProcessHeaderForClipboard = useCallback(
      (params: ProcessHeaderForExportParams) => {
        if (customSettings?.onProcessHeaderForClipboard)
          return customSettings?.onProcessHeaderForClipboard?.(params);
        else {
          const colDef = params.column.getColDef();
          let headerName = colDef.headerName || colDef.field || '';
          if (colDef.headerName !== '') {
            headerName =
              headerName.charAt(0).toUpperCase() + headerName.slice(1);
          }
          return headerName;
        }
      },
      []
    );

    const onProcessGroupHeaderForClipboard = useCallback(
      (params: ProcessGroupHeaderForExportParams) => {
        if (customSettings?.onProcessGroupHeaderForClipboard)
          return customSettings?.onProcessGroupHeaderForClipboard?.(params);
        else {
          const colGroupDef =
            params.columnGroup.getColGroupDef() || ({} as any);
          const headerName = colGroupDef.headerName || '';
          if (headerName === '') {
            return '';
          }
          return headerName;
        }
      },
      []
    );

    const onProcessCellFromClipboard = useCallback(
      (params: ProcessCellForExportParams) => {
        return customSettings?.onProcessCellFromClipboard
          ? customSettings?.onProcessCellFromClipboard?.(params)
          : params.value;
      },
      []
    );

    const onApplyFilters = useCallback(
      (api: GridApi, columnApi: GridColumnApiType) => {
        if (customSettings?.filterId) {
          applySavedFilters(customSettings.filterId, {
            api: api,
            gridColumnApi: columnApi,
          });
        } else {
          console.error(MISSING_FILTER_ID_ERR);
        }
      },
      []
    );

    const onClearFilters = useCallback((params: GridApiParams) => {
      params.api.setFilterModel(null)!;
      const allCols = params.gridColumnApi.getAllColumns()!;

      allCols.forEach((c) => {
        params.gridColumnApi.setColumnVisible(c, true)!;
      });
      localStorage.removeItem(`${customSettings!.filterId}-${LOCAL_STORAGE_FILTERS_MODEL_ID}`);
      localStorage.removeItem(`${customSettings!.filterId}-${LOCAL_STORAGE_COLUMNS_MODEL_ID}`);
      setSuccess?.('Cleared Toolpanel settings');
    }, []);

    const onSaveFilters = useCallback((params: GridApiParams) => {
      if (customSettings?.filterId) {
        saveFiltersModel(customSettings.filterId, params.api)!;
        saveColumnsModel(customSettings.filterId, params.gridColumnApi)!;
        setSuccess?.('Saved Toolpanel settings');
      } else {
        setError?.(MISSING_FILTER_ID_ERR);
      }
    }, []);

    let minRowHeight: number = customSettings?.rowHeight ?? 25;
    let currentRowHeight: number;

    const updateRowHeight = (params: { api: GridApi }) => {
      // get the height of the grid body - this excludes the height of the headers
      const bodyViewport = document.querySelector('.ag-body-viewport');
      if (!bodyViewport) {
        return;
      }
      const gridHeight = bodyViewport.clientHeight;
      // get the rendered rows
      const renderedRowCount = params?.api?.getDisplayedRowCount();
      // if the rendered rows * min height is greater than available height, just just set the height
      // to the min and let the scrollbar do its thing
      if (renderedRowCount * minRowHeight >= gridHeight) {
        if (currentRowHeight !== minRowHeight) {
          currentRowHeight = minRowHeight;
          params?.api?.resetRowHeights();
        }
      } else {
        // set the height of the row to the grid height / number of rows available
        currentRowHeight = Math.floor(gridHeight / renderedRowCount);
        params?.api?.resetRowHeights();
      }
    };

    const getRowHeight = useCallback((params: RowHeightParams) => {
      return currentRowHeight;
    }, []);

    const onFirstDataRendered = useCallback(
      (params: FirstDataRenderedEvent) => {
        updateRowHeight(params);
      },
      [updateRowHeight]
    );

    const onGridSizeChanged = useCallback(
      (params: GridSizeChangedEvent) => {
        updateRowHeight(params);
      },
      [updateRowHeight]
    );

    const onGridHeightPrefChange = (heightPrefId: string) => {
      setSelectedGridHeight(heightPrefId);
      switch (heightPrefId) {
        case AUTO_HEIGHT_ID:
          setAutoHeight();
          break;
        case FIXED_HEIGHT_ID:
        default:
          setFixedHeight();
          break;
      }
    };

    const onFilterChanged = (event: FilterChangedEvent) => {
        gridSettings.onFilterChanged?.(event)
    }

    useEffect(() => {
      if (customSettings?.prepareInitialized) {
        setRowData(gridSettings.rowData);
        setColumnDef(gridSettings.columnDefs);
        updateSideBar();
        sizeToFit();
      } else {
        updateGridRowData();
        updateSideBar();
      }

      return () => {
        console.log('Grid Cleared!');
      };
    }, [gridSettings.rowData]);

    // JSX Templates

    const addRowButton = (
      <Button variant="normal" iconName="add-plus" onClick={addRow} disabled={customSettings?.isAddRowDisabled!}>
        Add Row(s)
      </Button>
    );

    const FilterMemorizationButtons = customSettings?.allowLocalFilters && (
      <>
        <Button
          variant="normal"
          onClick={() => {
            onClearFilters({
              api: gridApi,
              gridColumnApi: gridColumApi,
            });
          }}
        >
          <SpaceBetween direction="horizontal" size="xxs">
            <Box>
              <Icon name="filter" variant="disabled" size="inherit" />
              <Icon name="menu" variant="disabled" size="inherit" />
            </Box>
            <div>Clear</div>
          </SpaceBetween>
        </Button>

        <Button
          variant="normal"
          onClick={() => {
            onSaveFilters({
              api: gridApi,
              gridColumnApi: gridColumApi,
            });
          }}
        >
          <SpaceBetween direction="horizontal" size="xxs">
            <Box>
              <Icon name="filter" variant="subtle" size="inherit" />
              <Icon name="menu" variant="subtle" size="inherit" />
            </Box>
            <div>Memorize</div>
          </SpaceBetween>
        </Button>
      </>
    );

    const panelActions = (
      <Box variant="div" float="left">
        <SpaceBetween direction="horizontal" size="s">
          {customSettings?.allowAddRow ? addRowButton : null}
          <Button variant="normal" iconName="download" onClick={downloadCsv}>
            Download CSV
          </Button>

          {!customSettings?.readOnly && (
            <>
              <Button
                variant="normal"
                iconName="refresh"
                loading={customSettings?.isRefreshLoading!}
                onClick={refreshData}
              >
                Refresh
              </Button>
              {onSave &&
              (<Button
                variant="primary"
                className="save-btn"
                iconName="file"
                loading={customSettings?.isSaveLoading!}
                disabled={customSettings?.isSaveDisabled!}
                onClick={saveData}
              >
                Save Data
              </Button>)
              }
              {actionComponents}
            </>
          )}
        </SpaceBetween>
      </Box>
    );


    const onActionsItemClick = (itemId: string) => {
      switch (itemId) {
        case "size-to-fit":
          sizeToFit();
          break;
        case "auto-size":
          autoSizeAll(false);
          break;
        case "clear":
          onClearFilters({
            api: gridApi,
            gridColumnApi: gridColumApi,
          });
          break;
        case "memorize":
          onSaveFilters({
            api: gridApi,
            gridColumnApi: gridColumApi,
          });
          break;
        default:
          break;
      }
    };

    const rightActionsPanel = (
      <Box variant="div" float="right">
        <SpaceBetween direction="horizontal" size="xs">
          {customSettings?.allowSetAutoHeight && (
            <SegmentedControl
              selectedId={selectedGridHeight}
              onChange={({ detail }) =>
                onGridHeightPrefChange(detail.selectedId)
              }
              options={[
                { text: AUTO_HEIGHT_TEXT, id: AUTO_HEIGHT_ID },
                { text: FIXED_HEIGHT_TEXT, id: FIXED_HEIGHT_ID },
              ]}
            />
          )}
          <SpaceBetween direction='horizontal' size="xxxs">
            <ButtonDropdown
              items={rightActionPanelItems}
              ariaLabel="Control instance"
              variant="primary"
              onItemClick={({ detail }) => onActionsItemClick(detail.id)}
            >Grid Actions</ButtonDropdown>
          </SpaceBetween>
        </SpaceBetween>
      </Box>
    );

    useEffect(() => {
      const apItems = [];
      if (customSettings?.allowLocalFilters) {
        const filterActs = {
          id: "filters",
          text: "Filters",
          items: [
            { id: "clear", text: "Clear" },
            { id: "memorize", text: "Memorize" }
          ]
        };
        apItems.push(filterActs);
      }
      setRightActionPanelItems([
        ...rightActionPanelItems,
        ...apItems,
      ])
    }, []);

    const template = (
      <div>
        {gridHeader && (
          <Grid disableGutters gridDefinition={[{ colspan: { default: 4 } }]}>
            <Box variant="h3" padding="xxxs" textAlign="left">
              <b>{gridHeader}</b>
            </Box>
          </Grid>
        )}
        {children && (
          <Container>
            <Grid disableGutters gridDefinition={[{ colspan: 12 }]}>
              <SpaceBetween size="xxxs">
                <Box variant="div" textAlign="left">
                  {children}
                </Box>
              </SpaceBetween>
            </Grid>
          </Container>
        )}
        <SpaceBetween size='l'>
            {!customSettings?.onlyGrid &&
                <Container>
                     <Grid
                     disableGutters
                     gridDefinition={[
                       { colspan: { default: 6 } },
                       { colspan: { default: 6 } },
                     ]}
                   >
                     <SpaceBetween size="s">{panelActions}</SpaceBetween>
                     {rightActionsPanel}
                   </Grid>
                </Container>
            }
          <div id="LabFinanceGrid" style={gridStyle}>
            <AgGridReact
                ref={gridRef}
                className={className}
                // modules={modules}
                columnDefs={columnDef}
                onCellDoubleClicked={onCellDblClick}
                onCellClicked={onCellClick}
                defaultColDef={defaultColumnDefs}
                defaultColGroupDef={defaultColumnGroupDef}
                columnTypes={columnTypes}
                sideBar={sideBar}
                tooltipShowDelay={gridSettings.tooltipShowDelay}
                tooltipMouseTrack={gridSettings.tooltipMouseTrack}
                rowData={rowData}
                context={contextMethods}
                rowDragManaged
                animateRows
                getRowHeight={getRowHeight}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                onFilterChanged={onFilterChanged}
                allowContextMenuWithControlKey
                getContextMenuItems={getContextMenuItems}
                onCellValueChanged={onCellValueChanged}
                stopEditingWhenCellsLoseFocus
                rowMultiSelectWithClick={false}
                onRowSelected={onRowSelection}
                undoRedoCellEditing
                undoRedoCellEditingLimit={20}
                enterMovesDownAfterEdit
                enterMovesDown
                enableRangeSelection
                enableFillHandle
                suppressClearOnFillReduction
                fillOperation={onFillOperation}
                processCellForClipboard={onProcessCellForClipboard}
                processHeaderForClipboard={onProcessHeaderForClipboard}
                processGroupHeaderForClipboard={onProcessGroupHeaderForClipboard}
                processCellFromClipboard={onProcessCellFromClipboard}
                popupParent={popupParent}
                excelStyles={excelStyles}
                rowSelection={gridSettings.rowSelection}
                suppressRowClickSelection={gridSettings.suppressRowClickSelection || false}
                rowHeight={minRowHeight}
                rowClass={rowClass}
                isRowSelectable={gridSettings.isRowSelectable}
                getRowNodeId={customSettings?.getRowId}
                onModelUpdated={gridSettings.onModelUpdated}
                colResizeDefault={gridSettings.colResizeDefault}
                onComponentStateChanged={gridSettings.onComponentStateChanged}
            ></AgGridReact>
          </div>
        </SpaceBetween>
      </div>
    );
    return template;
  }
);
