import { USER_ROLES } from '../../constants/constants';

export default () => {
    const filterRoles = (roleResMap: any, resources: string[]) => {
        return Object.values(USER_ROLES).filter((role) => roleResMap[role]?.some((res: string) => resources.includes(res)));
    };

    const getUserRole = (roleResMap: any, resources: string[]) => {
        if (!roleResMap || !Object.keys(roleResMap).length) {
            return USER_ROLES.readonly;
        }
    
        const roles = filterRoles(roleResMap, resources);
        const hasAsinOwner = roles.includes(USER_ROLES.asinOwner);
        if (hasAsinOwner) return USER_ROLES.asinOwner;
    
        return roles.length ? roles[0] : USER_ROLES.readonly;
    };
    
    const getUserRoles = (roleResMap: any, resources: string[]) => {
        if (!roleResMap || !Object.keys(roleResMap).length) {
            return [USER_ROLES.readonly];
        }
    
        return filterRoles(roleResMap, resources);
    };

    return { getUserRole, getUserRoles };
};
