import React, { useState, useEffect } from 'react';
import { Container, Header, SpaceBetween, Spinner, Toggle } from "@cloudscape-design/components";
import { IDynamicFieldProps } from '../../../common/DynamicForm/Interfaces';
import { SEPERATOR, SK_MAPPING, TAB_ID_LABEL, API_RESPONSE_FIELDS, ALL_ASSIGNEES_KEY, USER_ACTION_ROLES, ROLE_DEFAULT_VIEW, STAGES, UPDATED_FIELD } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SPECS, IFieldMetaData, FIELD_NAMES } from '../../constants/fieldData';
import { selectSpecsClientData, updateSpecsClientData, selectSpecsServerData, selectSpecsModified } from './specsSlice';
import ScreenUtils from '../../utils/screenUtils';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import DynamicForm from '../../../common/DynamicForm/DynamicForm';
import { SubmitTab } from '../programSetup/submitTabAction';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { ProgramAssignees } from '../userPolicy/program/assigneesList';
import { SANDBOX_UNAPPROVED_MESSAGE, VIEW_WIP_BUTTON } from '../../constants/displayMessages';

export const Specs = () => {
    const dispatch = useAppDispatch();
    const specs = useAppSelector(selectSpecsClientData);
    const serverData = Object.values(useAppSelector(selectSpecsServerData));
    const locationData = useAppSelector(selectLocationData);
    const isModified = useAppSelector(selectSpecsModified);
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [userRole, setUserRole] = useState('');
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [dynamicFormInput, setDynamicFormInput] = useState<IDynamicFieldProps[][]>();
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [viewApproved, setViewApproved] = React.useState(false);
    const stage = serverData?.length ? serverData?.[0].Stage ?? STAGES.sandbox : STAGES.sandbox;
    const submitDisabled = stage === STAGES.submitted || !hasWriteAccess || serverData.length === 0 || (isModified && serverData.length > 0);

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY, TAB_ID_LABEL.specs, TAB_ID_LABEL.specs + SEPERATOR + SK_MAPPING.specs]);
        setUserRole(currRole);
        if (USER_ACTION_ROLES.write.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    const updateState = (fieldValue: any, fieldName: string) => {
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.specs + SEPERATOR + SK_MAPPING.specs + SEPERATOR + fieldName;
        const value = fieldValue.value ?? fieldValue;
        let updatedData = ScreenUtils.updateClientState(value, fieldName, API_RESPONSE_FIELDS.itemValues, specs, PK, SK);
        if (fieldName === FIELD_NAMES.specsMicEnabled && value === 'No' && specs[FIELD_NAMES.specsMicRange]) {
            updatedData = ScreenUtils.updateClientState('', FIELD_NAMES.specsMicRange, API_RESPONSE_FIELDS.itemValues, updatedData, PK, SK);
        }
        if (fieldName === FIELD_NAMES.specsDispEnabled && value === 'No' && specs[FIELD_NAMES.specsDispAttr]) {
            updatedData = ScreenUtils.updateClientState('', FIELD_NAMES.specsDispAttr, API_RESPONSE_FIELDS.itemValues, updatedData, PK, SK);
        }
        dispatch(updateSpecsClientData(updatedData));
    };

    useEffect(() => {
        const fMetaData: Record<string, IFieldMetaData> = { ...SPECS };
        Object.entries(fMetaData).forEach(([field]) => {
            const label = fMetaData[field].label;
            fMetaData[field].actions = updateState;
            fMetaData[field].readonly = !hasWriteAccess || viewApproved;
            if (field === FIELD_NAMES.specsMicRange) fMetaData[field].visibility = specs[FIELD_NAMES.specsMicEnabled]?.ItemValues === 'Yes' ? true : false;
            if (field === FIELD_NAMES.specsDispAttr) fMetaData[field].visibility = specs[FIELD_NAMES.specsDispEnabled]?.ItemValues === 'Yes' ? true : false;
            if (label.endsWith(UPDATED_FIELD)) fMetaData[field].label = label.slice(0, label.length - UPDATED_FIELD.length);
            if (!viewApproved && specs[field] && !specs[field].Action && specs[field].ItemValues !== specs[field].ItemValuesWIP) fMetaData[field].label += UPDATED_FIELD;
        });
        const stageView = viewApproved ? STAGES.approved : ROLE_DEFAULT_VIEW[userRole];
        setDynamicFormInput(ScreenUtils.generateDynamicFormInput(specs, fMetaData, stageView));
    }, [specs, hasWriteAccess, userRole, viewApproved]);

    const loadSpecsDetails = () => {
        if (dynamicFormInput) return <DynamicForm formInput={dynamicFormInput} />;
        return "No data to display.";
    };

    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                <SubmitTab currentTab='specs'
                    saveDisabled={!hasWriteAccess || !isModified || stage === STAGES.submitted}
                    submitDisabled={submitDisabled}
                    approveDisabled={isModified || !ScreenUtils.isSandboxUpdated(specs)}
                    setIsLoading={setIsUpdateLoading}
                    userRole={userRole}
                    section={SK_MAPPING.specs}
                />
                {!isUpdateLoading ?
                    <>
                        <Container className=''
                            header={
                                <Header
                                    actions={
                                        <Toggle
                                            onChange={({ detail }) => setViewApproved(detail.checked)}
                                            checked={viewApproved}
                                        >
                                            {VIEW_WIP_BUTTON}
                                        </Toggle>
                                    }
                                    variant="h2"
                                    info={<ProgramAssignees
                                        tab={TAB_ID_LABEL.specs}
                                        section={SK_MAPPING.specs}
                                    />}
                                >
                                    Specs
                                </Header>
                            }
                            footer={<><strong>{UPDATED_FIELD}</strong> {SANDBOX_UNAPPROVED_MESSAGE}</>}
                        >
                            {loadSpecsDetails()}
                        </Container>
                    </> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </SpaceBetween>
        </>
    );
};
