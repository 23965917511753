import { ColDef, ColGroupDef } from "ag-grid-community";

export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Verity device key",
        field: "device_key",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "Product line",
        field: "product_line",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "Program (RPN)",
        field: "rpn",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "FPN",
        field: "fpn",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "Memory",
        field: "memory",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "Wireless",
        field: "wireless",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "DTCP",
        field: "DTCP",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "Country",
        field: "country",
        editable: false,
        floatingFilter: true
    },
    {
        headerName: "Channel",
        field: "channel",
        editable: false,
        floatingFilter: true
    }
];