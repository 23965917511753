import React, { useEffect, useState } from 'react';
import { Tabs } from "@cloudscape-design/components";
import { RefreshSnapshot } from '../snapshot/refreshSnapshot';
import { CreateSnapshot } from '../snapshot/createSnapshot';
import { RefreshWIP } from '../snapshot/refreshWIP';
import { useAppSelector } from 'src/components/redux/hooks';
import { selectCurrUserPolicyLoading, selectUserPolicy } from '../userPolicy/userPolicySlice';
import { USER_ACTION_ROLES } from '../../constants/constants';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { ACCESS_DENIED_MESSAGES } from '../../constants/displayMessages';

export const Snapshot = () => {
    const isUserPolicyLoading = useAppSelector(selectCurrUserPolicyLoading);
    const userPolicyData = useAppSelector(selectUserPolicy);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        if (userPolicyData) USER_ACTION_ROLES.snapshot.some(role => userPolicyData[role]) ? setHasAccess(true) : setHasAccess(false);
    }, [userPolicyData]);

    return (
        <>
            {isUserPolicyLoading ? <Spinner /> :
                <>
                    {!hasAccess ? 
                        <Alert type="error" header="Access Denied">{ACCESS_DENIED_MESSAGES.snapshot}</Alert> :
                        <Tabs
                            tabs={[
                                {
                                    label: "Create Snapshot",
                                    id: "create",
                                    content: < CreateSnapshot />
                                },
                                {
                                    label: "Refresh Snapshot",
                                    id: "refresh",
                                    content: <RefreshSnapshot />
                                } // Remove refresh WIP link only for UAT.
                            ]}
                        />
                    }
                </>

            }
        </>

    );
};