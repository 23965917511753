import {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import { getDomain } from '../Auth/AuthConfiguration'
import { getValidToken } from "./Auth";

let refreshing = false;

const onRequest = async (config: AxiosRequestConfig) => {
    const domain = getDomain();
    config.headers = {
        'Authorization': await getValidToken(refreshing),
        'x-username': sessionStorage.getItem('userName')!,
        'x-domain': domain === 'dev' ? 'alpha' : domain
    }

    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if(error?.response?.status === 401){
        window.sessionStorage.removeItem("username");
        Object.keys(localStorage).forEach(function(key){
           if (/^(CognitoIdentityServiceProvider.)/.test(key)) {
               localStorage.removeItem(key);
           }
       });
        window.location.href = '/';
    }
    console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
};

export function setupInterceptorsTo(
    axiosInstance: AxiosInstance
): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}