import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'account_code': 'Account Code',
    'budget_type': 'Budget Type',
    'description': 'Description'
};
export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Account Code",
        field: "account_code",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Budget Type",
        field: "budget_type",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Description",
        field: "description",
        editable: true,
        valueParser: GridValidation.trimValue
    }
    
];