import React, { ReactElement, createContext } from "react";
import productHelper from './productHelper';
import productGroupHelper from './productGroupHelper';
import costCenterGroupHelper from "./costCenterGroupHelper";
import { UseQueryResult } from "@tanstack/react-query";
import projectsHelper from "./projectsHelper";
import budgetTypeHelper from "./budgetTypeHelper";
import costCenterHelper from "./costCenterHelper";
import accountHelper from "./accountHelper";
import budgetBaselineHelper from "./budgetBaselineHelper";
import userValidation from './userValidation';

interface IGlobalProviderProps {
    children: ReactElement | JSX.Element;
}

interface IGlobalContext {
    productCodeData?: any;
    productGroupData?: any;
    ccGrpData?: any;
    projCodesData?: any;
    budgetTypeData?: any;
    ccCodesData?: any;
    accCodeData?: any;
    ccCategoryData?: any;
    bbLineItemData?: any;
    useProductCodesData: () => UseQueryResult;
    useProductGroupsData: () => UseQueryResult;
    useCostCenterGroupData: () => UseQueryResult;
    useProjCodesData: () => UseQueryResult;
    useBudgetTypeListData: () => UseQueryResult;
    useCostCenterCodesData: () => UseQueryResult;
    useAccCodesData: () => UseQueryResult;
    useCCCategoryData: () => UseQueryResult;
    useLineItemData: () => UseQueryResult;
    fetchUsers: (user:string) => any;
}

const qResp = {} as UseQueryResult;
export const GlobalContext = createContext<IGlobalContext>({
    useProductCodesData: () => qResp,
    useProductGroupsData: () => qResp,
    useCostCenterGroupData: () => qResp,
    useProjCodesData: () => qResp,
    useBudgetTypeListData: () => qResp,
    useCostCenterCodesData: () => qResp,
    useAccCodesData: () => qResp,
    useCCCategoryData: () => qResp,
    useLineItemData: () => qResp,
    fetchUsers:() => {}
});

export const GlobalContextProvider = ({ children }: IGlobalProviderProps): ReactElement => {
    const { useProductCodesData, productCodeData } = productHelper();
    const { useProductGroupsData, productGroupData } = productGroupHelper();
    const { useProjCodesData, projCodesData } = projectsHelper();
    const { useBudgetTypeListData, budgetTypeData } = budgetTypeHelper();
    const { useCostCenterGroupData, ccGrpData } = costCenterGroupHelper();
    const { useCostCenterCodesData, ccCodesData } = costCenterHelper();
    const { useAccCodesData, accCodeData } = accountHelper();
    const { useCCCategoryData, useLineItemData, ccCategoryData, bbLineItemData } = budgetBaselineHelper();
    const { fetchUsers }  = userValidation();
    const provider = {
        useProductCodesData,
        productCodeData,
        useProductGroupsData,
        productGroupData,
        useCostCenterGroupData,
        ccGrpData,
        useProjCodesData,
        projCodesData,
        useBudgetTypeListData,
        budgetTypeData,
        useCostCenterCodesData,
        ccCodesData,
        fetchUsers,
        accCodeData,
        useAccCodesData,
        useCCCategoryData, 
        useLineItemData, 
        ccCategoryData, 
        bbLineItemData
    };

    return (
        <GlobalContext.Provider value={provider}>
            {children}
        </GlobalContext.Provider>
    );
};
