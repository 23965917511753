import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';

type VersionState = {
    versions: Record<string, Record<string, any>>
    attributesOrder: string[]
  };

const initialState: VersionState = {
    versions: {},
    attributesOrder: []
};

export const versionSlice = createSlice({
    name: 'version',
    initialState,
    reducers: {
        resetVersionData: (state) => {
            state.versions = initialState.versions;
            state.attributesOrder = initialState.attributesOrder;
        },
        updateVersions: (state, action) => {
            state.versions = action.payload;
        },
        updateAttrsOrder: (state, action) => {
            state.attributesOrder = action.payload;
        }
    }
});

export const { resetVersionData, updateVersions, updateAttrsOrder} = versionSlice.actions;

export default versionSlice.reducer;

export const selectVersions = (state: RootState) => state.version.versions;
export const selectAttrsOrder = (state: RootState) => state.version.attributesOrder;