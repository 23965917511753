import React, { useState, useEffect } from 'react';
import { Container, Header } from "@cloudscape-design/components";
import { ATTRIBUTE_TYPE_NAMES, USER_ACTION_ROLES, SAME_FOR_ALL_FPNS } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ScreenUtils from '../../utils/screenUtils';
import { selectAttributeTypeListMap, selectAllStandardAttributes, selectAttributeValues, updateAllAttributeValues, selectSameForAllFPNs, updateSameForAllFPNs, selectUserRole, selectAsinAttributeValues } from './attributesSlice';
import { HELPER_SEPARATOR } from '../../constants/constants';
import { selectCountryMap, selectLocationData } from '../appLayout/appLayoutSlice';
import { StandardAttributeForm } from './standardAttributesForm';
import { IFormInputProps, ISelectOptions } from '../../interfaces/interfaces';
import { PopoverInfo } from '../../../common/Components/Popover/popover';
import { selectVerityData } from '../programOverview/programOverviewSlice';

export const StandardAttributes = ({ isLoading }: any) => {
    const dispatch = useAppDispatch();
    const allAttributes = useAppSelector(selectAllStandardAttributes);
    const attributeValues = useAppSelector(selectAttributeValues);
    const asinAttrValues = useAppSelector(selectAsinAttributeValues);
    const { asinCount } = useAppSelector(selectVerityData);
    const allTypeToValues = useAppSelector(selectAttributeTypeListMap);
    const sameForAllFPNs = useAppSelector(selectSameForAllFPNs);
    const [formInput, setFormInput] = useState<IFormInputProps[]>([]);
    const { fpn } = useAppSelector(selectLocationData);
    const countryNameMap = useAppSelector(selectCountryMap);
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole)) && !isLoading;
    const asinEditableAttr = [ATTRIBUTE_TYPE_NAMES.country, ATTRIBUTE_TYPE_NAMES.channel, ATTRIBUTE_TYPE_NAMES.color, ATTRIBUTE_TYPE_NAMES.row, ATTRIBUTE_TYPE_NAMES.skuTypes, ATTRIBUTE_TYPE_NAMES.countryChannel];

    useEffect(() => {
        const formProps: IFormInputProps[] = [];
        allAttributes?.forEach((attrType: string) => {
            const allOptions = allTypeToValues[attrType] ?? [];
            const disabledValues = asinAttrValues[attrType] ? asinAttrValues[attrType] : [];
            let readonly = (fpn.id && SAME_FOR_ALL_FPNS.includes(attrType)) ? true : !hasWriteAccess;
            let options = ScreenUtils.generateOptions(allOptions, disabledValues);
            const values = attributeValues[attrType] ? attributeValues[attrType] : [];
            const fieldType = attrType === ATTRIBUTE_TYPE_NAMES.productType ? "select" : "multiselect";
            if (!readonly && asinCount !== 0) readonly = !asinEditableAttr.includes(attrType);
            if (attrType === ATTRIBUTE_TYPE_NAMES.country) {
                options = [];
                allOptions.forEach(item => {
                    options.push({
                        label: item + ' - ' + countryNameMap[item],
                        value: item,
                        disabled: disabledValues.includes(item) ? true : false
                    });
                });
            }
            if (attrType === ATTRIBUTE_TYPE_NAMES.countryChannel) {
                options = getCountryChannelOpts(attributeValues[ATTRIBUTE_TYPE_NAMES.country], attributeValues[ATTRIBUTE_TYPE_NAMES.channel]);
            }
            formProps.push({
                name: attrType,
                label: attrType,
                type: fieldType,
                readOnly: readonly,
                all_options: options,
                IsInheritedByFPN: sameForAllFPNs.includes(attrType),
                toggleDisabled: SAME_FOR_ALL_FPNS.includes(attrType),
                values: fieldType === 'select' ? ScreenUtils.generateOptions([values], disabledValues)[0] : ScreenUtils.generateOptions(values, disabledValues)
            });
        });
        setFormInput(formProps);
    }, [allAttributes, attributeValues, allTypeToValues, hasWriteAccess, asinCount]);

    const getCountryChannelOpts = (countryVals: string[], channelVals: string[]) => {
        const options: ISelectOptions[] = [];
        countryVals?.forEach(country => {
            channelVals?.forEach(channel => {
                options.push({
                    label: country + '-' + channel,
                    value: country + '-' + channel,
                });
            });
        });
        return options;
    }

    const updateState = (fieldValue: any, fieldName: string) => {
        const field = fieldName.includes(HELPER_SEPARATOR) ? fieldName.split(HELPER_SEPARATOR)[0] : fieldName;
        if (fieldName.includes(HELPER_SEPARATOR)) {
            let updatedList = [...sameForAllFPNs];
            fieldValue ? updatedList.push(field) : updatedList = updatedList.filter(item => item !== field);
            dispatch(updateSameForAllFPNs(updatedList));
        } else {
            const allValues = { ...attributeValues };
            const values = Array.isArray(fieldValue) ? fieldValue.map(item => item.value) : fieldValue.value ? [fieldValue.value] : [];
            allValues[field] = values;
            if (allValues[ATTRIBUTE_TYPE_NAMES.countryChannel] && (fieldName === ATTRIBUTE_TYPE_NAMES.country || fieldName === ATTRIBUTE_TYPE_NAMES.channel)) {
                const validValues = getCountryChannelOpts(allValues[ATTRIBUTE_TYPE_NAMES.country], allValues[ATTRIBUTE_TYPE_NAMES.channel]).map(obj => obj.value);
                allValues[ATTRIBUTE_TYPE_NAMES.countryChannel] = allValues[ATTRIBUTE_TYPE_NAMES.countryChannel]?.filter(ccVal => validValues.includes(ccVal));
            }
            dispatch(updateAllAttributeValues(allValues));
        }
    };

    const loadForm = () => {
        if (formInput) {
            return <StandardAttributeForm
                formInputs={Object.values(formInput)}
                action={updateState}
                hideToggle={(fpn.id || asinCount > 0) ? true : false}
            />;
        } else {
            return "No data to display.";
        }
    };
    return (
        <>
            <Container className=''
                header={
                    <Header
                        variant="h2"
                    >
                        Standard attributes  <PopoverInfo text='Level1' header='Info' message='WIP' />
                    </Header>
                }
            >  {loadForm()}

            </Container>

        </>

    );
};
