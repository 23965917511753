import React, { useEffect } from "react";
import { Alert } from "@amzn/awsui-components-react/polaris";
import { IAlertMessage } from "./AlertMessage.interface";

import "./AlertMessage.scss";

export const SuccessMessage = ({
    message,
    timeout = 5000,
    autoDismiss = true
}: IAlertMessage) => {
    const [visible, setVisible] = React.useState(true);
    useEffect(() => {
        if (autoDismiss && message) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
            }, timeout);
        }
        return () => {
            console.log(`Success: ${message}`);
        };
    }, [message]);
    if (!message) return <></>;
    return (
        <Alert
            onDismiss={() => setVisible(false)}
            visible={visible}
            dismissAriaLabel="Close alert"
            dismissible
            type="success"
        >
            {message}
        </Alert>
    );
};

export const ErrorMessage = ({
    message,
    timeout = 5000,
    autoDismiss = true
}: IAlertMessage) => {
    const [visible, setVisible] = React.useState(true);
    const getMessage = (): string => {
        if (message?.response?.status < 500) return message.response.data;
        if (message?.message) return message.message;
        return message;
    };
    useEffect(() => {
        if (autoDismiss && message) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
            }, timeout);
        }
        return () => {
            console.log(`Error: ${message}`);
        };
    }, [message]);
    if (!message) return <></>;
    return (
        <Alert
            onDismiss={() => setVisible(false)}
            visible={visible}
            dismissAriaLabel="Close alert"
            dismissible
            type="error"
        >
            {/* {message} */}
            {getMessage()}
        </Alert>
    );
};

export const AlertMessage = ({
    successMessage,
    errorMessage,
    timeout,
    autoDismiss = true
}: {
    successMessage: string;
    errorMessage: string;
    timeout?: number;
    autoDismiss?: boolean;
}) => {
    return (
        <div className="alert-fixed-top">
            <SuccessMessage
                message={successMessage}
                timeout={timeout}
                autoDismiss={autoDismiss}
            />
            <ErrorMessage
                message={errorMessage}
                timeout={timeout}
                autoDismiss={autoDismiss}
            />
        </div>
    );
};
