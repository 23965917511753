import React, { useEffect } from "react";
import { Tabs } from "@cloudscape-design/components";
import { AttributeOrdering } from "./attributeOrdering";
import { AssignUsers } from '../userPolicy/productLine/assignUsers';
import { useAppDispatch, useAppSelector } from "src/components/redux/hooks";
import { selectLocationData } from "../appLayout/appLayoutSlice";
import ScreenUtils from "../../utils/screenUtils";
import { selectUserPolicy, updateRoleResourceMap, resetRoleResourceMap } from "../userPolicy/userPolicySlice";
import { ALL_ASSIGNEES_KEY } from "../../constants/constants";

export function ProductLineHome() {
    const dispatch = useAppDispatch();
    const {productLine} = useAppSelector(selectLocationData);
    const userPolicyData = useAppSelector(selectUserPolicy);

    useEffect(() => {
        dispatch(resetRoleResourceMap());
        const roleToResourceMap: any = {};
        const plKey = ScreenUtils.getPLUserPolicyKey(productLine.id);
        Object.entries(userPolicyData).forEach(([role, obj]) => {
            if (obj[ALL_ASSIGNEES_KEY]) 
                roleToResourceMap[role] ?  roleToResourceMap[role].push(ALL_ASSIGNEES_KEY) : roleToResourceMap[role] = [ALL_ASSIGNEES_KEY];
            if (obj[plKey]) 
                roleToResourceMap[role] ?  roleToResourceMap[role].push(...obj[plKey]) : roleToResourceMap[role] = obj[plKey];
             
        });
        dispatch(updateRoleResourceMap(roleToResourceMap));
    }, [userPolicyData, productLine]);

    return (
        <>
            <Tabs
                tabs={[
                    {
                        label: "Assign users",
                        id: "assignUser",
                        content: <AssignUsers />
                    },
                    {
                        label: "Attribute ordering",
                        id: "attributesOrdering",
                        content: <AttributeOrdering />
                    }
                ]}
            />
        </>
    );
}