import React, { useContext, useEffect, useState } from "react";
import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import { useAppSelector } from "../../../redux/hooks";
import { selectFinancialInforClientData } from '../financialInfo/financialInfoSlice';
import { selectMarketInforClientData } from '../marketInfo/marketInfoSlice';
import { selectSpecsClientData } from '../specs/specsSlice';
import { selectManufacturingInforClientData } from '../manufacturingInfo/manufacturingInfoSlice';
import { useUpdateProgramSetupMutation, useUpdateProgramStageMutation } from '../../services/api';
import { ALERT_MESSAGES, API_CONDITION_FAILED, MUTATION_METHODS, STAGES_ACTION, STATUS_CODES, TAB_ID, TAB_ID_LABEL, USER_ACTION_ROLES } from '../../constants/constants';
import { IResponsePayload, ISubmitTab } from "../../interfaces/interfaces";
import { IApiUpdateResponse } from "../../../Interfaces/interface";
import AlertMessageContext from "../../../common/Contexts/alertMessageContext";
import ScreenUtils from "../../utils/screenUtils";
import { ProgramAssignUsersModal } from "../userPolicy/program/assignUsersModal";
import { selectLocationData } from "../appLayout/appLayoutSlice";

/**
* This component provides all actions for Financial info, Maket info, Specs, Manufacturing info screens
*/
export const SubmitTab = ({ currentTab, saveDisabled, submitDisabled, approveDisabled, setIsLoading, userRole, section }: ISubmitTab) => {
    const financialInfo = useAppSelector(selectFinancialInforClientData);
    const marketInfo = useAppSelector(selectMarketInforClientData);
    const mfrInfo = useAppSelector(selectManufacturingInforClientData);
    const specs = useAppSelector(selectSpecsClientData);
    const locationData = useAppSelector(selectLocationData);
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [updateProgramSetup, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateProgramSetupMutation();
    const [updateProgramStage, { isLoading: updateStageLoading, data: updateStageData, isError: updateStageError }] = useUpdateProgramStageMutation();
    const [modalVisible, setModalVisible] = useState(false);
    const [pendingStageUpdate, setPendingStageUpdate] = useState<string>('');
    const { getPrimaryKey, getGraphQLPayload } = ScreenUtils;
    const actionDisabled = !locationData.fpn.id && TAB_ID.financialInfo === currentTab;

    useEffect(() => {
        setIsLoading(mutationLoading);
    }, [mutationLoading]);

    useEffect(() => {
        if (!pendingStageUpdate?.length && !updateStageLoading) setIsLoading(updateStageLoading);
    }, [updateStageLoading]);

    const handleMutationResponse = (respData: any, error: boolean, method: string, isStageUpdate: boolean) => {
        if (respData?.errors || error) setError?.(ALERT_MESSAGES.updateFailure);
        if (respData?.data && respData?.data[method]) {
            const { statusCode, error }: IApiUpdateResponse = respData.data[method];
            if(statusCode === STATUS_CODES.success) {
                if( isStageUpdate && pendingStageUpdate?.length) {
                    updateStage(pendingStageUpdate, true);
                    setPendingStageUpdate('');
                } else setSuccess?.(ALERT_MESSAGES.updateSuccess);
            } else error?.includes(API_CONDITION_FAILED) ? setError?.(ALERT_MESSAGES.incorrectVersionFailure) : setError?.(ALERT_MESSAGES.updateFailure);
        }
    };

    useEffect(() => {
        handleMutationResponse(mutationData, mutationError, MUTATION_METHODS.updateProgramSetup, false);
    }, [mutationData, mutationError]);

    useEffect(() => {
        handleMutationResponse(updateStageData, updateStageError, MUTATION_METHODS.updateProgramStage, true);
    }, [updateStageData, updateStageError]);

    const submit = () => {
        setIsLoading(true);
        const allValues: IResponsePayload[] = [];
        const tabsIdAction: Record<string, () => void> = {
            [TAB_ID.financialInfo]: () => allValues.push(...Object.values(financialInfo)),
            [TAB_ID.marketInfo]: () => allValues.push(...Object.values(marketInfo)),
            [TAB_ID.manufacturingInfo]: () => allValues.push(...Object.values(mfrInfo)),
            [TAB_ID.specs]: () => allValues.push(...Object.values(specs))
        };
        tabsIdAction[currentTab]();
        if (allValues.length) {
            const payload = ScreenUtils.generatePayload(allValues);
            if (payload) handleUpdate(payload);
        } else setIsLoading(false);
    };

    const handleUpdate = async (mutationPayload: string) => {
        console.log(mutationPayload);// console added for beta testing
        await updateProgramSetup(mutationPayload).unwrap();
    };

    const updateStage = async (stage: string, invalidateTag: boolean) => {
        setIsLoading(true);
        const payload = {
            InputGroup: TAB_ID_LABEL[currentTab as keyof typeof TAB_ID_LABEL],
            PK: getPrimaryKey(locationData, ''),
            Action: stage
        };
        await updateProgramStage({data: getGraphQLPayload(payload), invalidate: invalidateTag}).unwrap();
    };

    const submitAndAppove = () => {
        updateStage(STAGES_ACTION.submitted, false);
        setPendingStageUpdate(STAGES_ACTION.approved);
    };

    return (
        <>
            <SpaceBetween direction="vertical" size="s">
                <Box display="block" >
                    <Box float='right'>
                        <SpaceBetween direction="horizontal" size="s">
                            {!actionDisabled &&
                                <SpaceBetween direction="horizontal" size="s">
                                    {USER_ACTION_ROLES.save.includes(userRole) && <Button className="bg-primary" disabled={saveDisabled} onClick={submit} variant="primary">Save</Button>}
                                    {USER_ACTION_ROLES.approve.includes(userRole) && <Button className="bg-primary" disabled={approveDisabled} variant="primary" onClick={() => submitAndAppove()} >Push to WIP</Button>}
                                    {USER_ACTION_ROLES.submit.includes(userRole) && <Button className="bg-primary" disabled={submitDisabled} variant="primary" onClick={() => updateStage(STAGES_ACTION.submitted, true)} >Submit for approval</Button>}
                                </SpaceBetween>
                            }
                            {USER_ACTION_ROLES.assignUser.includes(userRole) && !locationData.fpn.id && <Button onClick={() => setModalVisible(true)} >Assign users</Button>}
                        </SpaceBetween>
                    </Box>
                </Box>
            </SpaceBetween>
            {modalVisible && <ProgramAssignUsersModal
                visible={modalVisible}
                setVisible={setModalVisible}
                tab={TAB_ID_LABEL[currentTab as keyof typeof TAB_ID_LABEL]}
                section={section ?? ''}
            />}
        </>

    );

};
