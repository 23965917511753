import React, { useState, useEffect } from 'react';
import { Alert, Container, Header, SpaceBetween, Spinner, Toggle } from "@cloudscape-design/components";
import { IDynamicFieldProps } from '../../../common/DynamicForm/Interfaces';
import ScreenUtils from '../../utils/screenUtils';
import { ALL_ASSIGNEES_KEY, API_RESPONSE_FIELDS, ROLE_DEFAULT_VIEW, SEPERATOR, SK_MAPPING, STAGES, TAB_ID_LABEL, UPDATED_FIELD, USER_ACTION_ROLES } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FINANCIAL_INFO, IFieldMetaData, FIELD_NAMES } from '../../constants/fieldData';
import { selectFinancialInforClientData, updateFinancialInfoClientData, selectFinancialInfoModified, selectFinancialInforServerData } from './financialInfoSlice';
import { selectLocationData, selectRpnToFpnMap } from '../appLayout/appLayoutSlice';
import DynamicForm from '../../../common/DynamicForm/DynamicForm';
import { SubmitTab } from '../programSetup/submitTabAction';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import { ProgramAssignees } from '../userPolicy/program/assigneesList';
import { SANDBOX_UNAPPROVED_MESSAGE, VIEW_WIP_BUTTON } from '../../constants/displayMessages';

export const FinancialInfo = () => {
    const dispatch = useAppDispatch();
    const financialInfo = useAppSelector(selectFinancialInforClientData);
    const locationData = useAppSelector(selectLocationData);
    const hasFPNs = useAppSelector(selectRpnToFpnMap)[locationData.program.id]?.length > 0 ? true : false;
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const isModified = useAppSelector(selectFinancialInfoModified);
    const serverData = Object.values(useAppSelector(selectFinancialInforServerData));
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const { getUserRole } = UserPolicyHelper();
    const [userRole, setUserRole] = useState('');
    const [dynamicFormInput, setDynamicFormInput] = useState<IDynamicFieldProps[][]>();
    const [formMetadata, setFormMetaData] = useState<Record<string, IFieldMetaData>>(FINANCIAL_INFO);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [viewApproved, setViewApproved] = React.useState(false);
    const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
    const SK = TAB_ID_LABEL.financialInfo + SEPERATOR + SK_MAPPING.financialInfo + SEPERATOR;
    const isJustificationRequired = formMetadata[FIELD_NAMES.financialBsnJustification].visibility && !financialInfo?.[FIELD_NAMES.financialBsnJustification]?.ItemValues;
    const stage = serverData?.length ? serverData?.[0].Stage ?? STAGES.sandbox : STAGES.sandbox;
    const isSubmitted = stage === STAGES.submitted;
    const saveDisabled = !isModified || !hasWriteAccess || isJustificationRequired || isSubmitted;
    const submitDisabled = isSubmitted || !hasWriteAccess || serverData.length === 0 || (isModified && serverData.length > 0);

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY, TAB_ID_LABEL.financialInfo, TAB_ID_LABEL.financialInfo + SEPERATOR + SK_MAPPING.financialInfo]);
        setUserRole(currRole);
        if (USER_ACTION_ROLES.write.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    useEffect(() => {
        FINANCIAL_INFO[FIELD_NAMES.financialBsnJustification].visibility = false;
        setFormMetaData({ ...FINANCIAL_INFO });
    }, []);

    const updateState = (fieldValue: any, fieldName: string) => {
        const value = fieldValue.value ?? fieldValue;
        dispatch(updateFinancialInfoClientData(ScreenUtils.updateClientState(value, fieldName, API_RESPONSE_FIELDS.itemValues, financialInfo, PK, SK + fieldName)));
    };

    const onPolicyChange = () => {
        const fMetaData: Record<string, IFieldMetaData> = { ...formMetadata };
        fMetaData[FIELD_NAMES.financialBsnJustification].visibility = false;
        if (financialInfo[FIELD_NAMES.financialRmrsePolicy]?.ItemValues && financialInfo[FIELD_NAMES.financialRmrsePolicy]?.ItemValues != 30) {
            fMetaData[FIELD_NAMES.financialBsnJustification].visibility = true;
        } else if (financialInfo?.[FIELD_NAMES.financialBsnJustification]?.ItemValues) {
            dispatch(updateFinancialInfoClientData(ScreenUtils.updateClientState('', FIELD_NAMES.financialBsnJustification, API_RESPONSE_FIELDS.itemValues, financialInfo, PK, SK + 'Business justification')));
        }
        setFormMetaData(fMetaData);
    };

    useEffect(() => {
        const fMetaData: Record<string, IFieldMetaData> = { ...formMetadata };
        Object.entries(fMetaData).forEach(([field]) => {
            const label = fMetaData[field].label;
            fMetaData[field].actions = updateState;
            fMetaData[field].readonly = viewApproved || !hasWriteAccess || !locationData.fpn.id;
            if (field === FIELD_NAMES.financialRmrsePolicy) fMetaData[field].onBlur = onPolicyChange;
            if (field === FIELD_NAMES.financialBsnJustification && financialInfo?.[FIELD_NAMES.financialBsnJustification]?.ItemValues) fMetaData[field].visibility = true;
            if (label.endsWith(UPDATED_FIELD)) fMetaData[field].label = label.slice(0, label.length - UPDATED_FIELD.length);
            if (!viewApproved && financialInfo[field] && !financialInfo[field].Action && financialInfo[field].ItemValues !== financialInfo[field].ItemValuesWIP) fMetaData[field].label += UPDATED_FIELD;
        });
        const stageView = viewApproved ? STAGES.approved : ROLE_DEFAULT_VIEW[userRole];
        setDynamicFormInput(ScreenUtils.generateDynamicFormInput(financialInfo, fMetaData, stageView));
    }, [financialInfo, formMetadata, hasWriteAccess, userRole, viewApproved, locationData.fpn]);


    const loadFinancialDetails = () => {
        if (dynamicFormInput) return (
            <>
                <SpaceBetween size='l' >
                    {!locationData.fpn.id && <Alert statusIconAriaLabel="Info">
                        Below inputs are expected to be provided at FPN level. {hasFPNs ? <>Please navigate to each FPNs</> : <>Once the FPNs are created, please naviagate to each FPNs</>}
                    </Alert>
                    }
                    <DynamicForm formInput={dynamicFormInput} />
                </SpaceBetween>
            </>
        );
        return "No data to display.";
    };

    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                <SubmitTab currentTab='financialInfo'
                    saveDisabled={saveDisabled}
                    submitDisabled={submitDisabled}
                    approveDisabled={isModified || !ScreenUtils.isSandboxUpdated(financialInfo)}
                    setIsLoading={setIsUpdateLoading}
                    userRole={userRole}
                    section={SK_MAPPING.financialInfo} />
                {!isUpdateLoading ?
                    <>
                        <Container
                            header={
                                <Header
                                    actions={ locationData.fpn.id && 
                                        <Toggle
                                            onChange={({ detail }) => setViewApproved(detail.checked)}
                                            checked={viewApproved}
                                        >
                                            {VIEW_WIP_BUTTON}
                                        </Toggle>
                                    }
                                    variant="h2"
                                    info={<ProgramAssignees
                                        tab={TAB_ID_LABEL.financialInfo}
                                        section={SK_MAPPING.financialInfo}
                                    />}
                                >
                                    Financial information
                                </Header>
                            }
                            footer={<><strong>{UPDATED_FIELD}</strong> {SANDBOX_UNAPPROVED_MESSAGE}</>}
                        >
                            {loadFinancialDetails()}
                        </Container>
                    </> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </SpaceBetween>
        </>

    );
};
