import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import Endpoints from '../../constants/endpoints';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import xhr from '../common/Auth/xhr';

export const VERITY_API_REDUCER_KEY = 'verityApi';

const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<{
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
}> => async (requestOpts) => {
    try {
        const result = await xhr({
            baseURL: baseUrl,
            ...requestOpts
        });
        return { data: result };
    } catch (axiosError) {
        const err = axiosError as AxiosError;
        return { error: { status: err.response?.status, data: err.response?.data } };
    }
};

export const verityApi = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: Endpoints.getBaseUrl() }),
    reducerPath: VERITY_API_REDUCER_KEY,
    tagTypes: ['ProductHierarchy', 'ProgramSetup', 'UserDetails', 'UserPolicy', 'PRMUserPolicy', 'FPNsByRPNList', 'AttributeListFPN',
        'VerityProgramDetails', 'ProductLineDetails', 'ScenarioConfiguration', 'DeviceKey', 'AttributeTypeRPN', 'AttributeListRPN', 
        'AttributeTypeMaster', 'AttributeListMaster', 'VersionsAGM', 'AGMAttrOrder', 'AGMAssortments', 'AGMUserPolicy','AGMSku'],
    keepUnusedDataFor: 0,
    endpoints: () => ({})
});

