import { Button, Spinner } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { STATUS_CODES } from '../../constants/constants';
import { ALERT_MESSAGES } from '../../constants/userMessages';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';

export default function RemoveBtnRenderer({
    removeVersionsHandler,
    addNotificationItem,
    assignedVersions,
    hasWriteAccess,
    node
}: any) {

    const [removing, setRemoving] = useState(false);

    const onClick = async () => {
        try {
            setRemoving(true);
            const response = await removeVersionsHandler(node.data.version_id);
            const { statusCode, error }: IApiUpdateResponse = response.data;
            if (statusCode === STATUS_CODES.success) {
                addNotificationItem({
                    type: "success",
                    dismissible: true,
                    content: ALERT_MESSAGES.versionRemoveSucc,
                    id: "VERSION_REMOVE_SUCCESS"
                });
            } else {
                addNotificationItem({
                    type: "error",
                    dismissible: true,
                    content: statusCode === STATUS_CODES.handledException ? error : ALERT_MESSAGES.versionRemoveErr,
                    id: "VERSION_REMOVE_ERROR"
                });
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: ALERT_MESSAGES.versionRemoveErr,
                id: "VERSION_REMOVE_ERROR"
            });
            setRemoving(false);
        }
    };

    if (assignedVersions.includes(node.data.version_id)) {
        return "SKU and Part-Number already created";
    }

    return (
        <>
            {removing
                ? <Spinner />
                : <Button iconName="remove" variant="icon" onClick={onClick} disabled={!hasWriteAccess} />
            }
        </>
    );
}
