import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const [projCodesData, setProjCodesData] = useState();
    const [framesProjData, setFramesProjData] = useState();
    const queryClient = useQueryClient();

    const fetchProjects = async (reqData: any) => {
        const response = await xhr.post(Endpoints.getBaseUrl(), reqData);
        const { body }: IApiResponse = response.data.data.get_projects;
        return body;
    };

    const fetchframesProjectCodes = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getFramesProj());
        const { body }: IApiResponse = response.data.data.get_frms_proj;
        console.log(body);
        return body;
    };

    const updateProjects = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_projects'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_projects as IApiUpdateResponse;
    };

    const useProjectsData = () => {
        return useQuery(
            ['projects'],
            () => fetchProjects(Queries.getProjectsQuery()),
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
   
    const useProjCodesData = () => {
        return useQuery(
            ['projectCodes'],
            () => fetchProjects(Queries.getProjCodesQuery()),
            {
                onSuccess: (data) => {
                    setProjCodesData(data);
                }
            }
        );
    };

    const useFramesProjData = () => {
        return useQuery(
            ['framesProjData'],
            fetchframesProjectCodes,
            {   
                retry: false,
                onSuccess: (data) => {
                    setFramesProjData(data);
                }
            }
        );
    };
    const useUpdatedProjectsData = () => {
        return useMutation(
            (requestData) => updateProjects(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['projects']);
                }
            }
        );
    };

    return { componentData, useProjectsData, useUpdatedProjectsData, useProjCodesData, projCodesData, framesProjData, useFramesProjData };
};


