import React, { ReactElement, createContext} from "react";
import budgetBaselineHelper from './budgetBaselineHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: void;
    useBudgetBaselineData: () => UseQueryResult;
    useUpdatedBudgetBaselineData: () =>  UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const BudgetBaselineContext = createContext<IProjectsContext>({
    useBudgetBaselineData: () => qResp,
    useUpdatedBudgetBaselineData: () => mResp
});

export const BudgetBaselineContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...budgetBaselineHelper()
    };

    return (
        <BudgetBaselineContext.Provider value={provider}>
            {children}
        </BudgetBaselineContext.Provider>
    );
};
