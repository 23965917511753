import React, { useContext, useEffect, useState } from 'react';
import { SpaceBetween, MultiselectProps } from "@cloudscape-design/components";
import { IFilters, InputPanel } from './inputPanel';
import { useGetDeviceKeySnroQuery, useGetConfigurationQuery } from '../../services/apis/scenarioApi';
import { ConfigTable } from './configTable';
import AlertMessageContext from "../../../common/Contexts/alertMessageContext";
import { ALERT_MESSAGES, ALL_OPTION, STATUS_CODES } from '../../constants/constants';
import { APP_LAYOUT_MESSAGES } from '../../constants/userMessages';
import { IDeviceKey, IScenarioConfig } from '../../interfaces/interfaces';
import { useGetCurrSnrUserPolicyDataQuery } from '../../services/apis/userPolicyApi';
import ScreenUtils from '../../utils/screenUtils';
import { IApiResponse } from 'src/components/Interfaces/interface';
import useNotification from 'src/components/Hooks/notifications';
import { Flashbar, Spinner } from '@amzn/awsui-components-react';

export const ScenarioConfigManager = () => {
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [deviceKeyParams, setDeviceKeyParams] = useState({});
    const [configParams, setConfigParams] = useState({});
    const [scenarios, setScenarios] = useState<Record<string, string>>({});
    const { data: userPolicyData, isError: userPolicyError, isLoading: policyLoading, isFetching: policyFetching } = useGetCurrSnrUserPolicyDataQuery({ userName: ScreenUtils.getUserId() });
    const { data: deviceKeyData, isLoading: deviceKeyLoading, isFetching: deviceKeyFectching, isError, refetch: refetchDeviceKey } = useGetDeviceKeySnroQuery(deviceKeyParams, { skip: !Object.keys(deviceKeyParams).length });
    const { data: configData, isLoading: configLoading, isFetching: configFetching, isError: configError, refetch: refetchConfig } = useGetConfigurationQuery(configParams, { skip: !Object.keys(configParams).length });
    const { setError } = useContext(AlertMessageContext);
    const [policyError, setPolicyError] = useState(false);
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();

    const search = (filters: IFilters) => {
        const deviceKeyParams = {
            country: filters.country?.length ? filters.country.map(item => `${item.value}`) : [ALL_OPTION],
            channel: filters.channel?.length ? filters.channel.map(item => `${item.value}`) : [ALL_OPTION],
            product_line_id: filters.productLine?.value ? [parseInt(filters.productLine.value)] : [],
            rpn_id: filters.rpn?.length ? filters.rpn.map(item => parseInt(item.value!.toString())) : [0]
        };
        const configParams = {
            dataset: filters.dataset?.value ?? '',
            scenario_id: filters.openScenario.map(item => parseInt(item.value!.toString()))
        };
        setDeviceKeyParams(deviceKeyParams);
        setConfigParams(configParams);
        const openScenarios: Record<string, string> = {};
        filters.openScenario.forEach((item: MultiselectProps.Option) => openScenarios[item.value!] = item.label!);
        setScenarios(openScenarios);
    };

    useEffect(() => {
        if (userPolicyData) {
            if (userPolicyData.data?.get_user_access_snro) {
                const { statusCode }: IApiResponse = userPolicyData.data?.get_user_access_snro;
                statusCode === STATUS_CODES.success ? setPolicyError(false) : setPolicyError(true);
            }
            if (userPolicyData.errors || userPolicyError) setPolicyError(true);
        }
    }, [userPolicyData, userPolicyError]);

    useEffect(() => {
        clearNotifications();
        if (policyError) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: APP_LAYOUT_MESSAGES.userPolicyError,
                id: "POLICY_ERROR"
            });
        }
    }, [policyError]);

    useEffect(() => {
        if (!deviceKeyFectching && !configFetching) {
            if (configError || isError || configData === null || deviceKeyData === null) {
                setError?.(ALERT_MESSAGES.fetchFailure);
            } else {
                const results: IDeviceKey[] = [];
                const deviceKeySnroMap: Record<string, number[]> = {};
                if (configData && deviceKeyData) {
                    configData.forEach((obj: IScenarioConfig) => {
                        obj.include_config_list?.forEach((item: number) => {
                            !deviceKeySnroMap[item] ? deviceKeySnroMap[item] = [obj.verity_scenario_id] : deviceKeySnroMap[item].push(obj.verity_scenario_id);
                        });
                    });
                    deviceKeyData.forEach((obj: IDeviceKey) => results.push({ ...obj, scenario_ids: deviceKeySnroMap[obj.device_key] ?? [] }));
                }
                setSearchResults(results);
            }
        } else setSearchResults([]);
    }, [deviceKeyData, configData, configError, isError, deviceKeyFectching, configFetching]);

    const refresh = () => {
        setSearchResults([]);
        refetchDeviceKey();
        refetchConfig();
    };

    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                {(policyLoading || policyFetching) && <div className='loadspinner'><Spinner />{APP_LAYOUT_MESSAGES.policyLoading}</div> }
                <Flashbar items={notificationItems} stackItems/>
                <InputPanel search={search} loading={deviceKeyLoading || deviceKeyFectching || configFetching || configLoading} />
                <ConfigTable results={searchResults} scenarios={scenarios} onRefresh={refresh} />
            </SpaceBetween>
        </>

    );
};
