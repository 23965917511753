import axios, { AxiosInstance } from "axios";
import Endpoints from "src/constants/endpoints";
import { apiConfig, apiConfigProd, apiConfigBeta } from '../../../constants/constants'
import { setupInterceptorsTo } from "./Interceptors";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: Endpoints.getBaseUrl(),
    timeout: 0,
    headers: {}
});
console.log(axiosInstance);
const xhr: AxiosInstance = setupInterceptorsTo(axiosInstance);

export default xhr;