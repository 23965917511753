import React from 'react';
import { FastField, Field, FieldProps } from 'formik';
import PolarisMultiselect, {
  MultiselectProps as PolarisMultiselectProps,
} from '@cloudscape-design/components/multiselect';
import { FormikFieldProps } from '../../field-props';

type MultiselectProps = FormikFieldProps &
  Omit<PolarisMultiselectProps, 'selectedOptions'>;

export const Multiselect: React.FC<MultiselectProps> = ({
  name,
  validate,
  fast,
  onBlur,
  onChange,
  ...props
}: MultiselectProps) => {
  const DesiredField = fast === true ? FastField : Field;

  return (
    <DesiredField name={name} validate={validate} fast={fast}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <PolarisMultiselect
          name={name}
          selectedOptions={value || []}
          onBlur={(event) => {
            setFieldTouched(name);
            onBlur?.(event);
          }}
          onChange={(event) => {
            setFieldValue(name, event.detail.selectedOptions);
            onChange?.(event);
          }}
          {...props}
        />
      )}
    </DesiredField>
  );
};