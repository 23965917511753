import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../redux/store';
import { IResponsePayload } from '../../interfaces/interfaces';

type FinancialInfoState = {
    serverData: Record<string, IResponsePayload>
    clientData: Record<string, IResponsePayload>
  };

const initialState: FinancialInfoState = {
    serverData: {},
    clientData: {}
};

export const financialInfoSlice = createSlice({
    name: 'financialInformation',
    initialState,
    reducers: {
        resetFinancialInfo: (state) => {
            state.serverData = initialState.serverData;
            state.clientData = initialState.clientData;
        },
        discardFinancialInfoUpdates: (state) => {
            state.clientData = state.serverData;
        },
        updateFinancialInfoClientData: (state, action) => {
            state.clientData = action.payload;
        },
        updateFinancialInfoServerData: (state, action) => {
            state.serverData = action.payload;
        }
    }
});

export const { updateFinancialInfoClientData, discardFinancialInfoUpdates, updateFinancialInfoServerData, resetFinancialInfo} = financialInfoSlice.actions;

export default financialInfoSlice.reducer;

export const selectFinancialInforClientData = (state: RootState) => state.financialInfo.clientData;
export const selectFinancialInforServerData = (state: RootState) => state.financialInfo.serverData;
export const selectFinancialInfoModified = (state: RootState) => !_.isEqual(state.financialInfo.clientData, state.financialInfo.serverData);



