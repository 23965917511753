export const  ALL_OPTION = 'ALL';

export const ALL_ASSIGNEES_KEY = '*';

export const SEPERATOR = '#';

export const USER_POLICY_SEPERATOR = '~';

export const PERSON_TYPE = 'PERSON';

export const API_METHODS = {
    get: 'GET',
    post: 'POST'
};

export const SCREEN_PATHS = {
    basePath: '#/verity',
    snroConfig: 'scenario-config'
};

export const HOME_PATH_HEADER = {
    'scenario-config': 'Scenario Configuration Manager'
};

export const MUTATION_METHODS = {
    updateScenarioConfig : 'update_snro_config',
    addUserAccessSnr: 'add_user_access_snro'
};

export const MUTATION_ACTION = {
    addPolicy: "add",
    deletePolicy: "delete"
};

export const STATUS_CODES = {
    success: 200,
    serverError: 500,
    handledException: 400
};

export const ALERT_MESSAGES = {
    updateSuccess: "Successfully updated data",
    updateFailure: "Update failed, please try again!",
    fetchFailure: "Error fetching data"
};

export const USER_ROLES = {
    scenarioConfigMgr: 'Scenario Configuration Manager',
    scmAdmin: 'SCM Admin',
    readonly: 'Readonly'
};

export const DISPLAY_ROLES = {
    scenarioConfigMgr: 'Scenario Configuration Manager',
    readonly: 'Readonly'
};

export const USER_ACTION_ROLES = {
    snrUpdate: [USER_ROLES.scenarioConfigMgr],
    adminPanel: [USER_ROLES.scmAdmin]
};

export const ASYNC_SERVICE_NAME = 'veritydg';