import React, { ReactElement, createContext } from "react";
import productHelper from './productHelper';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";
import { IApiUpdateResponse } from '../Interfaces/interface';

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: [];
    framesProdData?: [];
    useProductData: () => UseQueryResult;
    useFramesProdData: () => UseQueryResult;
    useUpdatedProductsData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const ProductContext = createContext<IProjectsContext>({
    useProductData: () => qResp,
    useFramesProdData: () => qResp,
    useUpdatedProductsData: () => mResp
});

export const ProductContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...productHelper()
    };

    return (
        <ProductContext.Provider value={provider}>
            {children}
        </ProductContext.Provider>
    );
};
