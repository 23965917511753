import React, { ReactElement, createContext} from "react";
import accessoryProjectHelper from './accessoryProjectHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: []; 
    useAccProjectsData: () => UseQueryResult;
    useUpdatedAccProjectsData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const AccessoryProjectContext = createContext<IProjectsContext>({
    useAccProjectsData: () => qResp,
    useUpdatedAccProjectsData: () => mResp
});

export const AccessoryProjectContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...accessoryProjectHelper()
    };

    return (
        <AccessoryProjectContext.Provider value={provider}>
            {children}
        </AccessoryProjectContext.Provider>
    );
};
