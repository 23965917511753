import React, { ReactElement, createContext } from "react";
import expenseUpdateHelper from './expenseUpdateHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";

interface IExpenseUpdateContextProps {
    children: ReactElement | JSX.Element;
}

type IExpenseUpdateContext = {
    componentData?: [];
    useExpenseUpdateData: () => UseQueryResult;
    useUpdatedExpUpdReason: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const ExpenseUpdateContext = createContext<IExpenseUpdateContext>({
    useExpenseUpdateData: () => qResp,
    useUpdatedExpUpdReason: () => mResp
});

export const ExpenseUpdateContextProvider = ({ children }: IExpenseUpdateContextProps): ReactElement => {
    const provider = {
        ...expenseUpdateHelper()
    };

    return (
        <ExpenseUpdateContext.Provider value={provider}>
            {children}
        </ExpenseUpdateContext.Provider>
    );
};
