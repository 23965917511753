import React from 'react';
import { FastField, Field, FieldProps } from 'formik';
import PolarisCheckbox, {
  CheckboxProps as PolarisCheckboxProps,
} from '@cloudscape-design/components/checkbox';
import { FormikFieldProps } from '../../field-props';

type CheckboxProps = FormikFieldProps & Omit<PolarisCheckboxProps, 'checked'>;

export const Checkbox = ({
  name,
  validate,
  fast,
  onBlur,
  onChange,
  ...props
}: CheckboxProps) => {
  const DesiredField = fast === true ? FastField : Field;

  return (
    <DesiredField name={name} validate={validate} fast={fast}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <PolarisCheckbox
          name={name}
          checked={value}
          onBlur={(event) => {
            setFieldTouched(name);
            onBlur?.(event);
          }}
          onChange={(event) => {
            setFieldValue(name, event.detail.checked);
            onChange?.(event);
          }}
          {...props}
        />
      )}
    </DesiredField>
  );
};