import React, { useContext, useEffect, useState } from 'react';
import { SpaceBetween, Modal, Box, Button, MultiselectProps, Spinner } from "@cloudscape-design/components";
import { UserSearchMultiselect } from '../../userSearch/userSearchMultiselect';
import { useAppSelector } from '../../../../redux/hooks';
import { selectLocationData, selectUserDetailsMap } from '../../appLayout/appLayoutSlice';
import { useUpdateProgramSetupMutation } from '../../../services/api';
import { IResponsePayload } from '../../../interfaces/interfaces';
import { selectProgAssignees } from '../userPolicySlice';
import { ALERT_MESSAGES, MUTATION_ACTION, MUTATION_METHODS, PERSON_TYPE, SEPERATOR, STATUS_CODES, USER_ASSIGNEES, USER_ROLES } from '../../../constants/constants';
import ScreenUtils from 'src/components/RPT/utils/screenUtils';
import AlertMessageContext from 'src/components/common/Contexts/alertMessageContext';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import UserPolicyHelper from '../userPolicyHelper';
import { useUpdateUserAccessMutation } from 'src/components/RPT/services/apis/userPolicyApi';

export interface IAssignUserModal {
    visible: boolean
    setVisible: (arg: boolean) => void,
    tab: string,
    section: string,
}

export const ProgramAssignUsersModal = ({
    visible,
    setVisible,
    tab,
    section
}: IAssignUserModal) => {
    const allProgAssignees = useAppSelector(selectProgAssignees);
    const { getProgAssignees, getProgAssigneesRespObj } = UserPolicyHelper();
    const [progAssignees, setProgAssignees] = useState<string[]>([]);
    const [progAssigneesObj, setProgAssigneesObj] = useState<IResponsePayload>({});
    const locationData = useAppSelector(selectLocationData);
    const [updateProgramSetup, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useUpdateProgramSetupMutation();
    const [addUserAccess, { isLoading: addMutationLoading, data: addMutationData, isError: addMutationError }] = useUpdateUserAccessMutation();
    const [deleteUserAccess, { isLoading: deleteMutationLoading, data: deleteMutationData, isError: deleteMutationError }] = useUpdateUserAccessMutation();
    const [mutationsActions, setMutationsActions] = useState<string[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<MultiselectProps.Option[]>([]);
    const userDetailsMap = useAppSelector(selectUserDetailsMap);
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const { getRPNUserPolicyKey, getPLUserPolicyKey, getGraphQLPayload, generatePayload } = ScreenUtils;
    const [metadataPayload, setMetadataPayload] = useState('');

    useEffect(() => {
        setProgAssignees(getProgAssignees(allProgAssignees, USER_ROLES.inputProvider, tab, section));
        setProgAssigneesObj(getProgAssigneesRespObj(allProgAssignees, USER_ROLES.inputProvider, tab, section));
    }, [allProgAssignees]);

    useEffect(() => {
        const values: MultiselectProps.Option[] = [];
        progAssignees?.forEach((alias: string) => {
            if (userDetailsMap[alias]) values.push(userDetailsMap[alias]);
        });
        setSelectedOptions(values);
    }, [userDetailsMap, progAssignees]);

    const submit = () => {
        const metadataRequest: IResponsePayload[] = [];
        const updatedAssignees = selectedOptions.map(item => item.value ?? '');
        const removedAssignees = progAssignees?.filter((alias: string) => !updatedAssignees?.includes(alias)) ?? [];
        const newAssignees = updatedAssignees?.filter((alias: string) => !progAssignees?.includes(alias)) ?? [];
        const actions = [];
        metadataRequest.push(progAssigneesObj ? {
            ...progAssigneesObj,
            ItemValues: updatedAssignees,
            Action: MUTATION_ACTION.update,
            Changes: progAssigneesObj.ItemValues.toString()
        } : {
            PK: ScreenUtils.getPrimaryKey(locationData, ''),
            SK: USER_ASSIGNEES + SEPERATOR + USER_ROLES.inputProvider + SEPERATOR + tab + SEPERATOR + section,
            ItemValues: updatedAssignees,
            Action: MUTATION_ACTION.put,
            Version: 0
        });
        const payloadItem = {
            roles: [{ name: USER_ROLES.inputProvider }],
            resources: {
                name: getPLUserPolicyKey(locationData.productLine.id) + SEPERATOR + getRPNUserPolicyKey(locationData.program.id),
                resources: { name:  tab + SEPERATOR + section }
            }
        };
        if (newAssignees.length) {
            const users = newAssignees.map(alias => {
                return { type: PERSON_TYPE, value: alias };
            });
            const addPayload = {
                action: MUTATION_ACTION.addPolicy,
                item: { ...payloadItem, users: users }
            };
            actions.push(MUTATION_ACTION.addPolicy);
            userPolicyUpdate(getGraphQLPayload(addPayload), MUTATION_ACTION.addPolicy);
        }
        if (removedAssignees.length) {
            const users = removedAssignees.map((alias: string) => {
                return { type: PERSON_TYPE, value: alias };
            });
            const deletePayload = {
                action: MUTATION_ACTION.deletePolicy,
                item: { ...payloadItem, users: users }
            };
            actions.push(MUTATION_ACTION.deletePolicy);
            userPolicyUpdate(getGraphQLPayload(deletePayload), MUTATION_ACTION.deletePolicy);
        }
        setMutationsActions(actions);
        if (metadataRequest.length) setMetadataPayload(generatePayload(metadataRequest) ?? '');

    };

    const metadataUpdate = async (mutationPayload: string) => {
        console.log(mutationPayload);// console added for beta testing
        await updateProgramSetup(mutationPayload).unwrap();
    };

    const userPolicyUpdate = async (mutationPayload: string, action: string) => {
        console.log(mutationPayload);// console added for beta testing
        action === MUTATION_ACTION.deletePolicy ? await deleteUserAccess(mutationPayload).unwrap() : await addUserAccess(mutationPayload).unwrap();
    };

    const isSuccess = (deleteStatus: number, addStatus: number) => {
        return (mutationsActions.length === 1 && ((mutationsActions.includes(MUTATION_ACTION.deletePolicy) && deleteStatus && deleteStatus === STATUS_CODES.success) ||
            (mutationsActions.includes(MUTATION_ACTION.addPolicy) && addStatus && addStatus === STATUS_CODES.success)))
            || (addStatus && deleteStatus && deleteStatus === STATUS_CODES.success && addStatus === STATUS_CODES.success);
    };
    const displayError = () => {
        setError?.(ALERT_MESSAGES.updateFailure);
        setVisible(false);
    };

    useEffect(() => {
        if (addMutationData?.errors || addMutationError || deleteMutationData?.errors || deleteMutationError) displayError(); 
        if (!deleteMutationLoading && !addMutationLoading && (deleteMutationData?.data?.[MUTATION_METHODS.updateUserAccess] || addMutationData?.data?.[MUTATION_METHODS.updateUserAccess])) {
            const { statusCode: addStatus }: IApiUpdateResponse = addMutationData?.data?.[MUTATION_METHODS.updateUserAccess] ?? {};
            const { statusCode: deleteStatus }: IApiUpdateResponse = deleteMutationData?.data?.[MUTATION_METHODS.updateUserAccess] ?? {};
            isSuccess(deleteStatus, addStatus) ? metadataUpdate(metadataPayload) : displayError();
        }
    }, [addMutationLoading, addMutationError, deleteMutationLoading, deleteMutationData, addMutationData, deleteMutationError]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) setError?.(ALERT_MESSAGES.updateFailure);
            if (mutationData.data?.[MUTATION_METHODS.updateProgramSetup]) {
                const { statusCode }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateProgramSetup];
                statusCode === STATUS_CODES.success ? setSuccess?.(ALERT_MESSAGES.updateSuccess) : setError?.(ALERT_MESSAGES.updateFailure);
            }
        }
    }, [mutationData, mutationError]);

    return (
        <>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={submit} variant="primary">Assign</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Assign input providers"
            >
                {mutationLoading || addMutationLoading || deleteMutationLoading ? <div className='loadspinner mg-top-md'><Spinner size="normal" /></div> : <SpaceBetween size='l'>
                    <UserSearchMultiselect
                        name="Assign to"
                        selectedValues={selectedOptions}
                        label="Assign to"
                        readOnly={false}
                        action={setSelectedOptions} />
                </SpaceBetween>}

            </Modal>

        </>
    );
};
