import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const queryClient = useQueryClient();

    const fetchAccProjects = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getAccProjQuery());
        const { body }: IApiResponse = response.data.data.get_acc_proj;
        return body;
    };

    const updateAccProjects = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_acc_proj'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_acc_proj as IApiUpdateResponse;
    };

    const useAccProjectsData = () => {
        return useQuery(
            ['accessoryProject'],
            fetchAccProjects,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useUpdatedAccProjectsData = () => {
        return useMutation(
            (requestData) => updateAccProjects(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['accessoryProject']);
                }
            }
        );
    };
   
    return { componentData, useAccProjectsData, useUpdatedAccProjectsData };
};


