export default () => {

    const seriesDataLabelFormatter = (pointData: Record<string, any>, titles: Record<string, string>, subtitles: Record<string, string>) => {
        if (pointData.isParent) return '';
        let titleStr = [pointData.program];
        let subtitleStr: string[] = [];
        const pointWidth = pointData.shapeArgs.width - 10;
        if (titles) titleStr.push(...Object.keys(titles)?.map((field: string) => pointData[field]?.value ?? '') ?? []);
        if (subtitles) subtitleStr.push(...Object.entries(subtitles).map(([field, value]) => `<span class="subtitle-field"><em>${value}</em> : <span style="font-weight: 200"> ${pointData[field]?.value ?? ''}</span></span>` ?? []));
        return `
            <span class="series-label-content" style="width: ${pointWidth}px;">
                <span class="label-title">  ${titleStr.join('<span style="font-weight: 200"> | </span>')} </span>
                <span class="label-sub-title">  ${subtitleStr.join('')} </span>  
            </span>`;
    }

    const xAxisQuaterFormatter = (value: number|string) => {
        try {
            const providedLocalDate = new Date(value);
            const realDate = new Date(providedLocalDate.getTime() + (providedLocalDate.getTimezoneOffset() * 60 * 1000));
            const quarterNumber = Math.floor((realDate.getMonth() + 3) / 3);
            return 'Q' + quarterNumber;
        } catch (error) {
            return '';
        }
        
    }

    return { seriesDataLabelFormatter, xAxisQuaterFormatter }
}