import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'cost_center_group': 'Cost Center Group'
};
export const COL_DEFS : (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Cost Center Group",
        field: "cost_center_group",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Long Label",
        field: "long_label",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Short Label",
        field: "short_label",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Description",
        field: "description",
        editable: true,
        valueParser: GridValidation.trimValue
    },
];