export const DEFAULT_HEADER = 'ASIN Generation Manager';

export const ERROR_MESSAGE = "Oh no, Something went wrong!";

export const ALERT_MESSAGES = {
    updateSuccess: "Successfully updated data",
    updateFailure: "Update failed, please try again!",
    versionSuccess: "Versions added successfully",
    versionError: "Failed to add Versions",
    versionRemoveSucc: 'Version removed succesfully',
    versionRemoveErr: 'Unable to remove version',
    attrOrderSuccess: "Attributes updated successfully",
    attrOrderFailure: "Failed to update attributes",
    userPolicyGetError : 'Error fetching user permissions.',
    addPolicySuccess: 'Successfully assigned user permission',
    addPolicyError: 'Error assigning user permission',
    removePolicySuccess: 'Successfully removed user permission',
    removePolicyError: 'Error removing user permission',
    pplsIdMissing: "This program doesn't exist in PPLS. Please create it first to make updates. In order to request part number, please also create it in AGILE.",
    pplsIdMissingNoAccess: "This program doesn't exist in PPLS. Please request Admin access <a href='https://t.corp.amazon.com/create/options?category=DaS-FinTech&type=Verity2.0&item=User%20Access&group=Planning-Roadmap2' target='_blank' rel='noopener noreferrer'>HERE</a> or contact the corresponding PL Admin.",
    pplsButtonRemoved: "This program doesn't exist in PPLS. 'Create Program in PPLS' function has been moved to PRM.",
};

export const APP_LAYOUT_MESSAGES = {
    authError : 'Error: Please reload screen to view assigned programs',
    policyLoading: 'Fetching assigned programs...',
    progLoading: 'Loading programs...',
    updatingProg: 'Updating programs...',
    progLoadingError: 'Error loading programs. Please reload and try again',
    userPolicyError: 'Error fetching assigned programs. Please reload and try again'
};

export const ACCESS_DENIED_MESSAGES = {
    adminPanel: "You don't have admin permissions to view this page. Please create a SIM ticket to request access."
};