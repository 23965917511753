import Queries from './queries/attributesQueries';
import CommonQueries from './queries/queries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../api';

const { getAttrListMaster, getAttrListRPN, getAttrTypeMaster, getAttrTypePL,getAttrListFPN,
    getAttrTypeRPN, getPlFPNRule, getRpnFPNRule} = Queries;
const { getUpdateQuery } = CommonQueries;

export const attributesAPIs = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getAttributeTypeMaster: build.query<any, void>({
            query: () => ({
                method: API_METHODS.post, data: {
                    "query": getAttrTypeMaster(),
                    "variables": { "is_deprecated": false }
                }
            }),
            providesTags: () => [{ type: 'AttributeTypeMaster' }],
            transformResponse: (response: any) => response.data.data?.get_attr_type_master?.body ?? null
        }),
        getAttributeTypePL: build.query({
            query: ({ verity_product_line_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getAttrTypePL(),
                    "variables": { "is_deprecated": false, "verity_product_line_id": verity_product_line_id }
                }
            }),
            transformResponse: (response: any) => response.data.data?.get_attribute_type_pl?.body ?? null
        }),
        getAttributeTypeRPN: build.query({
            query: ({ verity_rpn_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getAttrTypeRPN(),
                    "variables": { "is_deprecated": false, "verity_rpn_id": verity_rpn_id }
                }
            }),
            providesTags: () => [{ type: 'AttributeTypeRPN' }],
            transformResponse: (response: any) => response.data.data?.get_attr_type_rpn?.body ?? null
        }),
        getAttributeListMaster: build.query<any, void>({
            query: () => ({
                method: API_METHODS.post, data: {
                    "query": getAttrListMaster(),
                    "variables": { "is_deprecated": false }
                }
            }),
            providesTags: () => [{ type: 'AttributeListMaster' }],
            transformResponse: (response: any) => response.data.data?.get_attr_list_master?.body ?? null
        }),
        getAttributeListRPN: build.query({
            query: ({ verity_rpn_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getAttrListRPN(),
                    "variables": { "is_deprecated": false, "verity_rpn_id": verity_rpn_id }
                }
            }),
            providesTags: () => [{ type: 'AttributeListRPN' }],
            transformResponse: (response: any) => response.data.data?.get_attr_list_rpn?.body ?? null
        }),
        getAttributeListFPN: build.query({
            query: ({ verity_fpn_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getAttrListFPN(),
                    "variables": { "is_deprecated": false, "verity_fpn_id": verity_fpn_id }
                }
            }),
            providesTags: () => [{ type: 'AttributeListFPN' }],
            transformResponse: (response: any) => response.data.data?.get_attr_list_fpn?.body ?? null
        }),
        getPlFPNRule: build.query({
            query: ({ verity_product_line_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getPlFPNRule(),
                    "variables": { "is_deprecated": false, "verity_product_line_id": verity_product_line_id }
                }
            }),
            transformResponse: (response: any) => response.data.data?.get_pl_fpn_rule?.body ?? null
        }),
        getRpnFPNRule: build.query({
            query: ({ verity_rpn_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getRpnFPNRule(),
                    "variables": { "is_deprecated": false, "verity_rpn_id": verity_rpn_id }
                }
            }),
            transformResponse: (response: any) => response.data.data?.get_rpn_fpn_rule?.body ?? null
        }),
        updateAttrTypeMaster: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAttrTypeMaster) }),
            invalidatesTags: () => [{ type: 'AttributeTypeMaster' }],
            transformResponse: (response: any) => response.data
        }),
        updateAttrListMaster: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAttrListMaster) }),
            invalidatesTags: () => [{ type: 'AttributeListMaster' }],
            transformResponse: (response: any) => response.data
        }),
        updateAttrTypeRPN: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAttrTypeRPN) }),
            invalidatesTags: () => [{ type: 'AttributeTypeRPN' }],
            transformResponse: (response: any) => response.data
        }),
        updateAttrListRPN: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAttrListRPN) }),
            invalidatesTags: () => [{ type: 'AttributeListRPN' }],
            transformResponse: (response: any) => response.data
        }),
        updateAttrListFPN: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateAttrListFPN) }),
            invalidatesTags: () => [{ type: 'AttributeListFPN' }],
            transformResponse: (response: any) => response.data
        }),
        updateRpnFpnRule: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQuery(data, MUTATION_METHODS.updateRpnFpnRule) }),
            transformResponse: (response: any) => response.data
        })
    })
});

export const {
    useGetAttributeListMasterQuery,
    useGetAttributeListRPNQuery,
    useGetAttributeTypeMasterQuery,
    useGetAttributeTypePLQuery,
    useGetAttributeTypeRPNQuery,
    useGetAttributeListFPNQuery,
    useGetPlFPNRuleQuery,
    useGetRpnFPNRuleQuery,
    useUpdateAttrListMasterMutation,
    useUpdateAttrTypeMasterMutation,
    useUpdateAttrListRPNMutation,
    useUpdateAttrTypeRPNMutation,
    useUpdateAttrListFPNMutation,
    useUpdateRpnFpnRuleMutation
} = attributesAPIs;