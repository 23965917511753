import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Flashbar, FormField, Grid, Input, MultiselectProps, Select, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetAGMUserPolicyDataQuery, useUpdateAGMUserAccessMutation, useGetAGMOtherUserPolicyDataQuery } from '../../../services/apis/userPolicyApi';
import { IApiResponse, IApiUpdateResponse, IValueDescription } from "../../../../Interfaces/interface";
import { ALL_ASSIGNEES_KEY, MUTATION_ACTION, MUTATION_METHODS, PERSON_TYPE, SEPERATOR, STATUS_CODES, USER_ACTION_ROLES, USER_POLICY_SEPERATOR, USER_ROLES, USER_ROLE_DESCRIPTIONS } from "../../../constants/constants";
import { IApiProductHierarchy } from "../../../interfaces/interfaces";
import ScreenUtils from "../../../utils/screenUtils";
import { ExistingPermissions, IPermissions } from './existingAccessTable';
import { selectAllProductLines, selectAllRPNs, selectProductHierarchy } from "../../appLayout/appLayoutSlice";
import { selectUserPolicy } from "../userPolicySlice";
import { ACCESS_DENIED_MESSAGES, ALERT_MESSAGES } from "../../../constants/userMessages";
import useNotification from "src/components/Hooks/notifications";

export const AGMAdminPanel = () => {
    const [userAlias, setUserAlias] = useState("");
    const [searchAlias, setSearchAlias] = useState("");
    const plIdNameMap = useAppSelector(selectAllProductLines);
    const rpnNameMap = useAppSelector(selectAllRPNs);
    const policyData = useAppSelector(selectUserPolicy);
    const prodHierarchy = useAppSelector(selectProductHierarchy);
    const [existingPermissions, setExistingPermissions] = useState<IPermissions[]>([]);
    const [selectedRole, setSelectedRole] = useState<MultiselectProps.Option>({});
    const [selectedPL, setSelectedPL] = useState<MultiselectProps.Option>({});
    const [plOptions, setPlOptions] = useState<MultiselectProps.Option[]>([]);
    const [hasAccess, setHasAccess] = useState(false);
    const { getPLUserPolicyKey, getGraphQLPayload, getUserId } = ScreenUtils;
    const { data: userPolicyData, isError: userPolicyError, isLoading: policyLoading, isFetching: policyFetching, refetch } = useGetAGMOtherUserPolicyDataQuery({ userName: searchAlias }, { skip: !searchAlias });
    const { isLoading: currUserAccessLoading, isFetching: currUserAccessFetching } = useGetAGMUserPolicyDataQuery({ userName: getUserId() });
    const [addUserAccess, { isLoading: addMutationLoading, data: addMutationData, isError: addMutationError }] = useUpdateAGMUserAccessMutation();
    const [deleteUserAccess, { isLoading: deleteMutationLoading, data: deleteMutationData, isError: deleteMutationError }] = useUpdateAGMUserAccessMutation();
    const { notificationItems, addNotificationItem, clearNotifications } = useNotification();

    // ASIN Owner role is assigned at PL level or all PLs (*)
    const rolesWithDescriptions: IValueDescription = {
        [USER_ROLES.asinOwner]: USER_ROLE_DESCRIPTIONS.asinOwner,
        [USER_ROLES.agmAdmin]: USER_ROLE_DESCRIPTIONS.agmAdmin,
    };
    const plValidRoles = [USER_ROLES.asinOwner];

    useEffect(() => {
        USER_ACTION_ROLES.adminPanel.some(role => policyData?.[role]) ? setHasAccess(true) : setHasAccess(false);
    }, [policyData]);

    const isAssignDisabled = () => {
        if (searchAlias !== userAlias) return true;
        if (selectedRole?.value) {
            if (plValidRoles.includes(selectedRole?.value) && !selectedPL?.value) return true;
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (prodHierarchy) {
            const plOpts: MultiselectProps.Option[] = [{ label: 'ALL', value: ALL_ASSIGNEES_KEY, description: "All Product lines will get assigned with the role." }];
            prodHierarchy.forEach(({ product_line, verity_product_line_id }: IApiProductHierarchy) => {
                if (product_line && verity_product_line_id) {
                    plOpts.push({ label: product_line, value: verity_product_line_id.toString() });
                }
            });
            setPlOptions(plOpts);
        }
    }, [prodHierarchy]);

    useEffect(() => {
        const items: IPermissions[] = [];
        let count = 1;
        let hasError = false;
        if (userPolicyData?.data?.get_user_access_agm) {
            const { statusCode, body }: IApiResponse = userPolicyData.data?.get_user_access_agm;
            if (statusCode === STATUS_CODES.success) {
                body?.forEach((obj: any) => {
                    if (obj.roles?.length && obj.resource?.name) {
                        obj.roles.forEach((roleObj: any) => {
                            const role = roleObj.name;
                            // e.g resource name : PL~129
                            const [pl] = obj.resource.name.split(SEPERATOR);
                            const [, plId] = pl?.split(USER_POLICY_SEPERATOR) ?? [];
                            const item = {
                                id: count++,
                                role: role,
                                pl: plId ? plIdNameMap?.[plId] ?? '' : ALL_ASSIGNEES_KEY,
                                resource: {
                                    resource: obj.resource,
                                    role: roleObj
                                }
                            };
                            items.push(item);
                        });
                    }
                });
                setExistingPermissions(items);
            } else hasError = true;
        }
        if (userPolicyData?.errors || userPolicyError) hasError = true;
        if (hasError) addNotificationItem({
            type: "error",
            dismissible: true,
            content:  ALERT_MESSAGES.userPolicyGetError,
            id: "USER_POLICY_ERROR"
        });
    }, [userPolicyData]);

    const assign = async () => {
        const userPolicyResources: any[] = [];
        if (!selectedPL.value || selectedPL.value === ALL_ASSIGNEES_KEY) {
            userPolicyResources.push({
                name: ALL_ASSIGNEES_KEY
            });
        } else {
            userPolicyResources.push({
                name: getPLUserPolicyKey(selectedPL.value)
            });
        }
        if (userPolicyResources.length) {
            const addPayload = {
                action: MUTATION_ACTION.addPolicy,
                item: {
                    roles: [{ name: selectedRole.value }],
                    resources: userPolicyResources,
                    users: { type: PERSON_TYPE, value: userAlias }
                }
            };
            await addUserAccess(getGraphQLPayload(addPayload)).unwrap();
        }
    };

    const removeAccess = async (res: any) => {
        if (res.resource && res.role) {
            const deletePayload = {
                action: MUTATION_ACTION.deletePolicy,
                item: {
                    roles: [res.role],
                    resources: res.resource,
                    users: { type: PERSON_TYPE, value: userAlias }
                }
            };
            await deleteUserAccess(getGraphQLPayload(deletePayload)).unwrap();
        }
    };

    const handleResp = (resp: any, error: boolean, action: string) => {
        const errMsg = action === MUTATION_ACTION.deletePolicy ? ALERT_MESSAGES.removePolicyError : ALERT_MESSAGES.addPolicyError;
        const succMsg = action === MUTATION_ACTION.deletePolicy ? ALERT_MESSAGES.removePolicySuccess : ALERT_MESSAGES.addPolicySuccess;
        if (resp?.errors || error) addNotificationItem({
            type: "error",
            dismissible: true,
            content: errMsg,
            id: "POLICY_ERROR"
        });
        if (resp?.data && resp?.data?.[MUTATION_METHODS.updateUserPolicy]) {
            const { statusCode, error }: IApiUpdateResponse = resp.data[MUTATION_METHODS.updateUserPolicy];
            statusCode === STATUS_CODES.success ? addNotificationItem({
                type: "success",
                dismissible: true,
                content: succMsg,
                id: "POLICY_SUCCESS"
            }) : addNotificationItem({
                type: "error",
                dismissible: true,
                content: statusCode === STATUS_CODES.handledException ? error : errMsg,
                id: "POLICY_ERROR"
            });
            refetch();
        }
    };
    useEffect(() => {
        handleResp(addMutationData, addMutationError, MUTATION_ACTION.addPolicy);
    }, [addMutationData, addMutationError]);

    useEffect(() => {
        handleResp(deleteMutationData, deleteMutationError, MUTATION_ACTION.deletePolicy);
    }, [deleteMutationData, deleteMutationError]);

    return (
        <>
            {(currUserAccessLoading && currUserAccessFetching) ? <Spinner /> :
                <>
                    {hasAccess ?
                        <SpaceBetween size="xl">
                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                                <FormField label="User alias">
                                    <Input
                                        value={userAlias}
                                        placeholder="Enter user alias to fetch permissions"
                                        onChange={event =>
                                            setUserAlias(event.detail.value)
                                        }
                                    />
                                </FormField>
                                <FormField label="&nbsp; ">
                                    <Button
                                        iconName="search"
                                        variant="primary"
                                        onClick={() =>  {
                                            setSearchAlias(userAlias);
                                            setSelectedRole({});
                                            setSelectedPL({});
                                            clearNotifications();
                                        }}>
                                    </Button>
                                </FormField>
                            </Grid>
                            <Flashbar items={notificationItems} stackItems />
                            {(policyLoading || policyFetching || deleteMutationLoading) ? <><Spinner /></> :
                                <>
                                    {searchAlias.length > 0 &&
                                        <>
                                            <Container>
                                                <SpaceBetween size="xl">
                                                    <Grid gridDefinition={[{ colspan: 4 }]}>
                                                        <FormField label="Role">
                                                            <Select
                                                                selectedOption={selectedRole}
                                                                onChange={({ detail }) => {
                                                                    setSelectedRole(detail.selectedOption);
                                                                    setSelectedPL({});
                                                                }
                                                                }
                                                                options={ScreenUtils.generateOptionsWithDescription(rolesWithDescriptions)}
                                                            />
                                                        </FormField>
                                                    </Grid>
                                                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
                                                        {(selectedRole?.value && plValidRoles.includes(selectedRole.value)) && <FormField label="Product line">
                                                            <Select
                                                                selectedOption={selectedPL}
                                                                filteringType="auto"
                                                                onChange={({ detail }) =>
                                                                    setSelectedPL(detail.selectedOption)
                                                                }
                                                                options={plOptions}
                                                            />
                                                        </FormField>
                                                        }

                                                    </Grid>
                                                    <FormField>
                                                        <Button disabled={isAssignDisabled()} loading={addMutationLoading} variant="primary" onClick={assign}>Assign</Button>
                                                    </FormField>

                                                </SpaceBetween>
                                            </Container>
                                            <Container footer="* indicates All">
                                                < ExistingPermissions tableItems={existingPermissions} removeAccess={removeAccess} />
                                            </Container>
                                        </>
                                    }
                                </>
                            }
                        </SpaceBetween> :
                        <>
                            <Alert type="error" header="Access Denied">
                                {ACCESS_DENIED_MESSAGES.adminPanel}
                            </Alert>
                        </>
                    }
                </>
            }
        </>
    );
};