import React, { useEffect, useState } from 'react';
import { Button, ColumnLayout, Container, FormField, Header, Input, SpaceBetween, Flashbar } from '@amzn/awsui-components-react';
import ScreenUtils from '../../utils/screenUtils';
import useNotification from 'src/components/Hooks/notifications';
import { SNAPSHOT_MESSAGES } from '../../constants/displayMessages';
import Endpoints from 'src/constants/endpoints';
import { ASYNC_SERVICE_NAME } from 'src/components/Verity/constants/constants';
import { HttpMethod, acknowledge, process } from 'src/components/common/AsyncHandler/WebSocketHandler';
import { getUserId } from 'src/components/common/Auth/Auth';
import { statusCodes } from 'src/constants/constants';
import { useAsyncResponse } from 'src/components/Hooks/AsynResponseState';


export const CreateSnapshot = () => {
    const [scenarioName, setScenarioName] = React.useState('');
    const { notificationItems, addNotificationItem } = useNotification();
    const { getNotificationItem } = ScreenUtils;
    const [isLoading, setIsLoading] = useState(false);
    const [asyncResponse, setAsyncResponse] = useState<any>({});
    const { setCurrentProcessId, getCurrentProcessId, deleteCurrentProcessId } = useAsyncResponse();

    const save = async () => {
        if (scenarioName) {
            setIsLoading(true);
            const processResponse = await process(
                Endpoints.getSnapshotCreateUrl(),
                HttpMethod.Post,
                ASYNC_SERVICE_NAME,
                {
                    latestStatus: '',
                    response: {}
                },
                setAsyncResponse,
                getUserId() || '',
                `${getUserId()}@amazon.com` || '',
                {
                    target: { scenario_name: scenarioName }
                }
            );
            setCurrentProcessId('CREATE_SNAPSHOT', processResponse?.processId);
        }
    }

    useEffect(() => {
        try {
            const processID = getCurrentProcessId('CREATE_SNAPSHOT');
            if (processID && asyncResponse?.response?.statusCode) {
                if (asyncResponse.response.statusCode == statusCodes.success) {
                    addNotificationItem(getNotificationItem('success', 'SNAPSHOT_SUCCESS', SNAPSHOT_MESSAGES.snapshotCreateSuccess));
                } else {
                    addNotificationItem(getNotificationItem('error', 'SNAPSHOT_ERROR', SNAPSHOT_MESSAGES.snapshotCreateError));
                }
                acknowledge(processID);
                deleteCurrentProcessId('CREATE_SNAPSHOT');
                setIsLoading(false);
            }
        } catch (error) {
            addNotificationItem(getNotificationItem('error', 'SNAPSHOT_ERROR', SNAPSHOT_MESSAGES.snapshotCreateError));
            setIsLoading(false);
        }
    }, [asyncResponse]);

    return (

        <Container
            header={
                <Header variant='h2'>
                    Create snapshot
                </Header>
            }
        >
            <SpaceBetween size='xxl'>
                <Flashbar items={notificationItems} stackItems />
                <ColumnLayout columns={3}>
                    <FormField
                        label='Scenario'
                    >
                        <Input
                            onChange={({ detail }) => setScenarioName(detail.value)}
                            value={scenarioName}
                            ariaLabel='scenario-name'
                        />
                    </FormField>
                </ColumnLayout>
                <Button className='bg-primary'
                    ariaLabel='create-snapshot'
                    disabled={!scenarioName.length}
                    loading={isLoading}
                    onClick={save}
                    variant='primary'
                    data-testid='CreateSnapshotButton'
                    >
                    Create snapshot</Button>
            </SpaceBetween>
        </Container>

    );
};