import React, { useState } from 'react';
import { SpaceBetween, Modal, Box, Button, MultiselectProps, TextContent } from "@cloudscape-design/components";
import { UserSearchMultiselect } from '../../userSearch/userSearchMultiselect';

export interface IAssignUserModal {
    visible: boolean
    setVisible: (arg: boolean) => void,
    submit: (options: MultiselectProps.Option[]) => void,
    selectedSections: string
}

export const AssignUsersModal = ({
    visible,
    setVisible,
    submit,
    selectedSections
}: IAssignUserModal) => {
    const [selectedOptions, setSelectedOptions] = useState<MultiselectProps.Option[]>([]);
    return (
        <>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={() => submit(selectedOptions)} variant="primary">Assign</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Assign input providers"
            >
                <SpaceBetween size='l'>
                    <TextContent>
                        Selected sections: {selectedSections}
                    </TextContent>
                    <UserSearchMultiselect
                        name="Assign to"
                        selectedValues={selectedOptions}
                        label="Assign to"
                        readOnly={false}
                        action={setSelectedOptions} />
                </SpaceBetween>
            </Modal>

        </>
    );
};
