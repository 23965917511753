import React from 'react';
import { useField, useFormikContext } from 'formik';
import PolarisFormField, {
  FormFieldProps as PolarisFormFieldProps,
} from "@cloudscape-design/components/form-field";

interface FormFieldProps extends PolarisFormFieldProps {
  name: string;
  /**
   * Show errors even if form has not been submitted.
   */
  showInstantFeedback?: boolean;
}

/**
 * Form field that shows errors based on https://polaris.a2z.com/design/patterns/validation/
 */
export const FormField = ({
  name,
  showInstantFeedback,
  ...props
}: FormFieldProps) => {
  const [, meta] = useField(name);
  const { submitCount } = useFormikContext();

  const showFeedback = submitCount > 0 || showInstantFeedback;
  // error is not always a string https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas
  const errorText =
    meta.touched && meta.error && typeof meta.error === 'string' && showFeedback
      ? meta.error
      : '';

  return <PolarisFormField errorText={errorText} {...props} />;
};