import React, { useEffect, useState } from 'react';
import { SpaceBetween, Multiselect, FormField, Modal, Box, Button, MultiselectProps, TextContent } from "@cloudscape-design/components";

export interface IRemoveAssignee {
    section: string,
    assignees: MultiselectProps.Option[],
    submit: (section: string, allAssignees: MultiselectProps.Option[], updatedAssignees: MultiselectProps.Options) => void,
}

export const RemoveAssignee = ({
    section,
    assignees,
    submit
}: IRemoveAssignee) => {
   
    const [visible, setVisible] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<MultiselectProps.Options>([]);
    
    useEffect(() => {
        setSelectedOptions(assignees);
    }, [assignees]);

    return (
        <>
            <Button onClick={() => setVisible(true)} variant="link" > Remove assignee</Button>
            <Modal
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisible(false)} variant="link">Cancel</Button>
                            <Button onClick={() => submit(section, assignees, selectedOptions)} variant="primary">Save</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Remove assignee"
            >
                <SpaceBetween size='l'>
                    <TextContent>
                        Selected section: {section}
                    </TextContent>
                    <FormField
                        label="Assign to"
                    >
                        <Multiselect
                            name="Assign to"
                            selectedOptions={selectedOptions}
                            onChange={({ detail }) =>
                                setSelectedOptions(detail.selectedOptions)
                            }
                            options={assignees}
                        />
                    </FormField>

                </SpaceBetween>
            </Modal>


        </>
    );
};
