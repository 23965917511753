import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, FormField, Multiselect, Select, SelectProps, SpaceBetween, MultiselectProps, Button, Alert, Grid } from "@cloudscape-design/components";
import { useAppSelector } from '../../../redux/hooks';
import { IRPNs, IScenarioMD, IProductLine, IScenario } from '../../interfaces/interfaces';
import { useGetProductLinesQuery, useGetRPNsQuery } from '../../services/apis/commonApi';
import { useGetDatasetQuery, useGetOpenScenariosQuery } from '../../services/apis/scenarioApi';
import { selectCountryMap } from '../appLayout/appLayoutSlice';

export interface IFilters {
    dataset: SelectProps.Option | null;
    openScenario: MultiselectProps.Option[];
    productLine: SelectProps.Option | null;
    rpn: MultiselectProps.Option[];
    country: MultiselectProps.Option[];
    channel: MultiselectProps.Option[];
}

export interface IInputPanel { 
    search: (value: any) => void;
    loading: boolean;
}
export const InputPanel = ({ search, loading }: IInputPanel) => {
    const countryNameMap = useAppSelector(selectCountryMap);
    const [formFilters, setFormFilters] = useState<IFilters>({
        dataset: null,
        openScenario: [],
        productLine: null,
        rpn: [],
        country: [],
        channel: []
    });
    const { data: dataSetData, isLoading: dataSetLoading, isError: dataSetError } = useGetDatasetQuery();
    const { data: plData, isLoading: plLoading, isError: plError } = useGetProductLinesQuery();
    const { data: rpnData, isLoading: rpnLoading, isError: rpnError } = useGetRPNsQuery();
    const { data: scenariosData, isLoading: scenariosLoading, isFetching: scenariosFetching, isError: scenariosError } = useGetOpenScenariosQuery({dataset: formFilters.dataset?.value} ?? '', { skip: !formFilters.dataset?.value });
    const [countryOpts, setCountryOpts] = useState<SelectProps.Option[]>([]);
    const [dataSetOpts, setdataSetOpts] = useState<SelectProps.Option[]>([]);
    const [plOpts, setPlOpts] = useState<SelectProps.Option[]>([]);
    const [plToRpnMap, setPlToRpnMap] = useState<Record<string, SelectProps.Option[]>>({});
    const [scenariosOpts, setScenariosOpts] = useState<SelectProps.Option[]>([]);

    useEffect(() => {
        if (countryNameMap) {
            const opts: SelectProps.Option[] = [];
            Object.entries(countryNameMap).forEach(([code, name]) => opts.push({ label: code + ' - ' + name, value: code }));
            setCountryOpts(opts);
        }
    }, [countryNameMap]);

    useEffect(() => {
        if (dataSetData){
            const opts: SelectProps.Option[] = [];
            const arr : string[] = Array.from(new Set(dataSetData.map((item: IScenarioMD) => item.dataset_name)));
            arr.forEach((item) => opts.push({ label: item , value: item }) );
            setdataSetOpts(opts);
        }
    }, [dataSetData]);

    useEffect(() => {
        if (plData) {
            const opts: SelectProps.Option[] = [];
            plData.forEach((obj: IProductLine) => opts.push({ label: obj.product_line, value: obj.verity_product_line_id.toString() }));
            setPlOpts(opts);
        }
    }, [plData, plLoading]);

    useEffect(() => {
        if (rpnData) {
            const plRpnMap: Record<string, SelectProps.Option[]> = {};
            rpnData.forEach((obj: IRPNs) => {
                if (obj.product_line_id && obj.program){
                    const opt = { label: obj.program, value: obj.program_id };
                    plRpnMap[obj.product_line_id] ? plRpnMap[obj.product_line_id].push(opt) : plRpnMap[obj.product_line_id] = [opt];
                }
            });
            Object.entries(plRpnMap).forEach(([pl, opts]) => {
                plRpnMap[pl] = _.sortBy(opts, ['label']);
            });
            setPlToRpnMap(plRpnMap);
        }
    }, [rpnData]);

    useEffect(() => {
        if (scenariosData) {
            const opts: SelectProps.Option[] = [];
            scenariosData.forEach((obj: IScenario) => opts.push({ label: obj.scenario, value: obj.scenario_id.toString() }));
            setScenariosOpts(opts);
        }
    }, [scenariosData]);

    useEffect(() => {
        setFormFilters((prevState: any) => {
            return {
                ...prevState,
                openScenario: []
            };
        });
    }, [formFilters.dataset]);

    useEffect(() => {
        setFormFilters((prevState: any) => {
            return {
                ...prevState,
                rpn: []
            };
        });
    }, [formFilters.productLine]);

    const setValue = (field: string, value: any) => {
        setFormFilters((prevState: any) => {
            return {
                ...prevState,
                [field]: value
            };
        });
    };
    return (
        <>
            <SpaceBetween direction="vertical" size="xl">
                {(dataSetError || plError) && <Alert
                    dismissAriaLabel="Close alert"
                    statusIconAriaLabel="Error"
                    type="error"
                >
                    Error loading data
                </Alert>}
                <Grid gridDefinition={[
                    { colspan: { m: 2 } },
                    { colspan: { m: 2 } },
                    { colspan: { m: 2 } },
                    { colspan: { m: 2 } },
                    { colspan: { m: 2 } },
                    { colspan: { m: 2 } }
                ]}>
                    <FormField
                        label="Dataset"
                    >
                        <Select
                            selectedOption={formFilters.dataset}
                            onChange={({ detail }) =>
                                setValue('dataset', detail.selectedOption)
                            }
                            options={dataSetOpts}
                            loadingText='Loading datasets'
                            placeholder='Select Dataset'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-dataset'
                            statusType={dataSetLoading ? 'loading' : 'finished'}
                        />
                    </FormField>
                    <FormField
                        label="Open scenario"
                    >
                        <Multiselect
                            selectedOptions={formFilters.openScenario}
                            onChange={({ detail }) =>
                                setValue('openScenario', detail.selectedOptions)
                            }
                            options={scenariosOpts}
                            loadingText="Loading open scenarios"
                            placeholder='Choose options for Open Scenario'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-scenarios'
                            errorText="Error fetching open scenarios"
                            statusType={(scenariosLoading || scenariosFetching) ? 'loading' : (scenariosError || scenariosData === null) ? 'error' : 'finished'}
                        />
                    </FormField>
                    <FormField
                        label="Product line"
                    >
                        <Select
                            selectedOption={formFilters.productLine}
                            onChange={({ detail }) =>
                                setValue('productLine', detail.selectedOption)
                            }
                            options={plOpts}
                            loadingText="Loading product lines"
                            placeholder='Choose options for Product line'
                            selectedAriaLabel="Selected"
                            filteringType="auto"
                            ariaLabel='select-product-lines'
                            statusType={plLoading ? 'loading' : 'finished'}
                        />
                    </FormField>
                    <FormField
                        label="Program (RPN) - optional"
                    >
                        <Multiselect
                            selectedOptions={formFilters.rpn}
                            onChange={({ detail }) =>
                                setValue('rpn', detail.selectedOptions)
                            }
                            options={formFilters.productLine?.value ? plToRpnMap[formFilters.productLine?.value] : []}
                            placeholder='Choose options for Program (RPN)'
                            selectedAriaLabel="Selected"
                            loadingText="Loading RPNs"
                            filteringType="auto"
                            ariaLabel='select-rpns'
                            errorText="Error fetching programs"
                            statusType={rpnLoading ? 'loading' : rpnError ? 'error' : 'finished'}
                        />
                    </FormField>
                    <FormField
                        label="Country - optional"
                    >
                        <Multiselect
                            selectedOptions={formFilters.country}
                            onChange={({ detail }) =>
                                setValue('country', detail.selectedOptions)
                            }
                            options={countryOpts}
                            placeholder='Choose options for Country'
                            filteringType="auto"
                            ariaLabel='select-country'
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                    <FormField
                        label="Channel - optional"
                    >
                        <Multiselect
                            selectedOptions={formFilters.channel}
                            onChange={({ detail }) =>
                                setValue('channel', detail.selectedOptions)
                            }
                            options={[{ label: "ONLINE", value: "ONLINE" }, { label: "OFFLINE", value: "OFFLINE" }]}
                            placeholder='Choose options for Channel'
                            filteringType="auto"
                            ariaLabel='select-channel'
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </Grid>
                <hr></hr>
                <Box textAlign='center'>
                    <Button className='bg-primary' disabled={!formFilters.dataset || !formFilters.openScenario.length || !formFilters.productLine} loading={loading} ariaLabel='search-config' iconName="search" variant="primary" onClick={() => search(formFilters)} >Search</Button>
                </Box>
            </SpaceBetween>
        </>
    );
};
