import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts/highcharts-gantt'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility'
import { PRIMARY_COLORS, BAR_HEIGHTS, CHART_BORDER_COLOR, CHART_TITLE, CHART_SECONDARY_LABEL } from './constants';
import Formatters from './formatters';
HighchartsMore(Highcharts);
HC_exporting(Highcharts);
HC_accessibility(Highcharts)

export interface IHighchartGantt {
    seriesData: Highcharts.GanttPointOptionsObject[],
    enableNavigator?: boolean,
    uniqueNames?: boolean,
    groupBy?: string,
    titles?: Record<string, string>,
    subtitles?: Record<string, string>,
    dateRangeMin?: number,
    dateRangeMax?: number
}

export const HighchartGantt = ({ seriesData = [], enableNavigator = true, uniqueNames, titles = {}, subtitles = {}, dateRangeMin, dateRangeMax, groupBy }: IHighchartGantt) => {
    const [options, setOptions] = useState<Highcharts.Options>({});
    const { seriesDataLabelFormatter, xAxisQuaterFormatter } = Formatters();

    const generateMinHeight = (subtitles: Record<string, string>, seriesData: any) => {
        if (subtitles) {
            let extraRow = 0;
            seriesData.forEach((obj: any) => {
                let row = 0;
                Object.entries(subtitles!).forEach(([key, val]) => {
                    // Add additional row for subtitle field value if it's long char
                    if (obj[key]?.value?.length > 40) row++;
                });
                extraRow = Math.max(extraRow, row);
            });
            return BAR_HEIGHTS[Object.keys(subtitles).length + extraRow] ?? BAR_HEIGHTS[4]
        }
        return BAR_HEIGHTS[0];
    }

    useEffect(() => {
        setOptions({
            colors: PRIMARY_COLORS,
            exporting: {
                allowHTML: true
            },
            title: {
                text: CHART_TITLE
            },
            yAxis: {
                labels: {
                    formatter: (params: Highcharts.AxisLabelsFormatterContextObject) => {
                        return params.value?.toString().startsWith('hide') ? '' : `<span class="chart-yaxis-label">${params.value}<span/>`;
                    },
                    useHTML: true,
                    style: {
                        color: CHART_SECONDARY_LABEL
                    }
                },
                title: {
                    text: groupBy,
                    margin: 50,
                    style: {
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '0.8em',
                        whiteSpace: 'break-word',
                        width: 150
                    },
                },
                grid: {
                    borderColor: CHART_BORDER_COLOR,
                },
                uniqueNames: uniqueNames,
                staticScale: subtitles && seriesData ? generateMinHeight(subtitles, seriesData) : 50
            },
            xAxis: [{
                min: dateRangeMin,
                max: dateRangeMax,
                currentDateIndicator: true,
                grid: {
                    borderColor: CHART_BORDER_COLOR,
                },
                units: [['month', [3]]],
                labels: {
                    align: "center",
                    formatter: (params: Highcharts.AxisLabelsFormatterContextObject) => xAxisQuaterFormatter(params.value),
                    style: {
                        color: CHART_SECONDARY_LABEL
                    }
                },

            }, {
                grid: {
                    borderColor: CHART_BORDER_COLOR,
                },
                units: [['year', [1]]],
                labels: {
                    style: {
                        fontWeight: 'bold'
                    }
                }
            }],
            tooltip: {
                useHTML: true,
                headerFormat: '',
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        useHTML: true,
                        align: 'left',
                        crop: false,
                        overflow: "allow",
                        allowOverlap: true,
                        style: {
                            whiteSpace: 'pre-wrap'
                        },
                        formatter: function (this: Highcharts.PointLabelObject) {
                            return seriesDataLabelFormatter(this.point, titles, subtitles);
                        }
                    }
                }
            },
            navigator: {
                enabled: enableNavigator,
                series: {
                    type: 'gantt',
                    pointPadding: 0.25
                },
                yAxis: {
                    min: 0,
                    max: 7
                }
            },

            series: [{
                type: 'gantt',
                borderColor: CHART_BORDER_COLOR,
                data: seriesData,
                groupPadding: 0.05,
                pointPadding: 0.05,
            }]
        }
        );
    }, [seriesData, titles, subtitles]);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType="ganttChart"
            />
        </div>
    );
};

