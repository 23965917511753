import Queries from '../queries';
import { API_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

export const formResourcesAPIs = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getPRMChartResources: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: {
                "query": Queries.getPRMFormResources(), 
                "variables": { "for_chart" : true}
            } }),            
            transformResponse: (response: any) => response.data?.data?.get_prm_attributes?.body ?? null
        }),
        getPRMGanttChartData: build.query({
            query: ({params}) => ({ method: API_METHODS.post, data: {
                "query": Queries.getPRMGanttChartData(), 
                "variables": { "product_line_id" : params.product_line_id, "attribute_ids": params.attribute_ids, "scenario_id": params.scenario_id, "country": params.country}
            } }),            
            transformResponse: (response: any) => response.data
        })
    })
});

export const { 
    useGetPRMChartResourcesQuery,
    useGetPRMGanttChartDataQuery
} = formResourcesAPIs;