import * as React from "react";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
const SideNavPanel: React.FC<any> = () => {
    const [activeHref, setActiveHref] = React.useState("/");
    return (
        <SideNavigation
            className="sideNav"
            activeHref={activeHref}
            onFollow={(event) => {
                if (!event.detail.external) {
                    setActiveHref(event.detail.href);
                }
            }}
            items={[
                {
                    type: "section",
                    text: "COA",
                    items: [
                        { type: "link", text: "Accessory Project", href: "#/coa/accessoryProject" },
                        { type: "link", text: "Account", href: "#/coa/account" },
                        { type: "link", text: "Cost Center", href: "#/coa/costCenter" },
                        { type: "link", text: "Cost Center Group", href: "#/coa/costCenterGroup" },
                        { type: "link", text: "Cost Center Exp line", href: "#/coa/costCenterExpLine" },
                        { type: "link", text: "Product", href: "#/coa/product" },
                        { type: "link", text: "Product Group", href: "#/coa/productGroup" },
                        { type: "link", text: "Project", href: "#/coa/projects" }
                    ]
                },
                {
                    type: "section",
                    text: "Miscellaneous",
                    items: [
                        { type: "link", text: "Budget Type", href: "#/coa/budgetType" },
                        { type: "link", text: "Commodity", href: "#/coa/commodity" },
                        { type: "link", text: "Expense Type", href: "#/coa/expenseType" },
                        { type: "link", text: "Expense Update Reason", href: "#/coa/expenseUpdateReason" },
                        { type: "link", text: "Budget Baseline", href: "#/coa/budgetBaseline" }
                    ]
                }
            ]}
        />
    );
};

export const SideNav = SideNavPanel;