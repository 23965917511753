import React, { ReactElement, createContext} from "react";
import expenseTypeHelper from './expenseTypeHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
} 

interface IProjectsContext {
    componentData?: [];
    useExpenseTypeData: () => UseQueryResult;
    useUpdatedExpTypeData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const ExpenseTypeContext = createContext<IProjectsContext>({
    useExpenseTypeData: () => qResp, 
    useUpdatedExpTypeData: () => mResp
});

export const ExpenseTypeContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...expenseTypeHelper()
    };

    return (
        <ExpenseTypeContext.Provider value={provider}>
            {children}
        </ExpenseTypeContext.Provider>
    );
};
