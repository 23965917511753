import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'product_group': 'Product Group'
};
export const COL_DEFS : (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Product Group",
        field: "product_group",
        editable: GridValidation.existingRecord,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Description",
        field: "description",
        editable: true,
        valueParser: GridValidation.trimValue
    }
];