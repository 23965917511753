import * as React from "react";
import { Header } from "@cloudscape-design/components";

export const VerityPageHeader = () => {

    return (
        <>
            <Header
                variant="h1"
                className="page-header "
            >
                Scenario Config Manager
            </Header>
        </>

    );

};
