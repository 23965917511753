import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {
    const [componentData, setComponentData] = useState();
    const queryClient = useQueryClient();

    const fetchExpUpdReason = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getExpUpdReasonQuery());
        const { body }: IApiResponse = response.data.data.get_exp_up_re;
        return body;
    };

    const updateExpUpdReason = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_exp_up_re'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_exp_up_re as IApiUpdateResponse;
    };

    const useExpenseUpdateData = () => {
        return useQuery(
            ['expUpdReason'],
            fetchExpUpdReason,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useUpdatedExpUpdReason = () => {
        return useMutation(
            (requestData) => updateExpUpdReason(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['expUpdReason']);
                }
            }
        );
    };

    return { componentData, useUpdatedExpUpdReason, useExpenseUpdateData };
};