import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'project_code': 'Project Code',
    'device_project_name': 'Device Project Name',
    'device_product_code': 'Device Product Code'
};

export const COL_DEFS: (ColGroupDef | ColDef)[] = [
    {
        headerName: "Project Code",
        field: "project_code",
        editable: GridValidation.existingRecord,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Device Project Name",
        field: "device_project_name",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Accessory Project Name",
        field: "accessory_project_name",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Sub Device Project Name",
        field: "sub_device_project_name",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Device Product Code",
        field: "device_product_code",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Accessory Product Code",
        field: "accessory_product_code",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Product Life in Months",
        field: "product_life_in_months",
        editable: true,
        suppressPaste: true,
        valueParser: GridValidation.numberParser
    },
    {
        headerName: "Street Date",
        field: "street_date",
        editable: true,
        suppressPaste: true,
        valueParser: GridValidation.isSixDigitDate 
    },
    {
        headerName: "Depreciation Months",
        field: "depreciation_months",
        editable: true,
        suppressPaste: true,
        valueParser: GridValidation.numberParser
    },
    {
        headerName: "Baseline Date",
        field: "baseline_date",
        editable: true
    },
    {
        headerName: "Pred Project Code",
        field: "pred_project_code",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Pred Project Name",
        field: "pred_project_name",
        editable: false
    },
    {
        headerName: "Complexity Factor vs Pred Project",
        field: "complexity_factor",
        editable: true,
        suppressPaste: true,
        valueParser: GridValidation.floatParser
    },
    {
        headerName: "Proj HW Id",
        field: "proj_hw_id",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Street Name",
        field: "street_name",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Active Project(Y/N)",
        field: "is_active",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['Y', 'N']
        }
    },
    {
        headerName: "Is_Mfr(Y/N)",
        field: "is_mfr",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['Y', 'N']
        }
    }
];

