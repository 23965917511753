import React, { createContext, FC, useState, useEffect } from "react";
import { AlertMessage } from "../Components/AlertMessage/AlertMessage";

interface IAlertMessageContext {
    successMessage: string;
    errorMessage: string;
    setSuccess?: (message: string) => void;
    setError?: (message: string) => void;
}

const state: IAlertMessageContext = {
    successMessage: "",
    errorMessage: ""
};

const AlertMessageContext = createContext<IAlertMessageContext>(state);

export default AlertMessageContext;

export const AlertMessageProvider: FC = ({ children }) => {
    const [successMessage, setSuccessMessage] = useState(state.successMessage);
    const [errorMessage, setErrorMessage] = useState(state.errorMessage);
    const timeout = 10000;

    useEffect(() => {
        if (successMessage !== '') {
            setTimeout(() => {
                setSuccessMessage('');
            }, timeout);
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage !== '') {
            setTimeout(() => {
                setErrorMessage('');
            }, timeout);
        }
    }, [errorMessage]);



    return (
        <AlertMessageContext.Provider
            value={{
                successMessage: successMessage,
                errorMessage: errorMessage,
                setSuccess: setSuccessMessage,
                setError: setErrorMessage
            }}
        >
            <AlertMessage
                successMessage={successMessage}
                errorMessage={errorMessage}
                timeout={timeout}
            />
            {children}
        </AlertMessageContext.Provider>
    );
};
