import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Grid, Header, SelectProps, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { ALERT_MESSAGES, ERROR_MESSAGE, MUTATION_METHODS, STATUS_CODES } from '../../constants/constants';
import { useCreatePLAttributesMutation, useGetAttributeTypeQuery } from '../../services/api';
import { useGetAttributeTypePLQuery} from '../../../redux/services/attributesApi';
import { useAppSelector } from '../../../redux/hooks';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { IAttributeTypeResponse, IPLAttributeCreate, IPLAttributeTypeResponse } from '../../interfaces/interfaces';
import { AttributeOrderingTable, IPLAttributes } from './attributeOrderingTable';
import ScreenUtils from '../../utils/screenUtils';
import { ConfirmationModal } from 'src/components/common/Components/ConfirmationAlert/ConfirmationModal';
import AlertMessageContext from 'src/components/common/Contexts/alertMessageContext';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';

export const AttributeOrdering = () => {
    const { productLine } = useAppSelector(selectLocationData);
    const { data: plData, isLoading, isFetching, isError, refetch } = useGetAttributeTypePLQuery({verity_product_line_id :parseInt(productLine.id)}, { skip: !productLine.id });
    const { data: attrTypeData } = useGetAttributeTypeQuery();
    const [createPlAttribute, { isLoading: mutationLoading, data: mutationData, isError: mutationError }] = useCreatePLAttributesMutation();
    const [visible, setVisible] = useState(false);
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const [allTypes, setAllTypes] = useState<SelectProps.Option[]>([]);
    const [tableItems, setTableItems] = useState<IPLAttributes[]>([]);
    const [saveDisabled, setSaveDisabled] = useState(true);

    useEffect(() => {
        if (plData) {
            const items: IPLAttributes[] = [];
            plData.forEach((obj: IPLAttributeTypeResponse) => {
                items[obj.pl_attr_order] = {
                    id: obj.pl_attr_order,
                    attribute: {
                        label: obj.attribute_type,
                        value: obj.attribute_type
                    },
                    isInheritedByFPN: false
                };
            });
            setTableItems(items);
        }
    }, [plData, isLoading, isFetching]);

    useEffect(() => {
        setSaveDisabled(!tableItems.length || tableItems.some(item => !item.attribute?.label));
    }, [tableItems]);

    useEffect(() => {
        if (attrTypeData) {
            const attrTypes: SelectProps.Option[] = [];
            attrTypeData.forEach((obj: IAttributeTypeResponse) => {
                if (!obj.is_std_attr) {
                    attrTypes.push({
                        label: obj.attribute_type,
                        value: obj.id.toString()
                    });
                }
            });
            setAllTypes(attrTypes);
        }
    }, [attrTypeData]);

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) setError?.(ALERT_MESSAGES.updateFailure);
            if (mutationData.data && mutationData.data[MUTATION_METHODS.createPLAttributes]) {
                const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.createPLAttributes];
                if (statusCode === STATUS_CODES.success) {
                    refetch();
                    setSuccess?.(ALERT_MESSAGES.plAttrSuccess);
                } else {
                    error ? setError?.(error) : setError?.(ALERT_MESSAGES.updateFailure);
                }
            }
        }
    }, [mutationData, mutationError]);

    const submit = async () => {
        const payload: IPLAttributeCreate[] = [];
        tableItems.forEach((obj: IPLAttributes) => {
            if (obj.attribute?.label && obj.attribute?.value) {
                payload.push({
                    attribute_type: obj.attribute.label,
                    attribute_type_id: parseInt(obj.attribute.value),
                    product_line_id: parseInt(productLine.id),
                    user_id: ScreenUtils.getUserId() ?? '',
                    pl_attr_order: obj.id,
                    is_pl_attr: true,
                    is_inherited_by_fpn: obj.isInheritedByFPN
                }
                );
            }
        });
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        console.log(graphQL_payload);// console added for beta testing
        setVisible(false);
        await createPlAttribute(graphQL_payload).unwrap();
    };

    const addAttribute = () => {
        const items = [...tableItems];
        items.push({
            id: tableItems.length + 1,
            attribute: null,
            isInheritedByFPN: false
        });
        setTableItems(items);
    };

    if (isError) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {ERROR_MESSAGE}
            </Container>
        </Grid>
    );

    const loadTable = () => {
        if (isLoading || isFetching || mutationLoading) return <div className='loadspinner mg-top-md'><Spinner size="large" /></div>;
        if (!isLoading && !isFetching) {
            return <AttributeOrderingTable
                readOnly={true}
                allAttributes={allTypes}
                setTableItems={setTableItems}
                tableItems={tableItems} />;
        } else {
            return ERROR_MESSAGE;
        }
    };

    return (
        <>
            <Container
                header={
                    <Header
                        //Temporary disable until logic is added actions={!plData?.length &&
                        //     <SpaceBetween direction="horizontal" size="xs">
                        //         <Button onClick={addAttribute} >Add attribute</Button>
                        //         <Button ariaLabel='open-save-modal' className='bg-primary' disabled={saveDisabled} onClick={() => setVisible(true)} variant="primary">
                        //             Save
                        //         </Button>
                        //     </SpaceBetween>
                        // }
                        variant="h2">
                        Product line attributes
                    </Header>
                }
            >
                <SpaceBetween size="xl">
                    <div>The order of these attributes determined the names of FPNs created. FPNs are created on the 'Attribute' tab when selecting at a program level.</div>
                    {loadTable()}
                </SpaceBetween>
            </Container>
            <ConfirmationModal
                visible={visible}
                setVisible={setVisible}
                header='Save product line attributes'
                message='Product line attributes cannot be modified or re-ordered once saved. Do you want to save?'
                btnLabel='Save'
                type='warning'
                action={submit}
            />

        </>

    );
};
