import React, { ReactElement, createContext } from "react";
import costCenterGroupHelper from './costCenterGroupHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: [];
    ccGrpData?: void;
    useCostCenterGroupData: () => UseQueryResult;
    useUpdatedCCGrpData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const CostCenterGroupContext = createContext<IProjectsContext>({
    useCostCenterGroupData: () => qResp,
    useUpdatedCCGrpData: () => mResp
});

export const CostCenterGroupContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...costCenterGroupHelper()
    };

    return (
        <CostCenterGroupContext.Provider value={provider}>
            {children}
        </CostCenterGroupContext.Provider>
    );
};
