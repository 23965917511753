import React from 'react';
import {Checkbox} from "@cloudscape-design/components";

export default (props: any) => {
    const setChecked = (value: boolean) => {
        if(props.colDef?.field) props.node.setDataValue(props.colDef?.field, value);
    };
    return (
        <span>
            <Checkbox
                onChange={({ detail }) =>
                    setChecked(detail.checked)
                }
                disabled={props.disabled}
                checked={props.value}
                ariaLabel= {'checkbox-'+props.colDef?.field}
            >
            </Checkbox>
        </span>
    );
};