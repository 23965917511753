import Queries from '../queries/scenarioQueries';
import CommonQueries from '../queries/commonQueries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

const { getOpenScenario, getScenarioMD, getConfiguration, getDeviceKey } = Queries;
const { getUpdateQueryWithCodes } = CommonQueries;

export const ScenarioAPI = verityApi.injectEndpoints({
    endpoints: (build) => ({
        getOpenScenarios: build.query({
            query: ({ dataset }) => ({
                schemaName: 'get_open_scenario',
                method: API_METHODS.post, data: {
                    "query": getOpenScenario(), 
                    "variables": { "dataset": dataset }
                }
            }),
            transformResponse: (response: any) => response.data.data?.get_open_scenario?.body ?? null
        }),
        getDataset: build.query<any, void>({
            query: () => ({ method: API_METHODS.post, data: {"query" : getScenarioMD() } }),
            transformResponse: (response: any) => response.data.data?.get_scenario_md?.body ?? null
        }),
        getConfiguration: build.query({
            query: ({ dataset, scenario_id }) => ({
                method: API_METHODS.post, data: {
                    "query": getConfiguration(), 
                    "variables": { "dataset": dataset, "scenario_id": scenario_id }
                }
            }),
            providesTags: () => [{ type: 'ScenarioConfiguration' }],
            transformResponse: (response: any) => response.data.data?.get_configuration?.body ?? null
        }),
        getDeviceKeySnro: build.query({
            query: ({ product_line_id, rpn_id, channel, country }) => ({
                method: API_METHODS.post, data: {
                    "query": getDeviceKey(),
                    "variables": {
                        "product_line_id": product_line_id,
                        "rpn_id": rpn_id,
                        "channel": channel,
                        "country": country
                    }
                }
            }),
            providesTags: () => [{ type: 'DeviceKey' }],
            transformResponse: (response: any) => response.data.data?.get_device_key_v1?.body ?? null
        }),
        updateScenariConfig: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: getUpdateQueryWithCodes(data, MUTATION_METHODS.updateScenarioConfig) }),
            invalidatesTags: () => [{ type: 'ScenarioConfiguration' }],
            transformResponse: (response: any) => response.data
        })
    })
});

export const {
    useGetDatasetQuery,
    useGetOpenScenariosQuery,
    useGetDeviceKeySnroQuery,
    useGetConfigurationQuery,
    useUpdateScenariConfigMutation
} = ScenarioAPI;