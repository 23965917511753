import React from "react";
import { Formik } from 'formik';
import { ColumnLayout, Grid, SpaceBetween } from "@cloudscape-design/components";
import { Form } from '../Formik-Cloudscape/components/form/form';
import { IDynamicFormProps, IDynamicFieldProps } from './Interfaces';
import { FIELD_COMPONENT_MAP } from './Constants';

const DynamicForm = ({ formInput }: IDynamicFormProps) => {

    const initialValues: Record<string, any> = {};
    const renderForm = () => {
        formInput.forEach((inputArray) => {
            inputArray.forEach((obj: IDynamicFieldProps) => initialValues[obj.name] = obj.value || '');
        });
        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={() => {}}
                >
                    {({ values, errors }) => (
                        <Form
                        >
                            <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
                                <SpaceBetween size="xxl">
                                    {formInput.map((inputArray, index) => {
                                        return (
                                            <ColumnLayout key={index} columns={inputArray.length}>
                                                {inputArray.map((field: IDynamicFieldProps) => {
                                                    const width = field.full_width || inputArray.length > 1 ? 1 : 2;
                                                    const Component = FIELD_COMPONENT_MAP[field.type];
                                                    if (Component) {
                                                        return (
                                                            <ColumnLayout key={field.name} columns={width}>
                                                                <Component {...field} errors={errors[field.name]}/>
                                                            </ColumnLayout>
                                                        )
                                                    }
                                                })}
                                            </ColumnLayout>
                                        )
                                    })
                                    }
                                </SpaceBetween>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }

    return (
        <>
            {renderForm()}
        </>
    );
}

export default DynamicForm;

