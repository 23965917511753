import { BreadcrumbGroupProps, SideNavigationProps } from "@cloudscape-design/components";
import { ALL_ASSIGNEES_KEY, HOME_PATH_HEADER, PRIMARY_KEY_MAPPING, SCREEN_PATHS, URL_PARAMS, USER_ACTION_ROLES, USER_POLICY_SEPERATOR, USER_ROLES } from "../../constants/constants";
import { IApiFPNResponse, IApiProductHierarchy, IApiRPNResponse, ILocationData } from "../../interfaces/interfaces";

export default () => {

    const isSubstring = (str: string, substring: string) => str.toLowerCase().includes(substring.toLowerCase());

    // Check if user has RW access
    const checkAssigned = (showAssigned: boolean, hasRWAccess: boolean, childhasRWAccess: boolean) => !showAssigned || (showAssigned && (childhasRWAccess || hasRWAccess));

    // Check if PL or Programs are part of search string
    const checkSearchValue = (searchValue: string, isPartofSearch: boolean, isChildOrParentPartOfSearch: boolean) => !searchValue || (searchValue && (isPartofSearch || isChildOrParentPartOfSearch));

    // Check if 1.PL contains roadmap programs  2.RPN is on roadmap
    const checkRdMapProgram = (showRdMapProg: boolean, hasRdMapProg: boolean) => !showRdMapProg || (showRdMapProg && hasRdMapProg);

    const generateSideNavItems = (payload: IApiProductHierarchy[], userPolicy: Record<string, Record<string, string[]>>, showRdMapPrograms: boolean, showAssigned: boolean, searchValue: string) => {
        const sideNavItems: SideNavigationProps.Item[] = [];
        sideNavItems.push({ type: "link", text: 'Home', href: `${SCREEN_PATHS.basePath}` });
        if (USER_ACTION_ROLES.adminPanel.some(role => userPolicy?.[role]))
            sideNavItems.push({ type: "link", text: 'Access Management', href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.admin}`});
        const FpnIP: string[] = [];
        const RpnIP: string[] = [];
        const PLIP: string[] = [];
        const roleToIdMap : Record<string, string[]> = {};
        Object.entries(userPolicy).forEach(([role, obj]) => {
            roleToIdMap[role] = Object.keys(obj);
        });
        [...(roleToIdMap[USER_ROLES.inputProvider] ?? []), ...(roleToIdMap[USER_ROLES.inputSubmitter] ?? [])].forEach((resource: string) => {
            const [type, id] = resource.split(USER_POLICY_SEPERATOR);
            type === PRIMARY_KEY_MAPPING.pl ? PLIP.push(id) : type === PRIMARY_KEY_MAPPING.rpn ? RpnIP.push(id) : FpnIP.push(id);
        });
        const allPLsAccess = roleToIdMap[USER_ROLES.plAdmin]?.includes(ALL_ASSIGNEES_KEY);
        const PLAdmin = roleToIdMap[USER_ROLES.plAdmin]?.map(str => str.split(USER_POLICY_SEPERATOR)[1]) ?? [];
        payload.forEach((productLine: IApiProductHierarchy) => {
            const pItems: SideNavigationProps.Item[] = [];
            const isPLAdmin = allPLsAccess || PLAdmin.includes(productLine.verity_product_line_id.toString());
            const plPartOfSearch = searchValue && isSubstring(productLine.product_line, searchValue) ? true : false;
            let anyRPNHasRWAccess = false;
            let anyRPNPartOfSearch = false;

            if (productLine.verity_product_line_id > 0) {
                const plName = encodeURIComponent(productLine.product_line);

                productLine.rpn?.forEach((rpn: IApiRPNResponse) => {
                    const vItems: SideNavigationProps.Item[] = [];
                    if (rpn.verity_rpn_id && checkRdMapProgram(showRdMapPrograms, rpn.is_on_roadmap)) {
                        const isRPNInputProvider = RpnIP.includes(rpn.verity_rpn_id.toString()) || PLIP.includes(productLine.verity_product_line_id.toString()) || isPLAdmin;
                        const rpnName = encodeURIComponent(rpn.rpn);
                        const rpnPartOfSearch = searchValue && isSubstring(rpn.rpn, searchValue) ? true : false;
                        let anyFPNHasRWAccess = false;
                        let anyFPNPartOfSearch = false;

                        rpn.fpn?.forEach((fpn: IApiFPNResponse) => {
                            const isFPNInputProvider = FpnIP.includes(fpn.verity_fpn_id.toString()) || isRPNInputProvider;
                            const fpnName = encodeURIComponent(fpn.fpn);

                            if (checkAssigned(showAssigned, isFPNInputProvider, false) &&
                                checkSearchValue(searchValue, isSubstring(fpn.fpn, searchValue), (rpnPartOfSearch || plPartOfSearch))) {
                                vItems.push({
                                    type: "link",
                                    text: fpn.fpn,//`${fpn.fpn}${isFPNInputProvider ? ' (edit)' : ''}`,
                                    href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.programSetup}?${URL_PARAMS.productLine}=${productLine.verity_product_line_id}-${plName}&${URL_PARAMS.rpn}=${rpn.verity_rpn_id}-${rpnName}&${URL_PARAMS.fpn}=${fpn.verity_fpn_id}-${fpnName}`
                                });
                                if (isFPNInputProvider) anyFPNHasRWAccess = true;
                                anyFPNPartOfSearch = true;
                            }
                        });
                        if (checkAssigned(showAssigned, isRPNInputProvider, anyFPNHasRWAccess) &&
                            checkSearchValue(searchValue, rpnPartOfSearch, (anyFPNPartOfSearch || plPartOfSearch))) {

                            pItems.push({
                                type: "expandable-link-group",
                                text: rpn.rpn,//`${}${anyFPNHasRWAccess || isRPNInputProvider ? ' (edit)' : ''}`,
                                href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.programSetup}?${URL_PARAMS.productLine}=${productLine.verity_product_line_id}-${plName}&${URL_PARAMS.rpn}=${rpn.verity_rpn_id}-${rpnName}`,
                                defaultExpanded: false,
                                items: vItems
                            });
                            if (isRPNInputProvider || anyFPNHasRWAccess) anyRPNHasRWAccess = true;
                            anyRPNPartOfSearch = true;
                        }
                    }
                });
                if (checkAssigned(showAssigned, isPLAdmin, anyRPNHasRWAccess) &&
                    checkSearchValue(searchValue, plPartOfSearch, anyRPNPartOfSearch) &&
                    checkRdMapProgram(showRdMapPrograms, pItems.length ? true : false)) {

                    sideNavItems.push({
                        type: "expandable-link-group",
                        text: productLine.product_line,//`${}${anyRPNHasRWAccess || isPLAdmin ? ' (edit)' : ''}`,
                        href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.productLineHome}?${URL_PARAMS.productLine}=${productLine.verity_product_line_id}-${plName}`,
                        defaultExpanded: false,
                        items: pItems
                    });
                }
            }
        });
        return sideNavItems;
    };

    const generateLoactionData = (params: URLSearchParams, path: string) => {
        const pathArray = path.split("/");
        const locationData: ILocationData = {
            currentScreen: '',
            productLine: { id: '', name: '' },
            program: { id: '', name: '' },
            fpn: { id: '', name: '' }
        };
        if (pathArray.length > 2) locationData.currentScreen = pathArray[2];
        if (params.has(URL_PARAMS.productLine)) {
            const plParam = params.get(URL_PARAMS.productLine);
            const plindex = plParam!.indexOf('-');
            locationData.productLine = { id: plParam!.substring(0, plindex), name: plParam!.substring(plindex + 1) };
            if (params.has(URL_PARAMS.rpn)) {
                const rpnParam = params.get(URL_PARAMS.rpn);
                const rpnindex = rpnParam!.indexOf('-');
                locationData.program = { id: rpnParam!.substring(0, rpnindex), name: rpnParam!.substring(rpnindex + 1) };
                if (params.has(URL_PARAMS.fpn)) {
                    const fpnParam = params.get(URL_PARAMS.fpn);
                    const fpnindex = fpnParam!.indexOf('-');
                    locationData.fpn = { id: fpnParam!.substring(0, fpnindex), name: fpnParam!.substring(fpnindex + 1) };
                }
            }
        }
        return locationData;
    };

    const generateBreadCrumbsItems = ({ currentScreen, productLine, program, fpn }: ILocationData) => {
        const items: BreadcrumbGroupProps.Item[] = [];
        if (currentScreen) {
            if (currentScreen in HOME_PATH_HEADER) {
                items.push({ text: 'Home', href: `${SCREEN_PATHS.basePath}` });
                items.push({ text: HOME_PATH_HEADER[currentScreen as keyof typeof HOME_PATH_HEADER], href: `` });
            } else if (productLine.id) {
                const plName = encodeURIComponent(productLine.name);
                items.push({ text: 'Home', href: `${SCREEN_PATHS.basePath}` });
                items.push({ text: productLine!.name, href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.productLineHome}?${URL_PARAMS.productLine}=${productLine.id}-${plName}` });
                if (program.id) {
                    items.push({ text: program!.name, href: `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.programSetup}?${URL_PARAMS.productLine}=${productLine.id}-${plName}&${URL_PARAMS.rpn}=${program.id}-${encodeURIComponent(program.name)}` });
                    if (fpn.id) items.push({ text: fpn!.name, href: "#" });
                }
            }
        } else items.push({ text: 'Home', href: `${SCREEN_PATHS.basePath}` });
        return items;
    };

    return { generateSideNavItems, generateLoactionData, generateBreadCrumbsItems };
};
