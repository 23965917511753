import { Button } from '@amzn/awsui-components-react';
import React, {useState} from 'react';
import { ERROR_STRINGS, STATUS_CODES } from '../../constants/constants';

export default function PartNumBtnRenderer(props: any) {

    const[requestingPN, setRequestingPN] = useState(false);
    const data = props.node.data;
    const onClick = async () => {
        try {
            if(!data.marketPlace.length && !data.additional_marketplaces.length){
                props.addNotificationItem({
                    type: "error",
                    dismissible: true,
                    content: ERROR_STRINGS.PN_EMPTY_MARKETPLACE,
                    id: "PN_EMPTY_MARKETPLACE"
                });
            } else {
                setRequestingPN(true);
                props.api.getRowNode(data.sku_id).setData({...data, requestingPN:true});
                const response = await props.requestPNHandler(data.sku_id,data.descriptionPN);

                if (response.data.statusCode === STATUS_CODES.success) {
                    props.api.getRowNode(data.sku_id).setData({...data, pn:response.data.body.part_number});
                }else {
                    throw new Error(response.error);
                }
            }

        } catch (error) {
            props.addNotificationItem({
                type: "error",
                dismissible: true,
                content: ERROR_STRINGS.PN_REQUEST_FAILED,
                id: "PN_REQUEST_FAILED"
            });
        } finally {
            props.api.getRowNode(data.sku_id).setData({...data, requestingPN:false});
            setRequestingPN(false);
        }
    };

    if(data.is_deactivated) {
        return <></>;
    }

    return(
        <>
            <div className='part_num_renderer'>
                {props.value
                    ? <div className='value'> {props.value} </div>
                    : requestingPN
                        ?<div className='spinner'> Requested </div>
                        :<div className='btn'><Button  variant="normal" onClick={onClick} disabled={!props.hasWriteAccess}> Create PN</Button></div>
                }
            </div>
        </>
    );
}
