import { Button, Spinner } from '@amzn/awsui-components-react';
import React, {useState} from 'react';
import { STATUS_CODES } from '../../constants/constants';

export default function RemoveBtnRenderer({
    removeAssortmentHandler,
    addNotificationItem,
    assignedAssortments,
    hasWriteAccess,
    node
} : any) {

    const[removing, setRemoving] = useState(false);

    const onClick = async () => {
        try {
            setRemoving(true);
            const response = await removeAssortmentHandler(node.data.assortment_id);
            if (response.data.statusCode === STATUS_CODES.success) {
                addNotificationItem({
                    type: "success",
                    dismissible: true,
                    content: "Assortment removed successfully",
                    id: "ASSORTMENT_REMOVE_SUCCESS"
                });
            }else {
                throw new Error(response.error);
            }
        } catch (error) {
            addNotificationItem({
                type: "error",
                dismissible: true,
                content: "Unable to remove assortment.",
                id: "ASSORTMENT_REMOVE_FAILED"
            });
            setRemoving(false);
        }

    };

    if(assignedAssortments.includes(node.data.assortment_id)){
        return "SKU and Part-Number already created.";
    }

    return(
        <>
            {removing
                ? <Spinner />
                :<Button iconName="remove" variant="icon" onClick={onClick} disabled={!hasWriteAccess} />
            }
        </>
    );
}
