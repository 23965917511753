import * as tokens from "@amzn/awsui-design-tokens/polaris.js";

export const PRIMARY_COLORS = [
    tokens.colorChartsPaletteCategorical1,
    tokens.colorChartsPaletteCategorical2,
    tokens.colorChartsPaletteCategorical3,
    tokens.colorChartsPaletteCategorical4,
    tokens.colorChartsPaletteCategorical5,
    tokens.colorChartsPaletteCategorical6,
    tokens.colorChartsPaletteCategorical7,
    tokens.colorChartsPaletteCategorical8,
    tokens.colorChartsPaletteCategorical9,
    tokens.colorChartsPaletteCategorical10,
    tokens.colorChartsPaletteCategorical11,
    tokens.colorChartsPaletteCategorical12,
    tokens.colorChartsPaletteCategorical13,
    tokens.colorChartsPaletteCategorical14,
    tokens.colorChartsPaletteCategorical15
]

export const BAR_HEIGHTS = [35, 60, 85, 110, 135, 160, 185];

export const CHART_BORDER_COLOR = tokens.colorChartsLineAxis;

export const CHART_SECONDARY_LABEL = tokens.colorTextFormSecondary;

export const CHART_TITLE = 'Product Roadmap Gantt Chart';

