import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { SpaceBetween, FormField, Modal, Box, Button, Input, ColumnLayout, RadioGroup, Select, Spinner } from "@cloudscape-design/components";
import ScreenUtils from '../../utils/screenUtils';
import { IUpdateProgram } from '../../interfaces/interfaces';
import { useAppDispatch, useAppSelector } from 'src/components/redux/hooks';
import { resetProductHierarchy, selectAllProductLines, selectLocationData } from '../appLayout/appLayoutSlice';
import { useUpdateRPNMutation } from '../../services/api';
import AlertMessageContext from 'src/components/common/Contexts/alertMessageContext';
import { ALERT_MESSAGES, MUTATION_METHODS, SCREEN_PATHS, STATUS_CODES, URL_PARAMS, SELECT_OPTIONS } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { IApiUpdateResponse } from 'src/components/Interfaces/interface';
import { selectVerityData } from './programOverviewSlice';

export interface IUpdateProgramProps {
    visible: boolean;
    setVisibile: (arg: boolean) => void;
}

export const UpdateProgramModal = ({
    visible,
    setVisibile
}: IUpdateProgramProps) => {
    const dispatch = useAppDispatch();
    const allProductLines = useAppSelector(selectAllProductLines);
    const { productLine, program } = useAppSelector(selectLocationData);
    const [updateProgram, { isLoading, data: mutationData, isError: mutationError }] = useUpdateRPNMutation();
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const { validateProgramName, getUserId, generateOptionsFromIdLabel } = ScreenUtils;
    const programData = useAppSelector(selectVerityData);
    const navigate = useNavigate();
    const [formInput, setFormInput] = useState<IUpdateProgram>({});
    const [initialData, setInitialData] = useState<IUpdateProgram>({});
    const [rpnNameError, setRpnNameError] = useState<string>();

    useEffect(() => {
        const formData : IUpdateProgram = {
            "rpn": program.name,
            "product_line": { label: productLine.name, value: productLine.id },
            "program_status": { label: programData.programStatus, value: programData.programStatus },
            "id": parseInt(program.id),
            "is_name_approved": programData.isNameApproved ? "Yes" : "No",
            "is_brd_approved": programData.isBRDApproved ? "Yes" : "No",
            "is_on_roadmap": programData.isOnRoadmap ? "Yes" : "No",
        };
        setFormInput(formData);
        setInitialData(formData);
    }, [programData]);


    const setFieldValue = (field: string, value: any) => {
        setFormInput(values => ({
            ...values,
            [field]: value
        }));
    };

    const updateRPNName = (field: string, value: string) => {
        const error = validateProgramName(value);
        setRpnNameError(error ?? undefined);
        setFieldValue(field, value);
    };

    const save = async () => {
        const payload = {
            "rpn": formInput.rpn,
            "id": formInput.id,
            "user_id": getUserId() ?? '',
            ...(initialData.product_line.label !== formInput.product_line.label && {product_line: formInput.product_line.label}),
            ...(initialData.program_status.value !== formInput.program_status.value && {program_status: formInput.program_status.value}),
            ...(initialData.is_name_approved !== formInput.is_name_approved && {is_name_approved: formInput.is_name_approved === 'No' ? false : true}),
            ...(initialData.is_brd_approved !== formInput.is_brd_approved && {is_brd_approved: formInput.is_brd_approved === 'No' ? false : true}),
            ...(initialData.is_on_roadmap !== formInput.is_on_roadmap && {is_on_roadmap: formInput.is_on_roadmap === 'No' ? false : true}),
        };
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        console.log(graphQL_payload);// console added for beta testing
        await updateProgram(graphQL_payload).unwrap();
    };

    useEffect(() => {
        if (mutationData) {
            if (mutationData.errors || mutationError) setError?.(ALERT_MESSAGES.updateFailure);
            if (mutationData.data?.[MUTATION_METHODS.updateRPN]) {
                const { statusCode, error }: IApiUpdateResponse = mutationData.data[MUTATION_METHODS.updateRPN];
                if (statusCode === STATUS_CODES.success) {
                    setSuccess?.(ALERT_MESSAGES.updateProgramSuccess);
                    dispatch(resetProductHierarchy());
                    const href = `${SCREEN_PATHS.basePath}/${SCREEN_PATHS.programSetup}?${URL_PARAMS.productLine}=${formInput.product_line.value}-${formInput.product_line.label}&${URL_PARAMS.rpn}=${formInput.id}-${formInput.rpn}`;
                    navigate(href.substring(1), { replace: true });
                } else {
                    (statusCode === STATUS_CODES.handledException && error) ? setError?.(error) : setError?.(ALERT_MESSAGES.updateFailure);
                }
                setVisibile(false);
            }
        }
    }, [mutationData, mutationError]);

    return (
        <>
            <Modal
                onDismiss={() => setVisibile(false)}
                visible={visible}
                size="large"
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setVisibile(false)} variant="link">Cancel</Button>
                            <Button ariaLabel="save-program-change" className="bg-primary" disabled={_.isEqual(formInput, initialData) || rpnNameError ? true : false} onClick={save} variant="primary">Save</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Change program name/status"
            >
                {isLoading && <div className='loadspinner mg-top-md'><Spinner size="large" /></div>}
                {!isLoading &&
                    <ColumnLayout columns={2} variant="text-grid">
                        <div>
                            <SpaceBetween direction="vertical" size="l">
                                <FormField
                                    label="Program name"
                                    errorText={rpnNameError}
                                >
                                    <Input
                                        onChange={({ detail }) => updateRPNName("rpn", detail.value)}
                                        value={formInput["rpn"]!}
                                        placeholder="Enter program name"
                                        ariaLabel="program-name"
                                    />
                                </FormField>
                                <FormField label="Product line">
                                    <Select onChange={({ detail }) => setFieldValue("product_line", detail.selectedOption!)}
                                        options={allProductLines ? generateOptionsFromIdLabel(allProductLines) : []}
                                        selectedOption={formInput.product_line!}
                                        disabled={!programData.ppls_id ? false : true}
                                        filteringType="auto"
                                        placeholder="Select product line" />
                                </FormField>
                                <FormField label="Program status">
                                    <Select onChange={({ detail }) => setFieldValue("program_status", detail.selectedOption!)}
                                        options={SELECT_OPTIONS.status}
                                        selectedOption={formInput.program_status!}
                                        filteringType="auto"
                                        placeholder="Select product status" />
                                </FormField>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween direction="vertical" size="l">
                                <FormField label="Is name approved">
                                    <RadioGroup
                                        onChange={({ detail }) => setFieldValue("is_name_approved", detail.value)}
                                        value={formInput["is_name_approved"]!}
                                        items={SELECT_OPTIONS.yesNo}
                                    />

                                </FormField>
                                <FormField label="Is BRD approved">
                                    <RadioGroup
                                        onChange={({ detail }) => setFieldValue("is_brd_approved", detail.value)}
                                        value={formInput["is_brd_approved"]!}
                                        items={SELECT_OPTIONS.yesNo}
                                    />
                                </FormField>
                                <FormField label="Is on roadmap">
                                    <RadioGroup
                                        onChange={({ detail }) => setFieldValue("is_on_roadmap", detail.value)}
                                        value={formInput["is_on_roadmap"]!}
                                        items={SELECT_OPTIONS.yesNo}
                                    />
                                </FormField>
                            </SpaceBetween>
                        </div>
                    </ColumnLayout>
                }
            </Modal>
        </>
    );
};
