import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {
    const [componentData, setComponentData] = useState();
    const [productGroupData, setProductGroupData] = useState();
    const queryClient = useQueryClient();

    const fetchProductGroups = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getProductGroupQuery());
        const { body }: IApiResponse = response.data.data.get_product_gr;
        return body;
    };
    const updateProdGrp = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_product_gr'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_product_gr as IApiUpdateResponse;
    };

    const useProductGroupsData = () => {
        return useQuery(
            ['productGroup'],
            fetchProductGroups,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                    setProductGroupData(data);
                }
            }
        );
    };
    const useUpdatedProdGrpData = () => {
        return useMutation(
            (requestData) => updateProdGrp(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['productGroup']);
                }
            }
        );
    };

    return { componentData, useProductGroupsData, productGroupData, useUpdatedProdGrpData };
};