import { IValueDescription } from "src/components/Interfaces/interface";
import { ATTRIBUTE_TYPE_NAMES, NA_LABEL, PRIMARY_KEY_MAPPING, SEPERATOR, USER_POLICY_SEPERATOR } from "../constants/constants";
import { ISelectOptions } from "../interfaces/interfaces";


export default class ScreenUtils {

    public static readonly generateOptions = (values: any[], disabled : string[] = []) => {
        const options: ISelectOptions[] = [];
        values?.forEach((value: any) => {
            options.push({
                label: value,
                value: value,
                disabled: disabled.includes(value) ? true: false
            });
        });
        return options;
    }
    
    public static readonly generateOptionsWithDescription = (valuesWithDescriptions: IValueDescription) => {
        const options: ISelectOptions[] = [];
        Object.entries(valuesWithDescriptions).forEach(([value, description]) => {
            options.push({
                label: value,
                value: value,
                description: description,
            });
        });
        return options;
    }

    public static readonly getGraphQLPayload = (data: any) => JSON.stringify(data).replace(/"([^(")"]+)":/g, "$1:");

    public static readonly getUserId = () => sessionStorage.getItem('userName')?.split('_')[1] ?? '';

    public static readonly getVersionDescription = (data: Record<string, any>, attrOrder: string[], includeProdType: boolean = true) => 
        attrOrder?.filter(attr => includeProdType || (!includeProdType && attr !== ATTRIBUTE_TYPE_NAMES.productType)).map(attr => data[attr] ?? '').filter(val => val !== '' && val != NA_LABEL).join(', ') ?? '';

    public static readonly generateCombinations = (arrays: any) => {
        function helper(index: number, currentCombination: any[]) {
            if (index === arrays.length) {
                combinations.push(currentCombination.slice());
                return;
            }

            for (let i = 0; i < arrays[index].length; i++) {
                currentCombination.push(arrays[index][i]);
                helper(index + 1, currentCombination);
                currentCombination.pop();
            }
        }

        const combinations: any[] = [];
        helper(0, []);

        return combinations;
    }

    public static readonly getPLPrimaryKey = (id: any) => `${PRIMARY_KEY_MAPPING.pl}${SEPERATOR}${id}`

    public static readonly getPLUserPolicyKey = (id: any) => `${PRIMARY_KEY_MAPPING.pl}${USER_POLICY_SEPERATOR}${id}`

    public static readonly getRPNUserPolicyKey = (id: any) => `${PRIMARY_KEY_MAPPING.rpn}${USER_POLICY_SEPERATOR}${id}`
}
