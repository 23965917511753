import React from 'react';
import _ from 'lodash';
import { Box, Button, Checkbox, Grid, Icon, Select, SelectProps, Table, TableProps } from "@cloudscape-design/components";
import { ATTRIBUTES_ACTION } from '../../constants/constants';

export interface IPLAttributes {
    id: number,
    attribute: SelectProps.Option | null,
    isInheritedByFPN: boolean
}

export interface IAttributeTableProps {
    readOnly: boolean,
    allAttributes: SelectProps.Option[],
    setTableItems: any,
    tableItems: IPLAttributes[]
}

export const AttributeOrderingTable = ({ allAttributes, setTableItems, tableItems, readOnly }: IAttributeTableProps) => {

    const columnDefinitionsEdit: TableProps.ColumnDefinition<IPLAttributes>[] = [
        {
            id: 'id',
            header: '#',
            cell: (item: IPLAttributes) => item.id,
            maxWidth: 30
        },
        {
            id: 'PL attributes ',
            header: 'PL attributes',
            cell: (item: IPLAttributes) => (
                <Select
                    selectedOption={item.attribute}
                    onChange={({ detail }) =>
                        setSelectedAttribute(item.id, detail.selectedOption)
                    }
                    placeholder='Choose option for attribute'
                    options={getFilteredTypes()}
                    selectedAriaLabel="Selected"
                    expandToViewport
                    filteringType='auto'
                />
            ),
            minWidth: 200
        },
        {
            id: 'Use it in FPN',
            header: 'Use it in FPN',
            cell: (item: IPLAttributes) => (
                <Checkbox
                    onChange={({ detail }) =>
                        setIsInheritedByFPN(item.id, detail.checked)
                    }
                    checked={item.isInheritedByFPN}
                />
            ),
            maxWidth: 100
        },
        {
            id: 'Actions',
            cell: (item: IPLAttributes) => (
                <>
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                        <div >
                            <Button disabled={item.id > 1 ? false : true} onClick={() => reorder(item.id, ATTRIBUTES_ACTION.up)} variant="link" > Move up <Icon name="angle-up" /></Button>
                        </div>
                        <div className='border-left'>
                            <Button disabled={item.id >= tableItems.length ? true : false} onClick={() => reorder(item.id, ATTRIBUTES_ACTION.down)} variant="link" > Move down <Icon name="angle-down" /></Button>
                        </div>
                        <div className='border-left'>
                            <Button onClick={() => reorder(item.id, ATTRIBUTES_ACTION.remove)} variant="link" > Remove</Button>
                        </div>
                    </Grid>
                </>

            ),
            header: 'Actions',
            width: 550
        }
    ];

    const columnDefinitionsReadonly: TableProps.ColumnDefinition<IPLAttributes>[] = [
        {
            id: 'id',
            sortingField: 'id',
            header: '#',
            cell: (item: IPLAttributes) => item.id,
            maxWidth: 30
        },
        {
            id: 'PL attributes ',
            sortingField: 'PL attributes ',
            header: 'PL attributes ',
            cell: (item: IPLAttributes) => item.attribute?.label
        }
        // Temporary disable until logic is added {
        //     id: 'Use it in FPN',
        //     header: 'Use it in FPN',
        //     cell: (item: IPLAttributes) => {
        //         return item.isInheritedByFPN ? <Checkbox checked={item.isInheritedByFPN} /> : '';
        //     },
        //     maxWidth: 100
        // }
    ];

    const getFilteredTypes = () => {
        let attTypes = allAttributes;
        if (tableItems.length) {
            const existingIds = tableItems.map(item => item.attribute?.value);
            attTypes = allAttributes.filter(item => !existingIds.includes(item.value));
        }
        return _.sortBy(attTypes, ['label']);
    };

    const setSelectedAttribute = (id: number, option: SelectProps.Option) => {
        const items = [...tableItems];
        items[id - 1].attribute = option;
        setTableItems(items);
    };

    const setIsInheritedByFPN = (id: number, value: boolean) => {
        const items = [...tableItems];
        items[id - 1].isInheritedByFPN = value;
        setTableItems(items);
    };

    const reorder = (id: number, action: string) => {
        const index = id - 1;
        const items = [...tableItems];
        const actions: Record<string, () => void> = {
            up: () => [items[index], items[index - 1]] = [{ ...items[index - 1], id: index + 1 }, { ...items[index], id: index }],
            down: () => [items[index], items[index + 1]] = [{ ...items[index + 1], id: index + 1 }, { ...items[index], id: index + 2 }],
            remove: () => {
                items.splice(index, 1);
                items.forEach((obj, index) => obj.id = index + 1);
            }
        };
        actions[action]();
        setTableItems(items);
    };


    return (
        <Table
            columnDefinitions={readOnly ? columnDefinitionsReadonly : columnDefinitionsEdit}
            items={tableItems}
            sortingDisabled
            variant="embedded"
            resizableColumns={false}
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                This product line does not have any Level2 attributes.
                </Box>
            }
        ></Table>
    );
};
