import React from 'react';
import { Table, TableProps} from "@cloudscape-design/components";
import { IAttributesOrdering } from '../../interfaces/interfaces';

export const ATTRIBUTE_TYPE = {
    productLine: 'Product line',
    program: 'Program'
};

export interface IAttributeTableProps {
    tableItems: IAttributesOrdering[]
}

export const AttributeOrderingPanel = ({ tableItems }: IAttributeTableProps) => {

    const columnDefinitions: TableProps.ColumnDefinition<IAttributesOrdering>[] = [
        {
            id: 'id',
            header: '#',
            cell: (item: IAttributesOrdering) => item.id,
            maxWidth: 30
        },
        {
            id: 'attributes ',
            header: 'Attributes',
            cell: (item: IAttributesOrdering) => item.attribute,
            minWidth: 150
        },
        {
            id: 'type',
            header: 'Attribute level',
            cell: (item: IAttributesOrdering) => item.type,
            width: 120
        }
    ];

    return (
        <>
            <Table
                columnDefinitions={columnDefinitions}
                items={tableItems}
                sortingDisabled
                variant="embedded"
                resizableColumns={false}
            ></Table>
           
        </>

    );
};