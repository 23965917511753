import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';
export default () => {

    const [componentData, setComponentData] = useState();
    const [budgetTypeData, setBudgetTypeData] = useState();
    const queryClient = useQueryClient();

    const fetchBudgetType = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getBdjTypeQuery());
        const { body }: IApiResponse = response.data.data.get_bdgt_typ;
        return body;
    };

    const fetchBudgetTypeList = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getBdjTypeListQuery());
        const { body }: IApiResponse = response.data.data.get_bdgt_typ;
        return body;
    };

    const updateBudgetType = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_bdgt_typ'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_bdgt_typ as IApiUpdateResponse;
    };

    const deleteBudgetType = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'del_bdgt_typ'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.del_bdgt_typ as IApiUpdateResponse;
    };

    const useBudgetTypeData = () => {
        return useQuery(
            ['budgetType'],
            fetchBudgetType,
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useBudgetTypeListData = () => {
        return useQuery(
            ['budgetTypeList'],
            fetchBudgetTypeList,
            {
                onSuccess: (data) => {
                    setBudgetTypeData(data);
                }
            }
        );
    };
    const useUpdatedBudgetTypeData = () => {
        return useMutation(
            (requestData) => updateBudgetType(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['budgetType']);
                }
            }
        );
    };

    const useDeleteBudgetType = () => {
        return useMutation(
            (requestData) => deleteBudgetType(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['budgetType']);
                }
            }
        );
    };
   
    return { componentData, useBudgetTypeData, useUpdatedBudgetTypeData, useBudgetTypeListData, budgetTypeData, useDeleteBudgetType };
};


