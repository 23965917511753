import React, { useEffect, useRef, useState } from "react";
import { Container, Flashbar, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import { IFilters, InputPanel } from "./inputPanel";
import { useGetPRMGanttChartDataQuery } from "../../../services/apis/ganttChartApi";
import { HighchartGantt } from 'src/components/common/Highcharts/highchart'
import ScreenUtils from '../../../utils/screenUtils';
import { useAppSelector } from "src/components/redux/hooks";
import { selectAllRPNs } from "../../appLayout/appLayoutSlice";
import { STATUS_CODES } from "../../../constants/constants";
import { IApiResponse } from "src/components/Interfaces/interface";
import useNotification from "src/components/Hooks/notifications";
import { GANTT_CHART_MESSAGES } from "src/components/RPT/constants/displayMessages";
import ChartHelper from './chartHelper';

interface IChartDataAPIParams {
    product_line_id: number,
    attribute_ids: number[],
    scenario_id: number,
    country: string
}

export const ProductRoadmapChart = () => {
    const rpnIdName = useAppSelector(selectAllRPNs);
    const [chartParams, setChartParams] = useState<IChartDataAPIParams>({
        product_line_id: 0,
        attribute_ids: [],
        scenario_id: 0,
        country: ''
    });
    const [formInput, setFormInput] = useState<IFilters>();
    const [seriesData, setSeriesData] = useState<Highcharts.GanttPointOptionsObject[]>([]);
    const selectedRange = useRef({ min: new Date(), max: new Date() });
    const { notificationItems, addNotification, clearNotifications } = useNotification();
    const [chartError, setChartError] = useState(false);
    const [isCategorizedView, setIsCategorizedView] = useState(false);
    const { data: chartData, isError: dataErr, isLoading: dataLoading, isFetching: dataFetching } = useGetPRMGanttChartDataQuery({ params: chartParams }, { skip: !chartParams.product_line_id });

    const { generateIdLabelMap } = ScreenUtils;
    const { getMinMaxDateFromInput, generateChartSeriesData } = ChartHelper();

    const generateChart = (input: IFilters) => {
        setFormInput(input);
        selectedRange.current = getMinMaxDateFromInput(input.dateRange);
        const allAttrs = [...input.captionTitle, ...input.captionSubtitle, input.groupBy].map(item => parseInt(item?.value ?? '0'))
            .filter((value, index, array) => array.indexOf(value) === index);
        setChartParams({
            product_line_id: parseInt(input.productLine?.value ?? '0'),
            attribute_ids: allAttrs,
            scenario_id: parseInt(input.snroId?.value ?? '0'),
            country: input?.country?.value ?? ''
        });
    }

    useEffect(() => {
        selectedRange.current = getMinMaxDateFromInput(formInput?.dateRange ?? null);
    }, [formInput?.dateRange]);

    useEffect(() => {
        if ((chartData?.errors || dataErr) && !dataFetching && !dataLoading) {
            addNotification("error", "CHART_ERROR", GANTT_CHART_MESSAGES.getDataError);
            setChartError(true);
        } else if (chartData?.data?.get_prm_gantt_chart_data) {
            const { statusCode, body }: IApiResponse = chartData.data?.get_prm_gantt_chart_data;
            if (statusCode === STATUS_CODES.success) {
                setSeriesData(generateChartSeriesData(body, formInput!, rpnIdName, isCategorizedView));
                setChartError(false);
                clearNotifications();
            } else {
                addNotification("error", "CHART_ERROR", GANTT_CHART_MESSAGES.getDataError);
                setChartError(true);
            }
        }
    }, [chartData, formInput, dataErr, isCategorizedView]);

    return (
        <Container>
            <SpaceBetween direction="vertical" size="l">
                <Flashbar items={notificationItems} stackItems />
                <InputPanel generate={generateChart} loading={dataLoading || dataFetching} />
                {formInput?.productLine && formInput?.captionTitle &&
                    <>
                        {(dataLoading || dataFetching) ? <Spinner /> :
                            <>
                                {chartError ? <></> :
                                    <> <Toggle
                                        onChange={({ detail }) =>
                                            setIsCategorizedView(detail.checked)
                                        }
                                        checked={isCategorizedView}
                                    >
                                        Category view
                                    </Toggle>
                                        <HighchartGantt
                                            key={seriesData.toString()}
                                            seriesData={seriesData}
                                            enableNavigator={true}
                                            uniqueNames={isCategorizedView}
                                            groupBy={formInput?.groupBy?.label ?? ''}
                                            titles={generateIdLabelMap(formInput?.captionTitle ?? [])}
                                            subtitles={generateIdLabelMap(formInput?.captionSubtitle ?? [])}
                                            dateRangeMin={Date.parse(selectedRange.current.min.toUTCString())}
                                            dateRangeMax={Date.parse(selectedRange.current.max.toUTCString())}
                                        />
                                    </>}
                            </>
                        }
                    </>
                }
            </SpaceBetween>
        </Container>
    );
};

