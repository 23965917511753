import React, { ElementRef, forwardRef } from 'react';
import { FastField, Field, FieldProps } from 'formik';
import PolarisRadioGroup, {
  RadioGroupProps as PolarisRadioGroupProps,
} from '@cloudscape-design/components/radio-group';
import { FormikFieldProps } from '../../field-props';

type RadioGroupProps = FormikFieldProps & Omit<PolarisRadioGroupProps, 'value'>;
type RadioGroupRef = ElementRef<typeof PolarisRadioGroup>;

const RadioGroup = forwardRef<RadioGroupRef, RadioGroupProps>(
  ({ name, validate, fast, onChange, ...props }, ref) => {
    const DesiredField = fast === true ? FastField : Field;

    return (
      <DesiredField name={name} validate={validate} fast={fast}>
        {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
          <PolarisRadioGroup
            ref={ref}
            value={value}
            name={name}
            onChange={(event) => {
              setFieldValue(name, event.detail.value);
              onChange?.(event);
            }}
            {...props}
          />
        )}
      </DesiredField>
    );
  }
);
RadioGroup.displayName = 'RadioGroup';

export { RadioGroup };