import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { Button, SpaceBetween } from "@cloudscape-design/components";
import { selectLocationData } from "../appLayout/appLayoutSlice";
import { useAppSelector } from "../../../redux/hooks";
import { selectAttributesClientData, selectAttributesServerData, selectAttributeModified, selectUserRole, selectSameForAllFPNs, selectFPNsList } from '../attributes/attributesSlice';
import { useUpdateAttrListRPNMutation, useUpdateAttrTypeRPNMutation, useUpdateAttrListFPNMutation } from '../../../redux/services/attributesApi';
import { ALERT_MESSAGES, STATUS_CODES, TAB_ID_LABEL, MUTATION_METHODS, USER_ACTION_ROLES, ATTRIBUTE_TYPE_NAMES, SEPERATOR, SK_MAPPING, MUTATION_ACTION } from '../../constants/constants';
import { IAttrListMutation, IFPNAttrListMutation, IRPNAttrTypeMutation, IResponsePayload } from "../../interfaces/interfaces";
import { IApiUpdateResponse } from "../../../Interfaces/interface";
import AlertMessageContext from "../../../common/Contexts/alertMessageContext";
import ScreenUtils from "../../utils/screenUtils";
import { useUpdateProgramSetupMutation } from "../../services/api";

export const SubmitAttributes = ({ setIsUpdateLoading }: any) => {

    const clientData = useAppSelector(selectAttributesClientData);
    const serverData = useAppSelector(selectAttributesServerData);
    const { fpn, program } = useAppSelector(selectLocationData);
    const isModified = useAppSelector(selectAttributeModified);
    const locationData = useAppSelector(selectLocationData);
    const sameForAllFPNs = useAppSelector(selectSameForAllFPNs);
    const fpnList = useAppSelector(selectFPNsList);
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const { getGraphQLPayload, getUserId, generatePayload } = ScreenUtils;
    const [updateAttrList, { isLoading: attrListLoading, data: attrListMutationData, isError: attrListError }] = useUpdateAttrListRPNMutation();
    const [updateAttrListFPN, { isLoading: attrListFPNLoading, data: attrListFPNMutationData, isError: attrListFPNError }] = useUpdateAttrListFPNMutation();
    const [updateAttrType, { isLoading: attrTypeLoading, data: attrTypeMutationData, isError: attrTypeError }] = useUpdateAttrTypeRPNMutation();
    const [updateProgramSetup] = useUpdateProgramSetupMutation();
    const [attrListPayload, setAttrListPayload] = useState<string>('');
    const [attrListFPNPayload, setAttrListFPNPayload] = useState<string>('');
    const [keyDatesPayload, setKeyDatesPayload] = useState<string>('');
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole));
    const saveDisabled = !isModified || !hasWriteAccess || attrListLoading || attrTypeLoading || attrListFPNLoading;

    useEffect(() => {
        setIsUpdateLoading(attrListLoading);
    }, [attrListLoading]);

    useEffect(() => {
        setIsUpdateLoading(attrTypeLoading);
    }, [attrTypeLoading]);

    useEffect(() => {
        setIsUpdateLoading(attrListFPNLoading);
    }, [attrListFPNLoading]);

    const handleMutationResponse = (respData: any, error: boolean, method: string) => {
        if (respData?.errors || error) setError?.(ALERT_MESSAGES.updateFailure);
        if (respData?.data && respData?.data[method]) {
            const { statusCode, error }: IApiUpdateResponse = respData.data[method];
            if (statusCode === STATUS_CODES.success) {
                if (method === MUTATION_METHODS.updateAttrTypeRPN) {
                    attrListPayload ? attrListFPNPayload ? handleAttrListFPNUpdate(attrListFPNPayload) : handleAttrListUpdate(attrListPayload)  : setSuccess?.(ALERT_MESSAGES.updateSuccess);
                } else if (fpn.id || method === MUTATION_METHODS.updateAttrListRPN) setSuccess?.(ALERT_MESSAGES.updateSuccess);
                if (method !== MUTATION_METHODS.updateAttrTypeRPN && keyDatesPayload) handleDynamodbUpdate(keyDatesPayload);
                if (method === MUTATION_METHODS.updateAttrListFPN && attrListPayload) handleAttrListUpdate(attrListPayload);
            } else setError?.(statusCode === STATUS_CODES.handledException ? error : ALERT_MESSAGES.updateFailure);
        }
    };

    useEffect(() => {
        handleMutationResponse(attrTypeMutationData, attrTypeError, MUTATION_METHODS.updateAttrTypeRPN);
    }, [attrTypeMutationData, attrTypeError]);

    useEffect(() => {
        handleMutationResponse(attrListMutationData, attrListError, MUTATION_METHODS.updateAttrListRPN);
    }, [attrListMutationData, attrListError]);

    useEffect(() => {
        handleMutationResponse(attrListFPNMutationData, attrListFPNError, MUTATION_METHODS.updateAttrListFPN);
    }, [attrListFPNMutationData, attrListFPNError]);

    const getDifference = (arr1: string[], arr2: string[]) => arr1.filter(obj1 => !arr2.some(obj2 => obj1 === obj2));

    /*
    Sequence of APIs executed: 
    RPN: updateAttrTypeRPN (if L3 attr types added) -> updateAttrListFPN (if SAME_for_ALL_FPNS) -> updateAttrListRPN -> KeyDatesDynamoDB (if country updated)
    FPN: updateAttrListFPN -> KeyDatesDynamoDB (if country updated)
    */
    const submit = async () => {
        const userId = getUserId();
        const isAttrTypeUpdated = !_.isEqual(clientData.allProgAttributes, serverData.allProgAttributes);
        const isAttrListUpdated = !_.isEqual(clientData.allAttributesValues, serverData.allAttributesValues);
        if (isAttrTypeUpdated && !fpn.id) {
            const attrTypePayload: IRPNAttrTypeMutation[] = [];
            clientData.allProgAttributes.forEach(item => {
                attrTypePayload.push({
                    attribute_type: item.attribute_type,
                    in_fpn: item.in_fpn,
                    rpn_attr_order: item.rpn_attr_order,
                    user_id: userId,
                    verity_rpn_id: parseInt(program.id)
                });
            });
            if (attrTypePayload.length) handleAttrTypeUpdate(getGraphQLPayload(attrTypePayload));
        }
        if (isAttrListUpdated) {
            const attrListPayload: IAttrListMutation[] = [];
            const attrListFPNPayload: IFPNAttrListMutation[] = [];
            const keyDatesPayload: IResponsePayload[] = [];
            Object.entries(clientData.allAttributesValues).forEach(([type, values]) => {
                const serverValues = serverData.allAttributesValues?.[type] ?? [];
                const added = getDifference(values, serverValues);
                const deleted = getDifference(serverValues, values);
                if (added.length || deleted.length) {
                    attrListPayload.push({
                        attribute_type: type,
                        add_attribute_value: added,
                        remove_attribute_value: deleted,
                        user_id: userId,
                        ...(fpn.id ? { verity_fpn_id: parseInt(fpn.id) } : { verity_rpn_id: parseInt(program.id) })
                    });
                    if (!fpn.id && sameForAllFPNs.includes(type)) {
                        fpnList?.forEach(obj => {
                            attrListFPNPayload.push({
                                attribute_type: type,
                                add_attribute_value: added,
                                remove_attribute_value: deleted,
                                user_id: userId,
                                verity_fpn_id: obj.verity_fpn_id
                            });
                        });
                    }
                }
                if (type === ATTRIBUTE_TYPE_NAMES.country) {
                    // Create key dates for newly added countries
                    added.forEach((item: string) => {
                        keyDatesPayload.push({
                            PK: ScreenUtils.getPrimaryKey(locationData, ''),
                            SK: TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.streetDates + SEPERATOR + item,
                            Action: MUTATION_ACTION.put,
                            ItemValues: null,
                            Version: 0
                        });
                    });
                    // Delete key dates for removed countries
                    deleted.forEach((item: string) => {
                        keyDatesPayload.push({
                            PK: ScreenUtils.getPrimaryKey(locationData, ''),
                            SK: TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.streetDates + SEPERATOR + item,
                            Action: MUTATION_ACTION.delete
                        });
                    });
                }
            });
            const payload = getGraphQLPayload(attrListPayload);
            const fpnPayload = getGraphQLPayload(attrListFPNPayload);
            if (payload) {
                if (keyDatesPayload.length) setKeyDatesPayload(generatePayload(keyDatesPayload) ?? '');
                // For RPN standard attributes with SAME_FOR_ALL_FPNS fields: First FPNs will be updated, so store RPN payload to execute later
                if (attrListFPNPayload.length) setAttrListPayload(payload ?? '');
                if (isAttrTypeUpdated) {
                    attrListFPNPayload.length > 0 ? setAttrListFPNPayload(fpnPayload ?? '') : setAttrListPayload(payload ?? '');
                } else {
                    fpn.id ? handleAttrListFPNUpdate(payload) : attrListFPNPayload.length > 0 ? handleAttrListFPNUpdate(fpnPayload) : handleAttrListUpdate(payload)
                }
            }
        }
    };

    const handleAttrTypeUpdate = async (mutationPayload: string) => await updateAttrType(mutationPayload).unwrap();

    const handleAttrListUpdate = async (mutationPayload: string) => {
        if (mutationPayload.length) await updateAttrList(mutationPayload).unwrap();
    };

    const handleAttrListFPNUpdate = async (mutationPayload: string) => {
        if (mutationPayload.length) await updateAttrListFPN(mutationPayload).unwrap();
    };

    const handleDynamodbUpdate = async (mutationPayload: string) => await updateProgramSetup(mutationPayload).unwrap();

    return (
        <><SpaceBetween direction="horizontal" size="s">
            <Button className="bg-primary" disabled={saveDisabled} onClick={submit} variant="primary">Save {TAB_ID_LABEL.attributes}</Button>
        </SpaceBetween>
        </>

    );

};
