import React, { useState, useEffect } from 'react';
import { ColumnLayout, Container, Header, MultiselectProps, Spinner } from "@cloudscape-design/components";
import { SK_MAPPING, TAB_ID_LABEL, SEPERATOR, API_RESPONSE_FIELDS, USER_ACTION_ROLES } from '../../constants/constants';
import { useGetListOfUserDetailsQuery } from '../../services/api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { CONTACT_INFO, IFieldMetaData } from '../../constants/fieldData';
import { updateContactInfo, selectContactInfo, selectUserRole } from '../programOverview/programOverviewSlice';
import ScreenUtils from '../../utils/screenUtils';
import { UserSearchMultiselect, IUserMultiselectProps, getUserOption } from '../userSearch/userSearchMultiselect';
import { selectLocationData, selectUserDetailsMap, updateUserDetailsMap } from '../appLayout/appLayoutSlice';
import { IApiUserResponse, IResponsePayload } from '../../interfaces/interfaces';

export const ContactInformation = () => {
    const dispatch = useAppDispatch();
    const contactInfo = useAppSelector(selectContactInfo);
    const locationData = useAppSelector(selectLocationData);
    const userDetailsMap = useAppSelector(selectUserDetailsMap);
    const [formInput, setFormInput] = useState<IUserMultiselectProps[]>();
    const [userAliases, setUserAliases] = useState<string[]>([]);
    const { data: userData, isLoading: usersLoading } = useGetListOfUserDetailsQuery(userAliases, { skip: userAliases.length === 0 });
    const hasWriteAccess = USER_ACTION_ROLES.approve.includes(useAppSelector(selectUserRole));

    const updateUserValues = (fieldValue: MultiselectProps.Option[], fieldName: string) => {
        const userMap: Record<string, MultiselectProps.Option> = {};
        const values: string[] = [];
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.overview + SEPERATOR + SK_MAPPING.contactInfo + SEPERATOR + fieldName;
        fieldValue.forEach((obj: MultiselectProps.Option) => {
            if (obj.value) {
                if (!userDetailsMap[obj.value]) userMap[obj.value] = obj;
                values.push(obj.value);
            }
        });
        dispatch(updateUserDetailsMap(userMap));
        dispatch(updateContactInfo(ScreenUtils.updateClientState(values, fieldName, API_RESPONSE_FIELDS.itemValues, contactInfo, PK, SK)));
    };

    useEffect(() => {
        const aliases: string[] = [];
        Object.values(contactInfo).forEach((obj: IResponsePayload) => {
            obj.ItemValues.forEach((alias: string) => {
                if (!userDetailsMap[alias]) aliases.push(alias);
            });
        });
        setUserAliases(aliases);
    }, [contactInfo]);

    useEffect(() => {
        if (userData) {
            const userMap: Record<string, MultiselectProps.Option> = {};
            userData.forEach((obj: IApiUserResponse) => {
                if (obj?.exact_match?.length > 0) userMap[obj.exact_match[0].employee_login] = getUserOption(obj.exact_match[0]);
            });
            dispatch(updateUserDetailsMap(userMap));
        }
    }, [userData]);

    useEffect(() => {
        const fMetaData: Record<string, IFieldMetaData> = { ...CONTACT_INFO };
        const props: IUserMultiselectProps[] = [];
        Object.entries(fMetaData).forEach(([field]) => {
            const values: MultiselectProps.Option[] = [];
            if (contactInfo[field] && contactInfo[field].ItemValues) {
                contactInfo[field].ItemValues.forEach((alias: string) => {
                    if (userDetailsMap[alias]) values.push(userDetailsMap[alias]);
                });
            }
            props.push({
                name: field,
                selectedValues: values,
                label: fMetaData[field].label,
                readOnly: locationData.fpn.id ? true : !hasWriteAccess,
                action: updateUserValues
            });
        });
        setFormInput(props);
    }, [contactInfo, userDetailsMap, hasWriteAccess]);

    const loadContactEdit = () => {
        if (usersLoading) return <div className='loadspinner mg-top-md'><Spinner size="large" /></div>;
        if (formInput) {
            return (
                <>
                    <ColumnLayout columns={2}>
                        {formInput.map((field: IUserMultiselectProps) => {
                            return (
                                <div key={field.name}>
                                    <UserSearchMultiselect
                                        name={field.name}
                                        selectedValues={field.selectedValues}
                                        label={field.label}
                                        readOnly={field.readOnly ?? false}
                                        action={field.action}
                                    />
                                </div>
                            );
                        })}
                    </ColumnLayout>
                </>
            );
        } else {
            return "No data to display.";
        }
    };

    return (
        <>
            <Container
                header={
                    <Header variant="h2">
                        Contact Information
                    </Header>
                }
            >
                {loadContactEdit()}
            </Container>
        </>

    );
};
