import React from "react";
import { Textarea } from '../../Formik-Cloudscape/components/textarea/textarea';
import { FormField } from '../../Formik-Cloudscape/components/form-field/form-field';
import { IDynamicFieldProps } from "../Interfaces";

export const TextAreaField = ({ name, readOnly, label, action, placeholder }: IDynamicFieldProps) => {

    return (
        <>
            <FormField stretch label={label} name="input">
                <Textarea name={name}
                    placeholder={placeholder}
                    readOnly={readOnly || false}
                    onChange={({ detail }) => {
                        action(detail.value, name);
                    }} />
            </FormField>
        </>
    )
}