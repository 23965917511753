import { ColDef, ColGroupDef } from "ag-grid-community";
import GridValidation from "./gridValidation";

export const REQUIRED = {
    'product_code': 'Product Code',
    'product_name': 'Product Name',
    'product_group': 'Product Group'
};

export const COL_DEFS: (ColGroupDef | ColDef)[]  = [
    {
        headerName: "Product Code",
        field: "product_code",
        editable: GridValidation.existingRecord,
        cellEditor: 'agSelectCellEditor',
        suppressPaste: true,
        cellEditorPopup: true,
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Product Name",
        field:"product_name",
        editable: false,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Product Group",
        field: "product_group",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: []
        }
    },
    {
        headerName: "Is Accessory",
        field: "is_accessory",
        editable: true,
        suppressPaste: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: [1, 0, undefined]
        },
        valueFormatter: GridValidation.intToBoolean
    },
    {
        headerName: "Geo Market",
        field: "geo_market",
        editable: true,
        valueParser: GridValidation.trimValue
    },
    {
        headerName: "Comments",
        field: "comments",
        editable: true,
        valueParser: GridValidation.trimValue
    }
];
