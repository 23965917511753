export const MILLISECONDS_PER_SECOND = 1000;
	 export const MILLISECONDS_PER_MINUTE = 60 * MILLISECONDS_PER_SECOND;
	 export const MILLISECONDS_PER_HOUR = 60 * MILLISECONDS_PER_MINUTE;
	 export const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;
	 export const SECONDS_PER_MINUTE = 60;
	  
	 /**
	  * Convenience class wrapper to promote readable code
	  */
	 class Time {
	     static seconds(val: number) {
	         return val * MILLISECONDS_PER_SECOND;
	     }
	     static minutes(val: number) {
	         return val * MILLISECONDS_PER_MINUTE;
	     }
	     static hours(val: number) {
	         return val * MILLISECONDS_PER_HOUR;
	     }
	     static days(val: number) {
	         return val * MILLISECONDS_PER_DAY;
	     }
	 }
	  
	 export default Time;