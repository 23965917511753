import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { assortment } from '../../interfaces/assortmentInterface';


type AssortmentState = {
    assortmentsLoading: boolean,
    selectedAssortments: any,
    existingAssortmentsStrings: string[],
    assortments: {
        assortments: Record<string, assortment> | null
        assortmentsError: any
    }
  };

const initialState: AssortmentState = {
    assortmentsLoading: false,
    selectedAssortments: [],
    existingAssortmentsStrings:[],
    assortments: {
        assortments: null,
        assortmentsError: null
    }
};

export const assortmentSlice = createSlice({
    name: 'assortment',
    initialState,
    reducers: {
        resetAssortmentData: (state) => {
            state.selectedAssortments = initialState.selectedAssortments;
        },
        updateAssortmentsLoading: (state, action) => {
            state.assortmentsLoading = action.payload;
        },
        updateSelectedAssortments: (state, action) => {
            state.selectedAssortments = [...action.payload];
        },
        updateExistingAssortmentsStrings: (state, action) => {
            state.existingAssortmentsStrings = [...action.payload];
        },
        updateAssortments: (state, action) => {
            const {assortments, assortmentsError, assortmentsLoading } = action.payload;
            state.assortmentsLoading = assortmentsLoading;
            state.assortments = {assortments, assortmentsError};
        }
    }
});

export const { resetAssortmentData, updateSelectedAssortments, updateExistingAssortmentsStrings, updateAssortments, updateAssortmentsLoading } = assortmentSlice.actions;

export default assortmentSlice.reducer;

export const getSelectedAssortments = (state: RootState) => state.assortment.selectedAssortments;
export const getExistingAssortmentsStrings = (state: RootState) => state.assortment.existingAssortmentsStrings;
export const getAssortments = (state: RootState) => state.assortment.assortments;
export const getAssortmentsLoading = (state: RootState) => state.assortment.assortmentsLoading;