import React, { ReactElement, createContext } from "react";
import costCenterHelper from './costCenterHelper';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";
import { IApiUpdateResponse } from '../Interfaces/interface';

interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: [];
    framesCCData?: [];
    useCostCenterData: () => UseQueryResult; 
    useFramesCCData: () => UseQueryResult; 
    useUpdatedCCData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const CostCenterContext = createContext<IProjectsContext>({
    useCostCenterData: () => qResp,
    useFramesCCData: () => qResp,
    useUpdatedCCData: () => mResp
});

export const CostCenterContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...costCenterHelper()
    };

    return (
        <CostCenterContext.Provider value={provider}>
            {children}
        </CostCenterContext.Provider>
    );
};
