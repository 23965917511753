import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';

import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import { ensureAuthenticated, getValidToken } from './components/common/Auth/Auth';

import '@amzn/katal-components/styles.css';
import './index.scss';

ensureAuthenticated().then(async ()=>{
  await getValidToken(false);
  ReactDOM.render(
    <React.StrictMode>
      <AppInitWrapper>
        <React.Suspense fallback={<KatSpinner size='large' />}>
          <App />
        </React.Suspense>
      </AppInitWrapper>
    </React.StrictMode>,
    document.getElementById('root')
    );
}).catch((err)=>{
  console.error(err);
});