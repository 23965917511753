import React, { useState, useEffect } from 'react';
import { Box, Container, DatePicker, Header, SpaceBetween, Spinner, Table, TableProps, Toggle } from "@cloudscape-design/components";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { OTHER_KEY_DATES } from '../../constants/fieldData';
import { selectOtherDates, updateOtherDates } from './keyDatesSlice';
import ScreenUtils from '../../utils/screenUtils';
import { SEPERATOR, SK_MAPPING, TAB_ID_LABEL, API_RESPONSE_FIELDS, USER_ACTION_ROLES, ALL_ASSIGNEES_KEY, UPDATED_FIELD } from '../../constants/constants';
import { selectLocationData } from '../appLayout/appLayoutSlice';
import { SubmitKeyDates } from './submitKeyDates';
import { ProgramAssignees } from '../userPolicy/program/assigneesList';
import { selectRoleResourceMap } from '../userPolicy/userPolicySlice';
import UserPolicyHelper from '../userPolicy/userPolicyHelper';
import { SANDBOX_UNAPPROVED_MESSAGE, VIEW_WIP_BUTTON } from '../../constants/displayMessages';

interface ITableItems {
    name: string,
    value: string,
    isUpdated: boolean
}
export const OtherKeyDates = () => {
    const dispatch = useAppDispatch();
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const locationData = useAppSelector(selectLocationData);
    const otherKeyDates = useAppSelector(selectOtherDates);
    const [items, setItems] = useState<ITableItems[]>([]);
    const roleResMap = useAppSelector(selectRoleResourceMap);
    const [userRole, setUserRole] = useState('');
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const { getUserRole } = UserPolicyHelper();
    const [viewApproved, setViewApproved] = React.useState(false);
    const readOnly = !hasWriteAccess || viewApproved;

    useEffect(() => {
        const currRole = getUserRole(roleResMap, [ALL_ASSIGNEES_KEY, TAB_ID_LABEL.keyDates, TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.streetDates]);
        setUserRole(currRole);
        if (USER_ACTION_ROLES.write.includes(currRole)) setHasWriteAccess(true);
    }, [roleResMap, locationData]);

    useEffect(() => {
        const items: ITableItems[] = [];
        if (otherKeyDates) {
            OTHER_KEY_DATES.forEach((name: string) => {
                const data = otherKeyDates[name];
                const values = viewApproved ? data?.ItemValuesWIP ?? '' : data?.ItemValues ?? '';
                const isUpdated = !viewApproved && data && !data.Action && data.ItemValues !== data.ItemValuesWIP;
                items.push({
                    name: name,
                    value: values,
                    isUpdated: isUpdated
                });
            });
        }
        setItems(items);
    }, [otherKeyDates, userRole, viewApproved]);

    const updateState = (fieldValue: any, fieldName: string) => {
        const PK = ScreenUtils.getPrimaryKey(locationData, 'Sandbox');
        const SK = TAB_ID_LABEL.keyDates + SEPERATOR + SK_MAPPING.otherDates + SEPERATOR + fieldName;
        dispatch(updateOtherDates(ScreenUtils.updateClientState(fieldValue, fieldName, API_RESPONSE_FIELDS.itemValues, otherKeyDates, PK, SK)));
    };

    const columnDefinitions: TableProps.ColumnDefinition<ITableItems>[] = [
        {
            id: 'name',
            sortingField: 'name',
            header: 'Key Dates',
            cell: (item: any) => {
                return (<>{item.name}<strong>{item.isUpdated && UPDATED_FIELD}</strong></>);
            }
        },
        {
            id: "value",
            header: "",
            cell: (item: any) => {
                return (<DatePicker
                    value={item.value}
                    onChange={({ detail }) => {
                        updateState(detail.value, item.name);
                    }}
                    readOnly={readOnly}
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                    expandToViewport
                />);
            }
        }

    ];

    const loadKeyDatesTable = () => {
        return (
            <>
                <Table
                    columnDefinitions={columnDefinitions}
                    items={items}
                    variant="embedded"
                    loadingText="Loading resources"
                    sortingDisabled

                ></Table>
            </>
        );
    };

    return (
        <>
            <Container className=''
                header={
                    <SpaceBetween direction="vertical" size="l" >
                        <Header
                            variant="h2"
                            actions={
                                <Box float="right" variant='span'>
                                    <SubmitKeyDates
                                        setIsUpdateLoading={setIsUpdateLoading}
                                        userRole={userRole}
                                        section={SK_MAPPING.otherDates} />
                                </Box>
                            }
                            info={<ProgramAssignees
                                tab={TAB_ID_LABEL.keyDates}
                                section={SK_MAPPING.otherDates}
                            />}
                        >
                            Other key dates
                        </Header>
                        <Box float='right'>
                            <Toggle
                                onChange={({ detail }) =>
                                    setViewApproved(detail.checked)
                                }
                                checked={viewApproved}
                            >
                                {VIEW_WIP_BUTTON}
                            </Toggle>
                        </Box>

                    </SpaceBetween>

                }
                footer={<><strong>{UPDATED_FIELD}</strong> {SANDBOX_UNAPPROVED_MESSAGE}</>}
            >

                {!isUpdateLoading ?
                    <>{loadKeyDatesTable()}</> :
                    <div className='loadspinner mg-top-md'><Spinner size="large" /></div>
                }
            </Container>
        </>

    );
};

