import userQueries from '../queries/userPolicyQueries';
import Queries from '../queries/commonQueries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

export const scenarioUserPolicyApi = verityApi.injectEndpoints({
    endpoints: (build) => ({
        //current logged in user
        getCurrSnrUserPolicyData: build.query({
            query: ({ userName }) => ({
                method: API_METHODS.post, data: {
                    "query": userQueries.getUserAccessSnro(), 
                    "variables": { "user_name": userName}
                }
            }),
            providesTags: ({userName}) => [{ type: 'UserPolicy', id: userName }],
            transformResponse: (response: any) => response.data
        }),
        //other users for admin panel
        getSnrUserPolicy: build.query({
            query: ({ userName }) => ({
                method: API_METHODS.post, data: {
                    "query": userQueries.getUserAccessSnro(), 
                    "variables": { "user_name": userName}
                }
            }),
            transformResponse: (response: any) => response.data
        }),
        addSnrUserAccess: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.addUserAccessSnr) }),
            transformResponse: (response: any) => response.data
        })
    })
});

export const { 
    useGetCurrSnrUserPolicyDataQuery,
    useGetSnrUserPolicyQuery,
    useAddSnrUserAccessMutation
} = scenarioUserPolicyApi;