import React, { ReactElement, createContext } from "react";
import productGroupHelper from './productGroupHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";
interface IProductGroupProviderProps {
    children: ReactElement | JSX.Element;
}

type IProductGroupContext = {
    componentData?: [];
    useProductGroupsData: () => UseQueryResult;
    useUpdatedProdGrpData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}

const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const ProductGroupContext = createContext<IProductGroupContext>({
    useProductGroupsData: () => qResp,
    useUpdatedProdGrpData: () => mResp
});

export const ProductGroupContextProvider = ({ children }: IProductGroupProviderProps): ReactElement => {
    const provider = {
        ...productGroupHelper()
    };

    return (
        <ProductGroupContext.Provider value={provider}>
            {children}
        </ProductGroupContext.Provider>
    );
};
