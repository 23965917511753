import { useState } from "react";
import { AsyncResponse } from "../common/AsyncHandler/WebSocketHandler";

export interface IAsyncResponseState {
  getCurrentProcessId: (action: string) => string | undefined;
  setCurrentProcessId: (action: string, processId?: string) => void;
  deleteCurrentProcessId: (action: string) => void;
  getAsyncResponseByProcessId: (processId?: string) => AsyncResponse;
  updateAsyncResponseByProcessId: (processId?: string, response?: AsyncResponse) => void;
  deleteAsyncResponseByProcessId: (processId?: string) => void;
}

export const useAsyncResponse = (): IAsyncResponseState => {
    const [processIdByActionRecord, setProcessIdByActionRecord] = useState<Record<string, string>>({});
    const [asyncResponseRecord, setAsyncResponseRecord] = useState<Record<string, AsyncResponse>>({});

    const getCurrentProcessId = (action: string) => {
        return processIdByActionRecord[action];
    };

    const setCurrentProcessId = (action: string, processId: string) => {
        setProcessIdByActionRecord({ ...processIdByActionRecord, [action]: processId });
    };

    const deleteCurrentProcessId = (action: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [action]: actionName, ...rest } = processIdByActionRecord;
        setProcessIdByActionRecord(rest);
    };

    const getAsyncResponseByProcessId = (
        processId: string
    ): AsyncResponse | undefined => {
        if (processId) {
            return asyncResponseRecord[processId];
        }
    };

    const updateAsyncResponseByProcessId = (
        processId: string,
        response: AsyncResponse
    ) => {
        if (processId) {
            setAsyncResponseRecord({ ...asyncResponseRecord, [processId]: response });
        }
    };

    const deleteAsyncResponseByProcessId = (
        processId: string
    ) => {
        if (processId) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [processId]: processID, ...rest } = asyncResponseRecord;
            setAsyncResponseRecord(rest);
        }
    };

    return {
        getCurrentProcessId,
        setCurrentProcessId,
        deleteCurrentProcessId,
        getAsyncResponseByProcessId,
        updateAsyncResponseByProcessId,
        deleteAsyncResponseByProcessId
    } as IAsyncResponseState;
};
