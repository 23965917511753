import Queries from '../queries';
import { API_METHODS, MUTATION_METHODS } from '../../constants/constants';
import { verityApi } from '../../../redux/api';

const { getUserAccess } = Queries;
// These APIs are used in PRM app layout & Admin module
export const userPolicyApi = verityApi.injectEndpoints({
    endpoints: (build) => ({
        //Current logged in user
        getUserPolicyData: build.query({
            query: ({ userName }) => ({
                method: API_METHODS.post, data: {
                    "query": getUserAccess(), 
                    "variables": { "user_name": userName}
                }
            }),
            providesTags: ({userName}) => [{ type: 'PRMUserPolicy', id: userName }],
            transformResponse: (response: any) => response.data
        }),
        //Used in admin screens to fetch other users access
        getPRMUserPolicy: build.query({
            query: ({ userName }) => ({
                method: API_METHODS.post, data: {
                    "query": getUserAccess(), 
                    "variables": { "user_name": userName}
                }
            }),
            providesTags: ({userName}) => [{ type: 'PRMUserPolicy', id: userName }],
            transformResponse: (response: any) => response.data
        }),
        updateUserAccess: build.mutation({
            query: (data: string) => ({ method: API_METHODS.post, data: Queries.getUpdateQuery(data, MUTATION_METHODS.updateUserAccess) }),
            transformResponse: (response: any) => response.data
        })
    })
});

export const { 
    useGetUserPolicyDataQuery,
    useGetPRMUserPolicyQuery,
    useUpdateUserAccessMutation
} = userPolicyApi;