import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/store';
import { ALL_ASSIGNEES_KEY, SEPERATOR, USER_ASSIGNEES, USER_ROLES } from '../../constants/constants';
import { userPolicyApi } from '../../services/apis/userPolicyApi';
import { plAPIs } from '../../services/apis/productLineApi';
import ScreenUtils from '../../utils/screenUtils';

type UserAccessState = {
    userPolicyData: Record<string, Record<string, string[]>>;
    programAssignees: Record<string, Record<string, Record<string, any>>>;
    productLineAssignees: Record<string, any>;
    progRoleResourceMap: Record<string, any[]>;
    isCurrUserPolicyLoading: boolean
};

const initialState: UserAccessState = {
    userPolicyData: {}, //This is set below using extraReducers on app load. 
    programAssignees: {}, // This is set in programSetupHome on program change using metadata from dynamodb
    productLineAssignees: {}, // This is set in programSetupHome on PL change using metadata from dynamodb
    progRoleResourceMap: {}, // This is set in programSetupHome on program change using userPolicyData
    isCurrUserPolicyLoading: false
};

export const userPolicySlice = createSlice({
    name: 'userPolicy',
    initialState,
    reducers: {
        resetUserPolicy: () => initialState,
        resetProgAssignees: (state) => {
            state.programAssignees = initialState.programAssignees;
        },
        resetPLAssignees: (state) => {
            state.productLineAssignees = initialState.productLineAssignees;
        },
        resetRoleResourceMap: (state) => {
            state.progRoleResourceMap = initialState.progRoleResourceMap;
        },
        updateProgAssignee: (state, action) => {
            state.programAssignees = action.payload;
        },
        updatePLAssignee: (state, action) => {
            state.productLineAssignees = action.payload;
        },
        updateRoleResourceMap: (state, action) => {
            state.progRoleResourceMap = action.payload;
        },
        updateCurrUserPolicyLoading: (state, action) => {
            state.isCurrUserPolicyLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                userPolicyApi.endpoints.getUserPolicyData.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const userPolicyData: Record<string, any> = {};
                        payload.data?.get_user_access?.body?.forEach((obj: any) => {
                            if (obj.roles?.length && obj.resource?.name) {
                                const allRoles = obj.roles.map((item: any) => item.name);
                                allRoles.forEach((role: string) => {
                                    const [pl, rpn] = obj.resource.name.split(SEPERATOR);
                                    const plOrRPN = rpn ?? pl;
                                    if (!userPolicyData[role]) userPolicyData[role] = {};
                                    if (!userPolicyData[role][plOrRPN]) userPolicyData[role][plOrRPN] = [];
                                    if (!obj.resource.resources || obj.resource.resources?.length === 0) userPolicyData[role][plOrRPN].push(ALL_ASSIGNEES_KEY);
                                    obj.resource.resources?.forEach((tab: any) => userPolicyData[role][plOrRPN].push(tab.name));
                                });
                                
                            }
                        });
                        state.userPolicyData = userPolicyData;
                    }
                }
            )
            .addMatcher(
                plAPIs.endpoints.getProductLineDetails.matchFulfilled,
                (state, { payload }) => {
                    state.productLineAssignees = payload?.[USER_ASSIGNEES]?.[USER_ROLES.inputProvider] ?
                        ScreenUtils.formatUserAssigneeData(payload[USER_ASSIGNEES][USER_ROLES.inputProvider]) : {};
                }
            );
    }
});

export default userPolicySlice.reducer;

export const { resetUserPolicy, resetRoleResourceMap, resetPLAssignees, resetProgAssignees, updateProgAssignee, updatePLAssignee, updateRoleResourceMap, updateCurrUserPolicyLoading } = userPolicySlice.actions;

export const selectUserPolicy = (state: RootState) => state.userPolicy.userPolicyData;
export const selectRoleResourceMap = (state: RootState) => state.userPolicy.progRoleResourceMap;
export const selectProgAssignees = (state: RootState) => state.userPolicy.programAssignees;
export const selectPLAssignees = (state: RootState) => state.userPolicy.productLineAssignees;
export const selectCurrUserPolicyLoading = (state: RootState) => state.userPolicy.isCurrUserPolicyLoading;
