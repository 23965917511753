import React from 'react';
import {
    Cards,
    Link
} from "@cloudscape-design/components";
import { REPORTS_DATA } from "./reports";

const ReportCards = () => {
    return (
        <>
        <Cards
            cardDefinition={{
                header: item => (
                    <Link external href={item.link} target="_blank">
                      {item.title}
                    </Link>
                  ),
                sections: [
                    {
                        id: "description",
                        header: "Description",
                        content: item => item.description
                    },
                    {
                        id: "source",
                        header: "Source",
                        content: item => item.source
                    },
                ]
            }}
            cardsPerRow={[
                { cards: 3 },
                {minWidth: 500, cards: 3}
            ]}
            items={REPORTS_DATA}
        />
        </>
    );
};

export default ReportCards;