import { IResponsePayload } from '../interfaces/interfaces';
import { USER_ROLES as AGM_USER_ROLES, USER_ROLE_DESCRIPTIONS as AGM_USER_ROLE_DESCRIPTIONS } from '../../AGM/constants/constants';

export const ERROR_MESSAGE = "Oh no, Something went wrong!";

export const HELPER_SEPARATOR = '##';

export const SUBMIT_ALL_OPTION = 'All';

export const ALL_ASSIGNEES_KEY = '*';

export const PHONE_TOOL_URL = 'https://phonetool.amazon.com/users/';

export const DEFAULT_HEADER = 'Program Roadmap Manager';

export const USER_POLICY_PK = 'User';

export const SEPERATOR = '#';

export const USER_POLICY_SEPERATOR = '~';

export const VERITY_PL_LABEL = 'product_line';

export const NA_LABEL = 'N/A';

export const USER_ASSIGNEES = 'Assignees';

export const PERSON_TYPE = 'PERSON';

export const FPN_RULE_PROGRAM = 'Program';

export const UPDATED_FIELD = '**';

export const ATTR_NAME_REGEX = "^[0-9A-Za-z-_ ]+$";

export const ATTR_VALUE_REGEX = "^[0-9A-Za-z-_/ ]+$";

//Temporary until backend sends error code
export const API_CONDITION_FAILED = "ConditionalCheckFailed";

export const SUPPORT_SIM_URL = 'https://t.corp.amazon.com/create/templates/ce4bc0e2-64b9-4b5b-af44-0cabc730fce7';

export const SUPPORT_WIKI_URL = 'https://w.amazon.com/bin/view/DaS-FinTech/Products/Verity2/';

export const PRM_DASHBOARD_URL = 'https://skynet.db.amazon.com/#/workbooks/19816/views';

export const API_METHODS = {
    get: 'GET',
    post: 'POST'
};

export const METRICS_APP_NAME = 'PRM';

export const METRICS_PAGE_NAMES = {
    programSetup: 'PRMProgramHome',
    createProgram: 'CreateProgram'
}

export const ATTRIBUTE_TYPE_NAMES = {
    country: 'COUNTRY',
    channel: 'CHANNEL',
    countryChannel: 'COUNTRY_CHANNEL',
    skuTypes: 'SKU_TYPE',
    productType: 'PRODUCT_TYPE',
    version: 'VERSION',
    color: 'COLOR',
    row: 'ROW'
};

export const SAME_FOR_ALL_FPNS = [ATTRIBUTE_TYPE_NAMES.productType, ATTRIBUTE_TYPE_NAMES.version];

export const MUTATION_METHODS = {
    updateProgramSetup: 'update_prog_overview',
    updateProgramStage: 'update_program_stage',
    createProgram: 'create_program',
    createRPN: 'create_rpn',
    postFPN: 'post_fpn',
    updateRPN: 'update_rpn',
    saveApproveProgram: 'save_approve_program',
    createPLAttributes: 'create_attr_pl_type',
    updateUserAccess: 'add_user_access',
    updateAttrTypeRPN: 'post_attr_type_rpn',
    updateAttrListRPN: 'post_attr_list_rpn',
    updateRpnFpnRule: 'post_rpn_fpn_rule',
    updateAttrListFPN: 'post_attr_list_fpn',
    createSnapshot: 'take_prm_snapshot',
    refreshSnapshot: 'refresh_prm_snapshot',
    pplsCreate: 'ppls_create_program'
};

export const PPLS_PROGRAM_STATUS = {
    active: 'Active'
};

export const MUTATION_ACTION = {
    update: "UpdateItem",
    put: "PutItem",
    delete: "DeleteItem",
    addPolicy: "add",
    deletePolicy: "delete"
};

export const API_RESPONSE_FIELDS: Record<string, keyof IResponsePayload> = {
    isPartOfFPN: "IsPartOfFPN",
    isInheritedByFPN: "IsInheritedByFPN",
    setAsTBD: 'SetAsTBD',
    itemValues: "ItemValues"
};

export const STATUS_CODES = {
    success: 200,
    serverError: 500,
    handledException: 400
};

export const ALERT_MESSAGES = {
    updateSuccess: "Successfully updated data",
    programSuccess: "Successfully created new program",
    updateProgramSuccess: "Successfully updated program",
    updateFailure: "Update failed, please try again!",
    programFailure: "Program creation failed, please try again!",
    plAttrSuccess: "Successfully created product line attributes",
    fpnSuccess: "Successfully created FPNs",
    updateFPNFailure: "Successfully created FPNs; FPN update failed",
    incorrectVersionFailure: "Update failed: This page has been updated by some other user, please refresh.",
    urlRequired: "URL is required",
    linkNameRequired: "Link description is required",
    fpnListError: "Error fetching existing FPNs."
};

export const VALIDATION_MESSAGES = {
    rpnName: 'Underscores are not allowed',
    attributeTypeName: 'Only alphanumeric characters, spaces, hyphens and underscores are allowed',
    attributeValueName: 'Only alphanumeric characters, spaces, hyphens, underscores and backslashs are allowed',
    attributeTypeExists: 'Attribute already exists',
    attributeValueExists: 'Value already exists for ',
    unsavedAttributes: 'Attribute changes are not saved'
};

export const LOADING_APIS = {
    rpnAttrType: "rpnAttrType",
    rpnAttrList: "rpnAttrList"
};

export const SCREEN_PATHS = {
    basePath: '#/prm',
    productLineHome: 'product-line-home',
    programSetup: 'program-setup',
    createProgram: 'create-new-program',
    createSnapshot: 'create-snapshot',
    newAttribute: 'request-new-attribute',
    newAttributeValue: 'request-new-attribute-value',
    admin: 'admin',
    ganttChart: 'product-roadmap-chart'
};

export const URL_PARAMS = {
    productLine: 'productline',
    rpn: 'rpn',
    fpn: 'fpn'
};

export const HOME_PATH_HEADER = {
    'create-new-program': 'Create new program',
    'create-snapshot': 'Create snapshot',
    'request-new-attribute': 'Request new attribute',
    'request-new-attribute-value': 'Request new attribute value',
    'admin': 'Access Management',
    'product-roadmap-chart': 'Product Roadmap'
};

export const PRIMARY_KEY_MAPPING = {
    pl: "PL",
    rpn: 'RPN',
    fpn: 'FPN'
};

export const PL_SK_MAPPING = {
    plAttributes: 'Product line attributes'
};

export const SK_MAPPING = {
    overview: 'Program overview',
    contactInfo: 'Contact information',
    documents: 'Documents/Link to share',
    standardAttributes: 'Standard attributes',
    plAttributes: 'Product line attributes',
    programAttributes: 'Program attributes',
    streetDates: 'Street dates',
    alexaDates: 'Alexa enable dates',
    otherDates: 'Other key dates',
    marketInfo: 'Market information',
    financialInfo: 'Financial information',
    manufacturingInfo: 'Manufacturing information',
    specs: 'Specs'
};

export const SK_METADATA = 'METADATA';

export const TAB_ID_LABEL = {
    overview: 'Overview',
    attributes: 'Attributes',
    keyDates: 'Key Dates',
    financialInfo: 'Financial Info',
    manufacturingInfo: 'Manufacturing Info',
    marketInfo: 'Market Info',
    specs: 'Specs'
};
export const TAB_ID = {
    overview: 'overview',
    attributes: 'attributes',
    keyDates: 'keyDates',
    financialInfo: 'financialInfo',
    manufacturingInfo: 'manufacturingInfo',
    marketInfo: 'marketInfo',
    specs: 'specs'
};

export const ATTRIBUTES_ACTION = {
    up: 'up',
    down: 'down',
    remove: 'remove'
};

export const SKU_TYPE_MAP = {
    'Certified Refurb': 'RFB',
    'Refurb': 'RFB',
    'Warranty': 'RPL',
    'Demo': 'FL',
    'Fresh Lot': 'FL'
};

export const SKU_TYPES = {
    FL: ['Demo', 'Fresh Lot'],
    RFB: ['Certified Refurb', 'Refurb'],
    RPL: ['Warranty']
};

export const STAGES = {
    sandbox: 'Sanbox',
    submitted: 'Submitted',
    approved: 'Approved',
    rejected: 'Rejected'
};

export const STAGES_ACTION: Record<keyof typeof STAGES, string> = {
    sandbox: 'Sanbox',
    submitted: 'Submit',
    approved: 'Approve',
    rejected: 'Reject'
};

export const USER_ROLES = {
    plAdmin: 'PL Admin',
    asinOwner: AGM_USER_ROLES.asinOwner,
    agmTechAdmin: AGM_USER_ROLES.techAdmin,
    prmAdmin: 'PRM Admin',
    attrOwner: 'Attribute Owner',
    inputSubmitter: 'Input Submitter',
    inputProvider: 'Input Provider',
    readonly: 'Readonly'
};

export const USER_ROLE_DESCRIPTIONS = {
    plAdmin: 'PL level admin role to create new program, and edit, submit, approve',
    asinOwner: AGM_USER_ROLE_DESCRIPTIONS.asinOwner,
    agmTechAdmin: AGM_USER_ROLE_DESCRIPTIONS.techAdmin,
    prmAdmin: 'PRM level admin role to assign user access, create snapshot',
    attrOwner: 'Create new attributes and values',
    inputSubmitter: 'Program level role to submit changes in Sandbox for approval',
    inputProvider: 'Program level role to edit and save to Sandbox',
    readonly: 'Readonly access'
};

export const DISPLAY_ROLES = {
    plAdmin: 'PL Admin',
    inputSP: 'Input Submitter/Provider',
    inputProvider: 'Input Provider',
    inputSubmitter: 'Input Submitter',
    readonly: 'Readonly'
};

export const ROLE_DEFAULT_VIEW = {
    [USER_ROLES.plAdmin]: STAGES.sandbox,
    [USER_ROLES.inputSubmitter]: STAGES.sandbox,
    [USER_ROLES.inputProvider]: STAGES.sandbox,
    [USER_ROLES.readonly]: STAGES.sandbox
};

export const USER_ACTION_ROLES = {
    save: [USER_ROLES.plAdmin, USER_ROLES.inputProvider, USER_ROLES.inputSubmitter],
    submit: [USER_ROLES.inputSubmitter],
    approve: [USER_ROLES.plAdmin, USER_ROLES.asinOwner, USER_ROLES.agmTechAdmin],
    submitApprove : [USER_ROLES.plAdmin],
    reject: [USER_ROLES.plAdmin],
    assignUser: [USER_ROLES.plAdmin],
    adminPanel: [USER_ROLES.prmAdmin],
    attributeRequest: [USER_ROLES.attrOwner],
    snapshot: [USER_ROLES.prmAdmin],
    write: [USER_ROLES.plAdmin, USER_ROLES.inputProvider, USER_ROLES.inputSubmitter]
};

export const SELECT_OPTIONS = {
    yesNo: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" }
    ],
    status: [
        { value: "Active", label: 'Active'},
        { value: "Cancelled", label: 'Cancelled'},
        { value: "EOL", label: 'EOL'}
    ]
};

export const API_NAMES = {
    userPolicy: 'userPolicy',
    navBar: 'navBar'
};

export const API_STATUS = {
    success: 'success',
    error: 'error'
};
