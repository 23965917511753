import React, { ReactElement, createContext } from "react";
import projectsHelper from './projectsHelper';
import { IApiUpdateResponse } from '../Interfaces/interface';
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query";
interface IProjectsProviderProps {
    children: ReactElement | JSX.Element;
}

interface IProjectsContext {
    componentData?: [];
    framesProjData?: [];
    useProjectsData: () => UseQueryResult;
    useFramesProjData: () => UseQueryResult;
    useUpdatedProjectsData: () => UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
}
const qResp = {} as UseQueryResult;
const mResp = {} as UseMutationResult<IApiUpdateResponse, unknown, void, unknown>;
export const ProjectsContext = createContext<IProjectsContext>({
    useProjectsData: () => qResp,
    useFramesProjData: () => qResp,
    useUpdatedProjectsData: () => mResp
});

export const ProjectsContextProvider = ({ children }: IProjectsProviderProps): ReactElement => {
    const provider = {
        ...projectsHelper()
    };

    return (
        <ProjectsContext.Provider value={provider}>
            {children}
        </ProjectsContext.Provider>
    );
};
