import { useState } from "react";
import Endpoints from '../../constants/endpoints';
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import xhr from '../common/Auth/xhr';
import { IApiResponse, IApiUpdateResponse } from '../Interfaces/interface';
import Queries from '../queries/queries';

export default () => {

    const [componentData, setComponentData] = useState();
    const [ccCodesData, setCcCodesData] = useState();
    const [framesCCData, setFramesCCData] = useState();
    const queryClient = useQueryClient();

    const fetchCostCenter = async (reqData: any) => {
        const response = await xhr.post(Endpoints.getBaseUrl(), reqData);
        const { body }: IApiResponse = response.data.data.get_cc;
        return body;
    };
    const fetchFramesCC = async () => {
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getFramesCC());
        const { body }: IApiResponse = response.data.data.get_frms_cc;
        return body;
    };
    const updateCostCenter = async (payload: any) => {
        const graphQL_payload = JSON.stringify(payload).replace(/"([^(")"]+)":/g, "$1:");
        const response = await xhr.post(Endpoints.getBaseUrl(), Queries.getUpdateQuery(graphQL_payload, 'update_cc'));
        if(response.data.errors) return response.data as IApiUpdateResponse;
        return response.data.data.update_cc as IApiUpdateResponse;
    };

    const useCostCenterData = () => {
        return useQuery(
            ['costCenter'],
            () => fetchCostCenter(Queries.getCostCenterQuery()),
            {
                onSuccess: (data) => {
                    setComponentData(data);
                }
            }
        );
    };
    const useCostCenterCodesData = () => {
        return useQuery(
            ['costCenterCodes'],
            () => fetchCostCenter(Queries.getCCCodeQuery()),
            {
                onSuccess: (data) => {
                    setCcCodesData(data);
                }
            }
        );
    };
    const useFramesCCData = () => {
        return useQuery(
            ['costCenterCodes'],
            fetchFramesCC,
            {   
                retry: false,
                onSuccess: (data) => {
                    setFramesCCData(data);
                }
            }
        );
    };
    const useUpdatedCCData = () => {
        return useMutation(
            (requestData) => updateCostCenter(requestData),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(['costCenter']);
                }
            }
        );
    };
   
    return { componentData, useCostCenterData, useUpdatedCCData, useCostCenterCodesData, ccCodesData, useFramesCCData, framesCCData };
};


