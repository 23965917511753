import React from 'react';
import { FastField, Field, FieldProps } from 'formik';
import PolarisSelect, {
  SelectProps as PolarisSelectProps,
} from '@cloudscape-design/components/select';
import { FormikFieldProps } from '../../field-props';

type SelectProps = FormikFieldProps &
  Omit<PolarisSelectProps, 'selectedOption'>;

export const Select = ({
  name,
  validate,
  fast,
  onBlur,
  onChange,
  ...props
}: SelectProps) => {
  const DesiredField = fast === true ? FastField : Field;

  return (
    <DesiredField name={name} validate={validate} fast={fast}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => (
        <PolarisSelect
          name={name}
          selectedOption={value}
          onBlur={(event) => {
            setFieldTouched(name);
            onBlur?.(event);
          }}
          onChange={(event) => {
            setFieldValue(name, event.detail.selectedOption);
            onChange?.(event);
          }}
          {...props}
        />
      )}
    </DesiredField>
  );
};