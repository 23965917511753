import { MultiselectProps } from '@cloudscape-design/components';
import { createSlice } from '@reduxjs/toolkit';
import { IApiResponse } from 'src/components/Interfaces/interface';
import { RootState } from '../../../redux/store';
import { STATUS_CODES } from '../../constants/constants';
import { IApiFPNResponse, IApiProductHierarchy, IApiRPNResponse, IIdName, ILocationData, ICountry } from '../../interfaces/interfaces';
import { commonApi } from '../../../redux/services/commonApi';

type AppLayoutState = {
    productHierarchy: IApiProductHierarchy[];
    locationData: ILocationData;
    attributeTypeListMap: Record<string, string[]>;
    allProductLines: Record<string, string>,
    rpnToFPNMap: Record<string, IIdName[]>,
    allRPNs: Record<string, string>,
    programsWithFPN: string[],
    currentProgramSetupTab: string,
    currentProgramStatus: string,
    isDataLoaded: boolean,
    isLoading: boolean,
    isLocked: boolean,
    userDetailsMap: Record<string, MultiselectProps.Option>;
    countryMap: Record<string, string>;
};

const initialState: AppLayoutState = {
    productHierarchy: [],
    locationData: {
        currentScreen: '',
        productLine: { id: '', name: '' },
        program: { id: '', name: '' },
        fpn: { id: '', name: '' }
    },
    allProductLines: {},
    rpnToFPNMap: {},
    allRPNs: {},
    programsWithFPN: [],
    attributeTypeListMap: {},
    currentProgramSetupTab: 'overview',
    currentProgramStatus: 'Sandbox',
    isDataLoaded: false,
    isLoading: false,
    isLocked: false,
    userDetailsMap: {},
    countryMap: {}
};

export const appLayoutSlice = createSlice({
    name: 'appLayout',
    initialState,
    reducers: {
        resetProductHierarchy: (state) => {
            state.productHierarchy = [];
        },
        updateLocationData: (state, action) => {
            state.locationData = action.payload;
        },
        updateCurrentProgramSetupTab: (state, action) => {
            state.currentProgramSetupTab = action.payload;
        },
        updateIsDataLoaded: (state, action) => {
            state.isDataLoaded = action.payload;
        },
        updateCurrentProgramStatus: (state, action) => {
            state.currentProgramStatus = action.payload;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateIsLocked: (state, action) => {
            state.isLocked = action.payload;
        },
        updateUserDetailsMap: (state, action) => {
            state.userDetailsMap = { ...state.userDetailsMap, ...action.payload };
        },
        updateAttrTypeListMap: (state, action) => {
            state.attributeTypeListMap = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                commonApi.endpoints.getProductHierarchy.matchFulfilled,
                (state, { payload }) => {
                    if (payload?.data?.get_nav_bar) {
                        const { body, statusCode }: IApiResponse = payload.data.get_nav_bar;
                        if (statusCode === STATUS_CODES.success) {
                            const allProductLines: Record<string, string> = {};
                            const rpnToFPNMap: Record<string, IIdName[]> = {};
                            const allRPNs: Record<string, string> = {};
                            const programsWithFPN: string[] = [];
                            body.forEach((productLine: IApiProductHierarchy) => {
                                allProductLines[productLine.verity_product_line_id] = productLine.product_line;
                                productLine.rpn.forEach((rpn: IApiRPNResponse) => {
                                    allRPNs[rpn.verity_rpn_id] = rpn.rpn;
                                    if (rpn.fpn?.length > 0) programsWithFPN.push(rpn.verity_rpn_id.toString());
                                    rpn.fpn?.forEach((fpn: IApiFPNResponse) => {
                                        const fpnRecord = { id: fpn.verity_fpn_id.toString(), name: fpn.fpn };
                                        rpnToFPNMap[rpn.verity_rpn_id] ? rpnToFPNMap[rpn.verity_rpn_id].push(fpnRecord) : rpnToFPNMap[rpn.verity_rpn_id] = [fpnRecord];
                                    });
                                });
                            });
                            state.allProductLines = allProductLines;
                            state.rpnToFPNMap = rpnToFPNMap;
                            state.allRPNs = allRPNs;
                            state.programsWithFPN = programsWithFPN;
                            state.productHierarchy = body;
                        }
                    }
                }
            )
            .addMatcher(
                commonApi.endpoints.getCountryNames.matchFulfilled,
                (state, { payload }) => {
                    if (payload) {
                        const countryMap: Record<string, string> = {};
                        payload.forEach((obj: ICountry) => countryMap[obj.country_2_code] = obj.country);
                        state.countryMap = countryMap;
                    }
                }
            );
    }
});

export const { resetProductHierarchy, updateIsLoading,
    updateLocationData, updateCurrentProgramSetupTab, updateIsDataLoaded,
    updateCurrentProgramStatus, updateUserDetailsMap, updateAttrTypeListMap } = appLayoutSlice.actions;

export default appLayoutSlice.reducer;

export const selectProductHierarchy = (state: RootState) => state.appLayout.productHierarchy;
export const selectLocationData = (state: RootState) => state.appLayout.locationData;
export const selectAttributeTypeListMap = (state: RootState) => state.appLayout.attributeTypeListMap;
export const selectCurrentProgramSetupTab = (state: RootState) => state.appLayout.currentProgramSetupTab;
export const selectAllProductLines = (state: RootState) => state.appLayout.allProductLines;
export const selectRpnToFpnMap = (state: RootState) => state.appLayout.rpnToFPNMap;
export const selectAllRPNs = (state: RootState) => state.appLayout.allRPNs;
export const selectProgWithFPN = (state: RootState) => state.appLayout.programsWithFPN;
export const selectIsDataLoaded = (state: RootState) => state.appLayout.isDataLoaded;
export const selectIsLocked = (state: RootState) => state.appLayout.isLocked;
export const selectCurrentProgramStatus = (state: RootState) => state.appLayout.currentProgramStatus;
export const selectIsLoading = (state: RootState) => state.appLayout.isLoading;
export const selectUserDetailsMap = (state: RootState) => state.appLayout.userDetailsMap;
export const selectCountryMap = (state: RootState) => state.appLayout.countryMap;
