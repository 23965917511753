import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { Grid, Container } from "@amzn/awsui-components-react";
import { ProjectsContext } from '../components/context/projectsContext';
import { GlobalContext } from '../components/context/globalContext';
import { GridTable } from '../components/common/GridTable/GridTable';
import { IChangedCells, ICustomSettings } from '../components/common/GridTable/GridTableInterface';
import { ColDef, GridOptions } from "ag-grid-community";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { COL_DEFS, REQUIRED } from "../components/gridHelper/projectsHelper";
import { errorMessage, alertMessages } from 'src/constants/constants';
import { PageHeader } from '../components/common/Header/pageHeader';
import AlertMessageContext from '../components/common/Contexts/alertMessageContext';
import GridValidation from '../components/gridHelper/gridValidation';
import { IMutationResp, IValidationResponse } from "../components/Interfaces/interface";
import ScreenUtils from './Utils/ScreenUtils';
import { SearchEditor } from '../components/gridHelper/cellEditor/searchEditor';

export const Projects = forwardRef((props: any, ref: any) => {
    const projectsContext = useContext(ProjectsContext);
    const globalContext = useContext(GlobalContext);
    const { componentData, useProjectsData, useUpdatedProjectsData, useFramesProjData, framesProjData } = { ...projectsContext };
    const { useProductCodesData, productCodeData } = { ...globalContext };
    const updateMutation = useUpdatedProjectsData();
    const queryResult = useProjectsData();
    const prodQueryResult = useProductCodesData();
    const { setSuccess, setError } = useContext(AlertMessageContext);
    const framesResult = useFramesProjData();
    const [projectList, setProjectList] = useState<string[]>([]);
    const getCustomSettings = (): ICustomSettings => {
        return {
            className: 'ag-theme-balham',
            uid: "concat_string",
            readOnly: false,
            groupIncludeTotalFooter: true,
            allowLocalFilters: true,
            defaultAutoSizeAll: true,
            enableOnChangeHighlight: true,
            filterId: 'projects',
            allowAddRow: true,
            numAddRows: 1
        } as ICustomSettings;
    };
    const [customSettings] = useState<ICustomSettings>(getCustomSettings());
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: COL_DEFS,
        rowData: []
    });

    useEffect(() => {
        if (componentData && productCodeData) {
            // Variables to store dropdown data
            const projCodeList: string[] = [];
            const productList: string[] = [];
            const codeNamesMap = new Map();
            const projDropdown: string[] = [];
            componentData.forEach((obj: any) => {
                projCodeList.push(obj.project_code);
            });

            setProjectList(projCodeList);
            productCodeData.forEach((obj: any) => {
                productList.push(obj.product_code);
            });
            if (framesProjData) {
                framesProjData.forEach((obj: any) => {
                    if (!projCodeList.includes(obj.code_id)) projDropdown.push(obj.code_id);
                    codeNamesMap.set(obj.code_id, obj.code_name);
                });
            }
            COL_DEFS.forEach(function (colDef: ColDef) {
                colDef.floatingFilter = true;
                if (colDef.cellEditorParams) {
                    if ((colDef.field == 'device_product_code' || colDef.field == 'accessory_product_code')) colDef.cellEditorParams.values = productList;
                    if (colDef.field == 'pred_project_code') colDef.cellEditorParams.values = projCodeList;
                }
                if (framesProjData) {
                    if (colDef.field == 'project_code') {
                        colDef.cellEditor = SearchEditor;
                        colDef.cellEditorParams.options = projDropdown;
                        colDef.valueSetter = (params) => {
                            params.data.project_code = params.newValue;
                            if (codeNamesMap.has(params.newValue)) params.data.device_project_name = codeNamesMap.get(params.newValue);
                            return true;
                        };
                    }
                }
            });
            setGridOptions((prevState: any) => {
                return {
                    ...prevState,
                    columnDefs: COL_DEFS,
                    rowData: componentData
                };
            });
        }
    }, [componentData, productCodeData, framesProjData]);

    const onSave = async (event: Record<string, IChangedCells>) => {
        const requestData: Record<string, any> = {};
        const payload: any = [];
        if (event && Object.keys(event).length > 0) {
            const vResponse: IValidationResponse = GridValidation.validateRequired(event, REQUIRED);
            if (vResponse.error) { // Required fields validation
                setError?.(alertMessages.requiredMissing + vResponse.missingFields.join(', '));
            } else {
                Object.values(event).forEach((cell) => {
                    const cellId = cell.row.id ?? cell.row.rowId;
                    const userId = ScreenUtils.getUserId();
                    // Adding new rows
                    if (!cell.row.id && !Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                        requestData[cellId] = {
                            accessory_product_code: cell.row.accessory_product_code,
                            accessory_project_name: cell.row.accessory_project_name,
                            device_product_code: cell.row.device_product_code,
                            device_project_name: cell.row.device_project_name,
                            is_active: cell.row.is_active,
                            complexity_factor: cell.row.complexity_factor,
                            depreciation_months: cell.row.depreciation_months,
                            is_mfr: cell.row.is_mfr,
                            pred_project_code: cell.row.pred_project_code,
                            product_life_in_months: cell.row.product_life_in_months,
                            proj_hw_id: cell.row.proj_hw_id,
                            project_code: cell.row.project_code,
                            street_date: cell.row.street_date,
                            street_name: cell.row.street_name,
                            sub_device_project_name: cell.row.sub_device_project_name,
                            user_id: userId
                        };
                    }
                    // Updating rows
                    if (cell.row.id && cell.colId) {
                        if (!Object.prototype.hasOwnProperty.call(requestData, cellId)) {
                            requestData[cellId] = {
                                id: cellId,
                                project_code: cell.row.project_code,
                                user_id: userId
                            };
                        }
                        requestData[cellId][cell.colId] = cell.newValue;
                    }
                });
                Object.keys(requestData).forEach(cellId => {
                    payload.push(requestData[cellId]);
                });
                if (payload.length > 0) updateMutation.mutate(payload);
            }
        }
    };
    useImperativeHandle(ref, () => ({
        onSave(event: Record<string, IChangedCells>) {
            onSave(event);
        }
    }));

    useEffect(() => {
        if (updateMutation.data) {
            const resp: IMutationResp = ScreenUtils.getMutationMessage(updateMutation.data);
            resp.status === 'success' ? setSuccess?.(resp.message) : setError?.(resp.message);
        }
    }, [updateMutation.data]);

    useEffect(() => {
        if (framesResult.error) setError?.(alertMessages.framesFailure);
    }, [framesResult.error]);

    const loadGridTable = () => {
        if (gridOptions.rowData?.length) {
            return (
                <GridTable
                    gridSettings={gridOptions}
                    customSettings={{
                        ...customSettings
                    }}
                    onRefresh={queryResult.refetch}
                    onSave={onSave}
                />
            );
        } else {
            return "No data to display.";
        }
    };
    if (queryResult.isLoading || queryResult.isFetching || updateMutation.isLoading || prodQueryResult.isLoading || framesResult.isLoading) return <div className='loadspinner'><Spinner size="large" /></div>;

    if (queryResult.error || prodQueryResult.error || updateMutation.error) return (
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]}>
            <Container>
                {errorMessage}
            </Container>
        </Grid>
    );
    return (
        <>
            <PageHeader
                pageName='Project'
            />
            {loadGridTable()}
        </>
    );

});
