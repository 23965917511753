import { SelectProps } from "@amzn/awsui-components-react";

export default class ScreenUtils {

    public static readonly generateOptions = (values: any[]) => {
        const options: SelectProps.Option[] = [];
        values?.forEach((value: any) => {
            options.push({
                label: value,
                value: value
            });
        });
        return options;
    }
    
    public static readonly getUserId = () => sessionStorage.getItem('userName')?.split('_')[1] ?? '';

    public static readonly getGraphQLPayload = (data: any) => JSON.stringify(data).replace(/"([^(")"]+)":/g, "$1:");

}
